import { AfterViewInit, EventEmitter, Component, Input, OnInit, Output, Inject } from '@angular/core';
import { KeyPairsValue, ProfileDetailModel, RepliedEmailData } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { ActivityLogService } from '../../services/activity-log.service';
import { ProfileService } from 'src/app/modules/admin/profile-management/profile.service';
import { ActivityLogFilters, ListDataProfile, ListDataUser, ProfileLogDetail } from 'src/app/modules/admin/profile-management/profile-model';
import { ActivityLog } from 'src/app/modules/admin/campaign-management/campaign-tracking.model';
import { Task } from 'src/app/modules/admin/task-management/task-board/task-board-lane/task/task.model';
import { Helper } from '../../utility/Helper';
import { CampaignLinkscopeService } from 'src/app/modules/admin/profile-management/profile-detail/action-block/campaign-linkscope.service';
import { EmailCampaignTemplate } from 'src/app/modules/admin/profile-management/profile-detail/action-block/campaign-linkscope-email.model';
import { TaskBoardService } from 'src/app/modules/admin/task-management/task-board/task-board.service';
import { CreateCampaignService } from 'src/app/modules/admin/profile-management/create-campaign-model/create-campaign.service';
import { LinkScopeCampaignLst } from 'src/app/modules/admin/profile-management/create-campaign-model/create-campaign.model';
import { Priority } from 'src/app/modules/admin/task-management/task-board/task-board-lane/task-status.model';
import { ContactService } from '../../services/contact.service';
import { Observable, Subject, forkJoin } from 'rxjs';
import { ReturnResult } from '../../models/return-result';
import { map } from 'rxjs/operators';
import { ListDataWpDetail } from 'src/app/modules/admin/wp-homepage-management/wp-home-page.model';
import { ListDataContactType } from 'src/app/modules/admin/contact-type-management/contact-type.model';
import { EntityNameEnums } from '../../enums/entity-color.enums';
import { environment } from 'src/environments/environment';
import { permissionActivityLogsTab } from '../../contances/permission';
import { MatStartDate } from '@angular/material/datepicker';
import { AddEditTaskComponent } from 'src/app/modules/admin/task-management/add-edit-task/add-edit-task.component';
import { TblActionType } from '../../enums/tbl-action-type.enum';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConvertSaleLeadNotificationComponent } from 'src/app/modules/admin/sale-lead-management/convert-sale-lead/convert-sale-lead-notification/convert-sale-lead-notification.component';
import { ShowRepliedEmailComponent } from '../stand-alone-component/show-replied-email/show-replied-email.component';
import { SettingService } from '../../services/setting.service';
import { NbToastrService } from '@nebular/theme';
import { CRMTaskService } from 'src/app/modules/admin/opportunity-management/crm-opportunity-details/crm-task.service';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { CallflowEventDetailComponent } from 'src/app/modules/admin/dashboard-money-maker/contact-activity-table/callflow-event-detail/callflow-event-detail.component';
import { CallflowTranscriptionDetailComponent } from 'src/app/modules/admin/dashboard-money-maker/contact-activity-table/callflow-transcription-detail/callflow-transcription-detail.component';

@Component({
  selector: 'app-activity-timeline',
  templateUrl: './activity-timeline.component.html',
  styleUrls: ['./activity-timeline.component.scss']
})
export class ActivityTimelineComponent implements OnInit, AfterViewInit {

  @Input() id: string;
  @Input() model: ProfileDetailModel;
  @Input() maxHeight: '35vh';
  @Input() tooltipProp: KeyPairsValue[] = [];
  @Input() logData: DataLogs[] = [];
  @Input() currentPage: number;
  @Input() emailsCXInput: number;
  @Input() campaignsCXInput: number;
  @Input() logsInput: ActivityLog[] = [];
  @Input() tasksInput: Task[] = [];
  @Input() isOnlyDisplayProposal: boolean = false;
  @Output() afterTaskWasUpdated: EventEmitter<boolean> = new EventEmitter();

  isLoading: boolean = false;
  pageNumber: number = 0;
  filters: ActivityLogFilters = { key: 'communication', dateFilter: [], };
  logs: ActivityLog[] = [];
  tasks: Task[] = [];
  //Data activity log event show to UI
  dataLogs: DataLogs[] = [];
  //Email Template of Primas CX
  emailsCX: EmailCampaignTemplate;
  campaignsCX: LinkScopeCampaignLst;
  doneTaskId: number;
  infiniteScrollUpDistance: number = 1.5;
  scrollDistance: number = 1;
  throttle: number = 0;
  environment = environment;
  resourceTab = permissionActivityLogsTab;
  taskDialogInstance = null;
  loadingReplied = false;
  urlPreviewProposal: string = '';
  subjectProfile: string = "Artist";
  firstTimeLoad: boolean = true;
  enableCloseButton: boolean = false;

  private destroy$: Subject<void> = new Subject<void>();
  parentActId: string;
  user: any;

  constructor(
    private dialog: MatDialog,
    public dialModalRef: MatDialogRef<ActivityTimelineComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private profileService: ProfileService,
    private campaignService: CampaignLinkscopeService,
    private taskService: TaskBoardService,
    private createCampaignService: CreateCampaignService,
    private contactService: ContactService,
    private settingService: SettingService,
    private crmTaskService: CRMTaskService,
    private authService: NbAuthService,
  ) {
    this.id = data?.id || this.id;
    this.model = data?.model || this.model;
    this.filters = data?.filters || this.filters;
    this.maxHeight = data?.maxHeight || this.maxHeight;
    this.tooltipProp = data?.tooltipProp || this.tooltipProp;
    this.isOnlyDisplayProposal = data?.isOnlyDisplayProposal || this.isOnlyDisplayProposal;
    this.dataLogs = data?.logData || this.dataLogs;
    this.pageNumber = data?.currentPage || this.pageNumber;
    this.emailsCX = data?.emailsCXInput || this.emailsCX;
    this.campaignsCX = data?.campaignsCXInput || this.campaignsCX;
    this.tasks = data?.tasksInput || this.tasks;
    this.logs = data?.logsInput || this.logs;

    this.enableCloseButton = data?.enableCloseButton || this.enableCloseButton;
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.user = token.getPayload();
      }
    });
  }

  ngOnInit(): void {
    if (this.model?.actGroupId) {
      this.profileService.getMasterActGroupId(this.model?.actGroupId).subscribe(res => {
        if (res.result != null) {
          this.parentActId = res.result;
        }
      });
    }

    this.taskService.getTaskStatusDone()
      .subscribe(resp => this.doneTaskId = resp?.result);

    this.settingService.getSettingByKeyAndGroup('PREVIEW_PROPOSAL', 'SALE_CONFIG').subscribe(response => {
      if (response?.result) {
        if (response?.result?.value) {
          this.urlPreviewProposal = response.result.value;
        }
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.requestConfig();
  }

  async requestConfig() {
    this.isLoading = true;
    try {
      if (!this.emailsCX) {
        let resultEmails = await this.campaignService.getEmailTemplateCX().toPromise();
        this.emailsCX = this.emailsCX || resultEmails?.result;
      }
      if (!this.campaignsCX) {
        let resultCampaigns = await this.createCampaignService.RefreshGetLinkScopeCampaignList().toPromise();
        this.campaignsCX = this.campaignsCX || resultCampaigns?.result;
      }
      await this.refreshData();
    }
    catch (ex) {
      console.error(ex);
      this.isLoading = false;
    }
  }

  async refreshData() {
    if (this.firstTimeLoad) {
      this.firstTimeLoad = false;
      if (this.dataLogs.length != 0) {
        this.isLoading = false;
        return;
      }
    }

    this.isLoading = true;
    this.profileService.getActivityLogPaging(this.id, this.pageNumber, this.filters)
      .subscribe({
        next: resp => this.setupDataLog(resp.result || new ProfileLogDetail()),
        complete: () => {
          this.isLoading = false;
          setTimeout(() => {
            this.resizeImgFromAllActivity();
          }, 100)
        },
      });
  }

  mapActivityToData = (x: ActivityLog): DataLogs => Object.assign({}, { id: x.activityLogId.toString(), type: this.formatActivityType(x.activityType, x), data: x, dateTime: x.dateCreated } as DataLogs);
  mapTaskToData = (x: Task): DataLogs => Object.assign({}, { id: x.taskId.toString(), type: 'Task', data: x, dateTime: x.taskStartDate || x.dateCreated } as DataLogs);
  paginate = (pageNumber: number, size: number = 10): number => size * pageNumber;

  async setupDataLog(data: ProfileLogDetail) {
    try {
      if (data?.profileCampaignLogs?.length) {
        this.logs.push(...data.profileCampaignLogs);
        this.logs = [...Helper.uniqueObj(this.logs, 'activityLogId') as ActivityLog[]];
      }

      if (data?.taskLogs?.length) {
        this.tasks.push(...data.taskLogs);
        this.tasks = [...Helper.uniqueObj(this.tasks, 'taskId') as Task[]];
      }

      let allData: DataLogs[] = [];
      allData.push(...this.logs.map(this.mapActivityToData));
      allData.push(...this.tasks.map(this.mapTaskToData));
      allData.sort(function (a, b) {
        return new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime();
      });

      if (!this.dataLogs) this.dataLogs = [];
      let paging = [...allData.slice(
        this.paginate(this.pageNumber, data.systemSize),
        this.paginate(this.pageNumber + 1, data.systemSize)
      )];

      if (this.filters?.key == 'profile')
        paging = await this.lazyContentCommon(paging);

      this.dataLogs.push(...paging);
      this.dataLogs = [...new Set(this.dataLogs)];
    }
    catch (ex) {
      console.error(ex);
    }
  }

  resizeImgFromAllActivity() {
    //resize the img tag inside the activity log timeline content
    let listCard = document.querySelectorAll(".custom-card nb-card-body");
    listCard.forEach((card) => {
      let listImg = card.querySelectorAll("img");
      listImg.forEach((img) => {
        img.classList.add("mw-100");
      })
    })
  }

  async lazyContentCommon(data: DataLogs[] = []) {
    let result: DataLogs[] = data || [];
    try {
      let pushId = [];
      let ownerId = [];
      let wpId = [];
      let contactContactTypeId = [];
      if (data && data.length > 0) {
        for (let item of data) {
          try {
            if (item?.data?.hasOwnProperty('activityLogId')) {
              let log: ActivityLog = item?.data as ActivityLog;
              if (log?.action?.toLowerCase() == 'edit') {
                let id = log.activityLogId;
                if (log?.subObjName == 'wp_home_page' && (log?.subAction === 'Delete' || log?.subAction === 'Add'))
                  wpId.push({ activityLogId: id, typeId: log.subObjId });
                else if (log?.subObjName == 'contact_contact_type' && log?.subAction === 'Add' && log?.subObjId != null)
                  contactContactTypeId.push({ activityLogId: id, contactContactTypeId: log.subObjId });
                else if (log?.propertyName != null && log?.propertyName == 'ReferenceId' && log?.subObjName == 'sale_opportunity')
                  pushId.push({ activityLogId: id, logInfo1: log.logInfo1, logInfo2: log.logInfo2, });
                else if (log?.propertyName == 'PrimaryRelationship' || log?.propertyName == 'Resellers'
                  || log?.propertyName == 'PrimaryReseller' || log?.propertyName == 'Relationships')
                  pushId.push({ activityLogId: id, logInfo1: log.logInfo1, logInfo2: log.logInfo2 });
                else if (log?.propertyName == 'OwnerId')
                  ownerId.push({ activityLogId: id, logInfo1: log.logInfo1, logInfo2: log.logInfo2 });
              }
            }
          }
          catch (ex) {
            console.error(ex);
          }
        }

        let arrApi: Observable<ReturnResult<any>>[] = [];
        if (pushId?.length) arrApi.push(this.profileService.getListPropByListProfileId(pushId));
        if (ownerId?.length) arrApi.push(this.profileService.getListPropByListUserId(ownerId));
        if (wpId?.length) arrApi.push(this.profileService.getListPropByTypeId(wpId));
        if (contactContactTypeId?.length) arrApi.push(this.contactService.getListPropContactTypeById(contactContactTypeId));

        let respLazyContent = await forkJoin(arrApi)
          .pipe(map(x => x.map(y => y.result)))
          .toPromise();

        if (respLazyContent?.length) {
          let profileData = respLazyContent.find(x => x instanceof Array && x.length > 0 && x[0].hasOwnProperty('displayName1')) as ListDataProfile[];
          let userData = respLazyContent.find(x => x instanceof Array && x.length > 0 && x[0].hasOwnProperty('userName1')) as ListDataUser[];
          let wpData = respLazyContent.find(x => x instanceof Array && x.length > 0 && x[0].hasOwnProperty('typeId')) as ListDataWpDetail[];
          let contactData = respLazyContent.find(x => x instanceof Array && x.length > 0 && x[0].hasOwnProperty('contactContactTypeId')) as ListDataContactType[];

          result = this.descriptionCommon(profileData, result);
          result = this.descriptionOwnerId(userData, result);
          result = this.descriptionWpDetail(wpData, result);
          result = this.descriptionContactType(contactData, result);
          result = [...new Set(result)];
        }

      }
    } catch (ex) {
      console.error(ex);
    }

    return result;
  }

  descriptionCommon(resp: ListDataProfile[], dataLogs: DataLogs[] = []) {
    let result = dataLogs;
    try {
      if (resp != null && resp.length > 0) {
        let data: ListDataProfile[] = resp || [];
        if (data?.length > 0) {
          for (let item of data) {
            if (item) {
              result.filter(x => x.id == item.activityLogId.toString())
                .map(x => {
                  if (x?.data?.hasOwnProperty('activityLogId')) {
                    x.data = x.data as ActivityLog;
                    let user = x.data.userName || 'Unknown user';
                    x.description = `${user} has ${x.data?.subAction
                      ? x.data?.subAction.toLowerCase()
                      : x.data?.action?.toLowerCase()
                      } ${this.createSpaceProperty(x.data?.propertyName) || ''} from ${item?.displayName1 || 'unknown'} to ${item?.displayName2 || 'unknown'}`;
                  }
                  return x;
                });
            }
          }

          result = [...new Set(result)];
        }
      }
    }
    catch (ex) {
      console.error(ex);
    }

    return result;
  }

  descriptionOwnerId(resp: ListDataUser[], dataLogs: DataLogs[] = []) {
    let result = dataLogs;
    try {
      if (resp != null && resp.length > 0) {
        let data: ListDataUser[] = resp || [];
        for (let item of data) {
          if (item) {
            result.filter(x => x.id == item.activityLogId.toString())
              .map(x => {
                if (x?.data?.hasOwnProperty('activityLogId')) {
                  x.data = x.data as ActivityLog;
                  let user = x.data.userName || 'Unknown user';
                  x.description = `${user} has ${x.data?.subAction
                    ? x.data?.subAction.toLowerCase()
                    : x.data?.action?.toLowerCase()
                    } Owner from ${item?.userName1 || ''} (${item.name1 || ''}) to ${item?.userName2 || ''} (${item.name2 || ''})`;
                }
                return x;
              });
          }
        }

        result = [...new Set(result)];
      }
    }
    catch (ex) {
      console.error(ex);
    }

    return result;
  }

  descriptionWpDetail(resp: ListDataWpDetail[], dataLogs: DataLogs[] = []) {
    let result = dataLogs;
    try {
      if (resp != null && resp.length > 0) {
        let data: ListDataWpDetail[] = resp || [];
        for (let item of data) {
          if (item) {
            result.filter(x => x.id == item.activityLogId.toString())
              .map(x => {
                if (x?.data?.hasOwnProperty('activityLogId')) {
                  x.data = x.data as ActivityLog;
                  let user = x.data.userName || 'Unknown user';
                  if (x.data.subAction == 'Add')
                    x.description = `${user} has edit ${item.typeName.toLowerCase()} disable to enable`;
                  else
                    x.description = `${user} has edit ${item.typeName.toLowerCase()} enable to disable`;
                }

                return x;
              });
          }
        }

        result = [...new Set(result)];
      }
    }
    catch (ex) {
      console.error(ex);
    }

    return result;
  }

  descriptionContactType(resp: ListDataContactType[], dataLogs: DataLogs[] = []) {
    let result = dataLogs;
    try {
      if (resp != null && resp.length > 0) {
        let data: ListDataContactType[] = resp || [];
        for (let item of data) {
          if (item) {
            result.filter(x => x.id == item.activityLogId.toString())
              .map(x => {
                if (x?.data?.hasOwnProperty('activityLogId')) {
                  x.data = x.data as ActivityLog;
                  let user = x.data.userName || 'Unknown user';
                  x.description = `${user} has ${x.data.action.toLowerCase()} ${this.getEntity().toLowerCase()} type to ${this.displayMessageContactType(item.contactTypeName, item.contactTypeColorCode)}`;
                }
                return x;
              });

          }
        }

        result = [...new Set(result)];
      }
    }
    catch (ex) {
      console.error(ex);
    }

    return result;
  }

  displayMessageContactType(message: string, colorCode: string) {
    return `<span style="color:${colorCode};">${message}</span>`
  }

  formatActivityType(value: string, i: ActivityLog) {
    let result: string = value;
    try {
      switch (value.toLowerCase()) {
        case 'external':
        case 'externaladdins': {
          if (i.subObjName?.toLowerCase() == "externalemail") result = 'email';
          else if (i.subObjName?.toLowerCase() == "externalcall") result = 'pcall';
          else if (i.subObjName?.toLowerCase() == "externalother") result = 'other';
          else if (i.activityType?.toLowerCase() == "sendsms") result = 'smshistory';
          else if (i.activityType?.toLowerCase() == "outreach") result = 'outreach';
          else if (i.activityType?.toLowerCase() == "cfcall") result = "cfcall";
          break;
        }
        default:
          break;
      }
      result = result.toLowerCase();
    }
    catch (ex) {
      console.error(ex)
    }
    return result?.toLowerCase();
  }

  clickMore() {
    this.pageNumber += 1;
    this.refreshData();
  }

  clickRefresh(isFilter: boolean = false) {
    this.pageNumber = 0;
    this.logs = [];
    this.tasks = [];
    this.dataLogs = [];
    if (!isFilter)
      this.filters.dateFilter = [];
    this.refreshData();
  }

  getTaskPriorityImg(priority: Priority): string {
    if (priority) {
      return `assets/images/priority/${priority.priorityId.toString()}.svg`;
    }
  }

  onScroll() {
    if (!this.isLoading) {
      this.isLoading = true;
      this.pageNumber += 1;
      this.refreshData();
    }
  }

  createSpaceProperty(propertyName: string) {
    if (propertyName) {
      return Helper.createSpaceString(propertyName);
    }
  }

  getEntity() {
    if (this.model) {
      switch (this.model.typeName) {
        case EntityNameEnums.SaleLead:
          return environment.titleLead;
        case EntityNameEnums.SaleAccount:
          return environment.titleAccount;
        case EntityNameEnums.Opportunity:
          return environment.titleOpportunity;
        default:
          return 'Profile';
      }
    }
  }

  openTask(task: Task) {
    if (task) {
      if (!this.crmTaskService.isOpenTask) {
        this.crmTaskService.isOpenTask = true;
        this.crmTaskService.dialogTaskRef = this.dialog.open(AddEditTaskComponent, {
          disableClose: true,
          height: '65vh',
          width: '600px',
          panelClass: 'dialog-detail',
          autoFocus: false,
          hasBackdrop: false,
          data: {
            type: 'customType',
            action: TblActionType.Edit,
            profile: this.model,
            model: task,
            position: { left: '0', bottom: '0' },
          }
        });
        this.crmTaskService.dialogTaskRef.afterClosed().subscribe(response => {
          if (response) {
            this.clickRefresh();
            this.afterTaskWasUpdated.emit(true);
          }
          this.crmTaskService.isOpenTask = false;
        });
      }
      else {
        if (this.crmTaskService.dialogTaskRef.componentInstance.isMinimize)
          this.crmTaskService.dialogTaskRef.componentInstance.minimizeDialog();
      }
    }
  }

  onClickExport() {
    this.profileService.exportActivityLog(this.id, this.filters).subscribe(resp => {
      if (resp.result) Helper.downloadFile(resp.result);
    })
  }

  dateChange(data: any) {
    if (data.target instanceof MatStartDate)
      this.filters.dateFilter = [this.filters.dateFilter[0]]

    if (this.filters?.dateFilter?.length > 1 && !this.filters.dateFilter.some(e => e == null))
      this.clickRefresh(true);
  }

  clickZoomIn() {
    const zoomDialog = this.dialog.open(ActivityTimelineComponent, {
      width: '50vw',
      data: {
        id: this.id,
        model: this.model,
        maxHeight: '60vh',
        filters: this.filters,
        tooltipProp: this.tooltipProp,
        logData: this.dataLogs,
        currentPage: this.pageNumber,
        isOnlyDisplayProposal: this.isOnlyDisplayProposal,
        emailsCXInput: this.emailsCX,
        campaignsCXInput: this.campaignsCX,
        logsInput: this.logs,
        tasksInput: this.tasks,
        enableCloseButton: true,
      }
    });
  }

  emailClicked(activityLog: ActivityLog) {
    let action = activityLog.action.toLowerCase();
    if (action == "submit" || action == "click") {
      return this.redirectLink(activityLog);
    }
    else if (action == "convert") {
      return this.showConvertResult(activityLog);
    }
    else if (action == "reply" || action == "replydetail" || action.includes("send")) {
      if (activityLog.subObjName.toLowerCase() == "externalemail")
        return;

      if (activityLog.subAction.toLowerCase() == "addins")
        return;

      var emailSend = {} as RepliedEmailData;
      let isEmailInput: boolean = action.includes('send');
      if (isEmailInput) emailSend = this.readEmailDetail(activityLog);
      this.showRepliedEmail(activityLog.activityLogId, action, isEmailInput ? emailSend : null);
    }
  }

  defaultClicked(activityLog: ActivityLog) {
    if (activityLog && activityLog.activityType?.toLowerCase() === 'proposal' && activityLog?.action.toLowerCase() === 'view') {
      if (activityLog?.objId) window.open(`${this.urlPreviewProposal}/${activityLog?.objId}.html`, "_blank")
    }
  }

  redirectLink(activityLog: ActivityLog) {
    if (activityLog && this.campaignsCX) {
      var strLogInfo2 = activityLog.logInfo2.split(";");
      var referenceLink = strLogInfo2[1];
      if (referenceLink && referenceLink != '') {
        window.open(referenceLink, "_blank");
      }
    }
  }

  showConvertResult(activityLog: ActivityLog) {
    let action = activityLog.action.toLowerCase();
    if (activityLog.logInfo1 && !isNaN(+activityLog.subObjId) && action == "convert") {
      let historyConvertId = parseInt(activityLog.subObjId);
      let dialogResult = this.dialog.open(ConvertSaleLeadNotificationComponent, {
        width: '80vw',
        data: {
          convertHistoryId: historyConvertId,
          showMoreInformation: true,
          saleLeadName: !activityLog.propertyName ? activityLog.logInfo2 : null,
          saleLeadId: !activityLog.propertyName ? activityLog.logInfo1 : null
        }
      });
    }
  }

  readEmailDetail(log: ActivityLog) {
    var emailSend = {} as RepliedEmailData
    try {
      var logInfo1 = log?.logInfo1;
      emailSend.ativityId = log.activityLogId;
      emailSend.timestamp = log.dateCreated.toString();

      if (!emailSend.title && !emailSend.body) {
        emailSend.body = log?.logInfo2;
        emailSend.title = logInfo1.split(';')[1] ?? 'Unknown';
        if (emailSend.title == "Unknown") {
          var emailTemplate = this.emailsCX.data.find(x => x.id == Number.parseInt(log?.subObjId));
          emailSend.body = emailTemplate.body;
          emailSend.title = emailTemplate.title;
        }
      }
    } catch (ex) {
      console.error(ex);
    }
    return emailSend;
  }

  showRepliedEmail(activityLogid: number, action: string, emailSend?: RepliedEmailData) {
    this.loadingReplied = true;
    this.dialog.open(ShowRepliedEmailComponent, {
      disableClose: true,
      height: 'auto',
      minWidth: '30vw',
      maxWidth: '50vw',
      data: {
        activityLogId: activityLogid,
        profileModel: this.model,
        action: action == 'replydetail' ? 'reply' : action,
        emailSend: emailSend,
        isGetInboxed: action == 'replydetail' ? true : false
      }
    });
  }

  viewPhoneCallDetail(contactActionLog: ActivityLog) {
    try {
      this.dialog.open(CallflowEventDetailComponent, {
        disableClose: true,
        autoFocus: false,
        width: '500px',
        panelClass: 'confirm-crm',
        data: {
          contactActionLog: contactActionLog
        }
      });
    }
    catch (ex) {
      console.error(ex);
    }
  }

  viewTranscription(event: any, jsonData: any) {
    event.stopPropagation();
    this.dialog.open(CallflowTranscriptionDetailComponent, {
      width: '550px',
      data: {
        transcription: jsonData?.transcription,
        summaryText: jsonData?.summaryText,
        callerA: jsonData?.outboundCallerId,
        callerB: jsonData?.outboundPhoneNumber,
        referenceLogId: jsonData?.referenceLogId
      }
    })
  }
}

export class DataLogs {
  id: string;
  type: string;
  data: ActivityLog | Task;
  dateTime: Date;
  description: string;
}

