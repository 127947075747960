<div class="header-row" [ngClass]="(isCustomer) ? 'py-2': ''">

  <div class="row-1" appDetectTabMode typeElement="DetectHeader" *ngIf="isSwitchUserMode">
    <div class="alert-user-message">
      You're log-in as {{loginAsInfo}}, go back to <a class="pointer" href="#" (click)="backToOrgUser()">{{orgInfo}}</a>
    </div>
  </div>
  <div class="row-1-noti" appDetectTabMode typeElement="DetectHeader" *ngIf="systemNotification">
    <div class="alert-testing-message">
      {{systemNotification}}
    </div>
  </div>

  <!--Notification change state callback-->
  <div class="noti-state-phone" style="--background-state-phone: green;--color-text: white;"
    *ngIf="isNotiStatePhoneCall && isStatusCalling" [@openClose]="isNotiStatePhoneCall ? 'open' : 'closed'">
    YOU'RE READY TO RECEIVE THE CALLBACK
  </div>
  <div class="noti-state-phone" style="--background-state-phone: #fe8a05;--color-text: white;"
    *ngIf="isNotiStatePhoneCall && !isStatusCalling" [@openClose]="isNotiStatePhoneCall ? 'open' : 'closed'">
    YOU'RE NOT READY TO RECEIVE THE CALLBACK
  </div>

  <!--Notification change state chat-->
  <div class="noti-state-phone" style="--background-state-phone: green;--color-text: white;"
    *ngIf="isNotiStateChat && connectLSWChat" [@openClose]="isNotiStateChat ? 'open' : 'closed'">
    YOU'RE READY TO USE CHAT FUNCTIONALITY
  </div>
  <div class="noti-state-phone" style="--background-state-phone: #fe8a05;--color-text: white;"
    *ngIf="isNotiStateChat && !connectLSWChat" [@openClose]="isNotiStateChat ? 'open' : 'closed'">
    YOU'RE NOT READY TO USE CHAT FUNCTIONALITY
  </div>


  <div class="row-2">
    <div class="header-container" [ngClass]="isEmployee ? '' : 'header-nav'">
      <ng-container *ngIf="!tabMode">
        <div class="logo-container">
          <a *ngIf="!isEmployee" (click)="toggleSidebar()" href="#" class="sidebar-toggle">
            <nb-icon icon="menu-2-outline" style="color: white;"></nb-icon>
          </a>
          <a class="logo" href="#" (click)="navigateHome()" style="border-left: unset;">
            <img [src]="urlCompanyLogo | safeIframe" style="height: 40px; width: auto" class="img-fluid"
              alt="Responsive image" (error)="urlCompanyLogo ='../../../../assets/images/logo-altus-full.png'">
          </a>
        </div>
      </ng-container>
      <!-- tab mode: -->
      <ng-container *ngIf="tabMode">
        <ng-content select="[headerNav]"></ng-content>
      </ng-container>
    </div>

    <div class="header-container">
      <!-- <button mat-icon-button matBadge="8" matBadgeColor="accent" [matMenuTriggerFor]="menu">
        <mat-icon style="color: #ffffff;">notifications</mat-icon>
      </button> -->
      <nb-actions *ngIf="!isEmployee; else actionEmployeeMode" size="small">
        <!-- LINK_SCOPE_WEB_CHAT-->
        <nb-action *ngIf="isEnableChat" style="border-left: 0px !important">
          <mat-slide-toggle [nbSpinner]="connectLSWChat == null" [disabled]="connectLSWChat == null"
            [checked]="connectLSWChat" color="primary" style="margin-right: 5px;" (change)="switchLSWChat($event)">
          </mat-slide-toggle>
          <mat-icon (click)="openExtension()" [nbSpinner]="connectLSWChat == null" style="cursor: pointer;"
            [style.color]="connectLSWChat ? '#00d68f': '#f44336'" [matTooltip]="connectLSWChat ? 'Ready': 'Not ready'">
            {{ connectLSWChat ? 'mark_chat_read' : 'feedback'}}
          </mat-icon>
        </nb-action>

        <!-- change log btn -->
        <nb-action *ngIf="isEnableChangeStatePhoneCall" style="border-left: 0px !important">
          <mat-slide-toggle [nbSpinner]="isStatusCalling == null" [disabled]="isStatusCalling == null"
            [checked]="isStatusCalling" color="primary" style="margin-right: 5px;" (change)="updateStatusCall($event)">
          </mat-slide-toggle>
          <mat-icon [nbSpinner]="isStatusCalling == null" [style.color]="isStatusCalling ? '#00d68f': '#f44336'"
            [matTooltip]="isStatusCalling ? 'Ready': 'Not ready'">
            {{ isStatusCalling ? 'wifi_calling_3' : 'phone_locked'}}
          </mat-icon>
        </nb-action>
        <nb-action class="chat-icon" style="border-left: 0px !important"
          *nbIsGranted="['view','header-message-button']">
          <button mat-icon-button (click)="handleChatIconClick()" style="color: white" matTooltip="Chat">
            <mat-icon>chat</mat-icon>
            <div *ngIf="isChatReceiveNewMessage" class="chat-blink-icon"></div>
          </button>
        </nb-action>

        <nb-action style="border-left: 0px !important" class="notifications-icon">

          <button mat-icon-button (click)="getListNotifications()" style="color: white" matTooltip="Notifications"
            [matMenuTriggerFor]="realtimeNotification" #matMenuTrigger="matMenuTrigger" (menuClosed)="menuClosed()">
            <mat-icon>notifications</mat-icon>
            <ng-container *ngIf="numbersOfNotification != 0">
              <div class="icon-notification">
                <p class="numbers-of-notification">{{numbersOfNotification}}</p>
              </div>
            </ng-container>
          </button>
        </nb-action>

        <nb-action style="border-left: 0px !important">
          <button *ngIf="showSupportTicket === 'true' || showSupportTicket == '1'" mat-icon-button
            (click)="onClickSupportAgentTicket()" style="color: white" matTooltip="Support ticket management">
            <mat-icon>support_agent</mat-icon>
          </button>
        </nb-action>
        <nb-action style="border-left: 0px !important" [nbSpinner]="changelogLoading"
          [badgeDot]="changelogs.length > 0? true: false" badgeStatus="danger" icon="star-outline"
          matTooltip="Changelogs" (click)="showchangelogsDialog()">
        </nb-action>

        <!-- <nb-action style="border-left: 0px !important"
          *ngIf="tasksNotification && tasksNotification.length > 0;else NoNotifications" icon="bell-outline"
          [badgeDot]="showDotNotification(tasksNotification)" badgeStatus="warning" [matMenuTriggerFor]="menu"
          [matMenuTriggerData]="{notifies: notificationMenu}">
        </nb-action> -->
        <nb-action class="user-action">
          <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.userName"
            [picture]="currentPicture||picturelUrlSafe">
          </nb-user>
          <nb-action [badgeDot]="true" [badgeStatus]="statusBadge.className" [badgePosition]="statusBadge.position"
            style="position:absolute;bottom:0;left:20px">
          </nb-action>
        </nb-action>
      </nb-actions>
    </div>
  </div>
</div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent style="max-width: auto;" let-notifies="notifies"
    *ngIf=" tasksNotification && tasksNotification.length > 0">
    <div *ngFor="let noti of notifies | slice:0:maxNoti" style="margin: 1px">
      <button (click)="openTask(noti.task, noti)" *ngIf="noti.isSeen != true; else read"
        class="header-hover dropdown-item notification-size notification-word-display"
        style="background-color: rgb(214, 237, 252);">
        <span style="font-size: 12px;">{{noti.dateCreated | date: 'short'}}</span>
        <br>
        <span><b>{{noti.firstPart}}</b></span>
        <span>{{noti.connector}}</span>
        <b>{{noti.secondPart}} </b>
        <br>
        <span><b>{{noti.thirdPart|| ''}}</b></span>
        <span>{{noti.fourthPart|| ''}}</span>
        <br>
        <span><b>{{noti.fifthPart|| ''}}</b></span>
        <span>{{noti.sixthPart|| ''}}</span>
      </button>
      <ng-template #read>
        <button (click)="openTask(noti.task, noti)"
          class="header-hover dropdown-item notification-size notification-word-display" #read>
          <span style="font-size: 12px;">{{noti.dateCreated | date: 'short'}}</span>
          <br>
          <span><b>{{noti.firstPart}}</b></span>
          <span>{{noti.connector}}</span>
          <b>{{noti.secondPart}} </b>
          <br>
          <span><b>{{noti.thirdPart|| ''}}</b></span>
          <span>{{noti.fourthPart|| ''}}</span>
          <br>
          <span><b>{{noti.fifthPart|| ''}}</b></span>
          <span>{{noti.sixthPart|| ''}}</span>
        </button>
      </ng-template>
    </div>
    <button mat-button class="header-hover dropdown-item" (click)="seeMore($event)"
      *ngIf="maxNoti < tasksNotification.length" style="text-align:center; ">
      <b>Show more notifications </b>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>

  </ng-template>

  <ng-template matMenuContent style="max-width: auto;" let-notifies="notifies"
    *ngIf=" tasksNotification && tasksNotification.length == 0">
    <div class="container">
      <app-blank-page [message]="'No Notification available'"></app-blank-page>
    </div>
  </ng-template>

</mat-menu>

<ng-template #NoNotifications>
  <nb-action style="border-left: 0px !important" icon="bell-outline" [matMenuTriggerFor]="menu"
    [matMenuTriggerData]="{notifies: notificationMenu}">
  </nb-action>
</ng-template>
<!--       [nbContextMenu]="notificationMenu" nbContextMenuAdjustment="clockwise" -->

<mat-menu #realtimeNotification="matMenu">
  <div *ngIf="!skeletonLoading; else loadingNotification">
    <div matMenuContent style='width: 20vw'>
      <div style="width: 20vw; max-width: 20vw; max-height: 50vh !important; overflow-y: scroll;" infiniteScroll
        [infiniteScrollDistance]="scrollDistance" [infiniteScrollUpDistance]="infiniteScrollUpDistance"
        [infiniteScrollThrottle]="throttle" [scrollWindow]="false" (scrolled)="onScroll()" [immediateCheck]="true"
        [alwaysCallback]="true">
        <!-- handle display read and unread -->
        <div class="navbar-notification">
          <div class="option-notification">
            <button mat-button [ngClass]="!isAllNotification ? 'button-option-notification': ''"
            (click)="$event.stopPropagation();changeNotiToUnread()">Unread</button>
            <button mat-button [ngClass]="isAllNotification ? 'button-option-notification': ''"
              (click)="$event.stopPropagation();changeNotiToAll()">All</button>
          </div>
          <mat-hint class="text-mark-all-unread" (click)="$event.stopPropagation();UpdateMarkAllAsRead()"><mat-icon
              class="icon-mark-done">done_all</mat-icon>All as read</mat-hint>

        </div>

        <div *ngFor="let noti of realtimeNotifications; let i = index" style="margin: 1px" class="cheat_mode">
          <ng-container [ngTemplateOutlet]='realtimeNotificationDetail' [ngTemplateOutletContext]="{noti:noti, i:i}">
          </ng-container>
        </div>
        <div *ngIf="!loading; else loadingNotificationEnd"></div>
      </div>
    </div>

    <div matMenuContent *ngIf="realtimeNotifications.length === 0">
      <div class="container" style="max-width: 22vw; max-height: 50vh !important;">
        <app-blank-page class="no-notification" messageSize='0.875rem'
          [message]="isAllNotification ? 'No Notification Available': 'No unread notifications'"></app-blank-page>
      </div>
    </div>
  </div>
</mat-menu>

<ng-template #realtimeNotificationDetail let-noti="noti" let-i='i'>
  <div class="realtime-notification noti-{{noti.type}}" id="noti-{{noti.realtimeNotificationId}}"
    (click)="callReference(noti, $event)" [ngStyle]="{'background-color': !noti.isClicked? '#e6f2ff':'#fff'}">
    <div class="col col-1 icon-space">
      <div class="row" style="justify-content: flex-end; margin-top: 5px" [ngSwitch]="noti.type">
        <nb-icon *ngSwitchCase="'success'" icon="checkmark-square-2" status="success"></nb-icon>
        <nb-icon *ngSwitchCase="'warning'" icon="alert-triangle" status="warning"></nb-icon>
        <nb-icon *ngSwitchCase="'error'" icon="close-square" status="danger"></nb-icon>
      </div>
    </div>
    <div class="col col-11" style="padding: 0px 25px 0px 5px;">
      <div style="margin-top: 5px;">
        <span class="limit-line" style="--line: 1;font-size: smaller;"><b>{{noti.title}}</b></span>
      </div>
      <div class="wrap" style="display: flex">
        <input type="checkbox" id="checkbox{{i}}" class="checkbox" style="display: none">
        <div #elRefNotifications class="limit-line notification-body">
          <label class="btn" for="checkbox{{i}}" (click)="$event.stopImmediatePropagation(); clickExpandBtn(i, $event);"
            style="display: none;font-size: x-small;">
            {{realtimeNotificationExpandBtn[i]}}
          </label>
          {{noti.body}}
        </div>
      </div>
      <div class="notification-timer">
        <span style="font-size: 12px;">{{noti.dateCreated | date: 'MM/dd/yyyy - hh:mm:ss'}}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loadingNotification>
  <div style="width: 20vw">
    <ngx-skeleton-loader count="10" appearance="line"></ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #loadingNotificationEnd>
  <ngx-skeleton-loader count="2" appearance="line"></ngx-skeleton-loader>
</ng-template>

<ng-template #messageToast let-toastRef>
  <div style="min-width: 290px;">
    <div class="d-flex align-items-center">
      <nb-user [onlyPicture]="!toastRef.data.pictureURL" [name]="toastRef.data.userName"
        [picture]="toastRef.data.pictureURL">
      </nb-user>
      <span *ngIf="!toastRef.data.pictureURL" class="limit-line"
        style="--line: 2;"><strong>{{toastRef.data.userName}}</strong></span>
    </div>
    <div class="limit-line mt-2" style="--line: 5;">
      {{toastRef.data.message}}
    </div>
  </div>
</ng-template>

<ng-template #actionEmployeeMode>
  <nb-actions size="small">
    <nb-action class="user-action">
      <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.userName"
        [picture]="currentPicture||picturelUrlSafe">
      </nb-user>
      <nb-action [badgeDot]="true" [badgeStatus]="statusBadge.className" [badgePosition]="statusBadge.position"
        style="position:absolute;bottom:0;left:20px">
      </nb-action>
    </nb-action>
  </nb-actions>
</ng-template>