import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'seoColorScore'
})
export class SeoColorScorePipe implements PipeTransform {

  transform(value: string, maxScore: string): string {
    let result: string = 'text-unknown';
    try {
      if ((value != null && value != undefined) && +value >= 0 && +maxScore) {
        let checkValue = ((+value) / (+maxScore)) * 100;
        if (+checkValue >= 75) result = 'text-success';
        if (+checkValue < 75 && +checkValue >= 40) result = 'text-warning';
        if (+checkValue < 40) result = 'text-danger';
      }
    }
    catch (ex) {
      console.log(ex);
    }

    return result;
  }

}
