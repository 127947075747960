import { PrimasDropdownFilterOperator } from 'src/app/shared/enums/primas-dropdown-filter-operator';
import { PrimasDatetimeFilterOperator } from './../enums/primas-datetime-filter-operator';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as FileSaver from 'file-saver';
import { DataField } from 'src/app/modules/admin/data-field-management/data-field-model';
import { Contact, KeyPairsValue, ProfileContact } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { PrimasAllFilterOperator } from '../enums/primas-all-filter-operator';
import { PrimasNumberFilterOperator } from '../enums/primas-number-filter-operator';
import { PrimasTextFilterOperator } from '../enums/primas-text-filter-operator';
import { PrimasFilterType } from '../enums/primas-value-type.enum';
import { SortOrderType } from '../enums/sort-order-type.enum';
import { OrderMapping } from '../models/order-mapping';
import { FilterMapping } from '../models/paging/filter-mapping';
import { BuyerContacts } from '../models/buyer.model';
import { ObjectValue } from '../components/stand-alone-component/inline-edit-object/inline-edit-object.component';
import * as fileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Router, Routes } from '@angular/router';
import { TaskFilterProp } from 'src/app/modules/admin/task-management/task-board/task-board-lane/task/task.model';
import { ElementRef } from '@angular/core';
import { PrimasMailActionFilterOperator } from '../enums/primas-email-action-filter-operator';
import { ColumnMapping } from '../models/column-mapping';
import * as _moment from 'moment-timezone';
import { NAVIGATION_STACK, UserExperienceTrackingService } from '../components/user-experience-tracking/user-experience-tracking.service';
import { ContactActionLog } from 'src/app/modules/admin/dashboard-money-maker/contact-activity-table/contact-activity.model';

const moment = _moment;
export class Helper {
    static configurationUrlSuffixRegex = /(configuration\/)\w+(-?\w+)/;
    static _memoryPath: string[] = [];
    static downloadFile(data) {
        let byteCharacters = atob(data.fileContents);
        let byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            let slice = byteCharacters.slice(offset, offset + 512);

            let byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const file = new Blob(byteArrays, { type: data.contentType });
        FileSaver.saveAs(file, data.fileDownloadName);
    }
    static formatFilter(filter: FilterMapping[], prop, value, type: PrimasFilterType = PrimasFilterType.Text, operator, mappingFilter: ColumnMapping | FilterMapping = null): FilterMapping[] {
        if (filter == null) {
            filter = [];
        }
        if (operator == undefined) {
            switch (type) {
                case PrimasFilterType.DateTime:
                case PrimasFilterType.Date: operator = PrimasDatetimeFilterOperator.IsBefore
                case PrimasFilterType.Number: operator = PrimasNumberFilterOperator.IsEqualTo
                    break;
                default: operator = PrimasTextFilterOperator.Contains
                    break;
            }
        }
        let dynamicProperty = mappingFilter?.dynamicProperty ?? null;
        let delimiter = mappingFilter?.delimiter ?? null;

        let exist = filter.find(x => x.prop == prop);
        if (exist != null) {
            if ((value == null || value?.length <= 0) && (operator != PrimasAllFilterOperator.IsEmpty
                && operator != PrimasAllFilterOperator.IsNotEmpty) && operator != PrimasMailActionFilterOperator.NoEmailActions) {
                filter = filter.filter(x => x.prop != prop);
            } else {
                exist.value = value;
                exist.filterOperator = this.splitSpaceString(operator);
                exist.dynamicProperty = dynamicProperty ?? undefined;
                exist.delimiter = delimiter ?? undefined;
            }
        } else {
            switch (type) {
                case PrimasFilterType.Text:
                case PrimasFilterType.DropDown:
                case PrimasFilterType.DropDownList:
                case PrimasFilterType.DynamicContent:
                case PrimasFilterType.Number:
                case PrimasFilterType.Boolean:
                case PrimasFilterType.JsonString:
                case PrimasFilterType.JsonNumber:
                case PrimasFilterType.StartWithText:
                    if ((value && value.length > 0)
                        || operator == PrimasAllFilterOperator.IsEmpty
                        || operator == PrimasAllFilterOperator.IsNotEmpty) {
                        filter.push({
                            prop: prop,
                            value: value,
                            filterType: type,
                            filterOperator: this.splitSpaceString(operator),
                            dynamicProperty, delimiter
                        })
                    }
                    break;
                case PrimasFilterType.MailActions:
                    if ((value && value.length > 0)
                        || operator == PrimasAllFilterOperator.IsEmpty
                        || operator == PrimasAllFilterOperator.IsNotEmpty || PrimasMailActionFilterOperator.NoEmailActions) {
                        filter.push({
                            prop: prop,
                            value: value,
                            filterType: type,
                            filterOperator: this.splitSpaceString(operator),
                        })
                    }
                    break;
                case PrimasFilterType.DateTime:
                    if (value != null
                        || operator == PrimasAllFilterOperator.IsEmpty
                        || operator == PrimasAllFilterOperator.IsNotEmpty) {
                        filter.push({
                            prop: prop,
                            value: value,
                            filterType: type,
                            filterOperator: this.splitSpaceString(operator),
                        })
                    }
                    break;
                case PrimasFilterType.Date:
                    if (value != null
                        || operator == PrimasAllFilterOperator.IsEmpty
                        || operator == PrimasAllFilterOperator.IsNotEmpty) {
                        filter.push({
                            prop: prop,
                            value: value,
                            filterType: type,
                            filterOperator: this.splitSpaceString(operator),
                            dynamicProperty, delimiter
                        })
                    }
                    break;
            }
        }
        // console.log(filter);
        return filter;
    }

    static formatOrder(orders: OrderMapping[], prop, value, item = null): OrderMapping[] {
        if (orders == null) {
            orders = [];
        }
        let exist = orders.find(x => x.sort == prop);
        if (exist != null) {
            if (value == null || value?.length <= 0) {
                orders = orders.filter(x => x.sort != prop);
            } else {
                exist.sort = prop;
                exist.sortDir = value == "asc" || value == 0 ? SortOrderType.ASC : SortOrderType.DESC;
            }
        } else {
            if (value != null && value.length > 0) {
                orders.push({
                    sort: prop,
                    sortDir: value == "asc" || value == 0 ? SortOrderType.ASC : SortOrderType.DESC,
                    dynamicProperty: item ? item.filter?.dynamicProperty : null,
                    dataType: item ? item.filter?.dataType : null
                });
            }
        }
        return orders;
    }

    static arrayEquals(a, b) {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }

    static splitSpaceString(str: String) {
        return str.replace(/\s/g, "");
    }

    static createSpaceString(str: String) {
        if (str == null || str == undefined)
            return null
        return str.match(/[A-Z][a-z]+/g).join(" ");
    }

    static selectColumnGrid(isOpenDialog: boolean, widthScreen: number) {
        widthScreen = isOpenDialog ? widthScreen - 700 : widthScreen;
        return (widthScreen >= 1400) ? 3 : (widthScreen <= 900) ? 1 : 2;
    }

    static selectSizeElementGrid(isOpenDialog: boolean, widthScreen: number, cols: number) {
        widthScreen = isOpenDialog ? widthScreen - 700 : widthScreen;
        return cols !== 1 ? "3:2" : (widthScreen >= 400) ? "2:1" : "3:2";
    }
    static getCookie(cname) {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    //2022-03-22 vuonglqn add start
    static convertEnumToArray(enumObject: any) {
        var arrayObjects = []

        for (const [propertyKey, propertyValue] of Object.entries(enumObject)) {
            if (!Number.isNaN(Number(propertyKey))) {
                continue;
            }
            arrayObjects.push({ id: propertyValue, name: propertyKey });
        }

        return arrayObjects;
    }

    static getIdEnumTypeEnumString(enumObject: any, value: any, startIndex: number = 0) {
        var result = -1;
        var index = startIndex;

        for (const [propertyKey, propertyValue] of Object.entries(enumObject)) {
            if (!Number.isNaN(Number(propertyKey))) {
                continue;
            }
            if (propertyValue == value) {
                result = index;
                break;
            }
            index++;
        }

        return result;
    }
    //2022-03-22 vuonglqn add end

    //2022-03-27 vuonglqn add start
    static alphabetically(ascending, isCompareLowerAndUpper = true) {
        return function (a, b) {
            if (!isCompareLowerAndUpper) {
                a = a.toLowerCase();
                b = b.toLocaleLowerCase();
            }

            // equal items sort equally
            if (a === b) {
                return 0;
            }
            // nulls sort after anything else
            else if (a === null) {
                return 1;
            }
            else if (b === null) {
                return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (ascending) {
                return a < b ? -1 : 1;
            }
            // if descending, highest sorts first
            else {
                return a < b ? 1 : -1;
            }
        };
    }
    //2022-03-27 vuonglqn add end

    static displayNameProp(name: string, isBoolean: boolean = false, toLower: boolean = false) {
        var resultName = '';
        //logic tienlm
        if (!isBoolean) {
            if (name.length > 0) {
                let newName = name;
                newName = (!toLower ? newName[0].toUpperCase() : newName[0].toLowerCase()) + newName.substr(1);
                resultName = newName.replace(/([A-Z])/g, ' $1').trim();
            } else resultName = name;
        } else {
            if (name.length > 0) {
                let newName = name;
                newName = (!toLower ? newName[0].toUpperCase() : newName[0].toLowerCase()) + newName.substr(1);
                let formatedNewName = newName.replace(/([A-Z])/g, ' $1').trim();
                if (formatedNewName.toLowerCase().includes('is ')) {
                    resultName = formatedNewName.split('Is')[1]?.trim();
                } else {
                    resultName = formatedNewName;
                }
            } else resultName = name;
        }
        //end logic tienlm

        //logic vuonglqn
        if (resultName.length > 0) {
            resultName = resultName.split('.').pop().trim();
        }

        return resultName;
    }


    static convertArrayToObject = (array: any[], key?) => {
        const initialValue = {};
        return array.reduce((obj, item, index) => {
            return key
                ? { ...obj, [item[key]]: item, }
                : { ...obj, [index]: item };
        }, initialValue);
    };

    static isEmptyOrSpaces(str): boolean {
        return str === null || str === undefined || str.toString().match(/^ *$/) !== null;
    }

    static mapArrayToFormat(formatData: any, arr: any[], arrInsert?: KeyPairsValue[]) {
        return arr.map(val => {
            var newVal = {};
            //Format list data exited to new format;
            Object.keys(formatData).forEach(key => {
                newVal[key] = val[formatData[key]];
            });

            //Insert new data to object.
            if (arrInsert && arrInsert.length > 0) {
                arrInsert.forEach(item => {
                    newVal[item.key] = item.value;
                });
            }

            return newVal;
        })
    }

    static makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    static newGuid() {
        try {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0,
                    v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }
        catch (error) {
            console.error(error);
            return;
        }
    }

    static isNullOrEmpty(value: string) {
        return (!value || value == undefined || value == "" || value.length == 0);
    }

    static toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    static async FileToBase64(file) {
        try {
            const result = await this.toBase64(file);
            return result
        } catch (error) {
            console.error(error);
            return;
        }
    }
    static roughScale(x, base) {
        const parsed = parseInt(x, base);
        if (isNaN(parsed)) { return 0; }
        return parsed * 100;
    }
    static getFileExtension(fileName: string) {
        return (/[.]/.exec(fileName)) ? /[^.]+$/.exec(fileName) : undefined;
    }

    static getMediaUrl(externalUrl: string, internalUrl: string, defaultMedia: string, key: string = "BOF_IMG_SOURCE") {
        var result = "";
        try {
            var decodeData = window.localStorage.getItem(key);
            var keyValue = atob(decodeData);
            if (this.isNullOrEmpty(keyValue) && this.isEmptyOrSpaces(keyValue))
                result = defaultMedia;

            if (keyValue == "S3") result = (externalUrl || internalUrl) || defaultMedia;
            else if (keyValue == "NOT S3") result = internalUrl || defaultMedia;
            else result = defaultMedia;
        }
        catch (ex) {
            result = defaultMedia;
        }

        return result;
    }

    static getLastNameUrl(urlStr: string, limitLength: number = 0, isExtension: boolean = false) {
        var nameUrl = ""
        var extensionUrl = "";
        var limitStr = "";
        try {
            if (urlStr && urlStr.length > 0) {
                var replaceUrl = urlStr.slice().replace(/\\/g, "/");
                var splitUrl = replaceUrl.split("/");
                if (splitUrl && splitUrl.length > 0)
                    nameUrl = splitUrl[splitUrl.length - 1];

                if (limitLength > 0) {
                    limitStr = nameUrl.slice(0, limitLength) + "...";
                }

                if (isExtension) {
                    var splitNameUrl = nameUrl.split(".");
                    if (splitNameUrl && splitNameUrl.length > 1) {
                        extensionUrl = splitNameUrl[splitNameUrl.length - 1];
                    }
                }

                if (limitLength > 0) nameUrl = limitStr;
                if (isExtension) nameUrl += extensionUrl;
            }
        } catch (ex) { }
        return nameUrl;
    }


    static getExtensionUrl(urlStr) {
        var extensionUrl = ""
        try {
            if (urlStr && urlStr.length > 0) {
                var splitUrl = urlStr.split(".");
                if (splitUrl && splitUrl.length > 1)
                    extensionUrl = splitUrl[splitUrl.length - 1];
            }
        } catch (ex) { }
        return extensionUrl;
    }

    static toUpperCaseFirstLetter(str: string) {
        try {
            str = str.charAt(0).toUpperCase() + str.slice(1);
        } catch (ex) { }
        return str;
    }

    //Use in edit for compare data if it changed or not when user is typing to enable a save button
    static isDataChange(before, after): boolean {
        var isChange: boolean = false;
        if (before && after) {
            Object.keys(after).forEach((key: string) => {
                before[key] = before[key] ?? "";
                after[key] = after[key] ?? "";
                if (String(before[key]) != String(after[key])) {
                    isChange = true;
                }
            });
        }
        return isChange;
    }
    static filterClientSidePaging<T>(filters: FilterMapping[], data: Array<T>): Array<T> {
        var result = [...data.slice()];
        try {
            if ((data && data.length > 0) && (filters && filters.length > 0)) {
                filters.forEach(filter => {
                    switch (filter.filterType) {
                        case PrimasFilterType.Text:
                            data = Helper.filterStringClientSidePaging(filter, data);
                            break;
                        case PrimasFilterType.Number:
                            data = Helper.filterNumberClientSidePaging(filter, data);
                            break;
                        case PrimasFilterType.DropDown:
                            data = Helper.filterDropDownClientSidePaging(filter, data);
                            break;
                        case PrimasFilterType.DateTime:
                            data = Helper.filterDateTimeClientSidePaging(filter, data);
                            break;
                        case PrimasFilterType.Date:
                            data = Helper.filterDateTimeClientSidePaging(filter, data);
                            break;
                    }
                });

                result = [...data.slice()];
            }
        }
        catch (ex) { }
        return result;
    }

    static filterStringClientSidePaging<T>(filter: FilterMapping, data: Array<T>): Array<T> {
        var result = [...data.slice()];
        try {
            if ((data && data.length > 0) && (filter && !Helper.isNullOrEmpty(filter.value) && !Helper.isEmptyOrSpaces(filter.value))) {
                switch (filter.filterOperator) {
                    case Helper.splitSpaceString(PrimasTextFilterOperator.Contains):
                        data = data.slice().filter(x => x[filter.prop].toLowerCase().includes(filter.value.toLowerCase()));
                        break;
                    case Helper.splitSpaceString(PrimasTextFilterOperator.DoesNotContains):
                        data = data.slice().filter(x => !x[filter.prop].toLowerCase().includes(filter.value.toLowerCase()));
                        break;
                    case Helper.splitSpaceString(PrimasTextFilterOperator.StartsWith):
                        data = data.slice().filter(x => x[filter.prop].toLowerCase().startsWith(filter.value.toLowerCase()));
                        break;
                    case Helper.splitSpaceString(PrimasTextFilterOperator.EndsWith):
                        data = data.slice().filter(x => x[filter.prop].toLowerCase().endsWith(filter.value.toLowerCase()));
                        break;
                    case Helper.splitSpaceString(PrimasTextFilterOperator.IsEqualTo):
                        data = data.slice().filter(x => x[filter.prop].toLowerCase() == filter.value.toLowerCase());
                        break;
                    case Helper.splitSpaceString(PrimasTextFilterOperator.IsNotEqualTo):
                        data = data.slice().filter(x => x[filter.prop].toLowerCase() != filter.value.toLowerCase());
                        break;
                }
            }
            data = this.filterAllClientSide(filter, data);

            result = [...data.slice()];
        }
        catch (ex) { }
        return result;
    }

    static filterNumberClientSidePaging<T>(filter: FilterMapping, data: Array<T>): Array<T> {
        var result = [...data.slice()];
        try {
            if ((data && data.length > 0) && (filter && !Helper.isNullOrEmpty(filter.value) && !Helper.isEmptyOrSpaces(filter.value))) {
                switch (filter.filterOperator) {
                    case Helper.splitSpaceString(PrimasNumberFilterOperator.IsEqualTo):
                        data = data.slice().filter(x => parseInt(x[filter.prop]) == parseInt(filter.value));
                        break;
                    case Helper.splitSpaceString(PrimasNumberFilterOperator.IsNotEqualTo):
                        data = data.slice().filter(x => parseInt(x[filter.prop]) != parseInt(filter.value));
                        break;
                    case Helper.splitSpaceString(PrimasNumberFilterOperator.IsGreaterThanOrEqualTo):
                        data = data.slice().filter(x => parseInt(x[filter.prop]) >= parseInt(filter.value));
                        break;
                    case Helper.splitSpaceString(PrimasNumberFilterOperator.IsGreaterThan):
                        data = data.slice().filter(x => parseInt(x[filter.prop]) > parseInt(filter.value));
                        break;
                    case Helper.splitSpaceString(PrimasNumberFilterOperator.IsLessThanOrEqualTo):
                        data = data.slice().filter(x => parseInt(x[filter.prop]) <= parseInt(filter.value));
                        break;
                    case Helper.splitSpaceString(PrimasNumberFilterOperator.IsLessThan):
                        data = data.slice().filter(x => parseInt(x[filter.prop]) < parseInt(filter.value));
                        break;
                }
            }
            data = this.filterAllClientSide(filter, data);

            result = [...data.slice()];
        }
        catch (ex) { }
        return result;
    }

    static filterDropDownClientSidePaging<T>(filter: FilterMapping, data: Array<T>): Array<T> {
        var result = [...data.slice()];
        try {
            if ((data && data.length > 0) && (filter && !Helper.isNullOrEmpty(filter.value))) {
                switch (filter.filterOperator) {
                    case Helper.splitSpaceString(PrimasDropdownFilterOperator.Contains):
                        data = data.slice().filter((x) => {
                            let check: boolean = false;
                            filter.value.map((filterValue) => {
                                if (x[filter.prop] == filterValue) check = true;
                            })
                            return check;
                        })
                        break;
                    case Helper.splitSpaceString(PrimasDropdownFilterOperator.DoesNotContains):
                        filter.value.map((filterValue) => {
                            data = data.slice().filter(x => x[filter.prop] != filterValue);
                        })
                        break;
                }
            }

            result = [...data.slice()];
        }
        catch (ex) { }
        return result;
    }

    static filterDateTimeClientSidePaging<T>(filter: FilterMapping, data: Array<T>): Array<T> {
        var result = [...data.slice()];
        try {
            if ((data && data.length > 0) && (filter && !Helper.isNullOrEmpty(filter.value))) {
                let dataCheck = String(filter.value);
                dataCheck = dataCheck.substring(0, dataCheck.length - 3).replace('T', ' ');

                switch (filter.filterOperator) {
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsAfter):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(String(x[filter.prop])).getTime();
                            let dateCheck = new Date(String(dataCheck)).getTime();
                            return dateValue > dateCheck;
                        });
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsAfterOrEqual):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(String(x[filter.prop])).getTime();
                            let dateCheck = new Date(String(dataCheck)).getTime();
                            return dateValue >= dateCheck;
                        });
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsBefore):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(String(x[filter.prop])).getTime();
                            let dateCheck = new Date(String(dataCheck)).getTime();
                            return dateValue < dateCheck;
                        });
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsBeforeOrEqual):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(String(x[filter.prop])).getTime();
                            let dateCheck = new Date(String(dataCheck)).getTime();
                            return dateValue <= dateCheck;
                        });
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsEqualTo):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(String(x[filter.prop])).getTime();
                            let dateCheck = new Date(String(dataCheck)).getTime();
                            return dateValue == dateCheck;
                        });
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsNotEqualTo):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(String(x[filter.prop])).getTime();
                            let dateCheck = new Date(String(dataCheck)).getTime();
                            return dateValue != dateCheck;
                        });
                        break;
                }
            }
            result = [...data.slice()];
        }
        catch (ex) { }
        return result;
    }

    static filterDateClientSidePaging<T>(filter: FilterMapping, data: Array<T>): Array<T> {
        var result = [...data.slice()];
        try {
            if ((data && data.length > 0) && (filter && !Helper.isNullOrEmpty(filter.value))) {
                let dataCheck = String(filter.value);
                dataCheck = dataCheck.substring(0, dataCheck.length - 3).replace('T', ' ');
                console.log(dataCheck);

                switch (filter.filterOperator) {
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsAfter):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(String(x[filter.prop])).getTime();
                            let dateCheck = new Date(String(dataCheck)).getTime();
                            return dateValue > dateCheck;
                        });
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsAfterOrEqual):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(String(x[filter.prop])).getTime();
                            let dateCheck = new Date(String(dataCheck)).getTime();
                            return dateValue >= dateCheck;
                        });
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsBefore):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(String(x[filter.prop])).getTime();
                            let dateCheck = new Date(String(dataCheck)).getTime();
                            return dateValue < dateCheck;
                        });
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsBeforeOrEqual):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(String(x[filter.prop])).getTime();
                            let dateCheck = new Date(String(dataCheck)).getTime();
                            return dateValue <= dateCheck;
                        });
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsEqualTo):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(String(x[filter.prop])).getTime();
                            let dateCheck = new Date(String(dataCheck)).getTime();
                            console.log(dateValue, dateCheck);
                            return dateValue == dateCheck;
                        });
                        break;
                    case Helper.splitSpaceString(PrimasDatetimeFilterOperator.IsNotEqualTo):
                        data = data.slice().filter((x) => {
                            let dateValue = new Date(String(x[filter.prop])).getTime();
                            let dateCheck = new Date(String(dataCheck)).getTime();
                            return dateValue != dateCheck;
                        });
                        break;
                }
            }
            result = [...data.slice()];
        }
        catch (ex) { }
        return result;
    }

    static getColumnsPermission(columns: any[]) {
        var result = [];
        try {
            let accessToken = localStorage.getItem("accessToken");
            if (accessToken) {
                const helper = new JwtHelperService();
                const decodedToken = helper.decodeToken(accessToken);
                var permissions = JSON.parse(decodedToken['permission']) ?? {};
                if (permissions && permissions[Object.keys(permissions)[0]]['view'].length > 0)
                    result = [...columns.filter(x =>
                        permissions[Object.keys(permissions)[0]]['view'].includes(x.permissionColumn) ||
                        !x.hasOwnProperty('permissionColumn'))];
            }
        } catch (ex) {

        }

        return result;
    }

    static copyRemoveHeap(input: any) {
        return JSON.parse(JSON.stringify(input));
    }

    static formatDisplayColumn(columns: any[], displayColumns: DataField[] = [], propCompare: string = 'prop', propChange: string = 'name'): any[] {
        var result = columns;
        try {
            if (columns != null && columns.length > 0 && displayColumns.length > 0) {
                var sliceDisplayColumns = [...Helper.copyRemoveHeap(displayColumns)];
                sliceDisplayColumns.forEach(x => {
                    var arraySplit = x.dataFieldKey.split(".");
                    if (arraySplit != null && arraySplit.length > 0) {
                        x.dataFieldKey = arraySplit.slice(1, arraySplit.length).join(".");
                    }
                });

                var mapColumn = columns.filter(x => sliceDisplayColumns.map(y => y.dataFieldKey.toLocaleLowerCase())
                    .includes(x[propCompare].toLocaleLowerCase()));

                if (mapColumn != null && mapColumn.length > 0) {
                    mapColumn.forEach(x =>
                        x[propChange] = sliceDisplayColumns.find(y =>
                            y.dataFieldKey.toLocaleLowerCase() == x[propCompare].toLocaleLowerCase()
                        ).displayData ?? x[propChange])
                }

                result = [...columns];
            }
        }
        catch (ex) {
            console.log(ex);
        }

        return result;
    }

    static findItemByKey(array: KeyPairsValue[], key: string, propFind: string = 'key') {
        var result: string = null;
        try {
            if (array != null && array.length > 0)
                result = array.find(x => x[propFind].toLocaleLowerCase() == key.toLocaleLowerCase())?.value ?? "";
        } catch (ex) {
            console.log(ex);
        }
        return result;
    }
    static bindingImportWithImportProp(columnsImport: any[]) {
        columnsImport = JSON.parse(JSON.stringify(columnsImport));
        // 2022-09-07 tienlm add start
        columnsImport = columnsImport.map(item => {
            if (item?.importProp) {
                item.prop = item.importProp;
            }
            return item;
        });
        return columnsImport;
        // 2022-09-07 tienlm add end
    }

    static trimUserPhoneNumber(phone: string): string {
        // tslint:disable-next-line:prefer-const
        let newPhone = phone;
        newPhone = newPhone = newPhone.trim();
        newPhone = newPhone.replace(' ', '');
        newPhone = newPhone.replace('+', '');
        newPhone = newPhone.replace('-', '');
        newPhone = newPhone.replace('(', '');
        newPhone = newPhone.replace(')', '');
        newPhone = newPhone.replace('.', '');
        return newPhone;
    }
    static isDateString(s: string): boolean {
        if (!s) {
            return false;
        }
        if (isNaN(Date.parse(s))) {
            return false;
        }
        return true;
    }
    static userURL(data): string {
        if (data)
            return data.replace(/\\/g, '/');
        else null;
    }
    static checkHasEmail(entity: string, contact: Contact, contactLst: ProfileContact[] = [], buyerContacts: BuyerContacts[] = []) {
        if (contact) {
            return contact.contactEmail ? true : (contact.secondaryEmail ? true : false);
            return;
        }
        if (entity == 'profile') {
            if (contactLst.length > 0) {
                let alternativeEmail = contactLst[0];
                return alternativeEmail.contact?.contactEmail ? true : (alternativeEmail.contact?.secondaryEmail ? true : false);
            } else if (!contact && contactLst.length == 0) {
                return false;
            }
        } else if (entity == 'buyer') {
            if (buyerContacts.length > 0) {
                let alternativeEmail = buyerContacts[0];
                return alternativeEmail.contact?.contactEmail ? true : (alternativeEmail.contact?.secondaryEmail ? true : false);
            } else if (!contact && contactLst.length == 0) {
                return false;
            }
        }
    }
    static cancelNavigate(replaceUrl: string) {
        window.history.pushState({}, '', window.location.href);
        window.history.replaceState({}, '', `${replaceUrl}`);
    }
    static cancelNavigateNoTrackingHistory(replaceUrl: string) {
        window.history.replaceState({}, '', `${replaceUrl}`);
    }
    // Session URL will be: /configuration/profile ...
    static handleTabChangedUrl(tabIndex: number, sessionURL: string) {
        let patternRegex = /#([\d]+)/;
        let newReplaceUrl = sessionURL + window.location.href.split(sessionURL)[window.location.href.split(sessionURL).length - 1];
        if (patternRegex.test(newReplaceUrl)) {
            newReplaceUrl = newReplaceUrl.replace(patternRegex, `#${tabIndex}`);
        } else {
            newReplaceUrl = newReplaceUrl + `#${tabIndex}`;
        }
        Helper.cancelNavigateNoTrackingHistory(newReplaceUrl);
    }

    static removeStyleHtml(data?: string): string {
        var result = data;
        try {
            if (result) {
                let tmp = document.createElement("DIV");
                tmp.innerHTML = result;
                result = tmp.textContent || tmp.innerText || "";
            }
        } catch (ex) { }

        return result;
    }

    static checkTabMode = (): boolean => {
        var isTabMode = localStorage.getItem('tabMode');
        if (isTabMode && isTabMode === 'true')
            return true;
        return false;
    }

    static heightDialog(): string {
        if (Helper.checkTabMode())
            return 'calc(100vh - 49.3px)';
        return '100vh';
    }

    static heightDialogSelectOnly(): string {
        if (Helper.checkTabMode())
            return 'calc(100vh - 49.3px)';
        return '100vh';
    }

    static findLastIndex(array, searchKey, searchValue) {
        var index = array.slice().reverse().findIndex(x => x[searchKey] === searchValue);
        var count = array.length - 1
        var finalIndex = index >= 0 ? count - index : index;
        return finalIndex;
    }
    // function to get query parameter Ex:  /configuration/profile?profileId=00cd6e9c-d2e9-4087-8a1c-94cd49c07c5d#2
    // will return ?profileId=00cd6e9c-d2e9-4087-8a1c-94cd49c07c5d#2
    static checkUrlHasQueryParamString(value: string): string {
        let regexParam = /(\?[\s\S]*)/;
        if (value && value.match(regexParam)) {
            return value.match(regexParam)[0];
        }
        return null;
    }
    static getQueryParamObject(value: string): ObjectValue[] {
        let paramLst = value.split('&');
        let returnObjects: ObjectValue[] = [];
        paramLst.forEach(item => {
            if (item && item.trim().length > 0 && item.trim().split('=').length > 0) {
                let splittedValue = item.split('=');
                returnObjects.push({
                    id: splittedValue[0],
                    value: splittedValue[1]
                });
            }
        });
        return returnObjects;
    }

    static removeOverlay() {
        try {
            var overlayBackdropDetails = window.document.querySelectorAll(".cdk-overlay-backdrop.overlay-backdrop-tab-mode.cdk-overlay-backdrop-showing");
            var overlayContentDetails = window.document.querySelectorAll(".cdk-overlay-pane.dialog-detail");
            if (overlayBackdropDetails.length > 0) {
                for (var i = overlayBackdropDetails.length - 1; i >= 0; i--) {
                    overlayBackdropDetails[i].remove();
                }
            }

            if (overlayContentDetails.length > 0) {
                for (var i = overlayContentDetails.length - 1; i >= 0; i--) {
                    overlayContentDetails[i].remove();
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    static recursiveFindItemArray(data: any[], prop: string, value: any) {
        for (var i = 0; i < data.length; i++) {
            if (data[i][prop] === value) {
                return data[i];
            } else if (data[i].children && data[i].children.length && typeof data[i].children === "object") {
                let result = this.recursiveFindItemArray(data[i].children, prop, value);
                if (result) return result;
            }
        }
    }
    static verifyJson(text) {
        if (typeof text !== "string") {
            return false;
        }
        try {
            var json = JSON.parse(text);
            return typeof json === "object";
        } catch (error) {
            return false;
        }
    }
    static checkPhoneNumberContact(contact: Contact): boolean {
        let result = false;
        if (contact.contactPhone) result = true;
        else if (contact.secondaryPhone) result = true;
        else if (contact.cellPhone) result = true;
        return result;
    }
    static getEmailFromContact(contact: Contact): string | null {
        let result = null;
        if (!contact) return result;
        if (!this.isNullOrEmpty(contact.contactEmail)) return contact.contactEmail;
        if (!this.isNullOrEmpty(contact.secondaryEmail)) return contact.secondaryEmail;
        return result;
    }
    static getPhoneFromContact(contact: Contact): string {
        let result = null;
        if (!contact) return result;
        if (!this.isNullOrEmpty(contact.contactPhone)) return contact.contactPhone;
        if (!this.isNullOrEmpty(contact.secondaryPhone)) return contact.secondaryPhone;
        if (!this.isNullOrEmpty(contact.cellPhone)) return contact.cellPhone;
    }

    static exportFrontEnd(dataExport: any[], fileName: string = 'InvalidCampaignContact_Export_') {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataExport);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
        fileSaver.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
    }

    static sortBy(key, order: SortOrderType = SortOrderType.ASC) {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }

            const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === SortOrderType.DESC) ? (comparison * -1) : comparison
            );
        };
    }

    static sortClientSidePaging<T>(sorts: OrderMapping[], data: Array<T>): Array<T> {
        var result = [...data.slice()];
        try {
            if ((data && data.length > 0) && (sorts && sorts.length > 0)) {
                sorts.forEach((sort: OrderMapping) => {
                    data.sort(this.sortBy(sort.sort, sort.sortDir))
                });

                result = [...data.slice()];
            }
        }
        catch (ex) { }
        return result;
    }

    static formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    static filterAllClientSide<T>(filter: FilterMapping, data: Array<T>): Array<T> {
        var result = [...data.slice()];
        try {
            switch (filter.filterOperator) {
                case "Is Empty":
                    data = data.slice().filter(x => !x[filter.prop]);
                    break;
                case "Is Not Empty'":
                    data = data.slice().filter(x => x[filter.prop]);
                    break;
                case "IsEmpty":
                    data = data.slice().filter(x => !x[filter.prop]);
                    break;
                case "IsNotEmpty":
                    data = data.slice().filter(x => x[filter.prop]);
                    break;
            }

            result = [...data.slice()];
        }
        catch (ex) { }
        return result;
    }

    static getSessionPage() {
        var result = null;
        try {
            const isClose = window.sessionStorage.getItem("isClose");
            const pageSession = window.sessionStorage.getItem("page");

            if (isClose) window.sessionStorage.removeItem('isClose');
            if (pageSession) window.sessionStorage.removeItem("page");
            if (pageSession && isClose) result = JSON.parse(pageSession);
        }
        catch (ex) {
            console.warn(ex);
        }

        return result;
    }

    static checkUrlDetailObj(obj: string, urlParent: string = 'configuration'): boolean {
        var result: boolean = null;
        try {
            const url = window.location.pathname;
            if (url.includes(`/${urlParent}/${obj}/`) || url.includes(`/${urlParent}/${obj}`)) {
                window.sessionStorage.setItem('isClose', 'true');
                result = true;
            }
        }
        catch (ex) {
            console.warn(ex);
        }

        return result;
    }

    static checkMoreTable(obj: string): boolean {
        var result: boolean = false;
        try {
            const isExisted = window.sessionStorage.getItem(obj);
            const isClose = window.sessionStorage.getItem("isClose");

            if (isExisted) window.sessionStorage.removeItem(obj);
            if (isExisted && isClose) result = true;
        }
        catch (ex) {
            console.warn(ex);
        }

        return result;
    }

    static popBackURL(): string {
        var result: string;
        try {
            const isExistedUrl = window.sessionStorage.getItem("backURL");
            if (isExistedUrl) {
                var stackURL = [...JSON.parse(isExistedUrl)];
                var lastItem = stackURL?.pop();
                if (lastItem) result = lastItem;

                if (stackURL && stackURL.length > 0) {
                    window.sessionStorage.setItem("backURL", JSON.stringify(stackURL));
                    window.sessionStorage.setItem("cancelRemoveURL", 'true');
                } else {
                    window.sessionStorage.removeItem("backURL");
                    window.sessionStorage.removeItem("cancelRemoveURL");
                }
            }
        }
        catch (ex) {
            console.warn(ex);
        }

        return result;
    }

    static pushBackURL() {
        try {
            const checkStack = window.sessionStorage.getItem("backURL");
            var stackProfile = [];
            if (checkStack) stackProfile = [...JSON.parse(checkStack)] || [];
            stackProfile.push(window.location.pathname + (window?.location?.search || ''));
            window.sessionStorage.setItem("backURL", JSON.stringify(stackProfile));
            window.sessionStorage.setItem("cancelRemoveURL", 'true');
        }
        catch (ex) {
            console.warn(ex);
        }
    }

    // static circleDetail(id: string, obj: string, page: any, router: Router, tabMode: AdminTabModeService) {
    static circleDetail(id: string, obj: string, page: any, router: Router, userExTrackingService?: UserExperienceTrackingService) {
        try {
            // const tabData = tabMode.getCurrentActiveTab();
            // if (tabData) window.sessionStorage.setItem("tabIndexStorage", tabData.tabId?.toString());

            if (page != null) {
                window.sessionStorage.setItem("page", JSON.stringify(page));
            }
            else {
                window.sessionStorage.setItem("page", null);
            }

            const urlDetail = `/configuration/${obj}/${id}`;

            if (userExTrackingService) {
                userExTrackingService.navigateNext(urlDetail, null, null, true);
            } else {
                router.navigate([urlDetail]);
            }
        }
        catch (ex) {
            console.warn(ex);
        }
    }

    static cleanCircleDetail(userExperienceTrackingService: UserExperienceTrackingService) {
        try {
            let isKeepingNavigationStack: boolean = userExperienceTrackingService.checkIgnoreRemoveStackListWithTheCurrentURL();
            let navStack: string = "";
            if (isKeepingNavigationStack) {
                navStack = window.sessionStorage.getItem(NAVIGATION_STACK);
            }

            const tabTagIndex = window.sessionStorage.getItem("tabTagIndex");
            const backUrl = window.sessionStorage.getItem("backURL");
            const cancelRemoveURL = window.sessionStorage.getItem("cancelRemoveURL");
            window.sessionStorage.clear();
            if (tabTagIndex) window.sessionStorage.setItem("tabTagIndex", tabTagIndex);
            if (backUrl) window.sessionStorage.setItem("backURL", backUrl);
            if (cancelRemoveURL) window.sessionStorage.setItem("cancelRemoveURL", cancelRemoveURL);

            if (isKeepingNavigationStack) {
                window.sessionStorage.setItem(NAVIGATION_STACK, navStack);
            } else {
                //Remove Navigation Stack whenever reload or login, logout.
                window.sessionStorage.setItem(NAVIGATION_STACK, JSON.stringify([]));
            }

        }
        catch (ex) {
            console.warn(ex);
        }
    }

    // static validateTabSession(tabModeService: AdminTabModeService) {
    //     try {
    //         const tabCurSession = window.sessionStorage.getItem("tabIndexStorage");
    //         if (tabCurSession && +tabCurSession) {
    //             const tabCur = tabModeService.getCurrentActiveTab();
    //             if (tabCur?.tabId != +tabCurSession) Helper.cleanCircleDetail()
    //         }
    //     }
    //     catch (ex) {
    //         console.warn(ex);
    //     }
    // }

    static setItemSessionFilterTask(filterList: TaskFilterProp[]) {
        if (filterList && filterList.length > 0) {
            filterList.map(x => {
                switch (x.key) {
                    case 4: //search task
                        window.sessionStorage.setItem("searchTask", x.searchValue)
                        break;
                    case 2: //filter task type
                        window.sessionStorage.setItem("filterTaskType", JSON.stringify(x.propertyValue));
                        break;
                }
            });
        }
    }

    static validateRouteInApplication(route: Routes | Routes[]) {
        Helper._memoryPath = [];
        Helper.getPathFromRouting(route);
        return Helper._memoryPath;
    }

    static getPathFromRouting(entity: Routes | Routes[]) {
        entity.forEach(item => {
            if (!item.children) {
                // no children:
                Helper._memoryPath.push(item);
            } else {
                // has children:
                this.getPathFromRouting(item.children);
            }
        });
    }

    static getURLByRelationshipType(type: string) {
        var result: string = '';
        try {
            switch (type) {
                case "SALEPROFILE":
                    result = 'profile';
                    break;
                case "LEADS":
                    result = 'sale-lead';
                    break;
                case "SALEACCOUNT":
                    result = 'sale-account-crm';
                    break;
                case "OPPORTUNITY":
                    result = 'opportunity-crm';
                    break;
                case "TASK":
                    result = 'task';
                    break;
                default:
                    result = 'profile';
                    break;
            }
        }
        catch (ex) {
            console.warn(ex);
        }

        return result;
    }

    static convertStringToJson(char: string, data: string) {
        var rounds = {};
        try {
            if (data && char) {
                var intervalsSplit = data.split(char);
                if (intervalsSplit.length > 0)
                    for (var j = 0; j < intervalsSplit.length; j = j + 2) {
                        if (intervalsSplit[j]) rounds[intervalsSplit[j]] = intervalsSplit[j + 1];
                    }
            }
        }
        catch (ex) {
            console.warn(ex);
        }

        return rounds;
    }

    static toCamelCase(key, value) {
        var result = value;
        try {
            if (result && typeof result === 'object') {
                for (var k in result) {
                    if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(result, k)) {
                        result[k.charAt(0).toLowerCase() + k.substring(1)] = result[k];
                        delete result[k];
                    }
                }
            }

        }
        catch (ex) {
            console.warn(ex);
            result = value;
        }

        return result;
    }

    static toPascalCase(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                const newKey = key.charAt(0).toUpperCase() + key.slice(1);
                if (!obj.hasOwnProperty(newKey)) {
                    obj[newKey] = obj[key];
                    delete obj[key];
                }
            }
        }
        return obj;
    }


    static handleMergeTag(mergeTag: string, obj) {
        for (let key in obj) {
            if (mergeTag) {
                const tag = `{${key}}`;
                mergeTag = mergeTag.replace(tag, (obj[key]) ? obj[key] : '0');
            }
        }
        return mergeTag;
    }


    static exportToExcel(table: ElementRef<any>, fileName: string = "ExportFile") {
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table.nativeElement);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        /* save to file */
        XLSX.writeFile(wb, `${fileName}.xlsx`);

    }

    static checkTimePassOfMessageTime(messageDateCreated: string) {
        if (messageDateCreated) {
            var currentTime = (new Date().getTime() / 1000)
            var messageTime = (new Date(messageDateCreated).getTime() / 1000)
            var aDay = 86400
            var aYear = 31536000
            if (currentTime - messageTime > aYear) {
                return '1'
            }
            else if (currentTime - messageTime > aDay) {
                return '0'
            }
        }
        return '-1'
    }

    static onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    static removeNonAscii(str) {
        var result: string = str;

        try {
            if (!((result === null) || (result === ''))) {
                result = result?.toString();
                result = result?.replace(/[^\x20-\x7E]/g, '');
            }
        }
        catch (ex) {
            console.log(ex);
        }

        return result;
    }

    static toTitlecase(str) {
        if (!this.isNullOrEmpty(str)) {
            return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
                return match.toUpperCase();
            });
        }
    }

    static convertNumberPropertiesToString(dataModel: any) {
        var result = Object.assign({}, dataModel);
        try {
            for (const key in dataModel) {
                if (typeof dataModel[key] === 'number') {
                    result[key] = dataModel[key].toString();
                }
            }
        }
        catch (ex) {
            console.warn(ex);
        }
        return result;
    }
    static convertHtmlToNormalStyle(data: string) {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = data;
        return tmp.textContent || tmp.innerText || "";
    }
    static convertTwoToOneArr = (arr = [], mapData = (x) => x, result = []) => {
        try {
            result = result.concat(...arr.map(mapData).reduce((r, a) => {
                if (a) a.forEach((v, i) => (r[i] = r[i] || []).push(v)); return r;
            }, [])) || [];
        }
        catch (ex) {
            console.error(ex);
        }
        return result;
    }
    static numberOfRange(min: number, max: number) {
        let range: string = '[';
        for (let i = min; i <= max; i++) {
            range += i != max ? `${i}, ` : `${i} `;
        }
        range! += "]";
        return range;
    }

    static uniqueObj(array: any[] = [], idKey: string = 'id') {
        try {
            return Object.values(
                array.reduce((acc, obj) => {
                    const id = obj[idKey];
                    return { ...acc, [id]: obj };
                }, {})
            );
        } catch (ex) {
            console.error(ex);
        }
    }

    static getRelationshipURL(isNewUI: boolean, url: string, haveSplash: boolean = false, isAdding: boolean = true) {
        try {
            let listRelationship = ['opportunity', 'sale-account'];
            for (let relationship of listRelationship) {
                let firstData = relationship;
                let secondData = `${relationship}-crm`;
                if (haveSplash === true) {
                    firstData += '/';
                    secondData += '/';
                }

                // Check Condition
                if (isNewUI) {
                    if (isAdding) {
                        url = url.replace(firstData, secondData)
                    }
                    else {
                        url = url.replace(secondData, firstData)
                    }
                }
                // Only URL can roll back to default URL
                else if (haveSplash) {
                    url = url.replace(secondData, firstData)
                }
            }

            return url;
        }
        catch (ex) {
            console.error(ex);
        }
    }

    static formatKeyPairValue(rootColumn, row, extendDataField = "ExtendData") {
        const columns = []
        for (const key in row) {
            if (Object.prototype.hasOwnProperty.call(row, key)) {
                if (key == extendDataField) continue;
                if (rootColumn.findIndex(x => x.prop === key) != -1) continue;
                var column = {
                    name: Helper.displayNameProp(key),
                    prop: key,
                    value: row[key],
                    sortable: true,
                    filter: {
                        dynamicProperty: extendDataField,
                        delimiter: ':'
                    },
                    disableDefaultFilterOperator: true,
                };
                columns.push(column);
            }
        }
        return columns;
    }

    static isSpacesOnly(str): boolean {
        if (str) {
            if (str === null || str === undefined) {
                return false;
            } else {
                return str.toString().match(/^ *$/) !== null;
            }
        } else {
            return false;
        }
    }

    static getTimeMilisecond(date: Date) {
        let result: number = 0;
        try {
            if (date) {
                const hours = date.getHours();
                const minutes = date.getMinutes();
                const seconds = date.getSeconds();
                result = ((hours * 3600) + (minutes * 60) + seconds) * 1000;
            }
        }
        catch (ex) {
            console.error(ex);
        }

        return result;
    }

    static getNumberTimezoneOffsetString(date: Date, timezone: string): string {
        //Get the offset string in the Date.toString()
        //Output: "-1100"
        let result = "0000";
        try {
            let tz: string = timezone || JSON.parse(sessionStorage.getItem("timezone"))?.timezone || Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
            let convertDateString = moment(date)?.tz(tz)?.toString()?.split("GMT");
            if (convertDateString?.length == 2) {
                result = convertDateString[1];
            }
        }
        catch (ex) {
            console.error(ex);
        }
        return result;
    }

    static getTimezoneOffsetFormatted(date: Date, tzSelected?: string): number {
        // get the timezone offset of the datetime in the timezone we selected (including daylight - DST).
        let result = 0;
        try {
            let timezone: string = tzSelected || JSON.parse(sessionStorage.getItem("timezone"))?.timezone || Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
            result = moment(date).tz(timezone).utcOffset() / 60;
        } catch (ex) {
            console.error(ex);
        }
        return result;
    }

    static getDefaultJSTimeByTimezone(date: Date, timezone: string, isReverseToClientTime: boolean = false) {
        //Convert from the current datetime (client offset) into the datetime in the timezone selected but with the (client offset).
        //Most usage: all of the Angular component - because it just understand the datetime of the Client Offset.

        let result = new Date();
        try {
            let tz: string = timezone || Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
            let tzOffsetNumber: number = Helper.getTimezoneOffsetFormatted(date, tz);
            let tzDiff = tzOffsetNumber * 60 + date.getTimezoneOffset();
            tzDiff = isReverseToClientTime ? -tzDiff : tzDiff;
            result = new Date(date.getTime() + tzDiff * 60 * 1000);
        }
        catch (ex) {
            console.error(ex);
        }
        return result;
    }

    static changeDateTimeWithTimezoneToUTC(date: Date, startTime: Date): string {
        //Convert from this datetime (client offset) to ISO String at the timezone we selected
        //Convert to UTC of the client offset and add the timespan betwwen (client offset) and (timezone selected offset) (Math meaning: (client offset - timezone selected offset)).

        let result: string = "";
        try {
            let timezone: string = JSON.parse(sessionStorage.getItem("timezone"))?.timezone || Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

            let timespanOffset = (moment(date).add(startTime.getHours(), "h").utcOffset() - moment(date).add(startTime.getHours(), "h").tz(timezone).startOf("d").utcOffset()) / 60;

            result = moment(date).add(timespanOffset, "h").toISOString();
        }
        catch (ex) {
            console.error(ex);
        }
        return result;
    }

    static getISOTimezoneFormatFromDefaultDateTimeJS(datetime: any, isISO: boolean = false, includeTZ: boolean = false, isClientTZ: boolean = false): any {
        let result: any;
        try {
            //Return type "2023-11-13T00:00:00-11:00"
            //prop date will have 2 kind of value, 1 is Date, 2 is  string.
            //If Date (isISO == false) -> get Year-Month-Date from it to convert to ISO string with 00:00:00
            //If ISO (isISO == true) -> the includeTZ will decide to append TZ (ex: -1100) to the current ISO string or not (ex: 2023-11-13T00:00:00 -> 2023-11-13T00:00:00-1100)
            let timezone = JSON.parse(sessionStorage.getItem("timezone"))?.timezone || Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
            let clientTZ = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

            let tz = "";
            if (includeTZ) {
                if (!isClientTZ) {
                    tz = Helper.getNumberTimezoneOffsetString(moment(datetime).toDate(), timezone);
                } else {
                    tz = Helper.getNumberTimezoneOffsetString(moment(datetime).toDate(), clientTZ);
                }
            }

            if (!isISO) {
                let date = `${moment(datetime).date()}`;
                let month = `${moment(datetime).month() + 1}`;
                let year = `${moment(datetime).year()}`;
                date = date?.length == 1 ? `0${date}` : date;
                month = month?.length == 1 ? `0${month}` : month;

                let isoStringFormatted = `${year}-${month}-${date}T00:00:00${tz}`;
                result = isoStringFormatted;
            }
            else {
                let isoStringFormatted = `${datetime?.toString()}${tz}`;
                result = isoStringFormatted;
            }
        }
        catch (ex) {
            console.error(ex);
        }
        return result;
    }

    static isValidMaximumWords(text: String, maxNumber: number): boolean {
        var result = true;
        var totalWords = this.countWords(text.trim());
        if (totalWords > maxNumber) {
            result = false;
        }
        return result;
    }
    static joinArrToStrValue(value: string[]): string {
        let result: string = '';
        try {
            if (Array.isArray(value) && value.length > 0) {
                result = value.map(x => x.trim()).filter(Helper.onlyUnique).join(",");
                if (result.length > 0 && result.endsWith(","))
                    result = result.slice(0, -3);
            }
        }
        catch (ex) {
            console.error(ex);
        }
        return result;
    }

    static countWords(text: String): number {
        var count = 0;
        if (text) {
            var regexParam = /\s+/;
            count = text.trim().split(regexParam).length;
        }
        return count;
    }
    static splitStrToArrValue(value: string) {
        let result: string[] = [];
        try {
            if (value) {
                let splitArr = value.split(",");
                if (splitArr.length > 0)
                    result = splitArr.map(x => x.trim());
            }
        }
        catch (ex) {
            console.error(ex);
        }
        return result;
    }

    static delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    static cleanLocalStorageTenant(url: string) {
        var isTenantUrl: boolean = false;
        try {
            if (url.includes('project/schedule-group/') || url.includes('configuration/schedule-group/'))
                isTenantUrl = true;

            if (!isTenantUrl) window.sessionStorage.removeItem("TenantId");
        }
        catch (ex) {
            console.error(ex);
        }
    }

    static getCallTime(durationSeconds: number): string {
        const days = Math.floor(durationSeconds / 86400);
        const hours = Math.floor((durationSeconds % 86400) / 3600);
        const minutes = Math.floor((durationSeconds % 3600) / 60);
        const seconds = Math.floor(durationSeconds % 60);

        let duration = `${seconds} second${seconds > 1 ? 's' : ''}`;

        if (days > 0 || hours > 0) duration = `${minutes} minute${minutes > 1 ? 's' : ''} ${duration}`;
        else if (minutes > 0) duration = `${minutes} minute${minutes > 1 ? 's' : ''} ${duration}`

        if (days > 0) duration = `${hours} hour${hours > 1 ? 's' : ''} ${duration}`;
        else if (hours > 0) duration = `${hours} hour${hours > 1 ? 's' : ''} ${duration}`

        if (days > 0) duration = `${days} day${days > 1 ? 's' : ''} ${duration}`;

        return duration.trim();
    }

    static generatePhoneCallLog(caller: string, callEvent: string) {
        try {
            caller = caller.toUpperCase() === "ME" ? 'You' : caller;

            switch (callEvent.toUpperCase()) {
                case 'CALLING_AGENT':
                    return `The system calling to ${caller}`;
                case 'AGENT_NO_ANSWER':
                    return `${caller} didn't answer the call`;
                case 'AGENT_ANSWERED':
                    return `${caller} answered the call`;
                case 'AGENT_BUSY':
                    return `${caller} was busy or indicated a busy status`;
                case 'NO_MAKE_CALL':
                    return `${caller} answered the call but didn't make a call to the recipient`;
                case 'NO_ANSWER':
                    return `The recipient didn't answer the call`;
                case 'CALLING':
                    return `The system calling to customer`;
                case 'BUSY':
                    return `The recipient was busy or indicated a busy status`;
                case 'ANSWERED':
                    return `The call was connected and the recipient are answering the call`;
                case 'CANCELLED':
                    return `The call was cancelled before the recipient answered`;
                case 'UNAVAILABLE':
                case 'AGENT_UNAVAILABLE':
                    return `The call could not be created due to an invalid phone number or because ${caller} is not logged in to the SIP account`;
                case 'CONGESTION':
                case 'AGENT_CONGESTION':
                    return `The call could not be created due to network issues or system overload`;
                case 'CALL_ENDED':
                    return `The call was connected and ended successfully`;
                case 'LEAVE_VOICEMAIL':
                    return `The recipient has received a voicemail`;
            }
        }
        catch (ex) {
            console.error(ex);
        }
    }

    static calculatePhoneCallDuration(callEvent: string, duration: number) {
        try {
            if (callEvent === 'CALL_ENDED' || callEvent === 'LEAVE_VOICEMAIL')
                return `(${Helper.getCallTime(duration)})`;
            else return "";
        }
        catch (ex) {
            console.error(ex);
        }
    }

    static updateDataForPhoneCall(data: ContactActionLog, user, isLogDetail = false) {
        var phoneCallDetail = JSON.parse(data.logInfo1);
        var phoneCallStatus = phoneCallDetail?.["callEvent"];
        var phoneCallDuration = phoneCallDetail?.["duration"] || 0;
        var userMakeACall = (data.userName == user.userName) ? 'Me' : data.userName;
        var callDuration = Helper.calculatePhoneCallDuration(phoneCallStatus, phoneCallDuration);
        var phoneCallTranscription = phoneCallDetail.transcription;

        var phoneCallMessage = `
          <p class="mb-0" ${isLogDetail ? 'style="font-size: 12px"' : ''}>${Helper.generatePhoneCallLog(userMakeACall, phoneCallStatus)}.
          Call Result: <b>${phoneCallStatus}</b> ${callDuration}</p>
        `
        data.logInfo2 = JSON.stringify({
            ...phoneCallDetail,
            message: phoneCallMessage,
            transcription: phoneCallTranscription
        });

        return data;
    }

    static statusStopCall(activityLog: any) {
        let result: boolean = false;
        try {
            if (activityLog && activityLog.action) {
                switch (activityLog.action) {
                    case "AGENT_BUSY":
                    case "AGENT_NO_ANSWER":
                    case "AGENT_UNAVAILABLE":
                    case "AGENT_CONGESTION":
                    case "NO_MAKE_CALL":
                    case "NO_ANSWER":
                    case "BUSY":
                    case "CONGESTION":
                    case "UNAVAILABLE":
                    case "CALL_ENDED":
                    case "LEAVE_VOICEMAIL":
                        result = true;
                        break;
                }
            }
        }
        catch (ex) {
            console.error(ex);
        }

        return result;
    }
}
