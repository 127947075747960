import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipList } from '@angular/material/chips';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { fromEvent, Observable } from 'rxjs';
import { startWith, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { KeyPairsValueTree } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { ReturnResult } from '../../models/return-result';
import { Helper } from '../../utility/Helper';
import { AutoCompleteCategoriesService } from '../stand-alone-component/auto-complete-categories/auto-complete-categories.service';
import { PagingTreeFilter } from '../tree-structure-filter/paging-profile-filter';
import { TreeNode, TreeSelected, TreeStructureFilterComponent } from '../tree-structure-filter/tree-structure-filter.component';

@Component({
  selector: 'app-custom-ngx-tree-filter',
  templateUrl: './custom-ngx-tree-filter.component.html',
  styleUrls: ['./custom-ngx-tree-filter.component.scss']
})
export class CustomNgxTreeFilterComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() inputTree: Observable<ReturnResult<TreeNode[]>> | TreeNode[] = [];
  @Input() inputBackupSearch: Observable<ReturnResult<TreeNode[]>> | KeyPairsValueTree[] = [];

  @Output() closeFilter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() applyFilter: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('treefilter', { static: true }) treefilter: TreeStructureFilterComponent;
  @ViewChild('searchCategory', { static: true }) searchRef: ElementRef;
  @ViewChild('chipCategory', { static: true }) chipListRef;
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoComplete: MatAutocompleteTrigger;

  page: PagingTreeFilter = new PagingTreeFilter();
  searchFrm = this.frmBuilder.group({ input: '' });
  searchObject: KeyPairsValueTree[] = [];
  chipFilter: KeyPairsValueTree[] = [];
  backupSearch: KeyPairsValueTree[] = [];
  arrayFilter: TreeSelected[] = [];
  areaChip: number = 0;

  isLoading: boolean = true;
  autocomplete: Observable<KeyPairsValueTree[]>;

  constructor(
    private frmBuilder: RxFormBuilder,
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.autocomplete = this.searchFrm.get('input').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(this.searchFrm.get('input').value))
    );

    const keyup$ = fromEvent(this.searchRef.nativeElement, 'keyup');
    keyup$.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(res => {
      this.searchObject = this._filter(this.searchFrm.get('input').value);
      this.isLoading = false;
      this.inputAutoComplete.closePanel();
      this.inputAutoComplete.openPanel();
    });

    const click$ = fromEvent(this.searchRef.nativeElement, 'click');
    click$.pipe(
      debounceTime(1),
      distinctUntilChanged()
    ).subscribe(res => {
      if (this.searchFrm.get('input').value != '') {
        console.log(`click with ${this.searchFrm.get('input').value}`)
        this.searchObject = this._filter(this.searchFrm.get('input').value);
        this.isLoading = false;
        this.inputAutoComplete.closePanel();
        this.inputAutoComplete.openPanel();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    let onChangeInputTree = changes.inputTree;
    if (onChangeInputTree && onChangeInputTree.currentValue !== onChangeInputTree.previousValue)
      this.formatInputTree(onChangeInputTree.currentValue);

    let onChangeInputBackupSearch = changes.inputBackupSearch;
    if (onChangeInputBackupSearch && onChangeInputBackupSearch.currentValue !== onChangeInputBackupSearch.previousValue)
      this.formatInputSearch(onChangeInputBackupSearch.currentValue);
  }

  formatInputTree(input: Observable<ReturnResult<TreeNode[]>> | TreeNode[]) {
    if (input) {
      if (input instanceof Observable) {
        input.subscribe({
          next: resp => {
            if (resp.result) {
              const lstTreeMapping = resp.result.filter(x => x.children != null && x.children?.length > 0);
              var lstTreeNotChildren = resp.result.filter(x => x.children != null && x.children?.length == 0);
              lstTreeNotChildren.forEach(x => {
                if (x.children != null) {
                  x.children.push({ id: x.id, name: '!this!', parentId: x.id, children: [] });
                }
              });

              this.treefilter.dataSource.data = [...lstTreeMapping as TreeNode[], ...lstTreeNotChildren as TreeNode[]];
            }
          },
          complete: () => { this.isLoading = false }
        });
      } else {
        this.treefilter.dataSource.data = input;
        this.isLoading = false;
      }
    };
  }

  formatInputSearch(input: Observable<ReturnResult<TreeNode[]>> | KeyPairsValueTree[]) {
    if (input) {
      if (input instanceof Observable) {
        input.subscribe(resp => {
          if (resp.result.length > 0) {
            this.backupSearch = [...resp.result.map(x => {
              return {
                key: x.id,
                value: x.name,
                parentId: x.parentId
              } as KeyPairsValueTree
            })];
          }
        });
      } else this.backupSearch = input;
    }
  }

  closeFilterFunc() {
    this.closeFilter.emit(true);
  }

  applyFilterFunc() {
    this.applyFilter.emit(true);
  }

  refreshEventTree(event) {
    this.chipFilter = [...this.sortChipParentToChip(this.chipFilter)];
  }

  sortChipParentToChip(listChip: KeyPairsValueTree[]) {
    var listParent = listChip.filter(x => x.parentId == null);
    var listChildNotParent = listChip.filter(x => x.parentId);
    listParent.map(x => x.value).sort(Helper.alphabetically(true));
    listChildNotParent.map(x => x.value).sort(Helper.alphabetically(true));
    return [...listParent, ...listChildNotParent];
  }

  refreshMatChipSelectCate(data: any) {
    if (data.flag) {
      this.chipFilter = [];
      if (data.filterCate)
        this.arrayFilter = [...data.filterCate];

      this.chipFilter = this.backupSearch
        .filter(x => this.arrayFilter.find(y => x.key === y.id && y.flagSelected === true));

      var checkChildren = this.arrayFilter.filter(x => x.children?.length > 0 && x.flagSelected === false);

      var itemChild = [];

      if (checkChildren != null) {
        checkChildren.forEach(parent => {
          itemChild.push(...this.backupSearch.filter(x => parent.children
            .find(y => x.key === y.id && y.flagSelected === true)));
        })
      }

      this.chipFilter = [...this.chipFilter, ...itemChild];
      this.getHeightChipArea();
    }
  }

  clearSeach() {
    this.searchFrm.setValue({ input: '' });
  }

  private _filter(value: string): any[] {
    this.isLoading = true;
    const filterValue = value.toLowerCase();
    const listParent = this.arrayFilter.filter(x => x.parentId == null);
    var listChild = this.arrayFilter.filter(x => x.flagSelected && x.parentId != null);
    var listSearch = JSON.parse(JSON.stringify(this.backupSearch.filter(x => x.value.toLowerCase().includes(filterValue))));

    if (listParent && listParent.length > 0) {
      listSearch.filter(x => listParent.filter(x => x.flagSelected).map(y => y.id).includes(x.key)).forEach(item => item['disableSelect'] = true);
      listParent.forEach(itemParent => {
        if (itemParent.children && itemParent.children.length > 0)
          listChild = [...listChild, ...itemParent.children]
      });
    }

    if (listChild && listChild.length > 0)
      listSearch.filter(x => listChild.filter(x => x.flagSelected).map(y => y.id).includes(x.key)).forEach(item => item['disableSelect'] = true);

    return [...listSearch];
  }

  showSelected(value: string) {
    const selectItem = this.backupSearch.find(x => x.key === value);
    if (selectItem) {
      var itemTreeFlatNode = this.treefilter.getItemTreeFlatNodeDynamic(selectItem, 'key');
      if (itemTreeFlatNode.expandable == true && itemTreeFlatNode.level == 0) {
        this.treefilter.todoItemSelectionToggle(itemTreeFlatNode);
      } else {
        this.treefilter.todoLeafItemSelectionToggle(itemTreeFlatNode);
      }
      this.clearSeach();
    }
  }

  removeChip(data: KeyPairsValueTree) {
    if (data) {
      var existedParent = this.arrayFilter.find(x => x.id === data.parentId);
      if (existedParent != null) {
        var checkChild = existedParent.children.find(x => x.id === data.key);
        checkChild.flagSelected = !checkChild.flagSelected;
        existedParent.flagSelected = this.treefilter.checkFullItemChildOfParent(existedParent);
      } else {
        var existSelected = this.arrayFilter.find(x => x.id === data.key);
        if (existSelected) {
          existSelected.flagSelected = false;
          if (existSelected.children?.length > 0) {
            existSelected.children.forEach(child => {
              child.flagSelected = false;
            });

            existSelected.children = [...existSelected.children];
          }
        }
      }

      this.arrayFilter = [...this.arrayFilter];
      this.chipFilter = [...this.chipFilter.filter(x => x.key != data.key)];
      this.getHeightChipArea();
    }
  }

  getHeightChipArea() {
    setTimeout(() => {
      try {
        var height = this.chipListRef._elementRef.nativeElement.offsetHeight;
        this.areaChip = parseInt(height);
      } catch (ex) {
        console.log(ex);
        this.areaChip = 0;
      }
    }, 500)

  }
}
