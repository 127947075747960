import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneFormat'
})
export class PhoneFormatPipe implements PipeTransform {

  transform(tel) {
    if(!tel) return tel;
    let newVal: string = tel.replace(/\D/g, '');
    newVal = newVal.split("").reverse().join("");

    if (newVal.length === 0) {
      newVal = '';
    }
    else {
      newVal = newVal.replace(/^(\d{0,4})(\d{0,3})(\d{0,3})(\d{0})/, '$1 $2 $3 $4');
    }
  newVal = newVal.split("").reverse().join("").trim();
  newVal = newVal.replace(/ /g, '-');
  return  newVal
  }
  
}
