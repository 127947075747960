import { PhoneFormatPipe } from './../../pipes/phone-format.pipe';
import { saluationList } from 'src/app/shared/components/stand-alone-component/contact/contact.component';
import { NbToastrService } from '@nebular/theme';
import { permissionContact, permissionContactTab } from 'src/app/shared/contances/permission';
import { ContactService } from 'src/app/shared/services/contact.service';
import { DateFormatPipe } from 'src/app/shared/pipes/date-time-format.pipe';
import { Component, Input, OnInit, Output, ViewChild, OnChanges, SimpleChanges, TemplateRef, EventEmitter } from '@angular/core';
import { PrimasFilterType } from '../../enums/primas-value-type.enum';
import { countries } from '../stand-alone-component/contact/contact-type';
import { PrimasTableComponent } from '../template-management/primas-table/primas-table.component';
import { AddGroupContactComponent } from 'src/app/modules/admin/contact-management/add-group-contact/add-group-contact.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContactComponent } from '../stand-alone-component/contact/contact.component';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { ReturnResult } from '../../models/return-result';
import { DynamicContentService } from '../../services/dynamic-content.service';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Helper } from '../../utility/Helper';
import { SaleLeadService } from 'src/app/modules/admin/sale-lead-management/sale-lead.service';
import { ProfileService } from 'src/app/modules/admin/profile-management/profile.service';
import { Contact, ContactReferenceEntitiesViewModel } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators';
import { NumberFormatPipe } from '../../pipes/number-format.pipe';
import { PrimasConfirmPopComponent } from '../stand-alone-component/primas-confirm-pop/primas-confirm-pop.component';
import { EntityColorEnums, EntityIconEnums, EntityNameEnums } from '../../enums/entity-color.enums';
import { SettingService } from '../../services/setting.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserExperienceTrackingService } from '../user-experience-tracking/user-experience-tracking.service';
import { PhoneExtensionPipe } from '../../pipes/phone-extension.pipe';
import { NbAccessChecker } from '@nebular/security';

@Component({
  selector: 'app-contact-relationship-grid',
  templateUrl: './contact-relationship-grid.component.html',
  styleUrls: ['./contact-relationship-grid.component.scss']
})
export class ContactRelationshipGridComponent implements OnInit, OnChanges {

  @ViewChild('primasTable', { static: true }) primasTable: PrimasTableComponent;
  @ViewChild('primaryContact', { static: true }) primaryContact: TemplateRef<any>;
  @ViewChild('name', { static: true }) name: TemplateRef<any>;
  @ViewChild('lName', { static: true }) lName: TemplateRef<any>;
  @ViewChild('birthDate', { static: true }) birthDate: TemplateRef<any>;
  @ViewChild('email', { static: true }) email: TemplateRef<any>;
  @ViewChild('contactEmail', { static: true }) contactEmail: TemplateRef<any>;
  @ViewChild('contactPhone', { static: true }) contactPhone: TemplateRef<any>;
  @ViewChild('contactType', { static: true }) contactType: TemplateRef<any>;
  @ViewChild('confirmRemoveContactPop', { static: true }) confirmRemoveContactPop: TemplateRef<PrimasConfirmPopComponent>;
  @ViewChild('phoneExtTemp', { static: true }) phoneExtTemp: TemplateRef<any>;

  @Input() relationshipId: string;
  @Input() relationshipType: string = 'Profile';
  @Input() isHidePrimaryContact: boolean = true;
  @Input() maxHeight: string = '45vh';
  @Input() columnsOrder: any[];
  @Input() showContactType: boolean = false;
  @Input() formatData: (row: any) => any;
  @Input() readonlyGrid: boolean = false;

  @Output() refreshParentData: EventEmitter<any> = new EventEmitter<any>();
  @Output() getPrimaryContactRelationship: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshDataValue: EventEmitter<any> = new EventEmitter<any>();
  columnsGridAll = [];
  contactValidEmail = [RxwebValidators.email()]; messageErrorEmail = "Email is not valid";
  //contactValidPhone = [RxwebValidators.pattern({ expression: { phone: /^[(]?(\d|\+)\s?(?:[\d-.x\s()]*)$/ } })]; messageErrorPhone = "Phone is not valid";
  dialogRef: MatDialogRef<ContactComponent, any>;
  userUnsubscribed = [];

  // 2024_01_23 ducqm start add
  removeDialogRef: MatDialogRef<any>;
  removeDataInfo: ContactReferenceEntitiesViewModel;
  isLoading: boolean = false;
  isUseNewUI: boolean = false;
  domain: string = "";
  allowDeleteContact: boolean = false;

  entityName = EntityNameEnums;
  entityColor = EntityColorEnums;
  entityIcon = EntityIconEnums;
  environment = environment;
  // 2024_01_23 ducqm end add

  resource = permissionContactTab;
  rowClass = (row) => {
    var color: string = '';
    if (row)
      color = row?.profileId != this.relationshipId ? 'read-color' : '';
    return color;
  }

  colClass = (col) => {
    return this.rowClass(col.row);
  }

  addEditComponent = AddGroupContactComponent;
  isPerPhoneExt: boolean = false;
  phoneExtValid = { valid: [RxwebValidators.maxLength({ value: 6 }), RxwebValidators.digit()], message: "Phone Extension is not valid" };

  constructor(
    private router: Router,
    private datePipe: DateFormatPipe,
    private contactService: ContactService,
    private dialog: MatDialog,
    private toastService: NbToastrService,
    public phonePipe: PhoneFormatPipe,
    private dynamicContentService: DynamicContentService,
    private pipeDate: DatePipe,
    private saleLeadService: SaleLeadService,
    private profileService: ProfileService,
    public numberPipe: NumberFormatPipe,
    public settingService: SettingService,
    public userExperienceTrackingService: UserExperienceTrackingService,
    public phoneExtPipe: PhoneExtensionPipe,
    private accessChecker: NbAccessChecker,
  ) {
    // Check the current path is profile detail or not
    var currentURL = this.router.url;
    if (!currentURL.includes("profile") && this.relationshipType === 'Profile') {
      this.allowDeleteContact = true;
    }

    this.settingService.getSettingByKeyAndGroup("IS_USE_NEW_UI_DETAIL", "SYSTEM").subscribe(resp => {
      if (resp != null && resp?.result != null) {
        this.isUseNewUI = parseInt(resp.result.value) > 0;
      }
    })

    this.settingService.getSettingByName('BACK-OFFICE', 'DOMAIN').subscribe(res => {
      if (res.result) {
        this.domain = res.result.value.endsWith('/') ? res.result.value : res.result.value + '/';
      }
    });

    this.accessChecker.isGranted("view", "contact-phone-extension")
      .subscribe(resp => this.isPerPhoneExt = resp);
  }

  ngOnChanges(changes: SimpleChanges) {
    const inputChanges = changes.relationshipId;
    if (inputChanges.firstChange) {
      if (inputChanges.previousValue) {
        this.refreshData(true);
      }
    }
  }
  ngOnInit(): void {
    this.profileService.GetCommunicationLogUnsubscribed(this.relationshipId).subscribe((resp) => {
      if (resp.result) {
        this.userUnsubscribed = resp.result;
      }
    })

    this.columnsGridAll = [
      {
        name: 'Primary',
        prop: 'primaryContact',
        filter: false,
        cellTemplate: this.primaryContact,
        maxWidth: 100
      },
      // {
      //   name: 'Salutation',
      //   prop: 'contact.saluation',
      //   filter: false,
      //   maxWidth: 75,
      // },
      {
        name: 'First Name',
        prop: 'contact.contactName',
        filter: true,
        cellTemplate: this.name,
      },
      {
        name: 'Last Name',
        prop: 'contact.contactLastName',
        filter: true,
        cellTemplate: this.lName
      },
      {
        name: 'Contact Type',
        prop: 'contact.contactContactTypes',
        filter: {
          filterType: PrimasFilterType.DropDown,
          filterValue: this.contactService.GetAllContactTypeAsync().pipe(
            map(x => {
              if (x.result) {
                return x.result.map(item => {
                  return { text: item.contactTypeName, value: item.contactTypeId };
                });
              } else return [];
            })),
          displayText: 'text',
          displayValue: 'value',
        },
        sortable: false,
        cellTemplate: this.contactType
      },
      {
        name: 'Email',
        prop: 'contact.contactEmail',
        filter: true,
        pipe: this.phonePipe,
        cellTemplate: this.contactEmail,
      },

      {
        name: 'Phone',
        prop: 'contact.contactPhone',
        filter: true,
        pipe: this.phonePipe,
        cellTemplate: this.contactPhone,
      },
      {
        name: 'Phone Ext',
        prop: 'contact.primaryPhoneExt',
        filter: true,
        import: true,
        pipe: this.phoneExtPipe,
        cellTemplate: this.phoneExtTemp
      },
      {
        name: 'Contact Company',
        prop: 'contact.relationship',
        filter: true,
        // filter: {
        //   filterType: PrimasFilterType.DropDown,
        //   filterValue: this.dynamicContentService.getDynamicContentByType('general_company').pipe(
        //     map(x => {
        //       if (x.result) {
        //         return x.result.map(item => {
        //           return { text: item.content, value: item.content };
        //         });
        //       } else return [];
        //     })),
        //   displayText: 'text',
        //   displayValue: 'value',
        // },
      },
      {
        name: 'Secondary Email',
        prop: 'contact.secondaryEmail',
        filter: true,
      },
      {
        name: 'Secondary Phone',
        prop: 'contact.secondaryPhone',
        filter: true,
        pipe: this.phonePipe
      },
      {
        name: 'Secondary Phone Ext',
        prop: 'contact.secondaryPhoneExt',
        filter: true,
        import: true,
        pipe: this.phoneExtPipe,
      },
      {
        name: 'Cell Phone',
        prop: 'contact.cellPhone',
        filter: true,
        pipe: this.phonePipe
      },
      {
        name: 'Cell Phone Ext',
        prop: 'contact.cellPhoneExt',
        filter: true,
        import: true,
        pipe: this.phoneExtPipe,
      },
      {
        name: 'Website',
        prop: 'contact.website',
        filter: true,
      },
      {
        name: 'Source',
        prop: 'contact.source',
        filter: true,
      },
      {
        name: 'LinkedIn',
        prop: 'contact.linkedIn',
        filter: true,
        permissionColumn: 'linked-in-contact-table'
      },
      {
        name: 'Department',
        prop: 'contact.department',
        filter: true,
        // filter: {
        //   filterType: PrimasFilterType.DropDown,
        //   filterValue: this.dynamicContentService.getDynamicContentByType('general_department').pipe(map(x => {
        //     if (x.result) {
        //       return x.result.map(item => {
        //         return { text: item.content, value: item.content };
        //       });
        //     } else return [];
        //   })),
        //   displayText: 'text',
        //   displayValue: 'value',
        // },
      },
      {
        name: 'Birthday',
        prop: 'contact.birthDate',
        filter: true,
        cellTemplate: this.birthDate
      },
      {
        name: 'Description',
        prop: 'contact.description',
        filter: true,
      },
      {
        name: 'Facebook',
        prop: 'contact.facebook',
        filter: true,
      },
      {
        name: 'Skype',
        prop: 'contact.skype',
        filter: true,
      },
      {
        name: 'WhatsApp',
        prop: 'contact.whatsApp',
        filter: true,
      },
      {
        name: 'Tiktok',
        prop: 'contact.tiktok',
        filter: true,
      },
      {
        name: 'Address',
        prop: 'contact.location.address',
        filter: true,
      },
      {
        name: 'Address2',
        prop: 'contact.location.address2',
        filter: true,
      },
      {
        name: 'City',
        prop: 'contact.location.city',
        filter: true,
      },
      {
        name: 'State',
        prop: 'contact.location.state',
        filter: true,
        // filter: {
        //   filterType: PrimasFilterType.DropDown,
        //   filterValue: this.dynamicContentService.getDynamicContentByType('location-state').pipe
        //     (map(x => {
        //       if (x.result) {
        //         return x.result
        //           .map(item => item.content)
        //           .sort(Helper.alphabetically(true, false))
        //           .map(item => Object.assign({ text: item, value: item }));
        //       } else return [];
        //     })),
        //   displayText: 'text',
        //   displayValue: 'value',
        // },
      },
      {
        name: 'Region',
        prop: 'contact.location.region',
        filter: true,
      },
      {
        name: 'Country',
        prop: 'contact.location.country',
        filter: {
          filterType: PrimasFilterType.DropDown,
          filterValue: this.dynamicContentService.getDynamicContentByType('country').pipe
            (map(x => {
              if (x.result) {
                return x.result
                  .map(item => item.content)
                  .sort(Helper.alphabetically(true, false))
                  .map(item => Object.assign({ text: item, value: item }));
              } else return [];
            })),
          displayText: 'text',
          displayValue: 'value',
        },
      },
      {
        name: 'Zipcode',
        prop: 'contact.location.zipcode',
        filter: true,
      },
    ];

    if (!this.isPerPhoneExt)
      this.columnsGridAll = [...this.columnsGridAll.filter(x =>
        x.prop !== 'contact.primaryPhoneExt' &&
        x.prop !== 'contact.secondaryPhoneExt' &&
        x.prop !== 'contact.cellPhoneExt')]

    if (!this.showContactType)
      this.columnsGridAll = [...this.columnsGridAll.filter(x => x.prop !== 'contact.contactContactTypes')]

    if (this.columnsOrder && this.columnsOrder.length > 0) {
      this.columnsGridAll = [...this.columnsOrder.map(x => Object.assign(this.columnsGridAll.find(y => y.prop == x.prop) || x))];
    }

    this.primasTable.page.pageNumber = 1;
    if (this.isHidePrimaryContact) {
      this.columnsGridAll.splice(0, 1);
    }
  }

  refreshData(reset: boolean = false, data?: any): void {
    if (!this.relationshipId) return;
    this.primasTable.isLoading = 1;
    if (reset) {
      this.primasTable.page.pageNumber = 0;
      this.primasTable.cache = {};
      this.primasTable.rows = [];
      this.primasTable.selected = [];
      this.primasTable.table.offset = 0;
      this.primasTable.table.bodyComponent._offset = 0;
      this.primasTable.page.pageNumber = 0;
    }
    this.primasTable.page.size = -1;
    this.contactService
      .getContactRelationShip(this.relationshipId, this.relationshipType, this.primasTable.page)
      .subscribe((res) => {
        if (this.formatData && res?.result?.data?.length > 0)
          res.result.data = res.result.data.map(x => {
            x = this.formatData(x);
            return x;
          });
        this.primasTable.setData(res.result);
        this.refreshDataValue.emit(true);
        if (this.getPrimaryContactRelationship.observers.length > 0) {
          this.getPrimaryContactRelationship.emit(res.result.data);

        }
      });
  }

  onClickEdit({ row, rowIndex }) {
    if (this.readonlyGrid) return;

    const isSetPrimaryContact = this.relationshipId != row.profileId ? false : !this.isHidePrimaryContact;
    this.dialogRef = this.dialog.open(ContactComponent, {
      disableClose: true,
      autoFocus: false,
      data: {
        contactLst: [row.contact],
        isAddMoreBtn: false,
        profileId: this.relationshipType == 'Profile' ? row.profileId : null,
        buyerId: this.relationshipType == 'Buyer' ? this.relationshipId : null,
        selfGetApi: false,
        isDialog: true,
        includeLocation: true,
        isUpdateAction: true,
        contactIndex: `${row.contact.contactName ?? ''} ${row.contact.contactLastName ?? ''}`,
        readonlyContact: false,
        isSetPrimaryable: isSetPrimaryContact,
        closeDialogEvent: (result) => {
          if (this.dialogRef) {
            this.dialogRef.close(result);
          }
        },
      }
    });
    this.dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.refreshData();
        if (this.refreshParentData) this.refreshParentData.emit(res);
      }
    });
  }
  changeColor(row) {
    var color
    if (this.userUnsubscribed && row?.contact?.contactEmail) {
      var getColor = this.userUnsubscribed.includes(row.contact.contactEmail);
      if (getColor) {
        color = 'red';
      }
    }
    return color;
  }
  openConfirmDialog(row): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to unlink this contact?`
      }
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.contactService.unLinkContactAsync(row, this.relationshipId, this.relationshipType).subscribe((item: ReturnResult<boolean>) => {
          if (item.result) {
            this.toastService.success(`Unlink contact ${row.contactName ?? ''} ${row.contactLastName ?? ''} successfully!`, 'success');
            this.refreshData(true);
          }
        });
      }
    });
  }

  editInlineRow(value: any, prop: string, row: Contact) {
    if (!prop) {
      this.toastService.danger('Failed', "Error");
      return;
    }
    let contactDetailModel: Contact = Object.assign({}, row, { [prop]: value ?? 0 });
    if (contactDetailModel) {
      this.contactService.editInlineContact(contactDetailModel).subscribe(resp => {
        if (resp.message) return;
        if (!resp.message) {
          if (resp.result == true) {
            this.toastService.success('Saved', "Success");
          }
          else {
            this.toastService.danger('Failed', "Error");
          }
          this.refreshData();
        }
      })
    }
  }

  // 2024_01_23 ducqm start add
  openRemoveConfirmPop(row) {
    this.isLoading = true;
    this.contactService.getReferenceContactRemove(row.contactId).subscribe({
      next: res => {
        if (res.result) {
          this.removeDataInfo = res.result;
        }
      },
      complete: () => { this.isLoading = false; },
      error: () => { this.isLoading = false; }
    })
    this.removeDialogRef = this.dialog.open(this.confirmRemoveContactPop, {
      width: '30vw'
    });
    this.removeDialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.contactService.deleteContact(row.contactId).subscribe({
          next: result => {
            if (result.result) {
              this.toastService.success('Deleted contact successfully', 'Success');
            }
          },
          complete: () => { this.refreshData(true); },
          error: () => { this.refreshData(true); }
        });

      }
    });
  }

  closeRemoveDialog(value: boolean) {
    this.removeDialogRef.close(value);
  }

  openRemovedNewTab(entityId: string, entity: string) {
    const pageSession = this.userExperienceTrackingService.getCurrentSessionPage();
    switch (entity) {
      case EntityNameEnums.Opportunity:
        Helper.pushBackURL();
        if (this.isUseNewUI) Helper.circleDetail(entityId, 'opportunity-crm', pageSession, this.router, this.userExperienceTrackingService);
        else Helper.circleDetail(entityId, 'opportunity', pageSession, this.router, this.userExperienceTrackingService);
        break;
      case EntityNameEnums.SaleAccount:
        Helper.pushBackURL();
        if (this.isUseNewUI) Helper.circleDetail(entityId, 'sale-account-crm', pageSession, this.router, this.userExperienceTrackingService);
        else Helper.circleDetail(entityId, 'sale-account', pageSession, this.router, this.userExperienceTrackingService);
        break;
      default:
        Helper.pushBackURL();
        Helper.circleDetail(entityId, 'profile', pageSession, this.router, this.userExperienceTrackingService);
        break;
    }
  }
  // 2024_01_23 ducqm end add
}
