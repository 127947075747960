import { OrderMapping } from "../order-mapping";
import { FilterMapping } from "./filter-mapping";

/**
 * An object used to get page information from the server
 */
export class Page {
    // The number of elements in the page
    size: number = 25;
    // The total number of elements
    totalElements: number = 0;
    // The total number of pages
    totalPages: number = 0;
    // The current page number
    pageNumber: number = 0;
    viewId = null;

    orders?: OrderMapping[];

    filter?: FilterMapping[];
    // 2022-tienlm add start
    searchValue?: string;
    // 2022-tienlm add end
    selected?: string[];
    searchOptionValue?: string;
    changesColumnNameExport?: ChangesColumnNameExport[];
    algoliaSearchKeyword?: string;
}

export class ChangesColumnNameExport {
    oldColumnName?: string;
    newColumnName?: string;
}
export class SupportTicketPage extends Page {
    showAllSupportTicket?: boolean
}

export class ContactPage extends Page {
    relationshipId: string;
    type: string;
}
export interface ProfileDeleteFilter {
    typeName: string
    isTemporary: boolean;
    archive: boolean;
    page: Page;
}
export class InternalCampaignPage {
    contactGroupLst: number[];
    isGetTotalRows: boolean
}
