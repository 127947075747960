import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { environment } from 'src/environments/environment';
import { DataField, DataFieldModel } from '../../../data-field-management/data-field-model';
import { AutomateDataState, AutomateDataStateRequiredViewModel } from './automate-datastate-model';

@Injectable({
  providedIn: 'root'
})
export class AutomateDatastateService {
  baseUrl = environment.apiAutomateDataState;
  dataStateProfile: ReplaySubject<AutomateDataStateRequiredViewModel> = new ReplaySubject<AutomateDataStateRequiredViewModel>(1);
  mergeTag$: ReplaySubject<any> = new ReplaySubject<any>(1);
  automateDataStateLst = new BehaviorSubject<AutomateDataState[]>(undefined);
  constructor(private http: HttpClient) { }

  requestIsSatisfiedDataState(profileId: string) {
    if (this.dataStateProfile != null) {
      this.dataStateProfile.next(null)
    }
    this.http.get<ReturnResult<AutomateDataStateRequiredViewModel>>(`${this.baseUrl}/IsSatisfiedDataState/${profileId}`).pipe(first())
      .subscribe(resp => {
        if (resp.result) this.dataStateProfile.next(resp.result);
      });
  }

  getIsSatisfiedDataState(): Observable<AutomateDataStateRequiredViewModel> {
    return this.dataStateProfile.asObservable();
  }

  changeAutomateStateByProfileId(profileId: string): Observable<ReturnResult<boolean>> {
    return this.http.get<ReturnResult<boolean>>(`${this.baseUrl}/ChangeAutomateStateByProfileId/${profileId}`);
  }

  requestMergeTagEmail() {
    this.http.get<ReturnResult<any>>(`${this.baseUrl}/GetMergeTagEmail`).pipe(first()).subscribe(resp => {
      if (resp.result) this.mergeTag$.next(resp.result);
    });
  }

  getMergeTagEmail(): Observable<any> {
    return this.mergeTag$.asObservable();
  }

  getDataFieldPaging(page: Page): Observable<ReturnResult<PagedData<DataField>>> {
    return this.http.post<ReturnResult<PagedData<DataField>>>(`${this.baseUrl}/GetPagingDataFields`, page);
  }

  saveDataField(dataField: DataFieldModel): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/AddEditDataField`, dataField);
  }

  deleteDataField(id: number): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/DeleteDataField/${id}`);
  }

  deleteDataFields(ids: number[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteDataFields`, ids);
  }
  refreshAllAutomateDataState(event: (data: boolean) => void) {
    if (event) {
      event.call(true);
    }
    this.http.get<ReturnResult<AutomateDataState[]>>(`${this.baseUrl}/GetAllOpportunityAutomateDataState`).subscribe({
      next: data => {
        if (data.result) {
          this.automateDataStateLst.next(data.result);
        }
      },
      complete: () => {
        if (event) {
          event.call(false);
        }
      }
    });
  }
  getDataStateList(): Observable<AutomateDataState[]> {
    return this.automateDataStateLst.asObservable();
  }
  getOpportunityDataState(): Observable<ReturnResult<AutomateDataState[]>> {
    return this.http.get<ReturnResult<AutomateDataState[]>>(`${this.baseUrl}/GetAllOpportunityAutomateDataState`);
  }

  requestMergeTag() {
    return this.http.get<ReturnResult<any>>(`${this.baseUrl}/GetMergeTagEmail`)
      .pipe(first(), tap(resp => this.mergeTag$.next(resp.result)));
  }
}
