import { CdkDragMove, DragAxis } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ElementRef, Inject, Input, Output, NgZone, OnInit, TemplateRef, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-drag-block-screen',
  templateUrl: './drag-block-screen.component.html',
  styleUrls: ['./drag-block-screen.component.scss']
})
export class DragBlockScreenComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() ngTemplateHeaderOutlet: TemplateRef<any>;
  @Input() ngTemplateHeaderOutletContext: TemplateRef<any>;
  @Input() ngTemplateOutlet: TemplateRef<any>;
  @Input() ngTemplateOutletContext: any;
  @Input() onNoteInlineChanged: EventEmitter<boolean>;

  @ViewChild('resizeBox') resizeBox: ElementRef;
  @ViewChild('dragHandleCorner') dragHandleCorner: ElementRef;
  @ViewChild('dragHandleRight') dragHandleRight: ElementRef;
  @ViewChild('dragHandleBottom') dragHandleBottom: ElementRef;
  width: number;
  height: number;
  defaultHeight: string = "60vh";
  defaultWidth: string = "50vw";
  destroy$: Subject<void> = new Subject();
  isChangeNote: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<DragBlockScreenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ngZone: NgZone,
    private dialog: MatDialog,
  ) {
    this.ngTemplateOutlet = data?.ngTemplateOutlet || this.ngTemplateOutlet;
    this.ngTemplateOutletContext = data?.ngTemplateOutletContext || this.ngTemplateOutletContext;
    this.ngTemplateHeaderOutlet = data?.ngTemplateHeaderOutlet || this.ngTemplateHeaderOutlet;
    this.ngTemplateHeaderOutletContext = data?.ngTemplateHeaderOutletContext || this.ngTemplateHeaderOutletContext;

    this.onNoteInlineChanged = data?.onNoteInlineChanged || this.onNoteInlineChanged;
  }

  ngOnInit(): void {
    this.setDefaultHeightContainer();
    this.dialogRef.addPanelClass('custom-z-drag');
    this.dialogRef.updateSize('900px', '60vh');
  }

  ngAfterViewInit() {
    this.setDefaultHeightContainer(false);
    this.setAllHandleTransform();

    if (this.onNoteInlineChanged)
      this.onNoteInlineChanged.asObservable()
        .pipe(takeUntil(this.destroy$))
        .subscribe(x => this.isChangeNote = x);
  }

  ngOnDestroy(): void {
    if (this.destroy$) {
      this.destroy$.next();
      this.destroy$.complete();
    }
  }

  get resizeBoxElement(): HTMLElement {
    return this.resizeBox.nativeElement;
  }

  get dragHandleCornerElement(): HTMLElement {
    return this.dragHandleCorner.nativeElement;
  }

  get dragHandleRightElement(): HTMLElement {
    return this.dragHandleRight.nativeElement;
  }

  get dragHandleBottomElement(): HTMLElement {
    return this.dragHandleBottom.nativeElement;
  }

  setDefaultHeightContainer(enable: boolean = true) {
    let childDom = document.querySelector(".container-drag") as HTMLElement;
    let enableStyle = `max-width: ${this.defaultWidth}; max-height: ${this.defaultHeight}; height: ${this.defaultHeight}; width: ${this.defaultWidth}; position: relative;`;
    let disableStyle = `height: calc(100% - 42px); width: calc(100% - 2px); padding: 0; max-width: 80vw;`;
    if (childDom) {
      let matDialogDom = childDom.parentElement?.parentElement as HTMLElement;
      if (matDialogDom) {
        let matDialogStyle = matDialogDom.style.cssText;
        matDialogDom.style.cssText = `padding: 0; overflow: hidden; ${matDialogStyle}`;
      }

      if (enable) {
        childDom.style.cssText = `${enableStyle}`;
      } else {
        childDom.style.cssText = `${disableStyle}`;
      }
    }
  }

  setAllHandleTransform() {
    const rect = this.resizeBoxElement.getBoundingClientRect();
    this.setHandleTransform(this.dragHandleCornerElement, rect, 'both');
    this.setHandleTransform(this.dragHandleRightElement, rect, 'x');
    this.setHandleTransform(this.dragHandleBottomElement, rect, 'y');
  }

  setHandleTransform(
    dragHandle: HTMLElement,
    targetRect: ClientRect | DOMRect,
    position: 'x' | 'y' | 'both'
  ) {
    const dragRect = dragHandle.getBoundingClientRect();
    const translateX = targetRect.width - dragRect.width;
    const translateY = targetRect.height - dragRect.height;

    if (position === 'x') {
      dragHandle.style.transform = `translate(${translateX}px, 0)`;
    }

    if (position === 'y') {
      dragHandle.style.transform = `translate(0, ${translateY}px)`;
    }

    if (position === 'both') {
      dragHandle.style.transform = `translate(${translateX}px, ${translateY}px)`;
    }
  }

  dragMove(dragHandle: HTMLElement, $event: CdkDragMove<any>) {
    this.ngZone.runOutsideAngular(() => {
      this.resize(dragHandle, this.resizeBoxElement);
    });
  }

  resizeNote() {
    //Because at the first time render the note, the note doesn't apply the css.
    setTimeout(() => {
      let quillDom = document.getElementsByClassName("note-quill-editor")[0];
      let containerEditorDom = quillDom?.querySelector(".ql-container.ql-snow") as HTMLElement;
      let toolbarEditorDom = quillDom?.querySelector(".ql-toolbar.ql-snow") as HTMLElement;
      let toolbarEditorDomHeight = toolbarEditorDom?.offsetHeight;
      if (containerEditorDom && toolbarEditorDom && toolbarEditorDomHeight) {
        containerEditorDom.style.cssText = `max-height: calc(100% - ${toolbarEditorDomHeight}px); overflow: auto;`;
      }
    }, 500)
  }

  resize(dragHandle: HTMLElement, target: HTMLElement) {
    const dragRect = dragHandle.getBoundingClientRect();
    const targetRect = target.getBoundingClientRect();

    const width = dragRect.left - targetRect.left + dragRect.width;
    const height = dragRect.top - targetRect.top + dragRect.height;

    this.dialogRef.updateSize((width + 2) + 'px', (height + 40) + 'px');
    target.style.width = width + 'px';
    target.style.height = height + 'px';

    this.resizeNote();

    this.setAllHandleTransform();
  }

  async confirmClose() {
    try {
      if (this.isChangeNote) {
        let confirmDialog = await this.dialog.open(ConfirmModalComponent, {
          data: { message: `You have unsaved changes! If you leave, your NOTE changes will be lost.` }
        })

        let confirmResult = await confirmDialog.afterClosed().toPromise();
        if (!confirmResult) return;
      }

      this.dialogRef.close();
    }
    catch (ex) {
      console.error(ex);
    }
  }
}
