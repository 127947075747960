import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { NbToastrService } from '@nebular/theme';
import { HotToastService } from '@ngneat/hot-toast';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ProfileDetailModel } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { UserModel } from 'src/app/modules/admin/user-management/user-model';
import { UserService } from 'src/app/modules/admin/user-management/user.service';
import { UserUploadManagementService } from 'src/app/modules/admin/user-upload-management/user-upload-management.service';
import { MoveFileViewModel, UserUpload } from 'src/app/modules/admin/user-upload-management/user-upload.model';
import { permissionSaleAccountFile } from 'src/app/shared/contances/permission';
import { EntityNameEnums } from 'src/app/shared/enums/entity-color.enums';
import { PrimasFilterType } from 'src/app/shared/enums/primas-value-type.enum';
import { TabProfile } from 'src/app/shared/enums/tab-profile.enum';
import { DateTimeFormatPipe } from 'src/app/shared/pipes/date-time-format.pipe';
import { Helper } from 'src/app/shared/utility/Helper';
import { environment } from 'src/environments/environment';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { PrimasTableComponent } from '../../template-management/primas-table/primas-table.component';
import { ProfileFileManageOwnerComponent } from './profile-file-manage-owner/profile-file-manage-owner.component';
import { ProfileFileTabHistoryComponent } from './profile-file-tab-history/profile-file-tab-history.component';
import { UploadUrlComponent } from './upload-url/upload-url.component';
import { MicrosoftOffice365Service } from '../../../services/microsoft-office-365.service';
import { SettingService } from '../../../services/setting.service';
import { MoveObjectDialogComponent } from '../../move-object-dialog/move-object-dialog.component';

@Component({
  selector: 'app-profile-file-tab',
  templateUrl: './profile-file-tab.component.html',
  styleUrls: ['./profile-file-tab.component.scss']
})
export class ProfileFileTabComponent implements OnInit, OnDestroy {
  @ViewChild('profileFileTable') profileFileTable: PrimasTableComponent;
  @ViewChild('fileNameCol', { static: true }) fileNameCol: TemplateRef<any>;
  @ViewChild('ownersCol', { static: true }) ownersCol: TemplateRef<any>;
  @ViewChild('revisionCol', { static: true }) revisionCol: TemplateRef<any>;
  @ViewChild('fileInput', { static: true }) fileInput: TemplateRef<any>;
  @ViewChild('dataType', { static: true }) dataType: TemplateRef<any>;

  @Input() profileModel: ProfileDetailModel;
  @Input() perm: any;
  @Input() fromDialog: boolean = false;
  // Required when fromDialog = true if use as tab, don't need to pass in;
  @Input() entity: string;
  // This is from the parent, can be configurable. If user want to upload from outside:
  @Input() customUserUploadTemplate: UserUpload;
  @Input() maxHeight: string = '45vh';
  @Output() refreshDataValue: EventEmitter<boolean> = new EventEmitter<boolean>();

  isURL: boolean = false;
  constructor(
    private service: UserUploadManagementService,
    private dateTimeFormatPipe: DateTimeFormatPipe,
    private userService: UserService,
    private toast: NbToastrService,
    private dialog: MatDialog,
    private hotToast: HotToastService,
    private authService: NbAuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProfileFileTabComponent>,
    private microsoftOffice365Service: MicrosoftOffice365Service,
    private settingService: SettingService
  ) {
    this.userService.allUserList().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.userLst = res;
    });
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload();
        }
      });
    // inject dialog data:
    this.profileModel = data.profileModel ?? this.profileModel;
    this.perm = data.perm ?? this.perm;
    this.fromDialog = data.fromDialog ?? this.fromDialog;
    this.entity = data.entity ?? this.entity;
    this.defaultSetEntity();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private destroy$: Subject<void> = new Subject<void>();
  columns = [];
  resource = permissionSaleAccountFile;
  downloading = false;
  downloadPercent = 0;
  selectedRowIndex = -1;
  userLst: UserModel[] = [];
  selectedRow: UserUpload = null;
  selectedFile = null;
  user: any;
  anyUpdate = false;
  // Add new File Only
  uploading = false;
  uploadedFile: any;
  isOpenFile = false;

  ngOnInit() {
    this.columns = [
      {
        name: '',
        prop: 'dataType',
        maxWidth: 33,
        filter: false,
        sortable: false,
        cellTemplate: this.dataType,
        cellClass: 'remove-padding text-center',
        frozenLeft: true,
        import: false,
        headerClass: 'text-center remove-padding',
      },
      {
        name: 'File Name',
        prop: 'fileName',
        filter: true,
        import: false,
        frozenLeft: true,
        width: 350,
        cellTemplate: this.fileNameCol
      },
      {
        name: 'Owners',
        prop: 'owners',
        cellTemplate: this.ownersCol,
        import: false,
        width: 200,
        filter: {
          filterType: PrimasFilterType.DropDown,
          filterValue: this.userService.getAllUserModel(),
          displayText: 'userName',
          displayValue: 'id'
        },
      },
      {
        name: 'Revision(s)',
        prop: 'historyFiles',
        filter: false,
        sortable: false,
        import: false,
        cellClass: "text-center",
        cellTemplate: this.revisionCol,
        width: 100,
      },
      {
        name: "Date Created",
        prop: "dateCreated",
        pipe: this.dateTimeFormatPipe,
        headerClass: "text-center filter-date-time-column",
        cellClass: "text-center",
        filter: {
          filterType: PrimasFilterType.DateTime,
        },
        width: 200,
      },
      {
        name: "Date Modified",
        prop: "dateModified",
        pipe: this.dateTimeFormatPipe,
        headerClass: "text-center filter-date-time-column",
        cellClass: "text-center",
        filter: {
          filterType: PrimasFilterType.DateTime,
        },
        width: 200,
      },
    ];
  }


  refreshData(reset: boolean = false): void {
    this.profileFileTable.isLoading = 1;
    let offSetX
    if (this.fromDialog) offSetX = this.profileFileTable.table.bodyComponent.offsetX;
    if (reset) {
      this.profileFileTable.page.pageNumber = 0;
      this.profileFileTable.cache = {};
      this.profileFileTable.rows = [];
      this.profileFileTable.selected = [];
      this.profileFileTable.table.offset = 0;
      this.profileFileTable.table.bodyComponent._offset = 0;
      if (this.fromDialog) offSetX = 0;
    }
    this.profileFileTable.page.size = -1;
    this.service.getProfileEntityUploadFile(this.profileFileTable.page, this.profileModel.typeName, this.profileModel.profileId).subscribe((resp) => {
      if (resp) {
        this.profileFileTable.setData(resp.result);
        this.refreshDataValue.emit(true);
        if (this.fromDialog) setTimeout(() => {
          this.profileFileTable.table.recalculate();
          this.profileFileTable.table['cd'].markForCheck();
          document.body.style.width = 'auto';
          this.profileFileTable.table._offsetX.next(offSetX);
        }, 200);
      }
      this.profileFileTable.isLoading = 0;
    })
  }
  downLoadFile(row: UserUpload, rowIndex: number) {
    if (row.isURL == true && row.physicalPath) {
      window.open(`${row.physicalPath}`, '_blank', 'noreferrer');
    }
    else {
      this.hotToast.info('Your file is almost ready to be downloaded, please wait...');
      this.downloading = true;
      this.selectedRowIndex = rowIndex;
      this.service.downloadAsStream(row.userUploadId).subscribe({
        next: res => {
          this.downloadPercent = res.progress;
          if (res.response instanceof HttpResponse && res.response.body) {
            this.service.downLoadFile(res.response.body, row.fileName, true);
          }
          if (this.downloadPercent == 100) {
            this.downloading = false;
            this.selectedRowIndex = -1;
          }
        },
        complete: () => {
          this.downloading = false;
          this.downloadPercent = 0;
          this.selectedRowIndex = -1;
        }
      });
    }
  }
  get status() {
    if (this.downloadPercent <= 25) {
      return 'danger';
    } else if (this.downloadPercent <= 50) {
      return 'warning';
    } else if (this.downloadPercent <= 75) {
      return 'info';
    } else {
      return 'success';
    }
  }
  displayOwnerName(row: UserUpload) {
    if (row.owners) {
      let owners = row.owners.split(',').filter(x => x.trim() !== '');
      let ownerNames = owners.map(x => {
        if (this.userLst.find(item => item.id === x)) return this.userLst.find(item => item.id === x).userName;
        else return x.substring(0, 5) + '..';
      });
      return ownerNames.join(',');
    }
    return row.owners;
  }
  ChangeURL() {
    let row = this.selectedRow;
    const dialogRef = this.dialog.open(UploadUrlComponent, {
      width: '30vw',
      maxWidth: '30vw',
      data: {
        profileId: this.profileModel.profileId,
        nameId: this.user.nameid,
        modelUpload: row ?? null,
        referenceType: row != null ? row.referenceType : this.profileModel.typeName != null ? this.profileModel.typeName : 'SALEPROFILE',
      }
    });
    dialogRef.afterClosed().subscribe(resp => {
      if (resp) {
        this.refreshData(true);
      }
    });
  }
  async modifyUpload(fileInputEvent: any) {
    let row = this.selectedRow;
    this.selectedFile = fileInputEvent.target.files[0];
    // validating
    let acceptanceExtension = ["doc", "docx", "xls", "xlsx", "ppt", "pdf", "html", "zip", "csv", "txt"];
    let extension = Helper.getFileExtension(fileInputEvent.target.files[0].name) ? Helper.getFileExtension(fileInputEvent.target.files[0].name)[0] : null;
    // let isAccept = extension ? (acceptanceExtension.indexOf(extension) == -1 ? false : true) : false;
    let isAccept = true; // Accept all extensions
    if (isAccept) {

      if (row.userUploadHistories && row.userUploadHistories.length > 0 && !row.userUploadHistories[0].deleted) {
        const isEnableCoreRemoting = (await this.settingService.getSettingByKeyAndGroup("IS_ENABLE_CORE_REMOTING", "OFFICE_365_SSO").toPromise())?.result?.value.toString();
        this.service.modifyUserUploadFile(row.userUploadHistories[0].userUploadHistoryId, this.selectedFile).pipe(tap(x => {
          this.anyUpdate = true;
        }), this.hotToast.observe(
          {
            loading: 'Uploading file...',
            success: (s) => 'file successfully uploaded',
            error: (e) => 'An error occurred while uploading...'
          }
        )).subscribe(async res => {
          if (res.result) {
            this.refreshData(true);
            if (isEnableCoreRemoting != null && parseInt(isEnableCoreRemoting) == 0) {
              var toastRef = this.hotToast.loading('The attachment is waiting to be uploaded to OneDrive', { duration: 2000 });
              try {
                // submitModel.userUploadId = uploadFile.result?.[0]?.userUploadId;
                // submitModel.fileName = uploadFile.result?.[0]?.fileName;
                // const syncFile = await this.microsoftOffice365Service.uploadFileOffice(this.uploadedFile, submitModel).toPromise();
                const userUploadId = res.result?.userUploadId;
                this.microsoftOffice365Service.uploadFileOneDrive(userUploadId).subscribe(res => {
                  if (res.result) {
                    this.hotToast.success('The attachment is successfully added to queue for uploading to OneDrive', { duration: 2000 });
                    toastRef.close();
                  }
                });
              }
              catch (ex) {
                toastRef.close();
                this.hotToast.error('An error occurred while syncing...', { duration: 2000 });
              }
            }
            else {
              this.hotToast.success('The attachment is successfully added to queue for uploading to OneDrive', { duration: 2000 });
            }
          }
        })
      } else {
        this.toast.warning('Upload history of this file has been deleted or not existed.', 'Warning');
      }
    } else {
      this.toast.warning('Please select file with these extensions: doc,docx,xls,xlsx,ppt,pdf,html,zip,csv,txt', "Warning");
    }
  }
  displayFormatFileName(row: UserUpload) {
    if (row.dataType == "URL") {
      row.isURL = true;
      return row.fileName;
    }
    else {
      if (row.fileName) {
        let extension = Helper.getFileExtension(row.fileName)[0];
        let rawName = row.fileName.slice().replace(`.${extension}`, "");
        rawName = rawName.slice(0, rawName.length - 6);
        return rawName + '.' + extension;
      }
      return row.fileName;
    }

  }
  openUserFileHistory(row: UserUpload) {
    if (row && row.userUploadHistories && !row.userUploadHistories[0]?.deleted) {
      let historyRef = this.dialog.open(ProfileFileTabHistoryComponent, {
        disableClose: true,
        autoFocus: false,
        width: '30vw',
        data: {
          userUploadHistoryId: row.userUploadHistories[0].userUploadHistoryId,
          showBottomAction: true,
          isAllowed: !this.getManageOwnerPermissions(row),
          resource: this.perm
        }
      });
      historyRef.afterClosed().subscribe(res => {
        if (res) {
          this.refreshData(true);
        }
      });
    } else {
      this.toast.warning('Upload history of this file has been deleted or not existed.', 'Warning');
    }
  }
  // false is allowed and false for otherwise
  openManageOwner(row: UserUpload) {
    let manageOwnerRef = this.dialog.open(ProfileFileManageOwnerComponent, {
      disableClose: true,
      autoFocus: false,
      width: '40vw',
      data: {
        userUpload: row,
        showActionDialog: true,
      }
    });
    manageOwnerRef.afterClosed().subscribe(res => {
      if (res) {
        this.refreshData(true);
      }
    });
  }
  getManageOwnerPermissions(row: UserUpload) {
    if (row && row.owners && row.owners.includes(this.user.nameid)) return false;
    else return true;
  }
  async onClickDelete(row: UserUpload) {

    var isEnableCoreRemoting = 0;
    const setting = await this.settingService.getSettingByKeyAndGroup("IS_ENABLE_CORE_REMOTING", "OFFICE_365_SSO").toPromise();
    if (setting) {
      isEnableCoreRemoting = Number.parseInt(setting?.result?.value);
    }

    let confirmDialog = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: 'Do you wish to delete this file?'
      }
    });
    confirmDialog.afterClosed().subscribe(res => {
      if (res) {
        this.service.deleteUserUpload(row.userUploadHistories[0]?.userUploadHistoryId).pipe(tap(x => { this.anyUpdate = true })).subscribe(res => {
          if (res.result) {
            if (isEnableCoreRemoting == 0) {
              this.microsoftOffice365Service.deleteUserUpload(row.userUploadHistories[0]?.userUploadHistoryId).subscribe(res => { })
            }

            this.toast.success('Delete File Successfully', 'Success');
            this.refreshData(true);
          }
        });
      }
    })
  }
  private defaultSetEntity() {
    if (this.profileModel && this.fromDialog && !this.entity) {
      switch (this.profileModel.typeName) {
        case EntityNameEnums.SaleAccount:
          this.entity = 'Account';
          break;
        case EntityNameEnums.SaleLead:
          this.entity = 'Lead';
          break;
        case EntityNameEnums.Opportunity:
          this.entity = 'Opportunity';
          break;
        case EntityNameEnums.Profile:
          this.entity = 'Profile'
          break;
        default:
          break;
      }
    }
  }
  onClickDetail() {
    this.dialogRef.close('openDetail');
  }
  onCloseClick() {
    this.dialogRef.close(this.anyUpdate ? 'reset' : null);
  }
  async addFile(fileInputEvent: any) {
    this.uploading = true;
    this.uploadedFile = fileInputEvent.target.files[0];
    let acceptanceExtension = ["doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf", "html", "zip", "csv", "txt"];
    let extension = Helper.getFileExtension(fileInputEvent.target.files[0].name) ? Helper.getFileExtension(fileInputEvent.target.files[0].name)[0] : null;
    // let isAccept = extension ? (acceptanceExtension.indexOf(extension) == -1 ? false : true) : false;
    let isAccept = true;
    if (isAccept) {
      let submitModel = new UserUpload();
      if (!this.customUserUploadTemplate) {
        submitModel.fileName = this.uploadedFile.name;
        submitModel.referenceType = this.profileModel.typeName;
        submitModel.referenceId = this.profileModel.profileId;
        submitModel.owners = this.user.nameid;
      } else {
        submitModel = this.customUserUploadTemplate;
      }

      var toastRef = this.hotToast.loading('Uploading file...', { autoClose: false });
      const isEnableCoreRemoting = (await this.settingService.getSettingByKeyAndGroup("IS_ENABLE_CORE_REMOTING", "OFFICE_365_SSO").toPromise())?.result?.value.toString();
      try {
        const uploadFile = await this.service.uploadFile(this.uploadedFile, submitModel).toPromise();
        if (uploadFile?.result) {
          this.anyUpdate = true;
          this.refreshData(true);
          toastRef.close();

          if ((+isEnableCoreRemoting) == 0) {
            toastRef = this.hotToast.loading('The attachment is waiting to be uploaded to OneDrive', { duration: 2000 });
            try {
              // submitModel.userUploadId = uploadFile.result?.[0]?.userUploadId;
              // submitModel.fileName = uploadFile.result?.[0]?.fileName;
              // const syncFile = await this.microsoftOffice365Service.uploadFileOffice(this.uploadedFile, submitModel).toPromise();
              const userUploadId = uploadFile.result?.[0]?.userUploadId;
              this.microsoftOffice365Service.uploadFileOneDrive(userUploadId).subscribe(res => {
                if (res.result) {
                  this.hotToast.success('The attachment is successfully added to queue for uploading to OneDrive', { duration: 2000 });
                  toastRef.close();
                }
              });
            }
            catch (ex) {
              toastRef.close();
              this.hotToast.error('An error occurred while syncing...', { duration: 2000 });
            }
          }
          else {
            this.hotToast.success('The attachment is successfully added to queue for uploading to OneDrive', { duration: 2000 });
          }
        }
        else {
          toastRef.close();
        }
      }
      catch (ex) {
        console.log(ex);
        toastRef.close();
        this.hotToast.error('An error occurred while uploading...', { duration: 2000 });
      }

    } else {
      this.toast.warning('Please select file with these extensions: doc,docx,xls,xlsx,ppt,pptx,pdf,html,zip,csv,txt', "Warning");
    }
  }

  async openOneDriveFile(row: UserUpload, rowIndex: number) {
    try {
      row["isOpenLoading"] = true;
      row["selectedRowIndex"] = rowIndex;
      const fileDisplayName = row.fileDisplayName ? row.fileDisplayName : row.fileName;
      const isUserExistOrganization = await this.userService.checkUserExistOrganization(this.user.nameid).toPromise();
      if (isUserExistOrganization && isUserExistOrganization.result && row) {
        const extendData = JSON.parse(row.extendData);
        const isGrantPermission = await this.userService.grantPermissionOpenFileOrFolder(this.user.nameid, extendData?.Id).toPromise();
        if (isGrantPermission && isGrantPermission.result) {
          window.open(`${fileDisplayName}`, '_blank');
        }
        else {
          this.toast.warning("Cannot grant permission to access this file.", "Warning");
        }
      } else {
        this.toast.warning("You don't have permission to access this file.", "Warning");
      }
    } catch (ex) {
      console.error(ex);
      this.toast.warning("You don't have permission to access this file.", "Warning");
    }
    finally {
      row["isOpenLoading"] = false;
      row["selectedRowIndex"] = -1;
    }
  }

  async openDownloadFile(row: UserUpload, rowIndex: number) {
    if (row.dataType === "OneDriveURL") {
      this.openOneDriveFile(row, rowIndex);
    } else {
      this.downLoadFile(row, rowIndex);
    }
  }

  async openMoveEntity(row: UserUpload) {
    let moveObjectDialog = this.dialog.open(MoveObjectDialogComponent, {
      disableClose: true,
      autoFocus: false,
      width: '30vw',
      data: {
        userUpload: row,
        object: 'File'
      }
    });

    let dialogResult = await moveObjectDialog.afterClosed().toPromise();

    if (dialogResult && dialogResult.profile) {
      var modelRequest: MoveFileViewModel = {
        source: {
          referenceId: row.referenceId,
          referenceType: row.referenceType,
        },
        destination: {
          referenceId: dialogResult.profile.id,
          referenceType: dialogResult.profile.key,
        },
        userUploadId: row.userUploadId
      }

      const isEnableCoreRemoting = (await this.settingService.getSettingByKeyAndGroup("IS_ENABLE_CORE_REMOTING", "OFFICE_365_SSO").toPromise())?.result?.value.toString();
      let moveFileResult = await this.service.updateFileReference(modelRequest).toPromise();
      if (moveFileResult) {
        this.toast.success("Move file successfully", "Success");
        this.anyUpdate = true;
        this.refreshData(true);

        if ((+isEnableCoreRemoting) == 0) {
          try {
            this.microsoftOffice365Service.updateFileReference(modelRequest).subscribe();
          }
          catch (ex) {
          }
        }
      }
    }
  }
}
