import { TaskPriority } from 'src/app/shared/contances/task-priority';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddEditTaskComponent } from '../../../task-management/add-edit-task/add-edit-task.component';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { Task } from '../../../task-management/task-board/task-board-lane/task/task.model';
import { TaskBoardService } from '../../../task-management/task-board/task-board.service';
import { take, takeUntil } from 'rxjs/operators';
import { Priority, TaskStatus, TaskType } from '../../../task-management/task-board/task-board-lane/task-status.model';
import { Subject } from 'rxjs';
import { QuillConfiguration } from 'src/app/shared/components/stand-alone-component/rich-inline-edit/rich-inline-edit.component';
import { FormControl, FormGroup } from '@angular/forms';
import { UserService } from '../../../user-management/user.service';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-crm-task-template',
  templateUrl: './crm-task-template.component.html',
  styleUrls: ['./crm-task-template.component.scss']
})
export class CrmTaskTemplateComponent implements OnInit {

  @Input() currentProfile
  // @Output() afterCreateNew: EventEmitter<boolean> = new EventEmitter();

  // Change the value to scale up default remain day from DatePicker and RemainDay
  defaultRemainDay: number = 3;
  remainDaySelected: number = 3;

  tabId: number;
  userId: string = null;
  startDate = new Date();
  taskPriority: Priority[];
  buttonChosen: number = 0;
  description: string = "";
  taskType: TaskType[] = [];
  datePickerDay = new Date();
  recommendDay: number[] = [];
  taskStatus: TaskStatus[] = [];
  frmTaskDescription: FormGroup;
  editorOptions = QuillConfiguration
  dueDateOption = [
    {
      display: 'Remains Day',
      value: 'RemainDay'
    },
    {
      display: 'Chosen Date',
      value: 'ChooseDay'
    }
  ]
  selectedOption = null;
  position = { right: '0' };
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public dialModalRef: MatDialogRef<CrmTaskTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private taskService: TaskBoardService,
    private userService: UserService,
    private toast: NbToastrService
  ) {
    if (data) {
      this.position = data.position || this.position;
      this.currentProfile = this.currentProfile || data.currentProfile
    }
  }

  ngOnInit(): void {
    this.buttonChosen = 1;
    this.userService.getUserCurrentLogin().subscribe(res => {
      if (res.result) {
        this.userId = res.result?.id
      }
    });

    this.taskService.taskPriorityList().pipe(takeUntil(this.destroy$)).subscribe(e => {
      this.taskPriority = e;
      this.taskPriority.sort((first, second) => first.priorityOrder - second.priorityOrder);
    });
    this.taskService.taskStatusList().pipe(takeUntil(this.destroy$)).subscribe(e => this.taskStatus = e);
    this.taskService.taskTypeList().pipe(takeUntil(this.destroy$)).subscribe(e => this.taskType = e);

    for (let id = 1; id <= 14; id++) this.recommendDay.push(id);

    this.dialModalRef.updatePosition(this.position);
    this.resetData();
  }

  changeDateRemain(e) {
    if (e && e.value) {
      this.startDate.setTime(new Date().getTime() + (e.value * 3600 * 24 * 1000));
      this.startDate = new Date(this.startDate)
    }
  }

  changeDatePicker(e) {
    if (e && e.value) {
      this.datePickerDay = new Date(e.value);
    }
  }

  onChangeTab() {
    if (this.tabId === 0) {
      this.startDate.setDate(new Date().getDate() + this.remainDaySelected);
      this.startDate = new Date(this.startDate);
    }
    else {
      this.startDate.setDate(new Date().getDate() + this.defaultRemainDay);
      this.startDate = new Date();
    }
  }

  changeType(e) {
    if (e && e.value) {
      this.tabId = this.dueDateOption.findIndex(x => x.value === e.value);
      this.onChangeTab();
    }
  }

  reviewTask() {
    let task = new Task();

    task.taskName = `${(this.buttonChosen === 1) ? "Follow up Email" : "Follow up Phone Call"} (${this.currentProfile.displayName})`
    task.taskStartDate = (this.tabId === 0) ? this.startDate : this.datePickerDay
    task.taskDescription = this.description;
    task.taskTypeId = (this.buttonChosen === 1)
      ? (this.taskType.find(x => x.taskTypeName === "Send Email")?.taskTypeId || 1)
      : (this.taskType.find(x => x.taskTypeName === "Phone Call")?.taskTypeId || 1);
    task.taskPriority = this.taskPriority.find(x => x.priorityName === "High")?.priorityId || 1;

    const dialogRef = this.dialog.open(AddEditTaskComponent, {
      disableClose: true,
      height: '65vh',
      width: '600px',
      panelClass: 'dialog-detail',
      autoFocus: false,
      hasBackdrop: false,
      data: {
        action: TblActionType.Add,
        profile: this.currentProfile,
        position: { left: '0', bottom: '0' },
        model: task
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp) {
        this.buttonChosen = 0;
        this.resetData();
        this.dialModalRef.close({ success: true });
      }
    });
  }

  createTask() {
    let task = new Task();

    task.taskName = `${(this.buttonChosen === 1) ? "Follow up Email" : "Follow up Phone Call"} (${this.currentProfile.displayName})`
    task.taskStartDate = (this.tabId === 0) ? this.startDate : this.datePickerDay
    task.taskDescription = this.description;
    task.taskTypeId = (this.buttonChosen === 1)
      ? (this.taskType.find(x => x.taskTypeName.toLowerCase() === "Send Email".toLowerCase())?.taskTypeId || 1)
      : (this.taskType.find(x => x.taskTypeName.toLowerCase() === "Phone Call".toLowerCase())?.taskTypeId || 1);
    // Priority => Lowest
    task.taskPriority = this.taskPriority.find(x => x.priorityName === "High")?.priorityId || 1;
    // Status => To do
    task.taskStatusId = this.taskStatus.find(x => x.taskStatusName.toLowerCase() == "To Do".toLowerCase())?.taskStatusId || 1
    // Assignee
    task.assigneeTo = this.userId;
    // Relationship
    task.relationshipId = this.currentProfile.profileId;
    task.relationshipType = 'profile';

    this.taskService.saveTask(task).pipe(take(1)).subscribe(resp => {
      if (resp.result && resp.result.success) {
        this.toast.success('Save successfully', 'Success');
        this.buttonChosen = 0;
        this.resetData();
        this.dialModalRef.close({ success: true });
      }
    })
  }

  resetData() {
    this.tabId = 0;
    this.description = "";
    this.defaultRemainDay = 3;
    this.remainDaySelected = 3;
    this.selectedOption = this.dueDateOption[0].value;

    this.startDate.setTime(new Date().getTime() + this.remainDaySelected * 3600 * 24 * 1000);
    this.startDate = new Date(this.startDate);

    this.datePickerDay.setTime(new Date().getTime() + this.defaultRemainDay * 3600 * 24 * 1000);
    this.datePickerDay = new Date(this.datePickerDay);
  }

  closeDialog() {
    this.dialModalRef.close()
  }
}
