<div class="d-flex flex-wrap" mat-dialog-title style="margin: unset; padding: 1px 10px 1px 28px"
    [nbSpinner]="isLoading">
    <div class="d-flex flex-wrap title-custom" fxLayoutGap="10px" fxLayoutAlign="start center">
        <app-dropdown-filter *ngIf="phonesContact" class="custom-dropdown" [columnName]="'Phone number'"
            appearance="standard" [filter]="phonesContact" [value]="outboundPhone" [multiple]="false"
            (onFilter)="outboundPhone = $event">
        </app-dropdown-filter>
        <mat-divider [vertical]="true"></mat-divider>
    </div>
    <div class="ml-auto" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button mat-icon-button aria-label="Refresh" (click)="refresh(true)">
            <mat-icon>refresh</mat-icon>
        </button>
        <button mat-icon-button aria-label="Close the dialog" [mat-dialog-close]="true">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<mat-divider></mat-divider>

<nb-chat #chatComponent class="custom-header" [class.custom-header-optional]="isOptional"
    [style.--resize]="fitHeightBody">
    <div *nbCustomMessage="'loading'" nbSpinnerSize="tiny" [nbSpinner]="true" class="custom-loading-message"></div>
    <nb-chat-message *ngFor="let item of smsHistory" [type]="item.type" [message]="item.text" [reply]="item.reply"
        [sender]="item.userName" [avatar]="'./assets/images/avatar-sms.png'"
        [customMessageData]="item.customMessageData">
    </nb-chat-message>
    <span *nbCustomMessage="'text-custom'; noStyles: true; let isReply=isReply; let data" class="date-custom"
        [class.date-custom-reply]="isReply">
        <i>{{ data | date: 'short'}}</i>
    </span>
</nb-chat>

<div *ngIf="isOptional" fxLayout="column" fxLayoutAlign="start start" class="optional-area pt-2 px-3">
    <div class="mb-2" style="width: 100%" fxLayout="row">
        <div style="width: 100%; margin-left: 40px;" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
            <button mat-raised-button [color]="tabOptional == 0 ? 'primary': ''"
                (click)="tabOptional = 0; changeOptional('Search templates', searchOptional)">
                Templates<mat-icon>chat</mat-icon>
            </button>
            <button mat-raised-button [color]="tabOptional == 1 ? 'primary': ''"
                (click)="tabOptional = 1; getMergeTags(); changeOptional('Search merge tags', searchOptional)">
                Merge Tags<mat-icon>sell</mat-icon>
            </button>
        </div>
        <button mat-icon-button (click)="isOptional = false"><mat-icon>close</mat-icon></button>
    </div>

    <mat-form-field class="custom-chat-form mb-2" appearance="outline">
        <input #searchOptional matInput [placeholder]="searchOptionalPlaceholder" />
        <button mat-icon-button matSuffix color="primary">
            <mat-icon color="primary" matSuffix>search</mat-icon>
        </button>
    </mat-form-field>

    <ng-container [ngSwitch]="tabOptional">
        <ng-container *ngSwitchCase="0" [ngTemplateOutlet]="optionalTemplates"
            [ngTemplateOutletContext]="{ templates: optionalForm.templates }">
        </ng-container>
        <ng-container *ngSwitchCase="1" [ngTemplateOutlet]="optionalMergeTags"
            [ngTemplateOutletContext]="{ mergeTags: optionalForm.mergeTags }">
        </ng-container>
    </ng-container>
</div>

<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px" class="px-4 py-3 border-top">
    <mat-form-field #textFiled fxFlex="calc(100% - 45px)" class="custom-chat-form" appearance="outline">
        <textarea #inputText #autosize="cdkTextareaAutosize" matInput cdkTextareaAutosize cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5" [(ngModel)]="message" (keyup.enter)="sendSMS(message)"
            (keyup)="resizeMessage(textFiled)" placeholder="Type a message" trim="blur">
        </textarea>
        <button mat-icon-button matSuffix color="primary" (click)="isOptional = !isOptional; getTemplates()">
            <mat-icon>title</mat-icon>
        </button>
    </mat-form-field>
    <button nbButton class="send-button" status="primary" (click)="sendSMS(message); resizeMessage(textFiled)">
        <nb-icon icon="paper-plane-outline" pack="nebular-essentials"></nb-icon>
    </button>
</div>

<ng-template #optionalTemplates let-temps="templates">
    <ng-container *ngIf="!temps?.loading; else loadingOptional">
        <ng-container *ngIf="temps?.dataSearch?.length == 0" [ngTemplateOutlet]="blankOptional"
            [ngTemplateOutletContext]="{ obj: 'templates' }">
        </ng-container>

        <nb-list *ngIf="temps?.dataSearch?.length > 0" fxFlex="100" fxLayout="row wrap"
            fxLayoutAlign="space-between start">
            <nb-list-item fxFlex="49" class="optional-item border border-primary rounded mb-1"
                *ngFor="let item of temps?.dataSearch" (click)="selectTemplate(item.body, textFiled)">
                <span class="limit-line" style="--line: 3">{{item.body}}</span>
            </nb-list-item>
        </nb-list>
    </ng-container>
</ng-template>

<ng-template #optionalMergeTags let-mTags="mergeTags">
    <ng-container *ngIf="!mTags?.loading; else loadingOptional">
        <ng-container *ngIf="mTags?.dataSearch?.length == 0" [ngTemplateOutlet]="blankOptional"
            [ngTemplateOutletContext]="{ obj: 'merge tags' }">
        </ng-container>

        <mat-tab-group *ngIf="mTags?.dataSearch?.length > 0" headerPosition="below" style="height: 100%; width: 100%;">
            <mat-tab *ngFor="let obj of mTags?.dataSearch" [label]="obj.name">
                <ng-template mat-tab-label><b>{{obj.name}}</b></ng-template>
                <nb-list fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start">
                    <nb-list-item *ngFor="let item of obj.group" fxFlex="24" [matTooltip]="item.name"
                        class="optional-item merge-tag border border-primary rounded mr-1 mb-1"
                        (click)="selectMergeTag(inputText, item.value)">
                        <span class="limit-line" style="--line: 1">{{item.name}}</span>
                    </nb-list-item>
                </nb-list>
            </mat-tab>
        </mat-tab-group>
    </ng-container>
</ng-template>

<ng-template #loadingOptional>
    <div style="height: 100%; width: 100%" [nbSpinner]="true"></div>
</ng-template>

<ng-template #blankOptional let-obj="obj">
    <app-blank-page style="height: 100%; width: 100%" [message]="'Don\'t exist ' + obj"></app-blank-page>
</ng-template>