<!-- <div appChangeUrlObjects [id]="contactId" [isOpenDialog]="true" ></div> -->

<div fxLayout="row" fxLayoutAlign="space-between end" style="padding:0px 25px 0px 25px">
    <ng-container [ngTemplateOutlet]="data.externalTemplate? data.externalTemplate : ''"
        [ngTemplateOutletContext]="{row: data.row}"></ng-container>

    <button mat-icon-button aria-label="Close the dialog" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
    </button>

    <!-- unused code from tabmode -->
    <!-- <button mat-icon-button aria-label="Close the dialog" appChangeUrlObjects [isClose]="true"
        [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
    </button> -->

</div>
<mat-divider></mat-divider>

<mat-dialog-content>
    <div style="padding:12px 25px 12px 25px">
        <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="12px" style="padding-bottom: 9px">
            <div class="import-advanced-label" fxFlex="20"></div>
            <div   fxFlex="40"><b>Review contact</b></div>
            <div   fxFlex="40"><b>Original contact</b></div>
        </div>
        <mat-divider></mat-divider>

        <div style="overflow:auto; height:80vh">
            <div *ngFor="let item of data.importColumn">
                <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="12px" *ngIf="item.prop != 'location' && item.prop != 'extendGUID'">
                    <div class="import-advanced-label" fxFlex="20">
                        {{item.name}}
                    </div>
                    <div  style="max-height: 200px !important; overflow: auto;" [matTooltip]="item.value ??  'No data display'" fxFlex="40">
                        {{item.value || '_'}}
                    </div>
                    <div  style="max-height: 200px !important; overflow: auto;"  *ngIf="contactData" [matTooltip]="(contactData[item.prop] ?? contactData.location[item.prop])  ??  
                    'No data display'" fxFlex="40">
                        {{(contactData[item.prop] ?? contactData.location[item.prop])  || '_'}}
                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>
        </div>

    </div>
</mat-dialog-content>