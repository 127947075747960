import { EditSettingModel } from 'src/app/shared/models/setting.model';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { shareReplay, tap, timeout } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable, ReplaySubject, BehaviorSubject } from 'rxjs';
import { Setting } from '../models/setting.model';
import { environment } from 'src/environments/environment';
import { WorkFlow } from '../models/workflow.model';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { TaskStatus } from 'src/app/shared/models/task-status.model';
import { TaskType } from 'src/app/shared/models/task-type.model';
import { ResolutionCode, ResolutionCodeModel } from 'src/app/modules/admin/task-management/task-board/task-board-lane/task/task.model';
import { SettingCompany, SettingCompanyViewModel } from 'src/app/modules/admin/setting-management/setting-company/setting-company';
import { EmailTemplate } from 'src/app/modules/admin/setting-management/setting-campaign/email-template';
import { PriorityModel } from 'src/app/modules/admin/setting-management/priority/priority.model';
import { FileModel } from 'src/app/modules/admin/system-log-management/system-log.model';
import { SystemFileEnums } from '../enums/system-log.enum';
import { PageSpeedResponse } from '../models/page-speed.model';
import { SettingMessage } from 'src/app/modules/admin/setting-management/setting-message/setting-message.model';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  baseUrl = environment.apiSetting;
  campaignUrl = environment.apiCampaign;
  taskStatusUrl = environment.apiTaskStatus;
  taskTypeUrl = environment.apiTaskType;
  uniqueSetting = new BehaviorSubject<Setting>(undefined);
  customSettingGroup = new BehaviorSubject<Setting[]>(undefined);
  private _domainList$ = new ReplaySubject<Setting[]>(1);
  private _diagnosticsList$ = new ReplaySubject<Setting[]>(1);
  private taskSettingLst$ = new BehaviorSubject<Setting[]>(undefined);
  private _orderResolutionList$: ReplaySubject<[]> = new ReplaySubject<[]>(1);
  private _configCompany$: ReplaySubject<SettingCompanyViewModel> = new ReplaySubject<SettingCompanyViewModel>(1);
  private viewModelSetting$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private _systemLogsList$: ReplaySubject<FileModel[]> = new ReplaySubject<FileModel[]>(1);

  constructor(private http: HttpClient) {
    //this.getDefaultDomainList();
    //this.getDiagnostics();
  }

  // domainList(): Observable<Setting[]> {
  //   return this._domainList$.asObservable();
  // }
  // getDefaultDomainList() {
  //   return this.http.get<ReturnResult<Setting[]>>(`${this.baseUrl}/GetDomainSetting`).subscribe(
  //     (res: ReturnResult<Setting[]>) => {
  //       this._domainList$.next(res.result)
  //     }
  //   )
  // }
  getDefaultDomainListNoObservable(): Observable<ReturnResult<Setting[]>> {
    return this.http.get<ReturnResult<Setting[]>>(`${this.baseUrl}/GetDomainSetting`);
  }
  getSetting(): Observable<ReturnResult<Setting[][]>> {
    return this.http.get<ReturnResult<Setting[][]>>(`${this.baseUrl}/GetSetting`);
  }
  getCampaignUrl(): Observable<ReturnResult<Setting>> {
    return this.http.get<ReturnResult<Setting>>(`${this.baseUrl}/GetCampaignUrl`);
  }
  editSetting(settingList: Setting[]) {
    return this.http.post(`${this.baseUrl}/UpdateSetting`, settingList);
  }
  //2021-11-26 VHAKhang Add start
  diagnosticsList(): Observable<Setting[]> {
    return this._diagnosticsList$.asObservable();
  }
  getDiagnostics() {
    return this.http.get<ReturnResult<Setting[]>>(`${this.baseUrl}/GetDiagnostics`).subscribe(
      (res: ReturnResult<Setting[]>) => {
        this._diagnosticsList$.next(res.result)
      }
    )
  }
  getLinkScopeWebUrl(): Observable<ReturnResult<Setting>> {
    return this.http.get<ReturnResult<Setting>>(`${this.campaignUrl}/GetLinkScopeWebUrl`);
  }

  getInitialAMDCallStatus(): Observable<ReturnResult<Setting>> {
    return this.http.get<ReturnResult<Setting>>(`${this.baseUrl}/GetInitialAMDCallStatus`);
  }

  getTaskStatus(page: Page): Observable<ReturnResult<PagedData<TaskStatus>>> {
    return this.http.post<ReturnResult<PagedData<TaskStatus>>>(`${this.taskStatusUrl}/GetTaskStatusPaging`, page);
  }

  getTaskType(page: Page): Observable<ReturnResult<PagedData<TaskType>>> {
    return this.http.post<ReturnResult<PagedData<TaskType>>>(`${this.taskStatusUrl}/GetTaskTypePaging`, page);
  }

  deleteTaskStatus(id: number): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.taskStatusUrl}/DeleteTaskStatus/${id}`);
  }

  deleteTaskType(id: number): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.taskStatusUrl}/DeleteTaskType/${id}`);
  }

  deleteTaskStatusAsync(statusID: string[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.taskStatusUrl}/DeleteTaskStatusAysnc`, statusID);
  }

  deleteTaskTypeAsync(typeID: string[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.taskStatusUrl}/DeleteTaskTypeAysnc`, typeID);
  }

  SaveTaskStatus(model: TaskStatus): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.taskStatusUrl}/SaveTaskStatus`, model);
  }

  SaveTaskType(model: TaskType): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.taskStatusUrl}/SaveTaskType`, model);
  }
  //2022-01-12 hmtien add start
  getDataEntryRole(): Observable<ReturnResult<Setting>> {
    return this.http.get<ReturnResult<Setting>>(`${this.baseUrl}/GetDataEntryRole`);
  }
  //2022-01-12 hmtien add end

  //2022-01-12 hmtien add start
  getMaxNotiPerChunks(): Observable<ReturnResult<Setting>> {
    return this.http.get<ReturnResult<Setting>>(`${this.baseUrl}/GetMaxNotiPerChunks`).pipe(tap(x => {
      if (x.result) {
        window.localStorage.setItem('MAX_NOTI_PER_CHUNKS', '10');
      }
    }));
  }
  //2022-01-12 hmtien add end

  refreshTaskSetting() {
    this.http.get<ReturnResult<Setting[]>>(`${this.baseUrl}/GetTaskSetting`).subscribe(resp => {
      if (resp.result) {
        this.taskSettingLst$.next(resp.result);
      }
    });
  }
  getTaskSettingLst(): Observable<Setting[]> {
    return this.taskSettingLst$.asObservable();
  }
  // getValueByGroupName(): Observable<Setting[]> {
  //   return this.customSettingGroup.asObservable();
  // }
  // refreshGetValueByGroupName(name: string) {
  //   this.http.post<ReturnResult<Setting[]>>(`${this.baseUrl}/GetValueByGroupName`, { name }).subscribe(resp => {
  //     if (resp.result) {
  //       this.customSettingGroup.next(resp.result);
  //     }
  //   });
  // }
  // reFreshSettingByUniqueName(name: string, group: string) {
  //   this.http.post<ReturnResult<Setting>>(`${this.baseUrl}/GetSettingByUniqueName`, { name, group }).subscribe(resp => {
  //     if (resp.result) {
  //       this.uniqueSetting.next(resp.result);
  //     }
  //   });
  // }
  // getSettingByUniqueName(): Observable<Setting> {
  //   return this.uniqueSetting.asObservable();
  // }

  getSettingByName(name: string, group: string): Observable<ReturnResult<Setting>> {
    return this.http.post<ReturnResult<Setting>>(`${this.baseUrl}/GetSettingByUniqueName`, { name, group });
  }

  getPageResolutionCode(page: Page): Observable<ReturnResult<PagedData<ResolutionCode>>> {
    return this.http.post<ReturnResult<PagedData<ResolutionCode>>>(`${this.baseUrl}/GetPagingResolutionCode`, page);
  }

  saveResolutionCode(model: ResolutionCodeModel): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveResolutionCode`, model);
  }

  deleteResolutionCode(id: number): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/DeleteResolutionCode/${id}`);
  }

  deleteListResolutionCode(listId: number[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteListResolutionCode`, listId);
  }

  orderResolutionCodeList(): Observable<[]> {
    return this._orderResolutionList$.asObservable();
  }

  getOrderResolutionCodeList() {
    this.http.get<ReturnResult<[]>>(`${this.baseUrl}/GetListOrderResolutionCode`).subscribe(resp => {
      if (resp.result) {
        this._orderResolutionList$.next(resp.result);
      }
    });
  }
  checkByPassUserSetting(userId: string): Observable<ReturnResult<boolean>> {
    return this.http.get<ReturnResult<boolean>>(`${this.baseUrl}/GetByPassUserSetting/${userId}`);
  }

  saveSettingCompany(data: SettingCompany): Observable<ReturnResult<boolean>> {
    const company = new FormData();
    for (var key in data) {
      company.append(key, data[key]);
    }
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveSettingCompany`, company);
  }

  getRequestConfigCompany() {
    return this.http.get<ReturnResult<SettingCompanyViewModel>>(`${this.baseUrl}/GetConfigCompany`).subscribe(res => {
      if (res.result) {
        res.result.urlCompanyBackGround = res.result.urlCompanyBackGround != null ? res.result.urlCompanyBackGround?.replace(/\\/g, '/') : null;
        res.result.urlCompanyLogoPortal = res.result.urlCompanyLogoPortal != null ? res.result.urlCompanyLogoPortal?.replace(/\\/g, '/') : null;
        res.result.urlCompanyLogo = res.result.urlCompanyLogo != null ? res.result.urlCompanyLogo?.replace(/\\/g, '/') : null;
        res.result.urlDefaultAvatar = res.result.urlDefaultAvatar != null ? res.result.urlDefaultAvatar?.replace(/\\/g, '/') : null;

        this._configCompany$.next(res.result);
      }
    })
  }

  getConfigCompany(): Observable<SettingCompanyViewModel> {
    return this._configCompany$.asObservable();
  }
  saveSettingCampaign(
    contactConfigId, isTrackingEmail, isEnableOutreachByPhone,
    isNormalEditor, isTransferEMQ, defaultEmail, isTransferDefaultEmail,
    personalContactConfigId, isEnableSMTP, isNewCreateCampaignUI, reminderContactConfigId,
    isGetCXHistoryAPI, onBoardingSenderId, onBoardingReceiverId, campaignContactConfigList, defaultEmailList,
    isUsingSendGrid, sendGridDomain, reminderDefaultEmail, emailTemplateViewModel, generalSystemEmail,
  ): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveSettingCampaign`,
      {
        contactConfigId,
        isTrackingEmail,
        isEnableOutreachByPhone,
        isNormalEditor,
        isTransferEMQ,
        defaultEmail,
        isTransferDefaultEmail,
        personalContactConfigId,
        isEnableSMTP,
        isNewCreateCampaignUI,
        reminderContactConfigId,
        isGetCXHistoryAPI,
        onBoardingSenderId,
        onBoardingReceiverId,
        campaignContactConfigList,
        defaultEmailList,
        isUsingSendGrid,
        sendGridDomain,
        reminderDefaultEmail,
        emailTemplateViewModel,
        generalSystemEmail
      });
  }

  getSettingEmailTemplateCampaign(): Observable<ReturnResult<string>> {
    return this.http.get<ReturnResult<string>>(`${this.baseUrl}/GetSettingEmailTemplateCampaign`);
  }

  // 2022-03-17 toanpq add start

  getPagePriority(page: Page): Observable<ReturnResult<PagedData<PriorityModel>>> {
    return this.http.post<ReturnResult<PagedData<PriorityModel>>>(`${this.baseUrl}/GetPagingPriority`, page);
  }

  savePriority(model: PriorityModel): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SavePriority`, model);
  }

  deletePriority(id: number): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/DeletePriority/${id}`);
  }

  deleteListPriority(listId: number[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteListPriority`, listId);
  }
  //
  //2022-03-21 toanpq add header noti
  getNotificationByMode(name: string): Observable<ReturnResult<Setting>> {
    return this.http.post<ReturnResult<Setting>>(`${this.baseUrl}/GetSettingNotification`, { name });
  }
  //2022-03-21 toanpq add header noti-end
  SaveSettingTaskByKey(settingTask: Setting): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveSettingTaskByKey`, settingTask);
  }

  getSpotlightrKey(): Observable<ReturnResult<Setting>> {
    return this.http.get<ReturnResult<Setting>>(`${this.baseUrl}/GetSpotlightrKey`);
  }

  getSettingByKeyAndGroup(key: string, group: string): Observable<ReturnResult<Setting>> {
    return this.http.get<ReturnResult<Setting>>(`${this.baseUrl}/GetSettingByKeyAndGroup?key=${key}&group=${group}`);
  }

  //2022-04-07 toanpq add grid setting view start
  updateViewModelObser(viewModel: string) {
    this.viewModelSetting$.next(viewModel);
  }
  getViewModelObser(): Observable<string> {
    return this.viewModelSetting$.asObservable();
  }
  getPagingSetting(page: Page): Observable<ReturnResult<PagedData<Setting>>> {
    return this.http.post<ReturnResult<PagedData<Setting>>>(`${this.baseUrl}/GetPagingSetting`, page);
  }
  saveSetting(editSettingModel: EditSettingModel): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveSetting`, editSettingModel);
  }

  deleteSetting(key: string, group: string): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/DeleteSetting/?key=${key}&group=${group}`);
  }

  deleteListSetting(listId: number[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteListSetting`, listId);
  }
  checkSettingUnique(name: string, group: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/CheckSettingExist`, { name, group });
  }

  //2022-04-07 toanpq add end
  setDefaultNavigation(setting: Setting): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/EditSetting`, setting)
  }

  //2022-04-15 toanpq add start
  systemLogsList(): Observable<FileModel[]> {
    return this._systemLogsList$.asObservable();
  }
  getAllSystemLogsList(type: string) {
    let finalUrl = '';
    if (type == SystemFileEnums.SystemLogs) {
      finalUrl = "GetListSystemLogsFile";
    } else if (type == SystemFileEnums.SystemDbs) {
      finalUrl = "GetListSystemDBFile";
    }
    this.http.get<ReturnResult<FileModel[]>>(`${this.baseUrl}/${finalUrl}`).subscribe(resp => {
      if (resp.result) {
        this._systemLogsList$.next(resp.result);
        console.log(resp.result);
      }
      //return resp.result;
    });
  }
  DownloadFile(fileName: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/DownloadFile?fileName=${fileName}`, {
      observe: 'response',
      responseType: 'blob'
    });
  }
  //2022-04-15 toanpq add end

  getSettingByList(settingLst: Setting[], key: string, group: string) {
    return settingLst.find(x => x.group === group && x.key === key);
  }

  getPageSpeedWebSite(): Observable<ReturnResult<PageSpeedResponse>> {
    return this.http.get<ReturnResult<PageSpeedResponse>>(`${this.baseUrl}/GetPageSpeedWebSite`);
  }

  getSettingByGroupName(group): Observable<ReturnResult<Setting[]>> {
    return this.http.get<ReturnResult<Setting[]>>(`${this.baseUrl}/GetSettingByGroupName?group=${group}`,)
  }

  // 2022/01/17 ducqm start add
  getAllSettingAllow(): Observable<ReturnResult<Setting[]>> {
    return this.http.get<ReturnResult<Setting[]>>(`${this.baseUrl}/GetAllSettingAllow`);
  }
  // 2022/01/17 ducqm end add

  exportSetting(page: Page): Observable<ReturnResult<Setting>> {
    return this.http.post<ReturnResult<Setting>>(
      `${this.baseUrl}/export`,
      page
    );
  }
  importSetting(formData: FormData): Observable<ReturnResult<Setting>> {
    return this.http
      .post<ReturnResult<Setting>>(`${this.baseUrl}/import`, formData)
      .pipe(timeout(1200000));
  }

  saveSettingMessage(data: SettingMessage): Observable<ReturnResult<boolean>> {
    const message = new FormData();
    for (var key in data) {
      message.append(key, data[key]);
    }
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveSettingMessage`, message);
  }

  algoliaSetting(): Observable<ReturnResult<Setting[]>> {
    return this.http.get<ReturnResult<Setting[]>>(`${this.baseUrl}/GetSettingAlgolia`);
  }

  saveSettingList(settings: Setting[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveSettingList`, settings);
  }
  getSettingByKeyAndGroupAllow(key: string, group: string): Observable<ReturnResult<Setting>> {
    return this.http.get<ReturnResult<Setting>>(`${this.baseUrl}/GetSettingByKeyAndGroupAllow?key=${key}&group=${group}`);
  }
  
  getBandTemplate(): Observable<ReturnResult<Setting>> {
    return this.http.get<ReturnResult<Setting>>(`${this.baseUrl}/GetBandTemplate`);
  }
  saveBandTemplate(editSettingModel: EditSettingModel): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveBandTemplate`,editSettingModel.newSetting);
  }

}
