import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { ProposalArtists } from '../../../opportunity.model';
import { DatastateManagementService } from 'src/app/modules/admin/datastate-management/datastate-management.service';
import { DropDownValue } from 'src/app/modules/admin/summary-report/summary-report.component';
import { DropDownGroups } from 'src/app/shared/interfaces/dropdown-group';
import { OpportunityManagementService } from '../../../opportunity-management.service';
import { ProfileDetailModel } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { Helper } from 'src/app/shared/utility/Helper';
import { SettingPoolService } from 'src/app/shared/services/setting-pool.service';
import { environment } from 'src/environments/environment';
import { QuillConfiguration } from 'src/app/shared/components/stand-alone-component/rich-inline-edit/rich-inline-edit.component';
import { SettingService } from 'src/app/shared/services/setting.service';

@Component({
  selector: 'app-add-edit-artist',
  templateUrl: './add-edit-artist.component.html',
  styleUrls: ['./add-edit-artist.component.scss']
})
export class AddEditArtistComponent implements OnInit {

  action: TblActionType;
  frm: FormGroup;
  isLoading = false;
  dataModel: ProposalArtists;
  color1: string = '#2889e9';
  statusLst: DropDownValue[] = [];
  //tasktemplateLst: TaskTemplateModel[] = [];
  //taskscriptGroupLst: TaskScriptGroupModel[] = [];
  environment = environment;
  editorOptions = QuillConfiguration
  total: number = 0;
  totalMath: string;
  defaultArtistDatastate: string;

  constructor(
    public dialModalRef: MatDialogRef<AddEditArtistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private frmBuilder: RxFormBuilder,
    private toast: NbToastrService,
    private cdref: ChangeDetectorRef,
    public automatService: DatastateManagementService,
    public opportunityService: OpportunityManagementService,
    public settingService: SettingService,
  ) {
    this.action = data.action;
    this.dataModel = data.model ?? new ProposalArtists();
    if (this.action == TblActionType.Add) {
      this.dataModel.id = 0;
    }
    this.total = this.dataModel.total;
  }

  ngOnInit(): void {
    this.frm = this.frmBuilder.formGroup(ProposalArtists, Helper.convertNumberPropertiesToString(this.dataModel));
    this.dialModalRef.updatePosition({ right: '0' });
    this.registerValueChangeEvent();
    this.getTotalMath();
    this.getStatusLst();
  }

  async getTotalMath() {
    const rs = await this.settingService.getSettingByKeyAndGroup("TOTAL_OPPORTUNITY_ARTIST_MATH", "SALE_CONFIG").toPromise();
    if (rs.result && rs.result.value) {
      this.totalMath = rs.result.value;
    }
  }
  registerValueChangeEvent() {
    this.frm.valueChanges.subscribe(e => {
      try {
        const mergeTagMath = Helper.handleMergeTag(this.totalMath, Helper.toPascalCase(e));
        this.total = eval(mergeTagMath);
      }
      catch (ex) {
        this.total = 0;
      }
    })
  }


  async getStatusLst() {
    try {
      const res = await this.automatService.getAutomateDataState('PROPOSAL_ARTIST').toPromise();
      if (!res.message) {
        this.statusLst = res.result.map(e => { return { value: e.automateDataStateId.toString(), text: e.dataStateName, color: e.colorCode } });
        if (this.action === TblActionType.Add) {
          if (this.data?.model?.isFromProfile) {
            const proposedId = await this.settingService.getSettingByKeyAndGroup("PROPOSAL_ARTIST_PROPOSED_ID", "SALE_CONFIG").toPromise();
            if (proposedId.result) {
              this.frm.controls.automateDataState.patchValue(proposedId.result.value);
            }
          }
          else {
            let defaultArtistDatastateId = await this.settingService.getSettingByKeyAndGroup("DEFAULT_ARTIST_AUTOMATE_DATA_STATE", "PROFILE").toPromise();
            if (defaultArtistDatastateId?.result) {
              this.frm.controls.automateDataState.patchValue(defaultArtistDatastateId?.result?.value);
            }
          }
        }
      }
    }
    catch (ex) {
      console.log(ex);
    }
  }
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  closeDialog() {
    this.dialModalRef.close();
  }
  async saveData() {
    if (this.frm.valid) {
      const saveProposal = await this.opportunityService.saveProposalArtist(this.frm.value).toPromise();
      if (saveProposal.result) {
        this.toast.success("", "Saved successfully");
        this.dialModalRef.close(true);
      }
      else {
        this.toast.danger("", "Saved failed")
      }
    }
  }
  selectPID(data: any) {
    this.frm.controls.profileId.patchValue(data?.profileId || data);
  }
  selectProposal(data: ProfileDetailModel) {
    this.frm.controls.proposalId.patchValue(data.profileId);
  }
}