<div *ngIf="!isPreviewAll; else previewProposal" fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
    <div *ngIf="viewRevision" fxFlex="15vw" fxLayout="column" fxLayoutAlign="start start">
        <div class="ml-auto" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
            <button mat-icon-button aria-label="Refresh revision" (click)="loadRevisionPage()">
                <mat-icon>refresh</mat-icon>
            </button>
            <button mat-icon-button aria-label="Close the revision" (click)="switchSeenRevision()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <mat-divider style="width: 100%"></mat-divider>
        <mat-list class="revision-list" [nbSpinner]="!pagedData">
            <ng-container *ngIf="pagedData?.data">
                <mat-list-item *ngFor="let item of pagedData.data" [nbPopover]="selectRevision"
                    [nbPopoverContext]="{revision: item}" nbPopoverPlacement="left"
                    [style.background-color]="item.id == revisionCur.id ? '#E9F9DE' : ''">
                    <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between center">
                        <span>{{item.id}}</span>
                        <span style="opacity: 0.5; font-style: italic; text-align: end; font-size: 13px">
                            {{item.dateCreated | date: 'M/d, H:mm:ss'}}
                            <br>
                            {{(item?.userName ? 'by ' + item?.userName : '')}}
                        </span>
                    </div>
                    <mat-divider></mat-divider>
                </mat-list-item>
            </ng-container>
        </mat-list>
    </div>
    <mat-divider style="height: 100%" [vertical]="true"></mat-divider>
    <div fxFlex fxLayout="column" fxLayoutAlign="start start">
        <div *ngIf="!loadingTemplate; else loadingHeader" style="width: 100%;">
            <div fxLayout="row" fxLayoutAlign="start center">
                <button *ngIf="revisionCur" mat-button style="font-size: 16px; font-weight: 700;"
                    (click)="switchSeenRevision()">
                    <mat-icon class="mx-1">{{viewRevision ? 'chevron_right' : 'chevron_left'}}</mat-icon>
                    <span>
                        <span>Revision ID:</span>
                        <span style="font-style: italic; color: #307ABD">
                            {{revisionCur ? (revisionCur.id + ' - ' + (revisionCur.dateCreated | date: 'M/d, H:mm:ss')) :
                            'NEW'}}
                        </span>
                    </span>
                    <span *ngIf="isWhiteLabel" class="badge badge-info">White-Label Proposal</span>
                </button>
                <button mat-button color="primary" (click)="generateProposal()">
                    <mat-icon class="mx-1">save</mat-icon>Publish
                </button>
                <button mat-button (click)="getContentHTML(sectionCur)">
                    <mat-icon class="mx-1">refresh</mat-icon>Refresh
                </button>

                <div class="ml-auto" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <button mat-icon-button aria-label="Open URL" (click)="isMobile = !isMobile">
                        <mat-icon>{{ isMobile ? 'phone_android' : 'laptop_chromebook'}}</mat-icon>
                    </button>
                    <button mat-icon-button aria-label="Open URL" matTooltip="Open link"
                        (click)="openLink(revisionCur)">
                        <mat-icon>open_in_new</mat-icon>
                    </button>
                    <button mat-icon-button aria-label="Close the dialog" matTooltip="Close the dialog"
                        (click)="closeDialog()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div *ngIf="templates" fxLayout="row" fxLayoutAlign="center center" class="pt-3" fxLayoutGap="12px"
                style="background-color: #d7e2ff;">
                <div *ngFor="let item of templates">
                    <mat-form-field *ngIf="(item.key | uppercase) != 'BODY'" style="width:300px">
                        <mat-label>{{item.key | uppercase}} TEMPLATE</mat-label>
                        <mat-select [(ngModel)]="item.selected" (selectionChange)="selectTemplate(item.key)">
                            <mat-option *ngFor="let template of item.templates" [value]="template.fileName">
                                {{(template.fileName | findItem: nameFiles: 'extendData')?.content ||
                                template.fileName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <mat-divider></mat-divider>
        </div>

        <div class="content-dialog" mat-dialog-content fxLayout="row" fxLayoutAlign="start start">
            <div fxFlex="75" fxLayout="column" class="child-content-dialog px-4" fxLayoutAlign="center center"
                [style.background-color]="isMobile ? '#efefef' : ''">
                <iframe #iframeTemplate [style.width]="isMobile ? '412px' : '100%'"
                    class="content-template-html"></iframe>
            </div>
            <div fxFlex="25" fxLayout="column" class="child-content-dialog px-4" [nbSpinner]="loadingTemplate">
                <mat-form-field>
                    <mat-label>Proposal Section</mat-label>
                    <mat-select [(ngModel)]="sectionCur" (selectionChange)="selectionSection($event)">
                        <mat-option
                            *ngFor="let item of sections | orderByMatSelection: {property: 'order', direction: 1}"
                            [value]="item.key">
                            {{(item.value == "PROPOSAL" ? "PROPOSAL TITLE" : item.value)}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <form *ngIf="sectionModel && sectionModel.form && !loadingInput" [formGroup]="sectionModel.form"
                    [nbSpinner]="bodyLoading">
                    <ng-container *ngFor="let item of sectionModel.fields; let i = index">
                        <ng-container *ngIf="item != 'ORDER'">
                            <ng-container *ngIf="item != 'BODY'; else artistTemplate"
                                [ngSwitch]="sectionModel.fieldType[i]">
                                <!--TEXT AREA-->
                                <ng-container *ngSwitchCase="'TEXT_AREA'" [ngTemplateOutlet]="textAreaType"
                                    [ngTemplateOutletContext]="{frmControl: item}"></ng-container>
                                <!--COLOR CODE-->
                                <ng-container *ngSwitchCase="'COLOR_CODE'" [ngTemplateOutlet]="colorType"
                                    [ngTemplateOutletContext]="{frmControl: item}"></ng-container>
                                <!--RICH TEXT-->
                                <ng-container *ngSwitchCase="'RICH_TEXT'" [ngTemplateOutlet]="richTextType"
                                    [ngTemplateOutletContext]="{frmControl: item}"></ng-container>
                                <!--IMAGE_PICKER-->
                                <ng-container *ngSwitchCase="'IMAGE_PICKER'" [ngTemplateOutlet]="imagePickerType"
                                    [ngTemplateOutletContext]="{frmControl: item}"></ng-container>
                                <!--VIDEO_PICKER-->
                                <ng-container *ngSwitchCase="'VIDEO_PICKER'" [ngTemplateOutlet]="videoPickerType"
                                    [ngTemplateOutletContext]="{frmControl: item}"></ng-container>
                                <!--DEFAULT TEXT-->
                                <ng-container *ngSwitchDefault [ngTemplateOutlet]="textType"
                                    [ngTemplateOutletContext]="{frmControl: item}"></ng-container>
                            </ng-container>
                        </ng-container>

                        <ng-template #artistTemplate>
                            <ng-container *ngIf="templates">
                                <ng-container *ngFor="let i of templates">
                                    <mat-form-field *ngIf="(i.key | uppercase) == 'BODY'">
                                        <mat-label>ARTIST TEMPLATE</mat-label>
                                        <mat-select [formControlName]="item"
                                            (selectionChange)="selectArtistTemplate($event)">
                                            <mat-option *ngFor="let template of i.templates"
                                                [value]="template.fileName">
                                                {{(template.fileName | findItem: nameFiles: 'extendData')?.content ||
                                                template.fileName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>
                            </ng-container>
                        </ng-template>
                    </ng-container>
                </form>
            </div>
        </div>
    </div>
</div>

<ng-template #textType let-frmControl="frmControl">
    <mat-form-field [formGroup]="sectionModel.form" appearance="standard">
        <mat-label>{{frmControl}}</mat-label>
        <input matInput autocomplete="off" [formControlName]="frmControl" trim="blur"
            (focus)="initialValueFunc($event?.target?.value)" (blur)="detectModify($event?.target?.value)">
    </mat-form-field>
</ng-template>

<ng-template #richTextType let-frmControl="frmControl">
    <div style="height: fit-content; padding-bottom: 24px;" [formGroup]="sectionModel.form">
        <mat-label style="color: #666666">{{frmControl}}</mat-label>
        <ng-container *ngIf="frmControl === '[QUOTE]'; else richTextTypeAllowColor">
        <quill-editor [modules]="editorOptions" [placeholder]="frmControl" [formControlName]="frmControl" (onEditorCreated)="editorInit($event)"
            (onFocus)="initialValueFunc($event?.editor?.getText())" (onBlur)="detectModify($event?.editor?.getText())">
        </quill-editor>
      </ng-container>

      <ng-template #richTextTypeAllowColor>
        <quill-editor [modules]="editorOptions" [placeholder]="frmControl" [formControlName]="frmControl"
            (onFocus)="initialValueFunc($event?.editor?.getText())" (onBlur)="detectModify($event?.editor?.getText())">
        </quill-editor>
      </ng-template>

    </div>
</ng-template>

<ng-template #imagePickerType let-frmControl="frmControl">
    <mat-form-field [formGroup]="sectionModel.form" appearance="standard">
        <mat-label>{{frmControl}}</mat-label>
        <input matInput autocomplete="off" [formControlName]="frmControl" trim="blur"
            (focus)="initialValueFunc($event?.target?.value)" (blur)="detectModify($event?.target?.value)">
        <button matSuffix mat-icon-button (click)="selectMedia(frmControl, 1)"
            (focus)="initialValueFunc($event?.target?.value)"><mat-icon>collections</mat-icon></button>
    </mat-form-field>
</ng-template>

<ng-template #videoPickerType let-frmControl="frmControl">
    <mat-form-field [formGroup]="sectionModel.form" appearance="standard">
        <mat-label>{{frmControl}}</mat-label>
        <input matInput autocomplete="off" [formControlName]="frmControl" trim="blur"
            (focus)="initialValueFunc($event?.target?.value)" (blur)="detectModify($event?.target?.value)">
        <button matSuffix mat-icon-button (click)="selectMedia(frmControl, 2)"
            (focus)="initialValueFunc($event?.target?.value)"><mat-icon>collections</mat-icon></button>
    </mat-form-field>
</ng-template>

<ng-template #textAreaType let-frmControl="frmControl">
    <mat-form-field [formGroup]="sectionModel.form" appearance="standard">
        <mat-label>{{frmControl}}</mat-label>
        <textarea matInput autocomplete="off" [formControlName]="frmControl" trim="blur"
            (focus)="initialValueFunc($event?.target?.value)" (blur)="detectModify($event?.target?.value)">
        </textarea>
    </mat-form-field>
</ng-template>

<ng-template #colorType let-frmControl="frmControl">
    <mat-form-field [formGroup]="sectionModel.form" appearance="standard">
        <mat-label>{{frmControl}}</mat-label>
        <input matInput type="color" autocomplete="off" required [formControlName]="frmControl" trim="blur"
            (colorPickerChange)="onChangeColor($event)" value="#003066"
            (focus)="initialValueFunc($event?.target?.value)" (blur)="detectModify($event?.target?.value)">
    </mat-form-field>
</ng-template>

<ng-template #loadingHeader>
    <ngx-skeleton-loader style="width: 100%;" count="1" animation="progress"
        [theme]="{height: '35px', 'margin-bottom': '0'}"></ngx-skeleton-loader>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutAlign="space-around center" class="pt-1" style="width: 100%;">
        <ngx-skeleton-loader fxFlex="25" count="1" animation="progress"
            [theme]="{height: '50px', 'margin-bottom': '0'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex="25" count="1" animation="progress"
            [theme]="{height: '50px', 'margin-bottom': '0'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex="25" count="1" animation="progress"
            [theme]="{height: '50px', 'margin-bottom': '0'}"></ngx-skeleton-loader>
    </div>
    <mat-divider></mat-divider>
</ng-template>

<ng-template #selectRevision let-data>
    <div fxFlexFill fxLayout="column" fxLayoutAlign="start start">
        <button style="width: 100%" mat-button fxLayout="row" fxLayoutAlign="start start"
            (click)="restoreRevision(data.revision);">
            <mat-icon class="mx-1">restore</mat-icon>Restore
        </button>
        <mat-divider style="width: 100%"></mat-divider>
        <button style="width: 100%" mat-button fxLayout="row" fxLayoutAlign="start start"
            (click)="openLink(data.revision)">
            <mat-icon class="mx-1">open_in_new</mat-icon>Open URL
        </button>
        <mat-divider style="width: 100%"></mat-divider>
        <button style="width: 100%" mat-button fxLayout="row" fxLayoutAlign="start start"
            (click)="copyLink(data.revision)">
            <mat-icon class="mx-1">link</mat-icon>Copy URL
        </button>
    </div>
</ng-template>

<ng-template #previewProposal>
    <app-proposal-artist-preview #previewProposalComponent [id]="id" [revisionCur]="revisionCur" [(isMobile)]="isMobile"
        [message]="messageWarning" (onEdit)="isPreviewAll = !isPreviewAll;" (onRenew)="renewProposal($event)" [isWhiteLabel]="isWhiteLabel"
        [proposalWhiteLabelData]="proposalWhiteLabelData"
        (onOpenURL)="openLink($event)">
    </app-proposal-artist-preview>
</ng-template>
