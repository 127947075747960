import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ReturnResult } from '../models/return-result';
import { MoveFileViewModel, UserUpload } from '../../modules/admin/user-upload-management/user-upload.model';
import { timeout } from 'rxjs/operators';
import { CheckOneDriveDataResult, OneDriveItem, RelationshipReferenceDetail } from '../../modules/admin/sale-account-management/one-drive-item';

@Injectable({
  providedIn: 'root'
})
export class MicrosoftOffice365Service {
  baseUrl = environment.apiOffice;

  constructor(
    private http: HttpClient
  ) { }

  userLoggedIn(isSystem: boolean = false): Observable<ReturnResult<boolean>> {
    return this.http.get<ReturnResult<boolean>>(`${this.baseUrl}/User/LoggedIn?isSystem=${isSystem}`);
  }

  loginURL(type: string): Observable<ReturnResult<string>> {
    return this.http.get<ReturnResult<string>>(`${this.baseUrl}/GetLoginURL/${type}`);
  }

  logoutOffice(isSystem: boolean = false): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/Logout/Office?isSystem=${isSystem}`, {});
  }

  uploadFileOffice(file: File, model: UserUpload): Observable<ReturnResult<string>> {
    var formData = new FormData();
    formData.append("file", file);
    return this.http.post<ReturnResult<string>>(`${this.baseUrl}/UploadOfficeFile?model=${JSON.stringify(model)}`, formData)
      .pipe(timeout(600000));
  }

  modifyFileOffice(userUploadHistoryId: string, file: File): Observable<ReturnResult<boolean>> {
    var formData = new FormData();
    formData.append("file", file);

    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/ModifyOfficeFile?userUploadHistoryId=${userUploadHistoryId}`, formData).pipe(timeout(600000));
  }

  syncMultipleExistedAttachments(listAttachmentIds: number[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SyncMultipleExistedAttachments`, { AttachmentUploadIds: listAttachmentIds });
  }

  updateOneDriveFileName(): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/UpdateOneDriveFileName`, null);
  }

  moveFolderOneDriveProposal(opportunityId: string, oldSaleAccountId: string, newSaleAccountId: string, isLink: boolean): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/MoveFolderOneDriveProposal`, {
      IsLinking: isLink,
      SaleOpportunityId: opportunityId,
      SaleAccountId: newSaleAccountId,
      OldSaleAccountId: oldSaleAccountId
    });
  }

  moveFolderOneDriveClient(sourceAccountId: string, oldSaleAccountId: string, destinationAccountId: string, isLink: boolean): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/MoveFolderOneDriveClient`, {
      IsLinking: isLink,
      SourceAccountId: sourceAccountId,
      DesinationAccountId: destinationAccountId,
      OldSaleAccountId: oldSaleAccountId
    });
  }

  updateNameFolder(id: string, typeName: string, oldName: string, newName: string) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/ModifyFolderName`, { id, typeName, oldName, newName });
  }

  handleRemoveModelFromCRMSystem(profileId: string, typeName: string, relationshipReference: any = null) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/HandleRemoveModelFromCRMSystem`, {
      Id: profileId,
      TypeName: typeName,
      RelationshipReference: relationshipReference
    });
  }

  handleRemoveListModelFromCRMSystem(profileIds: string[], typeName: string, relationshipReference: any = null) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/HandleRemoveListModelFromCRMSystem`, {
      Ids: profileIds,
      TypeName: typeName,
      RelationshipReferences: relationshipReference
    });
  }



  deleteUserUpload(userUploadId: string) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/RemoveOneDriveFile/${userUploadId}`, null);
  }

  uploadFileOneDrive(userUploadId: number) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/UploadFileOneDrive/${userUploadId}`, null);
  }

  updateFileReference(model: MoveFileViewModel): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/HandleMoveOneDriveFile`, model)
  }

  getOneDriveFolderData(profileId: string, profileType: string) : Observable<ReturnResult<OneDriveItem>> {
    return this.http.get<ReturnResult<OneDriveItem>>(`${this.baseUrl}/GetOneDriveFolderDataItem/${profileId}?profileType=${profileType}`);
  }

  checkOneDriveDataByProfileId(profileId: string) : Observable<ReturnResult<CheckOneDriveDataResult>> {
    return this.http.get<ReturnResult<CheckOneDriveDataResult>>(`${this.baseUrl}/CheckOneDriveDataByProfileId/${profileId}`);
  }

  getRelationshipReferenceDetail(profileId: string): Observable<ReturnResult<RelationshipReferenceDetail>> {
    return this.http.get<ReturnResult<RelationshipReferenceDetail>>(`${this.baseUrl}/GetRelationshipReferenceDetail/${profileId}`);
  }

  getListRelationshipReferenceDetail(profileIds: string[]): Observable<ReturnResult<RelationshipReferenceDetail>> {
    return this.http.post<ReturnResult<RelationshipReferenceDetail>>(`${this.baseUrl}/GetListRelationshipReferenceDetail`, profileIds);
  }
}
