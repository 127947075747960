<mat-chip-list hidden>
    <!--It is magic, please don't remove-->
    <mat-chip></mat-chip>
</mat-chip-list>

<div fxFlex="100" fxLayout="column" fxLayoutAlign="start start" [nbSpinner]="isLoading">
    <div [hidden]="!isEdit" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="flex-start center" fxLayoutGap="10px"
        style="width: 100%">
        <div fxFlex="20" fxLayout="column">
            <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
                Relationship type
            </span>
            <mat-form-field fxFlex="100" appearance="standard" class="input-linked">
                <mat-select [formControl]="selectedForm" (openedChange)="changeSelected()"
                    [panelClass]="customClassIndex">
                    <mat-select-trigger *ngIf="selected">
                        <ng-container [ngTemplateOutlet]="cardTypeTemplate"
                            [ngTemplateOutletContext]="{ object: selected }">
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option *ngFor="let object of typeObjects" [value]="object.key">
                        <ng-container [ngTemplateOutlet]="cardTypeTemplate"
                            [ngTemplateOutletContext]="{ object: object }">
                        </ng-container>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="1 1 calc(80%-10px)" fxLayout="column"
            [matTooltip]="customClassIndex ? '' : 'Enter 3 words to search...'" matTooltipPosition="left">
            <span fxFlex="100" class="limit-line label-inline label-search" style="--line: 1;">
                Searching for {{selected.displayType || ''}}
            </span>
            <mat-form-field [formControl]="searchForm" ngDefaultControl class="input-linked input-search"
                [nbSpinner]="isLoadingSearch">
                <input #inputSearch matInput style="height: 26px" [matAutocomplete]="autocomplete">
                <mat-autocomplete #autocomplete="matAutocomplete" [class]="customClassIndex"
                    (opened)="customClassIndex ? customIndexAutocomplete() : ''"
                    (optionSelected)="selectedObject($event.option.value)">
                    <mat-option *ngIf="!objects || objects.length == 0" [disabled]="true">Not found</mat-option>
                    <mat-option *ngFor="let object of objects;" [value]="object" [disabled]="object.disabled">
                        <span fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
                            <img *ngIf="object?.media?.media1; else noImage" fxFlex="24px" class="option-img"
                                [src]="object?.media?.media1 ?? defaultAvatar"
                                (error)="defaultAvatar = 'assets/images/5.jpg'" height="24" width="24">
                            <ng-template #noImage>
                                <span fxFlex="30px" class="material-icons" [style.color]="selected.color ?? '#307abd'"
                                    style="font-size: 24px;">
                                    {{selected.iconType}}
                                </span>
                            </ng-template>
                            <span fxFlex="1 1 calc(100%-30px)" class="limit-line" style="--line: 1;">
                                {{object[selected.propName] || "Unknown"}}
                            </span>
                        </span>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
    <mat-chip-list fxFlex="100" style="width: 100%" class="mb-4">
        <ng-container *ngIf="isShowMatChip">
            <mat-chip *ngFor="let obj of selectedObjects" style="color: white"
                [style.background-color]="obj.color ?? '#307abd'" [selectable]="true" [removable]="obj.isRemove"
                (removed)="obj.isRemove ? removeSelectedObj(obj) : ''" (click)="openNewTab(obj)" class="hoverable">
                <span matTooltipClass="custom-tooltip-relationship" [matTooltip]="obj.displayType"
                    matTooltipPosition="left">
                    {{obj.value}}
                </span>
                <mat-icon matChipRemove style="opacity: 1;" [style.color]="obj.isRemove ? '#b30000': 'grey'"
                    matTooltipPosition="left"
                    [matTooltip]="obj.isRemove ? ''
                    : 'This ' + obj.displayType + ' cannot be removed because this contact is the primary contact of this ' + obj.displayType">
                    cancel
                </mat-icon>
            </mat-chip>
        </ng-container>
        <input #inputMatChip *ngIf="!isEdit" placeholder="Select relationship..." class="textarea-inline-control"
            readonly (click)="clickEditor()">
    </mat-chip-list>
</div>

<ng-template #cardTypeTemplate let-obj="object">
    <span fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
        <span fxFlex="30px" class="material-icons" [style.color]="obj.color ?? '#307abd'" style="font-size: 24px;">
            {{obj.iconType}}
        </span>
        <span fxFlex="1 1 calc(100%-30px)" class="limit-line uppercase-letter" style="--line: 1;">
            {{obj.displayType}}
        </span>
    </span>
</ng-template>