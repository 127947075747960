import { Component, Inject, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PrimasTableComponent } from 'src/app/shared/components/template-management/primas-table/primas-table.component';
import { PrimasToolbarComponent } from 'src/app/shared/components/template-management/primas-toolbar/primas-toolbar.component';
import { NbToastrService, NbWindowService, NbWindowState } from '@nebular/theme';
import { DateTimeFormatPipe } from 'src/app/shared/pipes/date-time-format.pipe';
import { permissionRevisionVerison } from 'src/app/shared/contances/permission';
import { PrimasFilterType } from 'src/app/shared/enums/primas-value-type.enum';
import { ListRevisionEntityEnum, ListRevisionStatusEnum, RestoreRevisionViewModel, RevisionEntity, RevisionExtendData, RevisionStatus, RVRevisionMeta } from '../revision-version.model';
import { RevisionVersionService } from '../revision-version.service';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ProfileService } from '../../profile-management/profile.service';
import { Helper } from 'src/app/shared/utility/Helper';
import { UserExperienceTrackingService } from 'src/app/shared/components/user-experience-tracking/user-experience-tracking.service';
import { ProfileDetailComponent } from 'src/app/modules/admin/profile-management/profile-detail/profile-detail.component';
import { AddEditCategoryComponent } from '../../category-management/add-edit-category/add-edit-category.component';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { SettingService } from 'src/app/shared/services/setting.service';
import { CategoryManagementService } from '../../category-management/category-management.service';
import { PrimasTextFilterOperator } from 'src/app/shared/enums/primas-text-filter-operator';
import { FormGroup } from '@angular/forms';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-revision-version-management',
  templateUrl: './revision-version-management.component.html',
  styleUrls: ['./revision-version-management.component.scss']
})
export class RevisionVersionManagementComponent implements OnInit {
  @Input() revisionVersionStatus: RevisionStatus;
  @Input() revisionEntity: string;
  @Input() id: string;

  @ViewChild('primasTable', { static: true }) primasTable: PrimasTableComponent;
  @ViewChild('primasToolbar', { static: true }) primasToolbar: PrimasToolbarComponent;
  @ViewChild('publishedContentTemplate', { static: true }) publishedContentTemplate: TemplateRef<any>;
  @ViewChild('submitter', { static: true }) submitter: TemplateRef<any>;
  @ViewChild('reviewer', { static: true }) reviewer: TemplateRef<any>;
  @ViewChild('publishCheckbox') publishCheckbox: TemplateRef<any>;
  @ViewChild('profileOpenInline', { static: true }) profileOpenInline: TemplateRef<any>;
  @ViewChild('categoryOpenInline', { static: true }) categoryOpenInline: TemplateRef<any>;
  @ViewChild('friendlyUrlOpenInline', { static: true }) friendlyUrlOpenInline: TemplateRef<any>;
  @ViewChild('SEOAuditTemp', { static: true }) seoAuditTemp: TemplateRef<any>;
  @ViewChild('status', { static: true }) status: TemplateRef<any>;
  @ViewChild('rejectReasonTemplate', { static: true }) rejectReasonTemplate: TemplateRef<any>;
  @ViewChild('rejectReasonOpenInline', { static: true }) rejectReasonOpenInline: TemplateRef<any>;
  @ViewChild('rejectReasonReadonlyTemplate', { static: true }) rejectReasonReadonlyTemplate: TemplateRef<any>;
  @ViewChild('restoreRevisionNoteTemplate', { static: true }) restoreRevisionNoteTemplate: TemplateRef<any>;
  @ViewChild('restoreNoteTemplate', { static: true }) restoreNoteTemplate: TemplateRef<any>;
  @ViewChild('restoreRevisionTemplate', { static: true }) restoreRevisionTemplate: TemplateRef<any>;
  @ViewChild('AICheckerTemplate', { static: true }) aiCheckerTemplate: TemplateRef<any>;
  @ViewChild('aiScoreTemplate', { static: true }) aiScoreTemplate: TemplateRef<any>;
  @ViewChild('seoScore', { static: true }) seoScore: TemplateRef<any>;
  @ViewChild('readabilityScore', { static: true }) readabilityScore: TemplateRef<any>;

  columns = [];
  resource = permissionRevisionVerison;
  revisionEntityEnum = RevisionEntity;
  revisonVersionType = RevisionStatus;
  tabValues = [
    RevisionStatus.WaitingForReview,
    RevisionStatus.Approved,
    RevisionStatus.Rejected
  ];
  buttonTypeEnum = ButtonType;
  isPublishNow: boolean = true;
  domains = '';
  RevisionStatus = RevisionStatus;
  rejectReasonData: string = "";
  formRestore: FormGroup;
  restoreNoteData;

  constructor(
    public dialModalRef: MatDialogRef<RevisionVersionManagementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: NbToastrService,
    private revisionVersionService: RevisionVersionService,
    private dateTimeFormatPipe: DateTimeFormatPipe,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private profileService: ProfileService,
    private userExperienceTrackingService: UserExperienceTrackingService,
    private settingService: SettingService,
    private categoryService: CategoryManagementService,
    private windowService: NbWindowService,
    private formBuilder: RxFormBuilder,
  ) {
    this.revisionVersionStatus = this.tabValues[0];
    this.revisionEntity = RevisionEntity.Profile;
    this.changeQueryParams();

    let restoreModel: RestoreRevisionViewModel = new RestoreRevisionViewModel();
    this.formRestore = this.formBuilder.formGroup(RestoreRevisionViewModel, restoreModel);

    if (this.data) {
      this.revisionVersionStatus = this.data?.revisionStatus ?? this.revisionVersionStatus;
      this.id = this.data?.id ?? this.id;
      this.revisionEntity = this.data?.revisionEntity ?? this.revisionEntity;
    }
  }

  ngOnInit() {
    this.columns = [
      {
        name: "SEO",
        prop: "OverallScore",
        maxWidth: 50,
        cellTemplate: this.seoScore,
        sortable: false,
        filter: {
          dynamicProperty: "ExtendData",
          filterType: PrimasFilterType.DropDown,
          filterValue: [
            {
              text: "Good",
              value: Helper.numberOfRange(75, 100),
            },
            {
              text: "Optimize",
              value: Helper.numberOfRange(40, 74),
            },
            {
              text: "Bad",
              value: Helper.numberOfRange(0, 39),
            },
            {
              text: "No Seo Audit",
              value: ", IsNullOrEmpty",
            }
          ],
          displayText: "text",
          displayValue: "value",
        },
        disableDefaultFilterOperator: true,
        cellClass: "text-center",
        headerClass: "text-center remove-header-padding",
        frozenLeft: true,
        import: false,
      },
      {
        name: 'AI',
        prop: 'AiScore',
        cellTemplate: this.aiScoreTemplate,
        maxWidth: 50,
        sortable: false,
        filter: {
          filterType: PrimasFilterType.DropDown,
          filterValue: [
            {
              text: 'Good',
              value: Helper.numberOfRange(0, 40)
            },
            {
              text: 'Bad',
              value: Helper.numberOfRange(41, 100)
            },
            {
              text: 'Never Check',
              value: ", IsNullOrEmpty"
            },
          ],
          displayText: 'text',
          displayValue: 'value',
        },
        disableDefaultFilterOperator: true,
        cellClass: 'text-center',
        headerClass: 'text-center remove-header-padding',
        frozenLeft: true,
        import: false,
        permissionColumn: 'display-AI-checker'
      },
    ];
    if (this.revisionEntity == RevisionEntity.Profile) {
      this.columns.push(
        {
          name: "R",
          prop: "ReadabilityScore",
          maxWidth: 50,
          cellTemplate: this.readabilityScore,
          sortable: false,
          filter: {
            dynamicProperty: "ExtendData",
            filterType: PrimasFilterType.DropDown,
            filterValue: [
              {
                text: 'Good',
                value: Helper.numberOfRange(71, 100)
              },
              {
                text: 'Optimize',
                value: Helper.numberOfRange(60, 70)
              },
              {
                text: 'Bad',
                value: Helper.numberOfRange(0, 59)
              },
              {
                text: 'Never Check',
                value: ", IsNullOrEmpty"
              },
            ],
            displayText: 'text',
            displayValue: 'value',
          },
          disableDefaultFilterOperator: true,
          cellClass: "text-center",
          headerClass: "text-center remove-header-padding",
          frozenLeft: true,
          import: false,
        }
      );
    }
    this.columns.push(
      {
        name: this.revisionVersionStatus == RevisionStatus.Recovery ? "Archived Id" : "Revision Id",
        prop: "revisionId",
        filter: {
          filterType: PrimasFilterType.Number
        },
        maxWidth: 160,
        frozenLeft: true,
      },
      {
        name: this.revisionVersionStatus == RevisionStatus.Recovery ? "Archived Date" : "Submission Date",
        prop: "dateCreated",
        pipe: this.dateTimeFormatPipe,
        headerClass: "text-center filter-date-time-column",
        cellClass: "text-center",
        filter: {
          filterType: PrimasFilterType.Date,
        },
        width: 250,
      },
      {
        name: "Name",
        prop: this.revisionEntity == RevisionEntity.Profile ? "rvProfile.displayName" : "rvCategory.categoryName",
        filter: true,
        // cellTemplate: this.revisionEntity == RevisionEntity.Profile ? this.profileOpenInline : this.categoryOpenInline
        width: 300,
      },
      {
        name: "Friendly URL",
        prop: "friendlyUrl",
        filter: true,
        cellTemplate: this.friendlyUrlOpenInline,
        width: 300,
      },
    );

    if (this.revisionVersionStatus == RevisionStatus.Recovery) {
      this.columns.push(
        {
          name: "Archived Information",
          prop: "extendData",
          filter: true,
          cellTemplate: this.restoreRevisionNoteTemplate,
          width: 250,
        },
      );
    }

    if (this.revisionVersionStatus == RevisionStatus.Rejected) {
      this.columns.push(
        {
          name: "Reject Reason",
          prop: "description",
          filter: true,
          cellTemplate: this.rejectReasonOpenInline,
        },
      );
    }

    if (this.revisionVersionStatus == RevisionStatus.Approved) {
      this.columns.push(
        {
          name: "Published",
          prop: "isPublish",
          filter: {
            filterType: PrimasFilterType.Boolean,
            filterValue: [
              {
                text: 'True',
                value: true
              },
              {
                text: 'False',
                value: false
              }
            ],
            displayText: 'text',
            displayValue: 'value',
          },
          cellTemplate: this.publishedContentTemplate,
          maxWidth: 150,
          cellClass: 'text-center'
        },
      )
    }

    this.columns.push(
      {
        name: this.revisionVersionStatus == RevisionStatus.Recovery ? "Archived By" : "Submitted By",
        prop: "createdBy",
        cellTemplate: this.submitter,
        filter: {
          filterType: PrimasFilterType.DropDown,
          filterValue: this.revisionVersionService.getListUserBaseOnRevision()
            .pipe(map(x => x.result?.map(user => {
              return {
                ...user,
                fullName: `${user?.userName}${user?.fullName ? ' - (' + user?.fullName + ')' : ''}`
              }
            }))),
          displayText: 'fullName',
          displayValue: 'id'
        },
      },
    )

    // WaitingForReview,
    // InReview,
    // Rejected,
    // Approved
    if (this.revisionVersionStatus == RevisionStatus.WaitingForReview ||
      this.revisionVersionStatus == RevisionStatus.InReview ||
      this.revisionVersionStatus == RevisionStatus.Rejected ||
      this.revisionVersionStatus == RevisionStatus.Approved
    ) {
      this.columns.push(
        {
          name: "Reviewer",
          prop: "reviewedBy",
          cellTemplate: this.reviewer,
          filter: {
            filterType: PrimasFilterType.DropDown,
            filterValue: this.revisionVersionService.getListUserBaseOnRevision()
              .pipe(map(x => x.result?.map(user => {
                return {
                  ...user,
                  fullName: `${user?.userName}${user?.fullName ? ' - (' + user?.fullName + ')' : ''}`
                }
              }))),
            displayText: 'fullName',
            displayValue: 'id'
          },
        },
        {
          name: "Reviewed Date",
          prop: "reviewedDate",
          pipe: this.dateTimeFormatPipe,
          headerClass: "text-center filter-date-time-column",
          cellClass: "text-center",
          filter: {
            filterType: PrimasFilterType.Date,
          },
          width: 250,
        },
        {
          name: "Published Date",
          prop: "publishedDate",
          pipe: this.dateTimeFormatPipe,
          headerClass: "text-center filter-date-time-column",
          cellClass: "text-center",
          filter: {
            filterType: PrimasFilterType.Date,
          },
          width: 250,
        },
      )
    }

    this.columns.push({
      name: "Date Modified",
      prop: "dateModified",
      pipe: this.dateTimeFormatPipe,
      headerClass: "text-center filter-date-time-column",
      cellClass: "text-center",
      filter: {
        filterType: PrimasFilterType.Date,
      },
      width: 250,
    }),

      this.settingService.getSettingByKeyAndGroup("FONT-FACING", "DOMAIN").subscribe(domains => {
        if (domains?.result) {
          this.domains = domains?.result?.value;
        }
      });
  }

  refreshData(reset: boolean = false) {
    this.primasTable.isLoading = 1;
    if (reset) {
      this.primasTable.page.pageNumber = 0;
      this.primasTable.cache = {};
      this.primasTable.rows = [];
      this.primasTable.selected = [];
      this.primasTable.table.offset = 0;
      this.primasTable.table.bodyComponent.offsetY = 0;
    }
    if (this.id) {
      this.primasTable.page.filter = Helper.formatFilter(this.primasTable.page.filter, 'objectId', this.id, PrimasFilterType.Text, PrimasTextFilterOperator.IsEqualTo);
    }
    this.revisionVersionService.getRevisionVersionMetaPaging(
      this.primasTable.page,
      this.revisionVersionStatus,
      this.revisionEntity
    ).subscribe((response) => {
      let result = response?.result;

      const formattedData = [];
      for (let i = 0; i < result?.data?.length; i++) {
        const item = result?.data[i];
        // Format creator fullName
        if (item?.submitter) {
          item.submitter.fullName = (`${item?.submitter?.firstName} ${item?.submitter?.lastName}`).trim();
        }
        // Format reviewer fullName
        if (item?.reviewer) {
          item.reviewer.fullName = (`${item?.reviewer?.firstName} ${item?.reviewer?.lastName}`).trim();
        }
        formattedData.push(item);
      }
      result.data = formattedData;
      if (result) {
      }
      setTimeout(() => {
        result.data = this.setupExtendData(result.data);
        this.primasTable.setData(result);
      }, 1)
    })
  }

  changeQueryParams() {
    this.route.queryParams.subscribe(e => {
      if (e) {
        if (ListRevisionEntityEnum.findIndex(x => x == e?.objectType) != -1) {
          this.revisionEntity = e?.objectType ?? this.revisionEntityEnum.Profile;
        }
        else {
          this.revisionEntity = this.revisionEntityEnum.Profile;
        }

        if (ListRevisionStatusEnum.findIndex(x => x == e?.status) != -1) {
          this.revisionVersionStatus = e?.status ?? RevisionStatus.WaitingForReview;
        }
        else {
          this.revisionVersionStatus = RevisionStatus.WaitingForReview;
        }

        if (this.primasTable) {
          this.primasTable.table.sorts = [];
          this.primasTable.page.orders = [];
          this.primasTable.clearAllFilter();
          this.primasTable.page.filter = [];

          this.ngOnInit();
          this.configHeader();
          this.primasTable.attachFilterTemplate();
          // this.refreshData(true);
        }
      }
    })
  }

  configHeader() {
    this.primasTable.columns.splice(1, this.primasTable.columns.length);
    this.primasTable.columns = [...Helper.getColumnsPermission([...this.primasTable.columns, ...this.columns])];
  }

  async approveRevision(row) {
    this.isPublishNow = true;

    const dialogApproveRevision = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to approve this revision?`,
        externalTemplate: this.publishCheckbox,
      }
    });

    let response = await dialogApproveRevision.afterClosed().toPromise();
    if (response) {
      this.primasTable.isLoading = 1;
      this.revisionVersionService.changeStatusRevision(row?.revisionId, RevisionStatus.Approved, this.isPublishNow).subscribe({
        next: (response) => {
          if (response?.result) {
            this.toast.success("Approve revision successfully", "Approve Revision");
          }
        },
        complete: () => {
          this.primasTable.isLoading = 0;
          this.refreshData();
        },
        error: () => {
          this.primasTable.isLoading = 0;
        }
      })
    }
  }

  async rejectRevision(row) {
    this.rejectReasonData = "";
    const dialogApproveRevision = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to reject this revision?`,
        externalTemplate: this.rejectReasonTemplate,
      }
    });

    let response = await dialogApproveRevision.afterClosed().toPromise();
    if (response) {
      this.primasTable.isLoading = 1;
      this.revisionVersionService.changeStatusRevision(row?.revisionId, RevisionStatus.Rejected, false, this.rejectReasonData).subscribe({
        next: (response) => {
          if (response?.result) {
            this.toast.success("Reject revision successfully", "Reject Revision");
          }
        },
        complete: () => {
          this.primasTable.isLoading = 0;
          this.refreshData();
          this.rejectReasonData = "";
        },
        error: () => {
          this.primasTable.isLoading = 0;
          this.rejectReasonData = "";
        }
      })
    }
  }

  async publishRevision(row) {
    const dialogApproveRevision = this.dialog.open(ConfirmModalComponent, {
      data: { message: `Do you wish to publish this revision?` }
    });

    let response = await dialogApproveRevision.afterClosed().toPromise();
    if (response) {
      this.primasTable.isLoading = 1;
      this.revisionVersionService.changeStatusRevision(row?.revisionId, RevisionStatus.Approved, true).subscribe({
        next: (response) => {
          if (response?.result) {
            this.toast.success("Publish revision successfully", "Publish Revision");
          }
        },
        complete: () => {
          this.primasTable.isLoading = 0;
          this.refreshData();
        },
        error: () => {
          this.primasTable.isLoading = 0;
        }
      })
    }
  }

  async unpublishRevision(row) {
    const dialogApproveRevision = this.dialog.open(ConfirmModalComponent, {
      data: { message: `Do you wish to unpublish this revision?` }
    });

    let response = await dialogApproveRevision.afterClosed().toPromise();
    if (response) {
      this.primasTable.isLoading = 1;
      this.revisionVersionService.changeStatusRevision(row?.revisionId, RevisionStatus.Approved, false).subscribe({
        next: (response) => {
          if (response?.result) {
            this.toast.success("Unpublish revision successfully", "Unpublish Revision");
          }
        },
        complete: () => {
          this.primasTable.isLoading = 0;
          this.refreshData();
        },
        error: () => {
          this.primasTable.isLoading = 0;
        }
      })
    }
  }

  async removeRevision(row) {
    const question = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to remove this revision?`
      },
      disableClose: true,
    });

    const response = await question.afterClosed().toPromise();
    if (response) {
      this.revisionVersionService.removeRevision(row?.revisionId).subscribe({
        next: (response) => {
          if (response?.result) {
            this.toast.success("Delete revision successfully", "Successfully Deleted");
          }
        },
        complete: () => {
          this.primasTable.isLoading = 0;
          this.refreshData();
        },
        error: () => {
          this.primasTable.isLoading = 0;
        }
      })
    }
  }

  onIsPublishNow() {
    this.isPublishNow = !this.isPublishNow;
  }

  openDialog = async (row) => {
    if (this.revisionEntity == RevisionEntity.Profile) {
      if (row?.rvProfile?.profileId) {
        let profileId: string = row?.rvProfile?.profileId;

        const dialogRef = this.dialog.open(ProfileDetailComponent, {
          disableClose: true,
          height: '100vh',
          width: '90vw',
          panelClass: 'dialog-detail',
          autoFocus: false,
          data: {
            id: profileId
          }
        });
        dialogRef.afterClosed().subscribe(response => {
          if (response != null) {
            this.refreshData(true);
          }
        });
      }
    }
    else if (this.revisionEntity == RevisionEntity.Category) {
      if (row?.rvCategory) {
        let categoryData = (await this.categoryService.getCategoryById(row?.rvCategory?.categoryId).toPromise())?.result;

        const dialogRef = this.dialog.open(AddEditCategoryComponent, {
          disableClose: true,
          height: '100vh',
          width: '600px',
          panelClass: 'dialog-detail',
          autoFocus: false,
          data: {
            model: categoryData,
            action: TblActionType.Edit
          }
        })
        dialogRef.afterClosed().subscribe(resp => {
          if (resp) {
            this.refreshData(true);
          }
        })
      }
    }
  }

  openDetailDialog = async (row: any) => {
    try {
      switch (this.revisionEntity) {
        case RevisionEntity.Profile: {
          const profileId = row?.rvProfile?.profileId;
          if (profileId) {
            const dialogRef = this.dialog.open(ProfileDetailComponent, {
              disableClose: true,
              height: "100vh",
              width: "63vw",
              panelClass: "dialog-detail",
              autoFocus: false,
              data: {
                id: profileId,
                isCustomSize: true,
              }
            });
            dialogRef.afterClosed().subscribe(res => this.refreshData());
          }
          break;
        }
        case RevisionEntity.Category: {
          const categoryId = row?.rvCategory?.categoryId;
          if (categoryId) {
            const categoryData = await this.categoryService.getCategoryById(categoryId).toPromise();
            if (categoryData?.result) {
              const dialogRef = this.dialog.open(AddEditCategoryComponent, {
                disableClose: true,
                height: "100vh",
                width: "600px",
                panelClass: "dialog-detail",
                autoFocus: false,
                data: {
                  model: categoryData?.result,
                  action: TblActionType.Edit,
                }
              });
              dialogRef.afterClosed().subscribe(res => this.refreshData());
            }
          }
          break;
        }
      }
    }
    catch (err) {
      console.error(err);
    }
  }

  goToLink(url: string, rowData: RVRevisionMeta) {
    if (url) {
      var configUrl = "";
      if (this.revisionEntity == RevisionEntity.Profile) {
        if (!rowData?.isPublish) {
          configUrl = `${this.domains}/profiles-preview/${rowData?.friendlyUrl}?rid=${this.encodeBigNumber(rowData?.revisionId)}`;
        }
        else {
          configUrl = `${this.domains}/act/${url}`;
        }
      }
      else if (this.revisionEntity == RevisionEntity.Category) {
        if (!rowData?.isPublish) {
          configUrl = `${this.domains}/categories-preview/${rowData?.friendlyUrl}?rid=${this.encodeBigNumber(rowData?.revisionId)}`;
        }
        else {
          configUrl = `${this.domains}/categories/${url}`;
        }
      }
      window.open(configUrl, '_blank');
    }
  }

  encodeBigNumber(param: number) {
    var result = "";
    var paramString = param.toString();
    result = btoa(paramString);
    return result;
  }
  clickSEOAudit(row) {
    this.windowService.open(this.seoAuditTemp, {
      hasBackdrop: false,
      context: row,
      initialState: NbWindowState.MAXIMIZED,
      title: `${row.revisionId} -  ${this.revisionEntity == RevisionEntity.Profile ? row.rvProfile.displayName : row.rvCategory.categoryName}`,
      windowClass: "seo-audit-class",
      buttons: {
        minimize: true,
        maximize: true,
        fullScreen: false,
      }
    });
  }
  groupingData() {
    if (this.primasTable) {
      this.primasTable.table.sorts = [];
      this.primasTable.page.orders = [];
      this.primasTable.clearAllFilter();
      this.primasTable.page.filter = [];

      this.ngOnInit();
      this.configHeader();
      this.primasTable.attachFilterTemplate();
    }
  }
  async openRejectReason(row) {
    if (row?.description) {
      this.rejectReasonData = row?.description;
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          message: null,
          externalTemplate: this.rejectReasonReadonlyTemplate,
          isDisableNoButton: true,
          yesTitle: "Close",
          externalTemplateContext: { row },
        }
      });
    }
  }

  async openRestoreNote(rowData) {
    if (rowData?.extendData) {
      let extendDataObj: RevisionExtendData = JSON.parse(rowData?.extendData);
      let restoreNote = extendDataObj?.RestoreNote;
      if (!Helper.isNullOrEmpty(restoreNote)) {
        this.restoreNoteData = { restoreNote: restoreNote };
        let row = this.restoreNoteData;

        let dialogRef = this.dialog.open(ConfirmModalComponent, {
          data: {
            message: null,
            externalTemplate: this.restoreNoteTemplate,
            isDisableNoButton: false,
            yesTitle: "Save",
            falseTitle: "Cancel",
            externalTemplateContext: { row },
          }
        });

        let response = await dialogRef.afterClosed().toPromise();
        if (response) {
          let model: RestoreRevisionViewModel = {
            revisionId: rowData?.revisionId,
            isSnapshot: false,
            note: this.restoreNoteData?.restoreNote,
          }

          this.revisionVersionService.changeRestoreNote(model).subscribe({
            next: (response) => {
              if (response) {
                this.toast.success("Update restore note successfully!", "Success");
              }
            },
            complete: () => {
              this.restoreNoteData = null;
              this.refreshData();
            },
            error: () => {
              this.restoreNoteData = null;
              this.refreshData();
            }
          })
        }
      }
    }
  }

  restoreNoteChange($event) {
    try {
      if ($event?.target?.value) {
        this.restoreNoteData = { restoreNote: $event?.target?.value }
      }
    }
    catch (ex) {
      console.error(ex);
    }
  }

  clickAIChecker(row) {
    this.windowService.open(this.aiCheckerTemplate, {
      hasBackdrop: false,
      context: row,
      initialState: NbWindowState.MAXIMIZED,
      title: `(AI Detection) ${row?.revisionId} -  ${this.revisionEntity == RevisionEntity.Profile ? row?.rvProfile?.displayName : row?.rvCategory?.categoryName}`,
      windowClass: "ai-checker-window-class",
      buttons: {
        minimize: true,
        maximize: true,
        fullScreen: false,
      }
    });
  }

  onAIDetectionChanged(value: boolean) {
    try {
      if (value) {
        this.refreshData();
      }
    }
    catch (ex) {
      console.error(ex);
    }
  }

  async restoreRevision(rowData) {
    try {
      this.formRestore?.reset();

      //Setup data before show the modal
      // const today = new Date();
      // const year = today.getUTCFullYear();  // Extract the year
      // const month = (today.getUTCMonth() + 1).toString().padStart(2, '0');  // Extract and format the month
      // const day = today.getUTCDate().toString().padStart(2, '0');  // Extract and format the day

      // let date = `${year}${month}${day}`;

      // const hours = today.getUTCHours().toString().padStart(2, '0');   // Get UTC hours and format to two digits
      // const minutes = today.getUTCMinutes().toString().padStart(2, '0'); // Get UTC minutes and format to two digits

      // let timestamp = `${hours}${minutes}`;
      // let utcTime = today.toUTCString();
      // let currentTimestamp = `Backup date: ${utcTime}\nFrom: ${rowData?.revisionId}\nDescription: `
      // this.formRestore?.get("note").setValue(currentTimestamp);

      let dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          message: null,
          externalTemplate: this.restoreRevisionTemplate,
          isDisableNoButton: false,
          yesTitle: "Restore",
          externalTemplateContext: { formDetail: this.formRestore }
        }
      });

      let response = await dialogRef.afterClosed().toPromise();
      if (response) {
        let model: RestoreRevisionViewModel = {
          revisionId: rowData?.revisionId,
          objectType: this.revisionEntity,
          isSnapshot: this.formRestore?.get("isSnapshot")?.value ?? false,
          note: this.formRestore?.get("note")?.value,
          // timestamp: currentTimestamp,
        }

        this.revisionVersionService.restoreRevision(model).subscribe({
          next: (response) => {
            if (response) {
              this.toast.success("Restore revision successfully", "Success");
            }
          },
          complete: () => {
            this.formRestore?.reset();
            if(this.id){
              if(this.revisionEntity == RevisionEntity.Profile) {
                window.location.reload();
              }
              else {
                this.dialModalRef.close(true);
              }
            }
            else {
              this.refreshData();
            }
          },
          error: () => {
            this.formRestore?.reset();
            this.refreshData();
          }
        })
      }

    }
    catch (ex) {
      console.error(ex);
    }
  }

  setupExtendData(data: RVRevisionMeta[]): RVRevisionMeta[] {
    for (let i = 0; i < data?.length; i++) {
      try {
        if (data[i]?.extendData) {
          const extendDataObj = JSON.parse(data[i].extendData);
          data[i] = Object.assign(data[i], extendDataObj);
        }
      }
      catch (err) { }
    }
    return data;
  }
}

export enum ButtonType {
  Approve = "Approve",
  Reject = "Reject",
  Publish = "Publish",
  Unpublish = "Unpublish",
  RequestIndexing = "Request Indexing",
  Restore = "Restore",
  RestoreNote = "Restore Note",
}