import { DOMAIN_GROUP } from './../../../contances/setting.constances';
import { SettingService } from './../../../services/setting.service';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ViewChild, ElementRef, OnDestroy, AfterViewInit, Directive, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { TextFieldModule } from '@angular/cdk/text-field';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { NbPopoverDirective, NbTrigger, NbToastrService } from '@nebular/theme';
import { Renderer2 } from '@angular/core';
import { prop, required } from '@rxweb/reactive-form-validators';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { th } from 'date-fns/locale';
import { Setting } from 'src/app/shared/models/setting.model';
import { fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Helper } from 'src/app/shared/utility/Helper';
@Component({
  selector: 'app-inline-edit',
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.scss']
})

export class InlineEditComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  input: ElementRef;
  @ViewChild('myInput', { static: false }) set contentInput(content: ElementRef) {
    if (content) {
      this.input = content;
      this.input.nativeElement.focus();
      this.eventBlur = fromEvent(this.input.nativeElement, 'focusout')
        .pipe(delay(500), takeUntil(this._destroy));
      this.eventBlur.subscribe(res => {
        this.onBlur();
        this.handleFocusOut.emit(true);
      });

      if (this.isAscii) {
        this.inputControl.valueChanges.pipe(
          takeUntil(this._destroy),
          distinctUntilChanged(),
          debounceTime(10),
        ).subscribe(resp => this.inputControl.setValue(
          Helper.removeNonAscii(this.inputControl.value)
        ));
      }

      this.cd.detectChanges();
    }
  };

  @Input() isCurrency = false;
  @Input() label: string;
  @Input() inputData;
  @Input() isAdding = false;
  @Input() popupComponent;
  @Input() isAccess = false;
  @Input() required: boolean = false;
  @Input() accessTo: string;
  @Input() parentPath: string;// belike /act/{{inputData}} or
  // 2022-04-19 tienlm add start
  @Input() defaultNullLabel = 'None';
  // 2022-04-19 tienlm add end
  //2022-03-17 hmtien add start
  @Input() width: string | null;
  @Input() display: string | null;
  showInputData: any | null;
  //2022-03-17 hmtien add end

  @Input() readonlyInline = false;
  @Input() validatorValue = [];
  @Input() messageError: string = "Invalid";
  // 2022-08-31 tienlm add start
  // if EnterToSubmit = true then enter to submit; else nothing
  @Input() enterToSubmit = false;
  // if EscapeToCancel = true then cancel submit; else nothing
  @Input() escapeToCancel = false;
  // 2022-08-31 tienlm add end
  @Input() removePaddingBottom = false;
  @Input() accessFullWidth = false;
  @Input() usingMessageParent = false;
  @Input() widthDisplay: string | null = null;
  @Input() pipe: PipeTransform;
  @Input() prefix: string;
  @Input() isAscii: boolean = false;
  @Input() colorText:string = '';
  @Input() suffixValue: string = '';
  @Input() removeHttpPrefix : boolean = true;
  @Input() openWithEditMode: boolean = false;
  @Input() textCenter: boolean = false

  isEditing: boolean = false;
  accessList: Setting[];
  matcher = new MyErrorStateMatcher();

  backupInput: string;
  @Output() handleSave = new EventEmitter<FormControl>();
  @Output() cacheInputChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleFocusOut = new EventEmitter<boolean>();
  inputControl: FormControl;
  eventBlur: Observable<unknown> = null;
  isProcess: boolean = false;
  private _destroy: Subject<void> = new Subject<void>();

  constructor(
    private render: Renderer2,
    private settingService: SettingService,
    private toast: NbToastrService,
    private cd: ChangeDetectorRef
  ) {
    this.inputControl = new FormControl(this.inputData, this.validatorValue);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const inputChange = changes?.inputData;
    if(inputChange != null) {
      if (inputChange?.currentValue !== inputChange?.previousValue) {
        this.inputControl = new FormControl(this.inputData, this.validatorValue);
        this.showInputData = this.inputData;
        if (this.inputData != null) {
          if(this.removeHttpPrefix) {
            this.showInputData = this.inputData?.toString().replace('https://', '');
            this.showInputData = this.showInputData?.replace('http://', '');
          }
          if (this.pipe) {
            this.showInputData = this.pipe.transform(this.showInputData);
          }
          if(this.suffixValue){
            this.showInputData += this.suffixValue;
          }
        }
      }
    }

    // if (this.accessTo) {
    //   this.settingService.domainList().subscribe((e: Setting[]) => {
    //     this.accessList = e;
    //   })
    // }

  }
  // getDefaultDomainData() {
  //   this.settingService.getDefaultDomainList();
  // }

  ngOnInit() {
    //2022-02-07 vuonglqn add start
    this.backupInput = this.inputData;
    //2022-02-07 vuonglqn add end
    //2022-03-17 hmtien add start
    if (this.width == null) {
      this.width = '100%';
    }
    this.showInputData = this.inputData;

    if (this.inputData != null) {
      if(this.removeHttpPrefix) {
        this.showInputData = this.inputData?.toString().replace('https://', '');
        this.showInputData = this.showInputData?.replace('http://', '');
      }
      if (this.pipe) {
        this.showInputData = this.pipe.transform(this.showInputData);
      }
      if(this.suffixValue){
        this.showInputData += this.suffixValue;
      }
    }

    this.isEditing = this.openWithEditMode;

    //2022-03-17 hmtien add end

    // if (this.accessTo) {
    //   this.settingService.domainList().subscribe((e: Setting[]) => {
    //     this.accessList = e;
    //   })
    // }
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  inputChange() {
    if (this.cacheInputChange && this.inputControl.valid) {
      if (this.required && (this.inputControl.value == "" || this.inputControl.value == undefined || this.inputControl.value == null))
        this.cacheInputChange.emit({ data: this.inputControl.value, isRemove: true });
      else this.cacheInputChange.emit({ data: this.inputControl.value, isRemove: false });
    } else this.cacheInputChange.emit({ data: this.inputControl.value, isRemove: true });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  onBlur() {
    if (!this.isProcess) {
      this.onConfirm();
    }
    this.setEditMode(false);
    this.isProcess = false;

  }

  goToLink(): void {
    if (this.accessTo) {
      window.open(`${this.accessTo}/${this.parentPath ?? ''}/${this.backupInput}`, "_blank");
    }
    else {
      if (this.backupInput.includes("https://"))
        window.open(this.backupInput, "_blank");
      else if (this.backupInput.includes("http://"))
        window.open(this.backupInput, "_blank");
      else window.open("https://" + this.backupInput, "_blank");
    }
  }

  focusText() {
    if (!this.input)
      setTimeout(() => { // this will make the execution after the above boolean has changed
        this.input?.nativeElement?.focus();
      }, 0);
  }

  onCancel() {
    this.isProcess = true;
    this.inputControl = new FormControl(this.backupInput, this.validatorValue);
    this.setEditMode(false);
    if (this.cacheInputChange)
      this.cacheInputChange.emit({ data: this.inputControl.value, isRemove: true });
  }

  onConfirm() {
    this.isProcess = true;
    if (this.inputControl.valid) {
      if (this.inputControl.value && this.inputControl.value.length != 0 && this.inputControl.value.length <= 0) return;
      if (this.handleSave && this.inputControl.value !== this.backupInput) {
        if (this.required) {
          if (this.inputControl.value) {
            this.handleSave.emit(this.inputControl.value);
          }
          else this.toast.warning("The field is not empty", "Invalid input")
          this.cacheInputChange.emit({ data: this.inputControl.value, isRemove: true });
        }
        else {
          this.handleSave.emit(this.inputControl.value);
          this.cacheInputChange.emit({ data: this.inputControl.value, isRemove: true });
        }
      }
      //this.inputControl = new FormControl(this.backupInput, this.validatorValue);
      this.setEditMode(false);
    }
  }

  setEditMode(mode: boolean) {
    if (this.isAdding) {
      this.inputData = '';
      this.inputControl = new FormControl(this.inputData, this.validatorValue);
    }
    if (!isNaN(this.inputControl.value)) {
      if (this.inputControl.value == 0) {
        this.inputControl.setValue("");
      }
    }
    this.focusText()
    this.backupInput = this.inputData;
    this.isEditing = mode;

  }
  // 2022-08-31 tienlm add start
  resetComponent() {
    this.backupInput = '';
    this.inputData = '';
    if (this.inputControl) {
      this.inputControl.reset();
    }
    this.inputControl = new FormControl(this.inputData, this.validatorValue);
    this.ngOnInit();
  }
  // 2022-08-31 tienlm add end
}
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
