export const defaultPerk: { [key: string]: any } = {
    numberOfVenue: 1,
    numberOfMedia: 5,
    numberOfEventSpace: 1,
    numberOfPricing: 1,
    seoReadability: false,
    seoAudit: false,
    venueTheme: false,
    requestToPublish: false,
}

export const perksProp = {
    numberOfVenue: 'numberOfVenue',
    numberOfMedia: 'numberOfMedia',
    numberOfEventSpace: 'numberOfEventSpace',
    numberOfPricing: 'numberOfPricing',
    seoReadability: 'seoReadability',
    seoAudit: 'seoAudit',
    venueTheme: 'venueTheme',
    requestToPublish: 'requestToPublish',
}

export const perksName = {
    numberOfVenue: 'Venue',
    numberOfMedia: 'Media',
    numberOfEventSpace: 'Event Space',
    numberOfPricing: 'Pricing',
    seoReadability: 'Readability',
    seoAudit: 'SEO Audit',
    venueTheme: 'Theme',
    requestToPublish: 'Request To Publish'
}
