<ng-container *ngIf="isEditing && !readonlyInline; else readonlyModeTmpl">
    <form>
        <mat-form-field class="mat-focused" [ngClass]="[
        removePaddingBottom ? 'remove-padding-form' : '',
        accessFullWidth ? 'access-full-width': 'full-width',
        ]" [ngStyle]="{'width': widthDisplay ? widthDisplay : ''}" appearance="legacy">
            <input id="myInput" #myInput class="custom-mat-input parent-font" matInput type="text"
                [formControl]="inputControl" [errorStateMatcher]="matcher" (change)="inputChange()"
                (keydown.enter)="enterToSubmit? onConfirm() : null" (keydown.escape)="escapeToCancel? onCancel() : null"
                [matAutocomplete]="autocomplete">
            <mat-autocomplete #autocomplete="matAutocomplete"
                (optionSelected)="showSelectedProfile($event.option.value)" [displayWith]="displayProperty"
                class="altus-mat-autocomplete-overlay">
                <mat-option *ngIf="isNew" [value]="'NEW'"
                    style="text-align: center; background-color: #307abd; height: 35px; color: white">
                    <mat-icon>add</mat-icon>
                </mat-option>
                <mat-option *ngIf="isShowTitle && isShowSuggest" style="height: 35px !important;" [disabled]="true">
                    We found {{list.length}} results recently <b style="color:green">updated</b>.
                </mat-option>
                <mat-option *ngIf="!list || list.length == 0 && isShowNotFound" [disabled]="true">Not found
                </mat-option>
                <mat-option *ngFor="let profile of list;" [value]="profile" class="three-line">
                    <ng-container [ngTemplateOutlet]="externalTemplate ? externalTemplate : optionDefault"
                        [ngTemplateOutletContext]="{ item: profile }">
                    </ng-container>
                    <ng-template #optionDefault>
                        <div *ngIf="profile?.profileId" fxLayout="row" fxLayoutAlign="start end">
                            <div style="font-size:14px" fxFlex="40" class="limit-line"
                                style="--line:1;font-size:12px; margin-right:15px;">
                                <b>{{profile?.displayName || "No name display"}}</b>
                            </div>
                            <div class="text-secondary limit-line" [matTooltip]=""
                                style="--line:1;font-size:12px; margin-right:15px ;" fxFlex="40">
                                {{profile?.saleLeadsContact?.contactEmail || "No email display" }}
                            </div>
                            <div class="text-secondary limit-line" style="--line:1;font-size:12px" fxFlex="25">
                                {{(profile?.saleLeadsContact?.contactPhone | phoneFormat) || "No phone display" }}
                            </div>
                        </div>
                        <div *ngIf="profile?.industryScriptId" fxLayout="row" fxLayoutAlign="start end">
                            <div style="font-size:14px" fxFlex="50" class="limit-line"
                                style="--line:1;font-size:12px; margin-right:15px;">
                                <b>{{profile?.industryName || "No data display"}}</b>
                            </div>
                            <div class="text-secondary limit-line" [matTooltip]=""
                                style="--line:1;font-size:12px; margin-right:15px ;" fxFlex="50">
                                {{profile?.body || "No data display" }}
                            </div>
                        </div>
                    </ng-template>
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="inputControl.hasError('required') && !usingMessageParent" trim="blur">
                The field is not <strong>empty</strong>
            </mat-error>
            <mat-error *ngIf="inputControl.errors" trim="blur">
                {{messageError}}
            </mat-error>
            <button *ngIf="isAccess" mat-icon-button matSuffix style="color:blue" (click)="goToLink()">
                <mat-icon class="custom-icon">open_in_new</mat-icon>
            </button>
            <button *ngIf="showRemoveButton" mat-icon-button matSuffix color="warn" (click)="onRemove()">
                <mat-icon class="custom-icon">delete</mat-icon>
            </button>
            <button mat-icon-button matSuffix color="primary" (click)="onConfirm()" [disabled]="loading"
                [nbSpinner]="loading">
                <mat-icon class="custom-icon">save</mat-icon>
            </button>
            <button mat-icon-button matSuffix color="warn" (click)="onCancel()">
                <mat-icon class="custom-icon">close</mat-icon>
            </button>
        </mat-form-field>
    </form>


</ng-container>
<ng-template #readonlyModeTmpl>
    <b> {{label ? label + ": " : ''}}</b>
    <ng-container *ngIf="!funcRedirect; else modelFuncRedirect">
        <ng-container [ngTemplateOutlet]="textDataTemp"></ng-container>
    </ng-container>
</ng-template>

<ng-template #textDataTemp>
    <div [ngClass]="inputData != null ? 'text-limit' : ''"
        [ngStyle]="{'max-width': width , 'display': display, 'width': widthDisplay ? widthDisplay : ''}"
        id="shownInputLabel" class="textarea-inline-control parent-font" (click)="setEditMode(true)">
        {{ isCurrency ? (showInputData | currency) : (showInputData) || defaultNullLabel}}
    </div>
</ng-template>

<ng-template #modelFuncRedirect>
    <div fxLayout="row" fxLayoutAlign="start center">
        <ng-container [ngTemplateOutlet]="textDataTemp"></ng-container>
        <button *ngIf="inputData" fxFlex="24px" mat-icon-button matSuffix
            style="color:blue; height: 24px; width: 24px; line-height: normal;" (click)="funcRedirect()">
            <mat-icon class="custom-icon">open_in_new</mat-icon>
        </button>
    </div>
</ng-template>