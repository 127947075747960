import { prop, required, alpha } from '@rxweb/reactive-form-validators';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { Producer } from 'src/app/shared/models/producer';
import { ActivityLog } from '../campaign-management/campaign-tracking.model';
import { TaskActivityLog } from '../task-management/task-activity-log/task-activity-log.model';
import { Task } from '../task-management/task-board/task-board-lane/task/task.model';
import { Contact, ProfileAdditionDetail } from './profile-detail.model';
import { Contact as ContactForm } from 'src/app/shared/components/stand-alone-component/contact/contact.model';
import { IndustryScript } from '../industry-management/industry.model';
export class ProfileModel {
    profileId: string;

    displayName: string;

    referenceLink: string;

    realName: string;

    webPage: string;

    bio: string;

    notes: string;

    rider: string;

    travel: string;

    consent: boolean | null;

    published: boolean | null;

    dataState: number;

    dateModified: Date | string | null;

    datecreated: Date | string | null;

    tags: string;

    lastUser: string;

    facebook: string;

    setList: string;

    testimonials: string;

    previousClients: string;

    parentId?: string;

    signature: string;

    isMaster: boolean | null;

    isActive: boolean | null;

    locationId: number;

    contactId: number;

    deleted: boolean;

    dateDeleted: Date | string | null;

    // 2021-08-17 hieuvm start add
    tiktok: string;
    instagram: string;
    youtube: string;
    // 2021-08-17 hieuvm end add
    action: TblActionType;
    producer: Producer;
    saleLeadsContact: Contact | null;

    typeName: string;
    profileAdditionDetail: ProfileAdditionDetail | null;
    company: string;
    industryScript: IndustryScript;
}

export class AddEditProfileModel {
    @prop()
    profileId: string;
    @required()
    displayName: string;
    @required()
    realName: string;
    @prop()
    webPage: string;
    @prop()
    facebook: string;
    @prop()
    tags: string;
    @prop()
    categories: { key: string, value: string }[];
    @prop()
    notes: string;

    static convertContactToProfile(contact: ContactForm) {
        if (contact == null) return new AddEditProfileModel();
        let profileForm = new AddEditProfileModel();
        profileForm.displayName = `${contact.contactName ?? ''} ${contact.contactLastName ?? ''}`.trim();
        profileForm.realName = `${contact.contactName ?? ''} ${contact.contactLastName ?? ''}`.trim();
        profileForm.webPage = contact.website;
        profileForm.facebook = contact.facebook;
        return profileForm;
    }
}

export class ProfileLogDetail {
    profileCampaignLogs: ActivityLog[] = [];
    taskLogs: Task[] = [];
    systemSize: number;
    filterInfo: ProfileLogDetailRequestViewModel;
}
export class ActivityLogFilters {
    key: string;
    dateFilter: string[] | null;

}
export interface ProfileLogDetailRequestViewModel {
    profileId: string;
    pageNumber: number;
    filters: ActivityLogFilters;
}

export class ProfileGeneralChangeModel {
    profileId: string;
    gender: string;
    isVirtualAct: boolean;
    officiateWedding: boolean;
}

export class ProfileLocationViewModel extends ProfileModel {
    farAway: number;
    lookupBy: string;
}
export class ProfilePropViewModel{
    profileId:string;
    activityLogId:number;
    displayName:string;
}
export class ListDataProfile{
    activityLogId:number;
    logInfo1:string;
    logInfo2:string;
    displayName1:string;
    displayName2:string;
}
export class ListDataUser{
    activityLogId:number;
    logInfo1:string;
    logInfo2:string;
    userName1:string;
    userName2:string;
    name1:string;
    name2:string;
}
export class ProfileChangeLocalBusinessModel {
    profileId: string;
    showLocalBusiness: boolean;
}