<!-- <div appChangeUrlObjects [id]="id" [isOpenDialog]="openByDialog" [topic]="'opportunity-crm'"
    [name]="opportunityModel?.displayName"></div> -->
<mat-chip-list></mat-chip-list>
<ng-container *ngIf="isShowButton">
    <div class="d-flex flex-wrap align-items-space-between" mat-dialog-title [nbSpinner]="isLoadingEdit"
        style="margin: unset;padding: 0px 10px 1px 28px;" fxLayoutAlign="center center">
        <div [hidden]="isLoading" class="d-flex flex-wrap" fxLayoutGap="10px" fxFlexOrder='1' fxFlexOrder.lt-lg='1'>
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <mat-icon style="transform: scale(1.5);" [ngStyle]="{'color':opportunityEnums.Opportunity}">
                    account_circle</mat-icon>
            </div>
            <div class="entity-name-id">
                <span class="entity-name" fxLayout="row" fxLayoutAlign="space-around center">
                    {{environment.titleOpportunity}}
                </span>
                <span *ngIf="!isCopy;else copied"
                    [matTooltip]="'Click to copy this ' + environment.titleOpportunity + ' ID: '+ id"
                    (click)="copyGUIDToClipboard()" class="underline-id">#{{id | truncateText:[6,
                    '...']}}</span>
            </div>
            <mat-divider [vertical]="true">
            </mat-divider>
        </div>

        <!-- Last Contact -->
        <div fxFlex="350px" class="mx-1" *nbIsGranted="['view', 'show-last-contact']" fxFlexOrder='2'
            fxFlexOrder.lt-lg='2'>
            <div fxLayoutAlign="center center" class="w-100" *ngIf="lastContact != null && lastContact != ''"
                style="height: 40px" [style.background-color]="lastContact | getColorLastContact">
                <span class="mt-1" style="font-size: 16px">
                    {{lastContact != "Never Contact" ? 'Last Contact: ' : ''}}
                    {{lastContact | date: 'MM/dd/YYYY'}}
                    {{lastContact != "Never Contact" ? '(' + (lastContact | subtractDate) + ')' : lastContact}}
                </span>
            </div>
        </div>
        <!-- End -->

        <app-crm-call-status #statusCallComponent fxFlex="auto" (onStartCall)="onStartCall($event)"
            (onEndCall)="onEndCall()" style="display: none; height: 40px" [onNoteInlineChanged]="onNoteInlineChanged"
            fxFlexOrder='3' fxFlexOrder.lt-lg='4'>
        </app-crm-call-status>

        <div class="ml-auto" fxLayout="row" fxFlexOrder='4' fxFlexOrder.lt-lg='3'>
            <div fxLayout="row" fxLayoutAlign="start center">
                <button *nbIsGranted="['view', 'button-public-link-proposal']" mat-icon-button aria-label="Open URL"
                    (click)="copyPublicURLProposal()" matTooltip="Copy public link Proposal"
                    [nbSpinner]="getWhiteLabelLoading">
                    <mat-icon>view_cozy</mat-icon>
                </button>
                <button [nbSpinner]="isCheckingProposalPrevision" [disabled]="isCheckingProposalPrevision"
                    *nbIsGranted="['view', 'button-generate-proposal']" mat-icon-button debouncedClick
                    [throttleTime]="500" (throttleClick)="proposalArtistTemplate()" matTooltip="Generate Proposal">
                    <mat-icon>assistant</mat-icon>
                </button>
                <button mat-icon-button class="ml-auto float-start" matTooltipPosition="left"
                    [matTooltip]="'Copy this ' + environment.titleOpportunity + ' url to clipboard'"
                    (click)="copyToClipboard()">
                    <mat-icon style="transform: scale(0.9);">
                        share</mat-icon>
                </button>
                <mat-divider [vertical]="true" style="height:100%">
                </mat-divider>
            </div>
            <app-next-or-previous-detail #btnNextOrPre>
            </app-next-or-previous-detail>
            <button [nbSpinner]="isClosing" [disabled]="isClosing" *ngIf="enableCloseBtn" mat-icon-button
                matTooltip="Close" (click)="closeDialog()">
                <mat-icon style="color:red;">close</mat-icon>
            </button>
        </div>
    </div>

    <mat-divider></mat-divider>
</ng-container>

<mat-dialog-content style="max-height: 100vh" *ngIf="isLoading; then skeleton else bodyDialog"></mat-dialog-content>

<ng-template #bodyDialog>
    <div *ngIf="opportunityModel; else notExistedById" appDetectTabMode [typeElement]="'BodyDetail'" fxLayout="column"
        class="body-detail-container pr-4 pl-1" [ngClass]="{'is-dialog-container' : openByDialog}">
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start" class="h-100">
            <div *ngIf="data && data.advancedImport" style="width:224px;">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px"
                    style="width:200px;padding-right: 24px;" *ngIf="!data.externalTemplate; else externalTemplate">
                    <button mat-menu-item (click)="onClickAdvanceImport('Add')" [matTooltip]="'Create new entry'">
                        <mat-icon style="color:#28a745">add</mat-icon>
                    </button>
                    <button mat-menu-item (click)="onClickAdvanceImport('Update')"
                        [matTooltip]="'Update existing entry'">
                        <mat-icon color="primary">edit</mat-icon>
                    </button>
                    <button mat-menu-item (click)="onClickAdvanceImport('Skip')" [matTooltip]="'Skip Entry'">
                        <mat-icon style="color: red;">close</mat-icon>
                    </button>
                </div>
                <div style="overflow:auto; height: 80vh;width:200px;">
                    <div *ngFor="let item of data.importColumn" style=" margin-right: 24px;">
                        <div class="import-advanced-label">
                            {{item.name}}:
                        </div>
                        <div [matTooltip]="item.value" style="white-space: nowrap;">
                            {{item.value || 'No data display'}}
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                </div>
            </div>
            <div fxFlex="100" fxLayoutGap="1rem" fxLayout="row wrap" fxLayoutAlign="start start" class="h-100">
                <!--Column Left-->
                <!-- The old code uses mat-accordion -->
                <div fxFlex.lt-lg="25" [fxFlex.gt-md]="optionalTaskId ? '25' : '15vw'" fxLayout="column"
                    fxLayoutAlign="start start" fxFlexOrder="1" class="mt-2" multi>
                    <ng-container [ngTemplateOutlet]="detailInformation"></ng-container>
                </div>

                <!--Column Center-->
                <div fxFlex="calc(100% - 30vw - 32px)" fxLayout="column" fxLayoutAlign="start start"
                    [fxFlexOrder.gt-md]="optionalTaskId ? 3 : 2" fxFlexOrder.lt-lg="3">
                    <app-chevron-progress-bar class="w-100 mt-2" style="padding-bottom:9px;"
                        [chevronProgressBarLst]="listStage" [displayText]="'value'" [displayValue]="'key'"
                        [selectedValue]="opportunityModel.automateDataStateId"
                        (onSelected)="!handleAutomate ? editStageOpportunity($event) : ''" [colorValue]="'color'">
                    </app-chevron-progress-bar>

                    <div class="w-100" fxLayout="row" fxLayoutAlign="start start">
                        <ng-container [ngTemplateOutlet]="contactTable"></ng-container>
                        <app-activity-timeline #activityLogTimeline fxFlex="auto" class="activity-log-timeline ml-3"
                            [id]="id" [model]="opportunityModel" [isOnlyDisplayProposal]="true"
                            (afterTaskWasUpdated)="refreshTaskTab()">
                        </app-activity-timeline>
                    </div>

                    <ng-container [ngTemplateOutlet]="tabGroup"></ng-container>
                </div>

                <!--Column Right-->
                <div #noteContainer class="note-item-container mt-2 pb-4" fxFlex.lt-lg="70"
                    [fxFlex.gt-md]="optionalTaskId ? '70':'15vw'" fxLayout.lt-lg="row"
                    [fxLayout.gt-md]="optionalTaskId ?'row' : 'column'" fxLayoutAlign="start start" fxLayoutGap="1rem"
                    [fxFlexOrder.gt-md]="optionalTaskId ? 2 : 3" fxFlexOrder.lt-lg="2"
                    [ngStyle.gt-md]="{'height': (optionalTaskId ? 'calc(45vh + 66px)' : '100%')}"
                    [ngStyle.lt-lg]="{'height': 'calc(45vh + 66px)'}">
                    <ng-container *ngIf="!isOpenCallNote" [ngTemplateOutlet]="noteCard" [ngTemplateOutletContext]="{
                        isNewNoteFE: false
                    }"></ng-container>
                    <ng-container [ngTemplateOutlet]="lostCard"></ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #detailInformation let-isSmall="isSmall">
    <!-- <mat-expansion-panel  [expanded]="isExpend" (afterExpand)="isExpend = true"
        (afterCollapse)="isExpend = false">
        <mat-expansion-panel-header>
            {{environment.titleOpportunity}}{{isExpend ? ' Information' : ': ' +
            opportunityModel?.saleOpportunity?.opportunityName}}
        </mat-expansion-panel-header> -->

    <div class="body-information">
        <div fxLayout="column" [ngStyle.gt-md]="{'max-height': (optionalTaskId ? '45vh': 'calc(105vh - 246px)')}"
            [ngStyle.lt-lg]="{'max-height': '45vh'}">
            <ng-container [ngTemplateOutlet]="defaultProperty" [ngTemplateOutletContext]="{
            icon: 'account_circle', toolTip: environment.titleOpportunity + ' Name',
            property: 'opportunityName',
            value: opportunityModel.saleOpportunity.opportunityName,
            typeModel: TypeEditModelOpportunity.Opportunity,
            validProp: validProperties.opportunityName
        }"></ng-container>
            <div fxFlex="100" fxFlexFill="100" fxLayout="column" class="pr-2"
                [matTooltip]="environment.titleAccount + ' Name'" matTooltipPosition="left">
                <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
                    {{environment.titleAccount}} Name
                </span>
                <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="flex-start center">
                    <span fxFlex="30px" class="material-icons-outlined blue-color" color="primary"
                        style="font-size: 24px;">
                        person
                    </span>
                    <app-search-edit-inline fxFlex="1 1 calc(100%-30px)" [isShowSuggest]="true"
                        [inputData]="opportunityModel?.saleOpportunity?.opportunityProfile?.displayName"
                        [apiSearching]="apiGetSearchRelationship" (handleSave)="saveAccount($event)"
                        [displayKey]="'displayName'" [widthDisplay]="'100%'" [isNew]="true"
                        [funcRedirect]="openRelationshipAccount">
                    </app-search-edit-inline>
                </div>
            </div>
            <ng-container [ngTemplateOutlet]="autocompleteInline" [ngTemplateOutletContext]="{
            icon: 'help', toolTip: 'Type',
            property: 'type',
            value: opportunityModel.saleOpportunity.type,
            typeModel: TypeEditModelOpportunity.Opportunity,
            observable :dynamicContentTypeAPI,
            searchProp: 'content'
        }"></ng-container>
            <ng-container *nbIsGranted="['view', 'probability-opportunity']" [ngTemplateOutlet]="defaultProperty"
                [ngTemplateOutletContext]="{
            icon: 'percent', toolTip:'Probability',
            property: 'probability',
            value: opportunityModel.saleOpportunity.probability,
            typeModel: TypeEditModelOpportunity.Opportunity,
            validProp: validProperties.probability,
            pipe: numPipe,
            disabled: opportunityModel['disabledProbability'],
            externalTootTip: opportunityModel['tooltipProbability']
        }"></ng-container>
            <ng-container [ngTemplateOutlet]="autocompleteInline" [ngTemplateOutletContext]="{
            icon: 'apartment', toolTip: 'Lead source',
            property: 'leadSource',
            value: opportunityModel.saleOpportunity.leadSource,
            typeModel: TypeEditModelOpportunity.Opportunity,
            observable :dynamicContentLeadSourceAPI,
            searchProp: 'content'
        }"></ng-container>
            <ng-container *ngIf="isCRMMode == '1'" [ngTemplateOutlet]="autocompleteInline" [ngTemplateOutletContext]="{
            icon: 'groups', toolTip: 'Company Size',
            property: 'companySize',
            value: opportunityModel.profileAdditionDetail?.companySize,
            typeModel: TypeEditModelOpportunity.Opportunity,
            searchProp: 'content'
        }"></ng-container>
            <ng-container [ngTemplateOutlet]="defaultProperty" [ngTemplateOutletContext]="{
            icon: 'numbers', toolTip: environment.titleEstimate,
            property: 'amount',
            value: opportunityModel.saleOpportunity.amount ,
            typeModel: TypeEditModelOpportunity.Opportunity,
            validProp: validProperties.amount,
            pipe: numPipe,
            prefix: prefixCurrency
        }"></ng-container>
            <ng-container *nbIsGranted="['view', 'non-artist-income']" [ngTemplateOutlet]="defaultProperty"
                [ngTemplateOutletContext]="{
            icon: 'numbers', toolTip: 'Non-Artist Income',
            property: 'nonArtistIncome',
            value: (opportunityModel.profileAdditionDetail?.nonArtistIncome) ? opportunityModel.profileAdditionDetail?.nonArtistIncome : 0,
            typeModel: TypeEditModelOpportunity.Opportunity,
            pipe: numPipe,
            prefix: prefixCurrency,
            disabled: true,
            externalTootTip: ''
        }"></ng-container>
            <ng-container *nbIsGranted="['view', 'one-time-product']" [ngTemplateOutlet]="defaultProperty"
                [ngTemplateOutletContext]="{
            icon: 'numbers', toolTip: environment.title1xProduct,
            property: 'oneTimeProduct',
            value: (opportunityModel.profileAdditionDetail?.oneTimeProduct) ? opportunityModel.profileAdditionDetail?.oneTimeProduct: 0,
            typeModel: TypeEditModelOpportunity.Opportunity,
            validProp: validProperties.oneTimeProduct,
            pipe: numPipe,
            prefix: prefixCurrency,
            disabled: isDisableProduct,
            externalTootTip: ''
        }"></ng-container>
            <ng-container *nbIsGranted="['view', 'total-client-fee']" [ngTemplateOutlet]="defaultProperty"
                [ngTemplateOutletContext]="{
            icon: 'numbers', toolTip: 'Total Client Fee',
            property: 'totalClientFee',
            value: (opportunityModel.profileAdditionDetail?.totalClientFee) ? opportunityModel.profileAdditionDetail?.totalClientFee : 0,
            typeModel: TypeEditModelOpportunity.Opportunity,
            pipe: numPipe,
            prefix: prefixCurrency,
            disabled: true,
            externalTootTip: ''
        }"></ng-container>
            <ng-container *nbIsGranted="['view', 'artist-fees-to-client']" [ngTemplateOutlet]="defaultProperty"
                [ngTemplateOutletContext]="{
            icon: 'numbers', toolTip: 'Artist Fees To Client',
            property: 'artistFeesToClient',
            value: (opportunityModel.profileAdditionDetail?.artistFeesToClient) ? opportunityModel.profileAdditionDetail?.artistFeesToClient : 0,
            typeModel: TypeEditModelOpportunity.Opportunity,
            validProp: validProperties.artistFeesToClient,
            pipe: numPipe,
            prefix: prefixCurrency,
            disabled: true,
            externalTootTip: ''
        }"></ng-container>
            <ng-container *nbIsGranted="['view', 'non-artist-expenses']" [ngTemplateOutlet]="defaultProperty"
                [ngTemplateOutletContext]="{
            icon: 'numbers', toolTip: 'Non-Artist Expenses',
            property: 'nonArtistExpense',
            value: (opportunityModel.profileAdditionDetail?.nonArtistExpense) ? opportunityModel.profileAdditionDetail?.nonArtistExpense : 0,
            typeModel: TypeEditModelOpportunity.Opportunity,
            pipe: numPipe,
            prefix: prefixCurrency,
            disabled: true,
            externalTootTip: ''
        }"></ng-container>
            <ng-container *nbIsGranted="['view', 'one-time-service']" [ngTemplateOutlet]="defaultProperty"
                [ngTemplateOutletContext]="{
            icon: 'numbers', toolTip: environment.title1xService,
            property: 'oneTimeService',
            value: (opportunityModel.profileAdditionDetail?.oneTimeService) ? opportunityModel.profileAdditionDetail?.oneTimeService : 0,
            typeModel: TypeEditModelOpportunity.Opportunity,
            validProp: validProperties.oneTimeService,
            pipe: numPipe,
            prefix: prefixCurrency
        }"></ng-container>
            <ng-container *nbIsGranted="['view', 'arr-opportunity']" [ngTemplateOutlet]="defaultProperty"
                [ngTemplateOutletContext]="{
            icon: 'numbers', toolTip: 'ARR',
            property: 'aRR',
            value: (opportunityModel.profileAdditionDetail?.arr)? opportunityModel.profileAdditionDetail?.arr : 0,
            typeModel: TypeEditModelOpportunity.Opportunity,
            validProp: validProperties.arr,
            pipe: numPipe,
            prefix: prefixCurrency
        }"></ng-container>
            <ng-container [ngTemplateOutlet]="defaultProperty" [ngTemplateOutletContext]="{
            icon: 'numbers', toolTip: 'Term Of the Contract',
            property: 'termOfContract',
            value: (opportunityModel.profileAdditionDetail?.termOfContract)? opportunityModel.profileAdditionDetail?.termOfContract : 0,
            typeModel: TypeEditModelOpportunity.Opportunity,
            validProp: validProperties.termOfContract,
            pipe: numPipe
        }"></ng-container>
            <ng-container *ngIf="!isCalculate; else avatarSkeleton" [ngTemplateOutlet]="defaultProperty"
                [ngTemplateOutletContext]="{
            icon: 'numbers', toolTip: environment.titleTotalValue,
            property: 'totalValue',
            value: opportunityModel.profileAdditionDetail?.totalValue ?? 0 ,
            suffixValue: (' (' + calculatePercentProfit + '%' + ')' ),
            typeModel: TypeEditModelOpportunity.Opportunity,
            validProp: validProperties.termOfContract,
            pipe: numPipe,
            prefix: prefixCurrency,
            disabled: true,
            colorText:colorTextDisplay,
            externalTootTip: ''
        }"></ng-container>
            <ng-container [ngTemplateOutlet]="ownerTemplate" [ngTemplateOutletContext]="{}"></ng-container>
            <div fxFlex="100" fxLayout="column" class="pr-3" matTooltip={{environment.titleClosedDate}}
                matTooltipPosition="left" fxLayout="column">
                <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
                    {{environment.titleClosedDate}}
                </span>
                <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="flex-start center">
                    <span fxFlex="30px" class="material-icons-outlined blue-color" color="primary"
                        style="font-size: 24px; padding-bottom: 0.5em;">
                        calendar_month
                    </span>
                    <mat-form-field fxFlex="1 1 calc(100%-30px)" class="inline-datetime-picker">
                        <input matInput [matDatepicker]="closeDatePicker" (click)="closeDatePicker.open()"
                            [value]="this.opportunityModel.saleOpportunity.closeDate" readonly
                            (dateChange)="editOpportunity($event.value, 'closeDate', TypeEditModelOpportunity.Opportunity)">
                        <mat-datepicker-toggle matSuffix [for]="closeDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #closeDatePicker>
                            <ng-template
                                (click)="editOpportunity($event.value, 'closeDate', TypeEditModelOpportunity.Opportunity)">
                                <span>OK</span>
                            </ng-template>
                        </mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxLayout="column" class="pr-3" matTooltip='End Date (Optional)'
                *nbIsGranted="['view','end-date-opportunity']" matTooltipPosition="left" fxLayout="column">
                <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
                    End Date (Optional)
                </span>
                <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="flex-start center">
                    <span fxFlex="30px" class="material-icons-outlined blue-color" color="primary"
                        style="font-size: 24px; padding-bottom: 0.5em;">
                        calendar_month
                    </span>
                    <mat-form-field fxFlex="1 1 calc(100%-30px)" class="inline-datetime-picker">
                        <input matInput [matDatepicker]="secondaryCloseDatePicker"
                            (click)="secondaryCloseDatePicker.open()"
                            [value]="this.opportunityModel.saleOpportunity.secondaryCloseDate" readonly
                            (dateChange)="editOpportunity($event.value, 'secondaryCloseDate', TypeEditModelOpportunity.Opportunity)">
                        <button mat-icon-button matSuffix
                            (click)="editOpportunity(null, 'secondaryCloseDate', TypeEditModelOpportunity.Opportunity)"
                            style="color: rgba(0, 0, 0, 0.54);"
                            [disabled]="!this.opportunityModel.saleOpportunity.secondaryCloseDate"><mat-icon>close</mat-icon></button>
                        <mat-datepicker-toggle matSuffix [for]="secondaryCloseDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #secondaryCloseDatePicker>
                            <ng-template
                                (click)="editOpportunity($event.value, 'closeDate', TypeEditModelOpportunity.Opportunity)">
                                <span>OK</span>
                            </ng-template>
                        </mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <!-- </mat-expansion-panel> -->
    </div>
</ng-template>

<ng-template #tabGroup>
    <mat-tab-group #tabGroup mat-stretch-tabs animationDuration="0ms" [selectedIndex]="selectTab"
        (selectedIndexChange)="tabIndexChanged($event)" class="w-100 mt-2">
        <mat-tab label="Vendor" *nbIsGranted="['view', 'profile-vendor-opportunity-tab']">
            <ng-template mat-tab-label>
                Vendor ({{('TotalVendor' | findItem: generalCount : 'typeName')?.totalNumber || 0}})
                <button mat-icon-button color="primary" debouncedClick [throttleTime]="500"
                    (throttleClick)="addVendorToOpportunity()">
                    <mat-icon>add</mat-icon>
                </button>
            </ng-template>
            <ng-template matTabContent>
                <app-profile-vendor #profileVendorGrid [profileId]="id" (refreshTotalNumber)="setupTotalNumber()"
                    [customHeight]="'35vh'">
                </app-profile-vendor>
            </ng-template>
        </mat-tab>
        <mat-tab label="Artist" *nbIsGranted="['view', 'artist-opportunity-tab']">
            <ng-template mat-tab-label>
                Artist
                <button mat-icon-button color="primary" debouncedClick [throttleTime]="500"
                    (throttleClick)="addArtistToProposal()">
                    <mat-icon>add</mat-icon>
                </button>
                <button *nbIsGranted="['view', 'wishlist-interactive-proposal']" mat-icon-button color="primary"
                    debouncedClick [throttleTime]="500" (throttleClick)="openWishList()" matTooltip="Open the wish list"
                    style="color: red">
                    <mat-icon>favorite</mat-icon>
                </button>
            </ng-template>
            <ng-template matTabContent>
                <app-artist-tab #artistGrid [opportunityId]="id"
                    (calcReload)="editOpportunity($event, 'totalValue',TypeEditModelOpportunity.Opportunity)"
                    [maxHeight]="'35vh'">
                </app-artist-tab>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                Tasks ({{('TotalTask' | findItem: generalCount : 'typeName')?.totalNumber || 0}})
                <app-add-task-button [model]="opportunityModel"
                    (refreshTaskGrid)="refreshTaskTab()"></app-add-task-button>
            </ng-template>
            <ng-template matTabContent>
                <app-task-grid-by-profile-id #taskGrid [profileId]="id" (refreshDataValue)="setupTotalNumber()"
                    [openFromExternal]="true" (refreshActivityLogTimeLine)="refreshActivityLog()" [maxHeight]="'35vh'"
                    [saleModel]="opportunityModel"></app-task-grid-by-profile-id>
            </ng-template>
        </mat-tab>
        <mat-tab label="Non-Artist Income" *nbIsGranted="['view', 'table-non-artist-income']">
            <ng-template mat-tab-label>
                Non-Artist Income
                <button mat-icon-button color="primary" debouncedClick [throttleTime]="500"
                    (throttleClick)="createProposalNonArtist('INCOME')">
                    <mat-icon>add</mat-icon>
                </button>
            </ng-template>
            <ng-template matTabContent>
                <app-non-artist-income #proposalNonArtistTab [resource]="resourceProposalNonArtistIncome"
                    [proposalId]="id" [className]="'INCOME'" [maxHeight]="'35vh'"
                    (calcReload)="editOpportunity($event, 'totalValue',TypeEditModelOpportunity.Opportunity)">
                </app-non-artist-income>
            </ng-template>
        </mat-tab>
        <mat-tab label="Non-Artist Expenses" *nbIsGranted="['view', 'table-non-artist-expenses']">
            <ng-template mat-tab-label>
                Non-Artist Expenses
                <button mat-icon-button color="primary" debouncedClick [throttleTime]="500"
                    (throttleClick)="createProposalNonArtist('EXPENSES')">
                    <mat-icon>add</mat-icon>
                </button>
            </ng-template>
            <ng-template matTabContent>
                <app-non-artist-income #proposalNonArtistTab [resource]="resourceProposalNonArtistExpenses"
                    [proposalId]="id" [className]="'EXPENSES'" [maxHeight]="'35vh'"
                    (calcReload)="editOpportunity($event, 'totalValue',TypeEditModelOpportunity.Opportunity)">
                </app-non-artist-income>
            </ng-template>
        </mat-tab>
        <mat-tab label="Files" *nbIsGranted="['view', 'table-sale-opportunity-file']">
            <ng-template mat-tab-label>
                Files ({{('TotalFile' | findItem: generalCount : 'typeName')?.totalNumber || 0}})
                <button *nbIsGranted="['view', 'add-profile-file']" #nbFilesBtn="nbPopover" mat-icon-button
                    color="primary" debouncedClick [throttleTime]="500" (throttleClick)="nbPopoverCLick()"
                    [nbPopover]="addFilesMenu" nbPopoverPlacement="left">
                    <mat-icon>add</mat-icon>
                </button>
                <button mat-icon-button *nbIsGranted="['view', 'open-folder-365']" style="color: rgb(5, 56, 5)"
                    debouncedClick [throttleTime]="500" (throttleClick)="openOneDriveFolderUrl()"
                    [nbPopover]="addFilesMenu" nbPopoverPlacement="left">
                    <mat-icon>cloud_queue</mat-icon>
                </button>
            </ng-template>
            <ng-template matTabContent>
                <app-profile-file-tab #profileFileTab [profileModel]="opportunityModel" [perm]="fileResource"
                    [maxHeight]="'35vh'" (refreshDataValue)="setupTotalNumber()">
                </app-profile-file-tab>
            </ng-template>
        </mat-tab>
        <mat-tab label="Note">
            <ng-template mat-tab-label>
                Note ({{('TotalNote' | findItem: generalCount : 'typeName')?.totalNumber || 0}})
                <button mat-icon-button color="primary" debouncedClick [throttleTime]="500" (throttleClick)="noteService.pushNoteDetails({
                        referenceId: id,
                        referenceType: 'OPPORTUNITY',
                        referenceName: opportunityModel.displayName
                    })">
                    <mat-icon>add</mat-icon>
                </button>
            </ng-template>
            <ng-template matTabContent>
                <app-note-tab #gridNoteTab [id]="id" [referenceType]="'OPPORTUNITY'" [resource]="resource"
                    (editChange)="noteService.pushNoteDetails($event.row, opportunityModel.displayName)"
                    (deletedChange)="overlayNoteDetails ? overlayNoteDetails.deleteNoteFromOutSide($event) : '';
                    inlineNote.getLastNote($event, true)" [nbSpinner]="overlayNoteDetails?.isLoading | async"
                    (refreshTotalNumber)="setupTotalNumber()" [customHeight]="'35vh'">
                </app-note-tab>
            </ng-template>
        </mat-tab>

    </mat-tab-group>
</ng-template>

<ng-template #contactTable>
    <div fxLayout="column" class="contact-table border rounded">
        <div class="w-100" fxLayout="row" fxLayoutAlign="start center" style="background-color: #ebecec;">
            <b style="padding-left: 8px;">Contact ({{totalContact || 0}})</b>
            <app-add-contact-button [id]="id" [type]="'Profile'" [showCampaignButton]="true"
                (onRefresh)="contactTab ? contactTab.refreshData(true) : ''; isHasPrimaryContact ? '': refreshData(); resetContacts()"
                [disableCampaignButton]="contactTab?.primasTable?.rows?.length == 0"
                (onClickCampaign)="createContactCampaign()"
                [showCompanyName]="opportunityModel?.saleOpportunity?.opportunityProfile?.displayName || ''">
            </app-add-contact-button>
            <ng-container [ngTemplateOutlet]=" actionButtonTemplate"></ng-container>
        </div>
        <app-contact-relationship-grid #contactGrid class="w-100" [relationshipId]="id" [relationshipType]="'Profile'"
            [maxHeight]="'35vh'" (refreshDataValue)="refreshTotalNumberData(refreshTotalValueType.contact)"
            (getPrimaryContactRelationship)="resetContacts()">
        </app-contact-relationship-grid>
    </div>
</ng-template>

<ng-template #noteCard let-isNoteRow="isNoteRow" let-isNewNoteFE="isNewNoteFE">
    <app-crm-inline-note #inlineNote [relationshipId]="id" [model]="opportunityModel" typeRelationship="OPPORTUNITY"
        (refreshGridNote)="refreshNoteTab()" class="w-100 h-100"
        [ngClass]="lostTypeId == opportunityModel.automateDataStateId && !optionalTaskId ? 'limit-note-height' : ''"
        [isDisableEditorArea]="true" [isNoteRow]="isNoteRow != null ? isNoteRow : optionalTaskId"
        [onNoteInlineChanged]="onNoteInlineChanged" (refreshTotalNumber)="setupTotalNumber()"
        [isNewNoteFE]="isNewNoteFE">
    </app-crm-inline-note>
</ng-template>

<ng-template #lostCard>
    <div fxFlex="40" class="w-100 h-100" style="height: inherit;"
        *ngIf="lostTypeId == opportunityModel.automateDataStateId"
        [ngStyle]="{'margin-top': (isOpenCallNote) ? '1rem' : ''}">
        <nb-card class="body-note-card">
            <nb-card-header class="alert-danger" style="padding: 0.5rem 1rem; background-color:#ffafb6 !important"
                fxLayout="row" fxLayoutAlign="space-between center">
                <div>Lost Reason</div>
            </nb-card-header>
            <nb-card-body class="alert-danger">
                <app-inline-edit-area [inputData]="opportunityModel.saleOpportunity.lostReason"
                    (handleSave)="editOpportunity($event, 'lostReason',TypeEditModelOpportunity.Opportunity )"
                    [defaultNullLabel]="'This ' + environment.titleOpportunity + ' was lost, click here to add reason...'"
                    [maxRow]="2" (handleAutomate)="handleAutomateDataState($event)">
                </app-inline-edit-area>
            </nb-card-body>
        </nb-card>
    </div>
</ng-template>

<ng-template #notExistedById>
    <app-blank-page [message]="'The ' + environment.titleOpportunity + ' with id ' + id +  ' is not exist or deleted'">
    </app-blank-page>
</ng-template>

<ng-template #skeleton>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-3 px-4">
        <ngx-skeleton-loader fxFlex="48" count="4" animation="progress"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex="48" count="4" animation="progress"></ngx-skeleton-loader>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between start" class="px-4">
        <ngx-skeleton-loader fxFlex="48" count="2" [theme]="{ height: '50px' }" animation="progress">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex="48" count="3" animation="progress"></ngx-skeleton-loader>
    </div>
    <mat-divider class="mt-2 mx-4"></mat-divider>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-3 px-4">
        <ngx-skeleton-loader fxFlex="32" count="1" animation="progress"></ngx-skeleton-loader>
        <mat-divider style="margin-top: -1rem; height: 44px;" [vertical]="true"></mat-divider>
        <ngx-skeleton-loader fxFlex="32" count="1" animation="progress"></ngx-skeleton-loader>
        <mat-divider style="margin-top: -1rem; height: 44px;" [vertical]="true"></mat-divider>
        <ngx-skeleton-loader fxFlex="32" count="1" animation="progress"></ngx-skeleton-loader>
    </div>
    <mat-divider class="mx-4"></mat-divider>
    <div fxLayout="column" class="mt-3 px-4">
        <ngx-skeleton-loader fxFlex="100" [theme]="{
            height: '50vh'
        }" animation="progress"></ngx-skeleton-loader>
    </div>
</ng-template>

<ng-template #defaultProperty let-icon="icon" let-toolTip="toolTip" let-property="property" let-value="value"
    let-typeModel="typeModel" let-validProp="validProp" let-disabled="disabled" let-pipe="pipe" let-prefix="prefix"
    let-externalTootTip="externalTootTip" let-colorText="colorText" let-suffixValue="suffixValue">
    <div fxFlex="100" fxFlexFill="100" fxLayout="column" class="mb-2 pr-2" [matTooltip]="externalTootTip || toolTip"
        matTooltipPosition="left">
        <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
            {{toolTip}}
        </span>
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="flex-start center">
            <span fxFlex="30px" class="material-icons-outlined blue-color" color="primary" style="font-size: 24px;">
                {{icon}}
            </span>
            <app-inline-edit
                [ngStyle]="(property == 'opportunityName') && {'font-weight': '700', 'font-size': '17px', 'color': '#307abd'}"
                fxFlex="1 1 calc(100%-30px)" [inputData]="value" [style.font-weight]="disabled ? '700' : 'normal'"
                (handleSave)="editOpportunity($event, property, typeModel)" [removePaddingBottom]="true"
                [widthDisplay]="'100%'" [validatorValue]="validProp ? validProp.valid : null"
                [messageError]="validProp ? validProp.message : null" [usingMessageParent]="true" [enterToSubmit]="true"
                [escapeToCancel]="true" [suffixValue]="suffixValue" [readonlyInline]="disabled ?? false" [pipe]="pipe"
                [colorText]="colorText" [prefix]="prefix">
            </app-inline-edit>
        </div>
    </div>
</ng-template>

<ng-template #actionButtonTemplate>
    <div fxLayout="row wrap" fxLayoutGap="1rem" class="ml-auto">
        <button #sendMailBtn="nbPopover" [nbPopover]="contactSelectPop" nbPopoverPlacement="bottom"
            nbPopoverTrigger="noop" mat-icon-button matTooltip="Send an email" (click)="nbMailPopover.show()">
            <mat-icon class="material-icons-outlined green-color">email</mat-icon>
            <!--  debouncedClick (throttleClick)="sendMailClick()" -->
        </button>
        <button #makeCallPopoverBtn="nbPopover" mat-icon-button matTooltip="Make a call"
            [nbPopover]="contactSelectPopCall" nbPopoverPlacement="bottom" nbPopoverTrigger="noop"
            (click)="makeCallPopoverBtn.show()" [disabled]="isCallLoading || isCalling"
            [nbSpinner]="isCallLoading || isCalling">
            <mat-icon class="material-icons-outlined green-color">call</mat-icon>
            <!-- debouncedClick (throttleClick)="onClickCall()" -->
        </button>
        <button *nbIsGranted="['view', 'send-sms-button']" #sendSMSBtn="nbPopover" mat-icon-button matTooltip="Send SMS"
            [nbPopover]="contactSelectPopSMS" nbPopoverPlacement="bottom" nbPopoverTrigger="noop"
            (click)="sendSMSPopover.show()">
            <mat-icon class="material-icons-outlined green-color">sms</mat-icon>
        </button>
    </div>
</ng-template>

<mat-menu #menuStage="matMenu" focusFirstItem="false" class="content-overlay" [overlapTrigger]="false">
    <button mat-menu-item mat-chip *ngFor="let state of listStage" class="chip-menu"
        [style.backgroundColor]="state.color"
        (click)="editOpportunity(state.key, 'automateDataStateId', TypeEditModelOpportunity.OpportunityProfile)">
        <strong>{{state.value}}</strong>
    </button>
</mat-menu>


<ng-template #copied>
    <span class="copied">
        Copied!!
    </span>
</ng-template>

<ng-template #ownerTemplate>
    <div fxFlex="100" *ngIf="ownerModel; else avatarSkeleton" fxLayout="column" class="mb-2 pr-2" matTooltip="Owner"
        matTooltipPosition="left">
        <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
            Owner
        </span>
        <div [style.color]="ownerModel?.id ? '' : 'rgb(0 0 0 / 60%)'">
            <nb-user class="custom-avatar-outline" fxFlex="30px" size="small" [onlyPicture]="true"
                [picture]="ownerModel?.pictureURL" [name]="ownerModel?.fullName">
            </nb-user>
            <app-search-edit-inline fxFlex="1 1 calc(100%-30px)" [inputData]="ownerModel?.id
                ? (ownerModel?.userName + ' (' + ownerModel?.fullName + ')')
                : 'Unassigning'" [apiSearching]="apiGetSearchOwner" [widthDisplay]="'100%'"
                [externalTemplate]="optionOwnerTemplate"
                (handleSave)="editOpportunity($event.id, 'ownerId', TypeEditModelOpportunity.OpportunityProfile)">
            </app-search-edit-inline>
        </div>
    </div>
</ng-template>

<ng-template #avatarSkeleton>
    <ngx-skeleton-loader fxFlex="100" fxFlexFill="100" class="pr-2" count="1" appearance="line" animation="progress"
        [theme]="{ 'margin-bottom': '0px', 'margin-top': '8px', 'height': '2.25rem' }">
    </ngx-skeleton-loader>
</ng-template>

<ng-template #optionOwnerTemplate let-item="item">
    <div fxLayout="row" fxLayoutAlign="start end">
        <div style="font-size:14px" fxFlex="40" class="limit-line" style="--line:1;font-size:12px; margin-right:15px;">
            <b>{{item?.userName || "No name display"}}</b> ({{item?.fullName || ""}})
        </div>
        <div class="text-secondary limit-line" [matTooltip]="" style="--line:1;font-size:12px; margin-right:15px ;"
            fxFlex="35">
            {{item?.email || "No email display" }}
        </div>
        <div class="text-secondary limit-line" style="--line:1;font-size:12px" fxFlex="25">
            {{(item?.phoneNumber | phoneFormat) || "No phone display" }}
        </div>
    </div>
</ng-template>


<ng-template #autocompleteInline let-icon="icon" let-toolTip="toolTip" let-property="property" let-value="value"
    let-observable="observable" let-searchProp="searchProp" let-typeModel="typeModel" let-validProp="validProp"
    let-disabled="disabled" let-pipe="pipe" let-prefix="prefix">
    <div fxFlex="100" fxFlexFill="100" fxLayout="column" class="mb-2 pr-2" [matTooltip]="toolTip"
        matTooltipPosition="left">
        <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
            {{toolTip}}
        </span>
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="flex-start center">
            <span fxFlex="30px" class="material-icons-outlined blue-color" color="primary" style="font-size: 24px;">
                {{icon}}
            </span>
            <app-auto-complete-inline-edit fxFlex="1 1 calc(100%-30px)" [inputData]="value"
                (handleSave)="editOpportunity($event, property, typeModel)" [removePaddingBottom]="true"
                [widthDisplay]="'100%'" [validatorValue]="validProp ? validProp.valid : null"
                [messageError]="validProp ? validProp.message : null" [usingMessageParent]="true" [enterToSubmit]="true"
                [escapeToCancel]="true" [readonlyInline]="disabled ?? false" [pipe]="pipe" [prefix]="prefix"
                [searchArrayAPI]="observable" [searchProperty]="searchProp">
            </app-auto-complete-inline-edit>
        </div>
    </div>
</ng-template>


<ng-template #contactSelectPop>
    <app-pop-over-select-contacts [id]="id" [type]="'OPPORTUNITY'" [(contactList)]="contacts"
        [primaryContactId]="opportunityModel.accountContactId" [action]="'email'"
        (onSelectedContact)="getSelectedContactMail($event)" [popover]="nbMailPopover" (refreshData)="refreshData()">
    </app-pop-over-select-contacts>
</ng-template>

<ng-template #contactSelectPopCall>
    <app-pop-over-select-contacts [id]="id" [type]="'OPPORTUNITY'" [(contactList)]="contacts"
        [primaryContactId]="opportunityModel.accountContactId" [action]="'call'"
        (onSelectedContact)="getSelectedContactCall($event)" [popover]="makeCallPopover" (refreshData)="refreshData()">
    </app-pop-over-select-contacts>
</ng-template>

<ng-template #contactSelectPopSMS>
    <app-pop-over-select-contacts [id]="id" [type]="'OPPORTUNITY'" [(contactList)]="contacts"
        [primaryContactId]="opportunityModel.accountContactId" [action]="'call'" [actionTitle]="'send SMS'"
        (onSelectedContact)="openSMS($event)" [popover]="sendSMSPopover" (refreshData)="refreshData()">
    </app-pop-over-select-contacts>
</ng-template>

<ng-template #externalTemplate>
    <ng-container [ngTemplateOutlet]="data.externalTemplate? data.externalTemplate : ''"
        [ngTemplateOutletContext]="{row: data.row}"></ng-container>
</ng-template>

<ng-template #addFilesMenu class="column d-flex flex-column align-items-start">
    <input #fileInput hidden="true" type="file" onclick="this.value=null" (change)="addSaleOpportunityFile($event)" />
    <!-- [extension]='{"extensions":["doc", "docx", "xls", "xlsx", "ppt", "pdf", "html", "zip", "csv", "txt"]}'
        accept=".doc,.docx,.xls,.xlsx,.ppt,.pdf,.html,.zip,.csv,.txt" /> -->

    <button mat-flat-button class="col float-start" debouncedClick [throttleTime]="500" matTooltipPosition="below"
        (throttleClick)="fileInput.click()">
        <mat-icon style="color: #007100;">add</mat-icon> Add New Files
    </button>

    <hr class="custom-divider">
    <button mat-flat-button class="col d-flex flex-row" debouncedClick [throttleTime]="500" matTooltipPosition="below"
        (click)="openURLDialogRef()">
        <mat-icon style="color: #007100;">add</mat-icon> Add URL
    </button>
</ng-template>
<ng-template #whiteLabelConfirmation>
    <mat-form-field appearance="standard">
        <mat-label>Proposal Template</mat-label>
        <mat-select [(ngModel)]="proposalOption">
            <mat-option value="Default">Default</mat-option>
            <mat-option value="White-Label">White-Label</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="standard" *ngIf="proposalOption == 'White-Label'">
        <mat-label>White-Label URL</mat-label>
        <input matInput [(ngModel)]="whiteLabelUrl">
        <span matPrefix>https://&nbsp;</span>
        <span matSuffix>.{{rootWhiteLabelUrl}}</span>
    </mat-form-field>
</ng-template>
<ng-template #copyExternalProposalUrl>

    <div fxFlex="100" fxFlexFill="100" fxLayout="column" class="my-2">
        <span fxFlex="100" style="font-size:12px; color: rgb(0,0,0,.7)">
            Default URL
        </span>
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center" class="altus-card ml-0 p-1">
            <div>{{this.backOfficeUrl}}proposal-views/{{this.id}}</div>
            <button mat-icon-button (click)="copyWhiteLabelUrl('Default')">
                <mat-icon>content_copy</mat-icon>
            </button>
        </div>
    </div>
    <div fxFlex="100" fxFlexFill="100" fxLayout="column" *ngIf="proposalWhiteLabelData?.whiteLabelUrl">
        <span fxFlex="100" style="font-size:12px; color: rgb(0,0,0,.7)">
            White-Label URL
        </span>
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center" class="altus-card ml-0 p-1">
            <div>
                https://{{proposalWhiteLabelData?.whiteLabelUrl}}.{{proposalWhiteLabelData?.rootWhiteLabelUrl}}/proposal-views/{{this.id}}
            </div>
            <button mat-icon-button (click)="copyWhiteLabelUrl('White-Label')">
                <mat-icon>content_copy</mat-icon>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #headerCallDialog let-statusCallModel="statusCallModel">
    <button mat-flat-button debouncedClick [throttleTime]="500" (click)="createFollowingUpTask()">
        <mat-icon>edit</mat-icon>
        Create Follow Up Task
    </button>

    <app-crm-call-status fxFlex="auto" [valueModel]="statusCallModel" (onStartCall)="onStartCall($event)"
        (onEndCall)="onEndCall()" [onNoteInlineChanged]="onNoteInlineChanged">
    </app-crm-call-status>
</ng-template>