import { Media } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { SeoInfoModel } from '../seo-form-control.model';

export class CategorySeoRequestModel {
    category: CategoryModel;
    seoInfo: SeoInfoModel;
    // tslint:disable-next-line:whitespace
    IsUpdateSeo?= false;
}

export class CategoryModel {
    categoryId: string;
    categoryName: string;
    parentId: string | null;
    description: string | null;
    topDescription: string | null;
    deleted = false;
    askAnExpertImage: string;
    askAnExpertDescription: string;
    firstHyperImage: Media;
    firstHyperLink: string;
    firstHyperDescription: string;
    firstActive: boolean;
    secondHyperImage: Media;
    secondHyperLink: string;
    secondHyperDescription: string;
    secondActive: boolean;
    faqs:string;
    tags: string;
    media: Media;
}

export interface ParentCategoryModel {
    categoryId: string;
    categoryParentId: string;

}