<nb-card style="height: 100% !important; padding-bottom: 10px !important"
  [style.backgroundColor]="isChosen ? '#f5faff' : null" [style.borderColor]="isChosen ? '#C7C6C6' : null">
  <nb-card-header [style.borderColor]="isChosen ? '#C7C6C6' : null">
    <div>
      <app-inline-edit [inputData]="displayName" (handleSave)="editDisplayName($event)"
        (cacheInputChange)="cacheInputProfile($event,'displayName')"
        [label]="findItemByKey(tooltipProp, 'displayName') ?? 'Display name'" [readonlyInline]="readonlySEO"
        [required]="true">
      </app-inline-edit>
      <span>
        <!-- <mat-icon *ngIf= "duplicateIconLoading" [nbSpinner]="duplicateIconLoading">error_outline</mat-icon> -->
        <mat-icon class="duplicate-display-name" [matTooltip]="tooltipDisplayName" *ngIf="isDuplicate"
          matTooltipPosition="right">error_outline</mat-icon>
      </span>
    </div>
    <div>
      <mat-icon class="help-icon" color="primary" style="font-size:18px"
        title="A page title, also known as a title tag, is a short description of a webpage and appears at the top of a browser window and in SERPs. It is an important element of an optimized SEO page. A page title should include a page's keyword in the title tag.">
        help</mat-icon>
      <app-inline-edit [inputData]="profileSeoInfo.seoInfo.title" (handleSave)="editTitle($event)" label="Title"
        [readonlyInline]="readonlySEO" (cacheInputChange)="cacheInputData($event,'title')">
      </app-inline-edit>
      <span style="display: inline-block; margin-left: 5px;" *ngIf="profileSeoInfo.primarySeoInfo; else primary">
        <mat-chip class="primary-tag" selected>Primary</mat-chip>
      </span>
      <div class="float-right">
        <button mat-icon-button (click)="openConfirmDialog()" *ngIf="!profileSeoInfo.primarySeoInfo; else disableDelBtn"
          [disabled]="readonlySEO">
          <mat-icon [color]="readonlySEO ? 'basic' : 'warn'">delete</mat-icon>
        </button>
      </div>
    </div>
    <div>
      <mat-icon class="help-icon" color="primary" style="font-size:18px"
        title="Unique URL for profiles, we can change it to anything we want and this URL will become the URL for the profiles.">
        help</mat-icon>

      <app-inline-edit [inputData]="profileSeoInfo.seoInfo.friendlyUrl" (handleSave)="editFriendlyUrl($event)"
        (cacheInputChange)="cacheInputData($event,'friendlyUrl')" label="Friendly URL" [readonlyInline]="readonlySEO"
        isAccess="true" [accessTo]="accessTo" parentPath='act' [required]="true"
        [validatorValue]="validateFriendlyUrl.validate"
        messageError="This field cannot have any char different than alphabet and number!">
      </app-inline-edit>

      <span style="display: inline-block; margin-left: 5px;" *ngIf="profileSeoInfo.primarySeoInfo && ffcUrlState">
        <mat-chip class="primary-tag" selected [style.background]="ffcUrlColor[ffcUrlState]">{{ffcUrlState}}</mat-chip>
      </span>
    </div>
  </nb-card-header>
  <nb-card-body [style.borderColor]="isChosen ? '#C7C6C6' : null">
    <mat-icon class="help-icon" color="primary" style="font-size:18px"
      title="The SEO site description represents the page. Search engines show this description in search results for your homepage if they don't find content more relevant to a visitor's search terms.">
      help</mat-icon>

    <app-inline-edit-area [inputData]="profileSeoInfo.seoInfo.description" (handleSave)="editDescription($event)"
      label="Description" [readonlyInlineArea]="readonlySEO" (cacheInputChange)="cacheInputData($event,'description')">
    </app-inline-edit-area>
    <app-seo-categories [categories]="profileSeoInfo?.seoInfo?.seoCategories" [profileId]="profileId"
      [relationId]="profileSeoInfo?.seoInfoId" [readonlySEOCategories]="readonlySEO">
    </app-seo-categories>
  </nb-card-body>
  <nb-card-footer [style.borderColor]="isChosen ? '#C7C6C6' : null">
    <mat-icon class="help-icon" color="primary" style="font-size:18px"
      title="SEO keywords are the keywords and phrases in your web content that make it possible for people to find your site via search engines. A website that is well optimized for search engines “speaks the same language” as its potential visitor base with keywords for SEO that help connect searchers to your site.">
      help</mat-icon>
    <app-inline-edit-area [inputData]="profileSeoInfo.seoInfo.keywords" (handleSave)="editKeywords($event)"
      label="Keywords" [readonlyInlineArea]="readonlySEO" (cacheInputChange)="cacheInputData($event,'keywords')">
    </app-inline-edit-area>
  </nb-card-footer>
</nb-card>

<ng-template #primary>
  <button *ngIf="!readonlySEO" mat-button color="primary" (click)="setPrimary()">Set primary</button>
</ng-template>
<ng-template #disableDelBtn>
  <button mat-icon-button [disabled]="true">
    <mat-icon>delete</mat-icon>
  </button>
</ng-template>
