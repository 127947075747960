<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    {{this.data.typeMediaType == 2 ? 'Video' : 'Image'}} Gallery
    <div fxLayout="row" fxLayoutAlign="end end">
        <button mat-icon-button (click)="onClickToggleButton()">
            <mat-icon>{{deleteMode ? 'arrow_back' : 'library_add_check'}}</mat-icon>
        </button>
        <button mat-icon-button (click)="getMedia()">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>

</div>
<mat-dialog-content>
    <div [nbSpinner]="loading" style="min-height:30vh; width:fit-content; min-width: 70vw;">
        <div *ngIf="medias.length > 0" class="image-gallery">
            <div class="image-container" *ngFor="let item of medias; index as i">
                <ng-container *ngIf="!deleteMode; else deletedMode;">
                    <ng-container *ngIf="this.data.typeMediaType == 2; else img">
                        <div (click)="selectItem(item)">
                            <iframe
                                [ngClass]="item.media1 == selectedItem?.media1 ? 'target disable-iframe pointer' : 'disable-iframe pointer'"
                                [src]="item.media1 | safeIframe" width="100%" height="100%"></iframe>
                        </div>
                    </ng-container>
                    <ng-template #img>
                        <img [ngClass]="item.media1 == selectedItem?.media1 ? 'target pointer' : null"
                            [src]="item.media1" loading="lazy" width="100%" height="100%" (click)="selectItem(item)">
                    </ng-template>
                </ng-container>

                <ng-template #deletedMode>
                    <mat-checkbox class="media-checkbox" [(ngModel)]="deleteItems[i].selected">
                        <div style="width:inherit;height:inherit"></div>
                    </mat-checkbox>
                    <ng-container *ngIf="this.data.typeMediaType == 2; else img1">
                        <div (click)="clickToAddDeletedItem(i)">
                            <iframe class="disable-iframe pointer opacity-media" [src]="item.media1 | safeIframe"
                                width="100%" height="100%"></iframe>
                        </div>
                    </ng-container>
                    <ng-template #img1>
                        <img [src]="item.media1" class="pointer opacity-media" loading="lazy" width="100%" height="100%"
                            (click)="clickToAddDeletedItem(i)">
                    </ng-template>
                </ng-template>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="multi-media-dialog-actions" align="end">
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <div *ngIf="deleteMode">
        <button mat-raised-button [disabled]="deleteItems?.length == 0" (click)="deleteMedia()" color="warn">
            <mat-icon>deleted</mat-icon>Delete
        </button>
    </div>
    <div *ngIf="!deleteMode" class="upload-upgrade-button-base">
        <button *cusPerk="[perksName.numberOfMedia, data?.profileId]" mat-raised-button (click)="clickUpload()"
            [disabled]="data.typeMediaType == 2 ? (obsUploadVideo | async) : false">
            <mat-icon>file_upload</mat-icon>Upload
        </button>
    </div>
    <button *ngIf="!deleteMode" mat-raised-button color="primary" [mat-dialog-close]="selectedItem">Ok</button>
</mat-dialog-actions>

<ng-template #uploadImage>
    <p *ngIf="invalidSize" style="color: red; text-align: center;">Total size each upload < 20MB</p>
            <div ngx-dropzone [ngStyle.xs]="{'width': '85vw'}" [ngStyle.sm]="{'width': '80vw'}"
                [ngStyle.md]="{'width': '60vw'}" [ngStyle.lg]="{'width': '30vw'}" [ngStyle.xl]="{'width': '30vw'}"
                (change)="onSelect($event)" [accept]="'image/*'">
                <ngx-dropzone-label>Drag your files here or click in this area.</ngx-dropzone-label>
                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files"
                    [removable]="true" [file]="f" (removed)="onRemove(f)" [removable]="true">
                    <ngx-dropzone-label></ngx-dropzone-label>
                </ngx-dropzone-image-preview>
            </div>
            <mat-dialog-actions align="end">
                <button mat-button (click)="dialogUpload ? dialogUpload.close() : ''">Cancel</button>
                <button mat-raised-button color="primary" (click)="onSaveUpload()" [nbSpinner]="loading"
                    [disabled]="invalidSize || loading">Save
                </button>
            </mat-dialog-actions>
</ng-template>