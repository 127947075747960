import { DOMAIN_GROUP, FONT_FACING } from './../../../../../../shared/contances/setting.constances';
import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { KeyPairsValue, ProfileSeo, SeoInfo } from '../../../profile-detail.model';
import { ProfileService } from '../../../profile.service';
import { ProfileRequest } from 'src/app/shared/models/request/profile-request.model';
import { mergeMap } from 'rxjs/operators';
import { SettingService } from 'src/app/shared/services/setting.service';
import { Helper } from 'src/app/shared/utility/Helper';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-seo-info',
  templateUrl: './seo-info.component.html',
  styleUrls: ['./seo-info.component.scss']
})
export class SeoInfoComponent implements OnInit, OnChanges {
  @Input() profileSeoInfo: ProfileSeo;
  @Input() profileId: string;
  @Input() displayName: string;
  @Input() isDuplicate: boolean;
  //2021-11-1 vuonglqn start add
  @Input() readonlySEO = false;
  //2021-11-1 vuonglqn start end

  @Input() isChosen: boolean = false;
  @Input() tooltipProp: KeyPairsValue[] = [];

  accessTo = FONT_FACING;
  tooltipDisplayName = "The display name is duplicated";
  findItemByKey = Helper.findItemByKey;
  validateFriendlyUrl = {
    validate: [RxwebValidators.pattern({ expression: { url: /^[a-zA-Z0-9]+([- ]?[a-zA-Z0-9]+)*$/ }, message: 'This field cannot have any char different than alphabet and number!' })],
    errorMessage: 'This field cannot have any char different than alphabet and number!'
  }
  ffcUrlState: string = "";
  ffcUrlColor = FfcUrlColor

  constructor(private profileService: ProfileService, private toast: NbToastrService, private dialog: MatDialog,
    public dialModalRef: MatDialogRef<SeoInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public settingService: SettingService
  ) {
    this.profileId = data.id;
  }

  ngOnInit(): void {
    this.settingService.getSettingByKeyAndGroup(FONT_FACING, DOMAIN_GROUP).subscribe(e => {
      if (e.result) this.accessTo = e.result.value;
    })

    this.settingService.getSettingByKeyAndGroup("SHOW_GOOGLE_STATUS", "SEO").subscribe(resp => {
      if (resp?.result?.value?.trim() === "1") {
        if (this.profileSeoInfo?.primarySeoInfo) {
          this.ffcUrlState = Helper.toTitlecase(this.profileSeoInfo?.seoInfo?.ffcUrl?.state);
          if (this.ffcUrlState === "Notindexed") {
            this.ffcUrlState = "Not Indexed";
          }

          if (!this.ffcUrlState) {
            this.profileService.bindingFfcUrl(this.profileSeoInfo).subscribe({
              next: (resp) => {
                if (resp?.result) {
                  if (resp.result.state === "NOTINDEXED") {
                    this.ffcUrlState = "Not Indexed";
                  }
                  else {
                    this.ffcUrlState = Helper.toTitlecase(resp.result.state);
                  }
                }
                else {
                  this.ffcUrlState = "Not On Google";
                }
              },
            })
          }

        }
      }
      else {
        this.ffcUrlState = null;
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    let onChangeTooltipProp = changes.tooltipProp;
    if (onChangeTooltipProp && onChangeTooltipProp.currentValue !== onChangeTooltipProp.previousValue) {
      this.tooltipDisplayNameFunc();
    }
  }

  refreshData() {
    this.profileService.refreshSeoInfo(this.profileId)
  }
  editDisplayName(data) {
    const profile = new ProfileRequest();
    profile.displayName = data;
    this.profileService.editProfile(this.profileId, profile).pipe(mergeMap(e =>
      this.profileService.refreshData(this.profileId)
    )).subscribe();
  }
  editKeywords(data): void {
    let seoInfoRequest: SeoInfo = new SeoInfo();
    seoInfoRequest.seoInfoId = this.profileSeoInfo?.seoInfo?.seoInfoId;
    seoInfoRequest.keywords = data;
    this.profileService.editSeoInfo(seoInfoRequest, this.profileId).subscribe((response) => {
      if (response.message) return;
      if (response.result) {
        this.toast.success('Saved');
      }
      else this.toast.danger('Failed')
      this.refreshData();
    })
  }
  editDescription(data): void {
    let seoInfoRequest: SeoInfo = new SeoInfo();
    seoInfoRequest.seoInfoId = this.profileSeoInfo?.seoInfo?.seoInfoId;
    seoInfoRequest.description = data;
    this.profileService.editSeoInfo(seoInfoRequest, this.profileId).subscribe((response) => {
      if (response.message) return;
      if (response.result) {
        this.toast.success('Saved');
      }
      else this.toast.danger('Failed')
      this.refreshData();
    })
  }
  editTitle(data): void {
    let seoInfoRequest: SeoInfo = new SeoInfo();
    seoInfoRequest.seoInfoId = this.profileSeoInfo?.seoInfo?.seoInfoId;
    seoInfoRequest.title = data;
    this.profileService.editSeoInfo(seoInfoRequest, this.profileId).subscribe((response) => {
      if (response.message) return;
      if (!response.message) {
        if (response.result == true) {
          this.toast.success('Saved');
        }
        else this.toast.danger('Failed')
        this.refreshData();
      }
    })
  }
  editFriendlyUrl(data): void {
    let seoInfoRequest: SeoInfo = new SeoInfo();
    seoInfoRequest.seoInfoId = this.profileSeoInfo?.seoInfo?.seoInfoId;
    seoInfoRequest.friendlyUrl = data;
    this.profileService.editSeoInfo(seoInfoRequest, this.profileId).subscribe((response) => {
      if (response.message) return;
      if (!response.message) {
        if (response.result == true) {
          this.toast.success('Saved');
        }
        else this.toast.danger('Failed')
        this.refreshData();
      }

    })
  }
  openConfirmDialog(): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to delete this SEO information?`
      }
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.profileService.deleteSeoInfo(this.profileSeoInfo.seoInfoId).subscribe((item: ReturnResult<boolean>) => {
          if (!item.message) {
            if (item.result) {
              this.toast.success(`Delete SEO information successfully!`, 'Success');
              this.refreshData();
            }
          }

        });
      }
    });
  }
  setPrimary(): void {
    this.profileService.setPrimary(this.profileId, this.profileSeoInfo?.seoInfoId).subscribe((response) => {
      if (!response.message) {
        if (response.result == true) {
          this.toast.success('Saved');
        }
        else this.toast.danger('Failed')
        this.refreshData();
      }
    })
  }
  cacheInputData(data, prop: string) {
    this.profileService.dataSeoChange(data, prop, this.profileSeoInfo.seoInfoId);
  }
  cacheInputProfile(data, prop: string) {
    this.profileService.dataChange(data, prop);

  }

  tooltipDisplayNameFunc() {
    this.tooltipDisplayName = `The ${(this.findItemByKey(this.tooltipProp, 'displayName') ?? 'display name').toLocaleLowerCase()} is duplicated`;
  }
}

export const FfcUrlColor =  {
  "Indexed": "green !important",
  "Requested Success": "#2920a7 !important",
  "Requesting": "var(--orange) !important",
  "Queue": "var(--orange) !important",
  "Crawl": "var(--info) !important",
  "Recrawl": "var(--danger) !important",
  "Deindex": "var(--warning) !important",
  "Ignored": "var(--gray) !important",
  "Not On Google": "var(--gray) !important",
  "Not Indexed": "var(--red) !important",
  "Requested Failed": "var(--red) !important",
  "Removed": "var(--red) !important",
  "Redirect": "var(--orange) !important",
}
