import { BREAKPOINT } from '@angular/flex-layout';

export const CUSTOM_BREAKPOINTS = [
    { alias: 'lt-1440', mediaQuery: 'screen and (max-width: 1339px)', overlapping: false, priority: 1001 },
    { alias: 'gt-1440', mediaQuery: 'screen and (min-width: 1440px)', overlapping: false, priority: 1001 },
];

export const CustomBreakPointsProvider = {
    provide: BREAKPOINT,
    useValue: CUSTOM_BREAKPOINTS,
    multi: true,
};