import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Page } from 'src/app/shared/models/paging/page';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { environment } from 'src/environments/environment';
import { Contact, ProfileDetailModel } from '../profile-detail.model';
import {
  CampaignDataDetail,
  CampaignPerformanceReport,
  ContactGroupReturn, ImportCampaignContactResponseModel, LinkScopeCampaignLst, LinkScopeCreateCampaignResponseModel
} from './create-campaign.model';
import { CampaignReport } from './step3/email-linkscope-template.model';

@Injectable({
  providedIn: 'root'
})
export class CreateCampaignService {

  constructor(private http: HttpClient) { }
  baseUrl = environment.apiCampaign;
  linkScopeCampaignLst$ = new BehaviorSubject<LinkScopeCampaignLst>(undefined);
  addContactGroups(LINKSCOPEWEB_URL: string, contactGroupName: string): Observable<ContactGroupReturn> {
    return this.http.post<ContactGroupReturn>(`${LINKSCOPEWEB_URL}/OutboundCampaign/AddGroup`, {
      DisplayName: contactGroupName
    });
  }
  createContactGroupCampaign(displayName: string): Observable<ReturnResult<ContactGroupReturn>> {
    return this.http.post<ReturnResult<ContactGroupReturn>>(`${this.baseUrl}/CreateContactGroup`, { DisplayName: displayName });
  }
  //typeName is a type of profile LEADS, SALEPROFILE, SALEACCOUNT
  exportContactCampaignByProfile(
    profiles: ProfileDetailModel[], page: Page, groupName: string, isSelectAll: boolean = false, appId: string = null, insertPhoneCall: boolean = false, typeName: string = null): Observable<ReturnResult<ImportCampaignContactResponseModel>> {
    if(typeName) {
      return this.http.post<ReturnResult<ImportCampaignContactResponseModel>>(`${this.baseUrl}/ImportContact?isSelectAll=${isSelectAll}&typeName=${typeName}`,
      {
        profiles,
        pagingTreeFilter: page,
        groupName,
        appId,
        insertPhoneCall
      });
    }
    else {
      return this.http.post<ReturnResult<ImportCampaignContactResponseModel>>(`${this.baseUrl}/ImportContact?isSelectAll=${isSelectAll}`,
      {
        profiles,
        pagingTreeFilter: page,
        groupName,
        appId,
        insertPhoneCall
      });
    }
    
     
  }
  exportContactCampaignByContact(contacts: Contact[], page: Page, groupName: string, appId: string = null, insertPhoneCall: boolean = false): Observable<ReturnResult<ImportCampaignContactResponseModel>> {
    return this.http.post<ReturnResult<ImportCampaignContactResponseModel>>(`${this.baseUrl}/ImportContact`, {
      contacts,
      page,
      groupName,
      appId,
      insertPhoneCall,
      ContactPaging: page
    });
  }
  // 2021-12-1 HMTien add start
  loadListCampaign(): Observable<ReturnResult<CampaignDataDetail>> {
    // return list of campaign
    return;
  }
  getLinkScopeCampaginLst() {
    return this.linkScopeCampaignLst$.asObservable();
  }
  RefreshGetLinkScopeCampaignList(): Observable<ReturnResult<LinkScopeCampaignLst>> {
    return this.http.get<ReturnResult<LinkScopeCampaignLst>>(`${this.baseUrl}/GetCampaignList`);
  }
  createLinkScopeCampaign(campaign: CampaignDataDetail): Observable<ReturnResult<LinkScopeCreateCampaignResponseModel>> {
    return this.http.post<ReturnResult<LinkScopeCreateCampaignResponseModel>>(`${this.baseUrl}/AddLinkScopeCampaign`, campaign);
  }
  saveCampaignReport(campaignReport: CampaignReport) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveCampaignReport`, campaignReport);
  }
  //2022-05-10 gnguyen start add

  RefreshGetLinkScopePhoneCallCampaignList(): Observable<ReturnResult<LinkScopeCampaignLst>> {
    return this.http.get<ReturnResult<LinkScopeCampaignLst>>(`${this.baseUrl}/GetPhoneCallCampaignList`);
  }
  createLinkScopePhoneCallCampaign(campaign: CampaignDataDetail): Observable<ReturnResult<LinkScopeCreateCampaignResponseModel>> {
    return this.http.post<ReturnResult<LinkScopeCreateCampaignResponseModel>>(`${this.baseUrl}/AddLinkScopePhoneCallCampaign`, campaign);
  }
  //2022-05-10 gnguyen end add

  campaignPerformanceReport(campaignId: number, mode: string ): Observable<ReturnResult<CampaignPerformanceReport[]>> {
    return this.http.get<ReturnResult<CampaignPerformanceReport[]>>(`${this.baseUrl}/CampaignPerformanceReport?campaignId=${campaignId}&mode=${mode}`);
  }
  getContactWithMostOpens(campaignId: number ): Observable<ReturnResult<[]>> {
    return this.http.get<ReturnResult<[]>>(`${this.baseUrl}/GetContactMostReads?campaignId=${campaignId}`);
  }
}
