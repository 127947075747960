import { Pipe, PipeTransform } from '@angular/core';
import { NbDateService } from '@nebular/theme';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {

  /**
   *
   */
  constructor(protected dateService: NbDateService<Date>,) {

  }
  transform(value: any, args?: any): any {
    if (value != null && value.length > 0) {
      let dateParsed = value;
      try {
        dateParsed = this.dateService.format(new Date(value), environment.formatDateTime);
      } catch (error) {
        console.warn(error);
        dateParsed = this.dateService.format(new Date(), environment.formatDateTime);
      }
      return dateParsed;
    }
  }

}


@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  /**
   *
   */
  constructor(protected dateService: NbDateService<Date>,) {

  }
  transform(value: any, args?: any): any {
    if (value != null && value.length > 0) {
      let dateParsed = value;
      try {
        dateParsed = this.dateService.format(new Date(value), environment.formatDate);
      } catch (error) {
        console.warn(error);
        dateParsed = this.dateService.format(new Date(), environment.formatDate);
      }
      return dateParsed;
    }
  }
}


@Pipe({
  name: 'enumPipe',
})
export class EnumPipe implements PipeTransform {
  constructor() {

  }
  transform(value: any, type: any): any {
      let newValue = type[value] as string;
      
      if(newValue) {
        const result = newValue.replace(/([A-Z])/g, " $1");
        newValue = result.charAt(0).toUpperCase() + result.slice(1);
        newValue = newValue.toUpperCase();
      }
      return newValue;
  }
}