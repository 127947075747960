<div class="d-flex flex-wrap" mat-dialog-title style="margin: unset;" [nbSpinner]="isLoading"
  style="margin: unset;padding: 1px 10px 1px 63px;">
  <button mat-button color="primary" (click)="stepper.previous()" *ngIf="stepper.selectedIndex==1">
    Back
  </button>
  <button mat-button color="primary" (click)="stepper.next();" [disabled]="!formDoc.valid"
    *ngIf="stepper.selectedIndex==0">
    Next
  </button>
  <button mat-button color="primary" (click)="importFile()" [disabled]="!formDoc.valid"
    *ngIf="stepper.selectedIndex==1">
    Import
  </button>
  <button mat-icon-button aria-label="Close the dialog" (click)="closeDialog()" [disabled]="disableClose"
    class="ml-auto"><mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="formDoc" style="padding-top: 25px;padding-right: 50px;padding-left: 50px;">
    <mat-horizontal-stepper labelPosition="bottom" linear #stepper (selectionChange)="onStepChange($event)">
      <mat-step label=" UPLOAD" [completed]="formDoc.valid" [editable]="previousEnable" state="upload">
        <mat-form-field>
          <ngx-mat-file-input (click)="$event.target.value=null" formControlName="requiredfile" placeholder="Upload your file"
            valuePlaceholder="No file selected" required [accept]="'.xlsx'"></ngx-mat-file-input>
          <mat-icon matSuffix>folder</mat-icon>
          <mat-error *ngIf="formDoc.get('requiredfile').hasError('required')">
            Please select a file
          </mat-error>
          <mat-error *ngIf="formDoc.get('requiredfile').hasError('maxContentSize')">
            The total size must not exceed {{formDoc.get('requiredfile')?.getError('maxContentSize').maxSize |
            byteFormat}} ({{formDoc.get('requiredfile')?.getError('maxContentSize').actualSize
            | byteFormat}}).
          </mat-error>
        </mat-form-field>
        <small class="text-muted row" *ngIf="templateFile">
          <em class="col-12"><a class="float-left" href="{{templateFile}}">Click here to download the
              excel
              template.</a></em>
        </small>
      </mat-step>
      <mat-step label="MAP" state="map" [completed]="isComplete" [editable]="previousEnable">
        <p class="text-center">Map columns in your file to item properties.</p>
        <ngx-datatable style="height: inherit;" #table class="material" [rows]="rows" [columns]="columns"
          [columnMode]="ColumnMode.force" [footerHeight]="0" [headerHeight]="50" [rowHeight]="50" [scrollbarV]="true"
          [scrollbarH]="true" [reorderable]="false" [swapColumns]="false">
        </ngx-datatable>
      </mat-step>
      <mat-step label="RESULT" state="result">
        <nb-alert status="danger" *ngIf="systemError"> Sorry, the system has an unexpected technical issue.</nb-alert>
        <nb-card *ngIf="!systemError">
          <nb-card-header>Import Complete</nb-card-header>
          <nb-card-body>
            <p> <b> There are <span class="badge badge-pill badge-info">{{totalRow}}</span> item(s) were processed to
                import with: </b></p>
            <br>
            <p><span class="badge badge-pill badge-success">{{totalNew}}</span> item(s) were created successfully</p>
            <p><span class="badge badge-pill badge-success"> {{totalUpdate}}</span> item(s) were modified from the
              existing entry successfully
            </p>
            <p><span class="badge badge-pill badge-danger"> {{totalError}}</span> item(s) were error
              <span *ngIf="fileError">. Please <a href="javascript:void(0)" (click)="downloadFileError()"> click
                  here</a> to download the error records</span>
            </p>
            <p><span class="badge badge-pill badge-warning">{{totalReview}}</span> item(s) need to review </p>
          </nb-card-body>
        </nb-card>
        <!-- <app-primas-toolbar #primasToolbar [selectedCout]="primasTable?.selected?.length"
          (onAfterAddFinish)="refreshData(true)" [disableAddNewButton]="true" [columns]="columns" [table]="primasTable" [resource]="data.toolbarResource">
        </app-primas-toolbar> -->

        <div [hidden]="totalReview == 0">
          <div class="alert alert-warning" role="alert">
            We found {{totalReview}} item(s) that have no email, but they have the same First Name and Last Name in our
            system.
            Please select to create new entry, update the existing one individually or in batch.
          </div>
          <nb-card-header>Review List</nb-card-header>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" *ngIf="reviewList.length > 0">
            <button mat-raised-button style="background-color:#28a745; color:white" (click)="bulkAction('Add')"
              [disabled]="primasTable.table.rows.length <= 0">
              <mat-icon>playlist_add</mat-icon>
              Bulk Create New
            </button>
            <button mat-raised-button style="background-color:#916f0b;  color:white" (click)="bulkAction('Update')"
              [disabled]="primasTable.table.rows.length <= 0">
              <mat-icon>edit_note</mat-icon>
              Bulk Update For Existing Entry
            </button>
            <button mat-button (click)="bulkSkipRecord()" [disabled]="primasTable.table.rows.length <= 0"
              style="background-color: red;color:white">
              <mat-icon>playlist_remove</mat-icon>
              Bulk Skip Entry
            </button>
          </div>
          <app-primas-table #primasTable class="content-table" [columnsTable]="reviewColumns" [resource]="data.resource"
            (onRefresh)="refreshData($event)" [tableAction]="true" [customAction]="customAction" [optionHeight]="'40vh'"
            [externalSorting]="false">
          </app-primas-table>
        </div>

        <br>

      </mat-step>
    </mat-horizontal-stepper>
  </form>
</mat-dialog-content>

<ng-template #importRequired let-row="row" let-value="value">
  <p [ngClass]="{'asterix-after':row.importRequired}">{{value}}</p>
</ng-template>

<ng-template #headerFromFile let-row="row" let-value="value" let-rowIndex="rowIndex">
  <mat-select [value]="value" (selectionChange)="onChangeColumn($event,value,rowIndex)">
    <mat-option [value]="-1">Ignore</mat-option> <!--Not map-->
    <mat-option [value]="-2">Type input</mat-option> <!--Type input-->
    <mat-option *ngFor="let column of  columnsFile; let i = index" [value]="column.value" [disabled]="column.selected">
      {{column.name}}
    </mat-option>
  </mat-select>
</ng-template>

<ng-template #reviewInfo let-row="row" let-value="value" let-rowIndex="rowIndex">
  <p *ngIf="row.columnFile > -1">{{value}}</p>
  <input matInput *ngIf="row.columnFile == -2" style="border-bottom: 1px solid #307abd;" type="text"
    placeholder="Typing value" (input)="inputProperty(rowIndex, $event.target.value)">
</ng-template>

<ng-template #customAction let-row="row" let-rowIndex="rowIndex">
  <button mat-menu-item (click)="onClickToOpenDialog(row)">
    <mat-icon color="primary">visibility</mat-icon>
    <span>View entry</span>
  </button>
  <button mat-menu-item (click)="onClickRow(row, 'Add')">
    <mat-icon style="color:#28a745">add</mat-icon>
    <span>Create new entry</span>
  </button>
  <button mat-menu-item (click)="onClickRow(row, 'Update')">
    <mat-icon color="primary">edit</mat-icon>
    <span>Update existing entry</span>
  </button>
  <button mat-menu-item (click)="onClickToSkipRecord({row, rowIndex})">
    <mat-icon style="color: red;">close</mat-icon>
    <span>Skip Entry</span>
  </button>
</ng-template>


<ng-template #valueTemplate let-value="value" let-row="row">
  <div fxFlex="100" (click)="onClickToOpenDialog(row)" class="pointer">
    {{value}}
  </div>
</ng-template>