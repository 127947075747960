import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { IndexingService } from '../../../../indexing/indexing.service';
import { SettingService } from 'src/app/shared/services/setting.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FFCUrl, GDAILYINDEXCOUNTER } from 'src/app/modules/admin/indexing/indexing.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Page } from 'src/app/shared/models/paging/page';
import { HotToastService } from '@ngneat/hot-toast';
import { SeoInfo } from '../../../profile-detail.model';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-submit-indexing-dialog',
  templateUrl: './submit-indexing-dialog.component.html',
  styleUrls: ['./submit-indexing-dialog.component.scss']
})
export class SubmitIndexingDialogComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  dailyIndexCounter: GDAILYINDEXCOUNTER = new GDAILYINDEXCOUNTER();
  resetDate: Date;
  currentDate: Date = new Date();
  indexDailyLimit: number;
  ffcUrl: FFCUrl;
  isIndexing: boolean = false;
  seoInfo: SeoInfo;
  domainFFC: string;
  type: string;

  constructor(
    public dialModalRef: MatDialogRef<SubmitIndexingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private indexingService: IndexingService,
    private settingService: SettingService,
    private hotToastService: HotToastService,
    private toast: NbToastrService,
  ) {
    this.settingService.getSettingByKeyAndGroup("G_DAILY_SUBMIT", "SEO")
    .pipe(takeUntil(this.destroy$)).subscribe(resp => {
      if (resp?.result?.value) {
        this.indexDailyLimit = parseInt(resp.result.value);
      }
    })
    
    this.settingService.getSettingByKeyAndGroup("G_DAILY_INDEX_COUNTER", "SEO")
    .pipe(takeUntil(this.destroy$)).subscribe(resp => {
      if (resp?.result?.value) {
        this.dailyIndexCounter = JSON.parse(resp.result.value)
        // reset = last date + 24h(86400000ms)
        this.resetDate = new Date(new Date(this.dailyIndexCounter.LastIndexDate).getTime() + 86400000);
      }
    })

    this.ffcUrl = data?.ffcUrl ?? data?.seoInfo?.ffcUrl;
    this.seoInfo = data?.seoInfo;
    this.type = data?.type ?? "PROFILE";

    this.settingService.getSettingByKeyAndGroup('FONT-FACING','DOMAIN').subscribe(resp=>{
      if(resp.result){
        this.domainFFC = resp.result.value;

        // always add the '/' at last for domain url
        if(!this.domainFFC.endsWith('/'))
          this.domainFFC = this.domainFFC.concat('/');
      }
    })
  }

  ngOnInit(): void {
  }
  
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async submitIndexing() {
    this.isIndexing = true;

    let ffcUrl: FFCUrl = this.ffcUrl;
    if (this.seoInfo) {
      ffcUrl = this.seoInfo?.ffcUrl
      if (!ffcUrl) {
        if (this.seoInfo?.friendlyUrl) {
          let url;
          if (this.type === "PROFILE") {
            url = `${this.domainFFC}act/${this.seoInfo.friendlyUrl}/`;
          }
          else {
            url = `${this.domainFFC}categories/${this.seoInfo.friendlyUrl}/`
          }

          const data = await this.indexingService.saveIndexingUrl({
            url: url,
            seoId: this.seoInfo.seoInfoId
          } as FFCUrl).toPromise();
          
          if (data && data.result) {
            ffcUrl = data.result;
          }
          else {
            this.toast.danger("Friendly url is null");
            return;
          }
        }
        else {
          this.toast.danger("Friendly url is null");
          return;
        }
      }
    }

    let page: Page = new Page();
    ffcUrl["friendlyUrl"] = ffcUrl.url.replace(`${this.domainFFC}act/`, '').replace(`${this.domainFFC}categories/`, '').replace('/', '');
    ffcUrl["urlType"] = this.type === "PROFILE" ? 'act' : 'categories';
    page["fFCUrlList"] = [ffcUrl];
  
    this.indexingService
    .requestBulkIndexing(page)
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: resp => {
        if(resp?.result) {
            this.hotToastService.success(`Indexing url ${ffcUrl.url} successfully!`)
        }
      },
      error: () => {
        this.isIndexing = false;
        this.dialModalRef.close();
      },
      complete: () => {
        this.isIndexing = false;
        this.dialModalRef.close();
      }
    });    
  }
}
