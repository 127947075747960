import { prop, required, minLength, email, compare, numeric, pattern } from '@rxweb/reactive-form-validators';
import { Observable } from 'rxjs';
import { CountryObject } from 'src/app/shared/models/country-object.model';
import { countries } from './contact-type';
import { AltusLocation } from '../location/location';
import { KeyPairsValue, Contact as ContactDetail } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { UserModel } from 'src/app/modules/admin/user-management/user-model';
import { AddEditProfileModel } from 'src/app/modules/admin/profile-management/profile-model';
import { SaleLeadsViewModel } from 'src/app/modules/admin/sale-lead-management/add-sale-leads.model';
export class Contact {
    @prop()
    contactId?: number | null;
    //tslint:disable-next-line:object-literal-key-quotes
    //@pattern({ expression: { phone: /^[(]?(\d|\+)\s?(?:[\d-.x\s()]*)$/ } })
    @prop()
    contactPhone: string;
    @prop()
    contactName: string;
    //@required()
    @email()
    contactEmail: string;
    @prop()
    contactLastName: string;
    @prop()
    relationship?: string | null;
    @prop()
    // @pattern({ expression: { phone: /^(1[ \-\+]{0,3}|\+1[ -\+]{0,3}|\+1|\+)?((\(\+?1-[2-9][0-9]{1,2}\))|(\(\+?[2-8][0-9][0-9]\))|(\(\+?[1-9][0-9]\))|(\(\+?[17]\))|(\([2-9][2-9]\))|([ \-\.]{0,3}[0-9]{2,4}))?([ \-\.][0-9])?([ \-\.]{0,3}[0-9]{2,4}){2,3}$/ } })
    secondaryPhone?: string | null;
    @prop()
    // @pattern({ expression: { phone: /^(1[ \-\+]{0,3}|\+1[ -\+]{0,3}|\+1|\+)?((\(\+?1-[2-9][0-9]{1,2}\))|(\(\+?[2-8][0-9][0-9]\))|(\(\+?[1-9][0-9]\))|(\(\+?[17]\))|(\([2-9][2-9]\))|([ \-\.]{0,3}[0-9]{2,4}))?([ \-\.][0-9])?([ \-\.]{0,3}[0-9]{2,4}){2,3}$/ } })
    cellPhone?: string | null;
    @prop()
    @email()
    secondaryEmail?: string | null;
    @prop()
    facebook?: string | null;
    @prop()
    skype?: string | null;
    @prop()
    whatsApp?: string | null;
    @prop()
    tiktok?: string | null;
    @prop()
    title?: string | null;
    @prop()
    primaryContact: boolean | null = false;
    @prop()
    deleted: boolean | null = false;
    @prop()
    locationId: number | null;
    // @required()
    @prop()
    city: string | null;
    @prop()
    // @required()
    country: string = countries[233].value;
    @prop()
    zipcode: string;
    @prop()
    address: string;
    @prop()
    address2: string;
    @prop()
    region: string;
    @prop()
    state: string;
    @prop()
    primaryLocation: boolean;
    @prop()
    nearestBigCity: string;
    @prop()
    willTravel: boolean | null = false;
    @prop()
    howFar: string;
    countryObservable: Observable<CountryObject[]>;
    @prop()
    ownerId?: string | null;
    @prop()
    @required()
    listContactTypeId: number[];
    // 2021-01-04 tienlm add start
    @prop()
    website: string | null;
    // 2021-01-04 tienlm add start
    @prop()
    tags: string | null;
    @prop()
    jobTitle: string | null;
    // 2022-09-12 tienlm add start
    @prop()
    saluation: string;
    @prop()
    accountName: string;
    @prop()
    department: string;
    @prop()
    birthDate: string;
    @prop()
    description: string;
    // 2022-09-12 tienlm add end
    objectsRelationship?: KeyPairsValue[] = [];
    @prop()
    dateCreated?: string | null;
    @prop()
    dateModified?: string | null;

    ownerUser: UserModel
    @prop()
    linkedIn: string;
    @prop()
    source: string;
    @prop()
    majorCity: string | null;
    @prop()
    // @required()
    majorCountry: string = countries[233].value;
    @prop()
    majorZipcode: string;
    @prop()
    majorState: string;

    @prop()
    primaryPhoneExt: string;
    @prop()
    secondaryPhoneExt: string;
    @prop()
    cellPhoneExt: string;
}

export class SimpleCovertContact {
    contactId: number;
    contact: Contact;
}

export class SimpleCovertStepModel {
    @prop()
    contactId: number;
    @required()
    entity: string;
    @prop()
    profile: AddEditProfileModel;
    @prop()
    lead: SaleLeadsViewModel;
}