import { UserModel } from 'src/app/modules/admin/user-management/user-model';
import { TaskPriority } from './../../../../shared/contances/task-priority';
import { Priority, TaskStatus } from './../task-board/task-board-lane/task-status.model';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuillConfiguration } from 'src/app/shared/components/stand-alone-component/rich-inline-edit/rich-inline-edit.component';
import { TaskType } from '../task-board/task-board-lane/task-status.model';
import { TaskBoardService } from '../task-board/task-board.service';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { ClickUpExtend, ResolutionCode, Task, TaskTemplate } from '../task-board/task-board-lane/task/task.model';
import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { DateAdapter, ErrorStateMatcher, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { T } from '@angular/cdk/keycodes';
import { Contact, KeyPairsValue, ProfileDetailModel } from '../../profile-management/profile-detail.model';
import { NbPopoverDirective, NbToastrService } from '@nebular/theme';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { TaskTemplateTarget } from 'src/app/shared/contances/task-template';
import { UserService } from '../../user-management/user.service';
import { ProfileService } from '../../profile-management/profile.service';
import { ContactService } from 'src/app/shared/services/contact.service';
import { SettingCompanyViewModel } from '../../setting-management/setting-company/setting-company';
import { Subject } from 'rxjs';
import { skip, take, takeUntil } from 'rxjs/operators';
import { BuyerModel } from 'src/app/shared/models/buyer.model';
import { DisableAfterClickDirective } from 'src/app/shared/directives/disable-after-click.directive';
import { Helper } from 'src/app/shared/utility/Helper';
import { DataFieldsManagementService } from 'src/app/shared/services/data-fields-management.service';
import { SaleLeadService } from '../../sale-lead-management/sale-lead.service';
import { ShadowProfileEnum } from 'src/app/shared/enums/shadow-profile.enum';
import { SaleAccountService } from '../../sale-account-management/sale-account.service';
import { OpportunityManagementService } from '../../opportunity-management/opportunity-management.service';
import { DISPLAY_INPUT_DATE, GENERAL_DATE_TIME_FORMATS } from 'src/app/shared/components/stand-alone-component/primas-custom-date-time-picker/date-format.model';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import { environment } from 'src/environments/environment';
import { ApplicationUserViewModel } from '../../enquiry-basket-management/add-enquiry/assign-agent/assign-agent.model';
import { SettingService } from 'src/app/shared/services/setting.service';

@Component({
  selector: 'app-add-edit-task',
  templateUrl: './add-edit-task.component.html',
  styleUrls: ['./add-edit-task.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE], },
    { provide: MAT_DATE_FORMATS, useValue: DISPLAY_INPUT_DATE }]
})
export class AddEditTaskComponent implements OnInit, OnDestroy, AfterViewInit {
  public reenableButton = new EventEmitter<boolean>();
  @Input() action: TblActionType = null;
  @Input() model = null;
  @Input() profileSelected: ProfileDetailModel = null;
  @Input() buyerSelected: BuyerModel = null;
  @Input() tooltipProp: KeyPairsValue[];
  @Input() relationshipType: string = 'none';
  @Input() contactSelected: Contact = null;
  @Input() isWindow: boolean = false;

  constructor(
    public dialModalRef: MatDialogRef<AddEditTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskBoardService,
    private cdref: ChangeDetectorRef,
    private frmBuilder: RxFormBuilder,
    private dialog: MatDialog,
    private toast: NbToastrService,
    private userService: UserService,
    private profileService: ProfileService,
    private contactService: ContactService,
    private dataFieldsService: DataFieldsManagementService,
    private saleLeadService: SaleLeadService,
    private saleAccountService: SaleAccountService,
    private opportunityService: OpportunityManagementService,
    private settingService: SettingService
  ) {
    this.position = data.position || this.position;
    this.type = data.type || this.type;
    this.action = this.action || data.action;
    this.task = this.model || (data.model ?? new Task());
    this.userChosen = this.model?.user || (data.model?.user ?? new UserModel());
    this.actionTypeDefault = TblActionType.Edit;
    //this.userModel.action = data.action;
    this.profileSelected = this.profileSelected || (data.profile ?? null);
    this.buyerSelected = this.buyerSelected || (data.buyer ?? null);
    //2022-02-09 tienlm add start
    this.contactSelected = this.contactSelected || (data.contact ?? null);
    this.tooltipProp = this.tooltipProp || (data.tooltipProp ?? []);
    this.relationshipType = this.relationshipType || data.relationshipType || this.task.relationshipType || this.relationshipType;
    this.modeForm = data.mode || this.modeForm;
    this.totalBulkAdd = data.totalAdd || this.totalBulkAdd;
    if (this.task && this.task.extendDatasource) {
      const clickUpExtend: ClickUpExtend = JSON.parse(this.task.extendDatasource, Helper.toCamelCase);
      this.task.clickUpExtend = clickUpExtend ?? new ClickUpExtend();
    }

    if (this.tooltipProp && this.tooltipProp.length == 0)
      this.dataFieldsService.getDisplayDataByScreen("TaskManagement").subscribe(resp => {
        this.tooltipProp = [
          { key: 'displayName', value: 'Display Name' },
          { key: 'realName', value: 'Real Name' },
        ]
        if (resp.result) this.tooltipProp = [...Helper.formatDisplayColumn(this.tooltipProp, resp.result, 'key', 'value')];
      });

    this.configRelationshipTask();

    if (this.contactSelected !== null) {
      // has data:
      this.relationshipType = this.task.relationshipType;
    }
    if (this.buyerSelected) {
      this.relationshipType = this.task.relationshipType;
    }
    if (this.action == TblActionType.Add) {
      if (this.buyerSelected) {
        this.relationshipType = 'buyer';
      }
      this.userService.getUserCurrentLogin().subscribe(res => {
        if (res.result) {
          this.getAssignee(res.result);
        }
      });
    }

  }

  type = 'Normal';
  isMinimize = false;
  position = { right: '0' };
  environment = environment;
  relationshipData = [
    { value: 'none', viewValue: 'None' },
    { value: 'profile', viewValue: 'Profile' },
    // { value: 'contact', viewValue: 'Contact' },
    //{ value: 'agent', viewValue: 'Agent' },
    //{ value: 'buyer', viewValue: 'Buyer' },
    { value: 'saleLeads', viewValue: this.environment.titleLead },
    { value: 'saleAccount', viewValue: this.environment.titleAccount },
    { value: 'opportunity', viewValue: this.environment.titleOpportunity }
  ];
  // buyerSelected: BuyerModel;
  actionTypeDefault: TblActionType.Edit;
  //action: TblActionType;
  task: Task = new Task();
  userChosen: UserModel;
  frmTask: FormGroup;
  isLoading = false;
  taskType: TaskType[];
  taskStatus: TaskStatus[];
  taskPriority: Priority[];
  taskPrioritySelected: Priority;
  priorityImg: string;
  taskTypeSelected: TaskType;
  // profileSelected: ProfileDetailModel;
  // contactSelected: Contact;
  matcher = new MyErrorStateMatcher();
  // relationshipType: string = 'none';
  editorOptions = QuillConfiguration
  displayImage = SettingCompanyViewModel.golbalDefaultAvatar;
  defaultAvatar = SettingCompanyViewModel.golbalDefaultAvatar;
  // 2021-12-31 tienlm add start
  resolutionCodes: ResolutionCode[] = [];
  originalResolutionCode: ResolutionCode[] = [];
  taskTemplateLst: TaskTemplate[] = [];
  // 2021-12-31 tienlm add end
  //2022-02-08 tienlm add start
  isAutomateTask = false;
  selectedTemplate: TaskTemplate;
  targetType = '';
  // 2022-03-10 tienlm add start
  allowChangeDueDate = true;
  // 2022-03-10 tienlm add end
  //2022-02-08 tienlm add end
  // tooltipProp: KeyPairsValue[] = [];
  findItemByKey = Helper.findItemByKey;
  apiGetSearchRelationship: (data) => void = null;
  funcMapTitleSearch: (data: ProfileDetailModel[]) => ProfileDetailModel[] = null;

  placeholderSearch: string = null;
  isChange: boolean = false;
  isLoadingDuplicate: boolean = false;
  modeForm: ModeAddTask = ModeAddTask.Basic;
  modeAddTask = ModeAddTask;
  totalBulkAdd: number = 0;
  taskTypesCallReminder: any[];

  private destroy$: Subject<void> = new Subject<void>();

  clearAssignee() {
    this.task.assigneeTo = null;
    this.task.userName = null;
    this.userChosen = null;
  }
  getAssignee(data: UserModel) {
    this.task.assigneeTo = data.id;
    this.task.userName = data.userName;
    this.userChosen = data;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    // Load if open by window
    this.configRelationshipTask();
    this.settingService.getSettingByKeyAndGroup("CALLABLE_TASK_TYPE_ID_IN_CAL_SYSTEM", "TASK").subscribe(resp => {
      if (resp?.result?.value) this.taskTypesCallReminder = resp?.result?.value?.split(',').map(x => ({ id: +x })) || [];
    });

    this.taskService.selfGetListResolutionCode().pipe(takeUntil(this.destroy$)).subscribe(resp => {
      this.resolutionCodes = JSON.parse(JSON.stringify(resp));
      this.originalResolutionCode = JSON.parse(JSON.stringify(resp));
      // filter task
      this.filterResolutionCodeByTaskType();
    });
    //2022-08-02 tienlm add start
    // this.taskService.getTaskTemplate().subscribe(res => {
    //   if (res.result) {
    //     this.taskTemplateLst = res.result;
    //     // check task type when init:
    //     if (this.task.taskTypeId) {
    //       const taskTemplate = this.taskTemplateLst.filter(x => x.targetType == TaskTemplateTarget.taskType && parseInt(x.targetId) == this.task.taskTypeId)
    //       if (taskTemplate.length > 0) {
    //         this.isAutomateTask = true;
    //         this.selectedTemplate = taskTemplate[0];
    //         this.targetType = 'Task Type';
    //       }
    //     }
    //   }
    // })
    //2022-08-02 tienlm add end
    this.taskService.taskStatusList().pipe(takeUntil(this.destroy$)).subscribe(e => {
      this.taskStatus = e;
    });
    this.taskService.taskTypeList().pipe(takeUntil(this.destroy$)).subscribe(e => this.taskType = e);
    this.taskService.taskPriorityList().pipe(takeUntil(this.destroy$)).subscribe(e => {
      this.taskPriority = e;

      this.taskPriority.sort((first, second) => first.priorityOrder - second.priorityOrder);
    });
    this.frmTask = this.frmBuilder.formGroup(Task, this.task);
    this.dialModalRef.updatePosition(this.position);
    if (this.task) {
      this.taskPrioritySelected = { ...this.taskPriority.find(x => x.priorityId === this.task.taskPriority) };
      this.priorityImg = `assets/images/priority/${this.taskPrioritySelected.priorityId.toString().toLowerCase()}.svg`;
      this.taskTypeSelected = { ...this.taskType.find(x => x.taskTypeId === this.task.taskTypeId) }
      // 2022-03-10 tienlm add start
      if (this.task.resolutionCodeId && this.task.resolutionCodeId !== -1) {
        this.allowChangeDueDate = false;
      } else {
        this.allowChangeDueDate = true;
      }
      // 2022-03-10 tienlm add end

    }
    if (this.profileSelected) {
      const media = this.profileSelected?.medias?.find(m => m.mediaId === this.profileSelected.primaryImage);
      if (media !== undefined) {
        this.displayImage = media.media1;
      } else {
        this.displayImage = this.defaultAvatar;
      }

    }

    // console.log(this.relationshipType)

  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngAfterViewInit(): void {
    if (this.frmTask)
      this.frmTask.valueChanges.pipe(skip(2), take(1))
        .subscribe(resp => this.isChange = true);
  }

  closeDialog() {
    if (this.isChange) {
      this.isChange = false;
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          message: "Do you wish to close this popup? You will lose your unsaved data."
        }
      });

      dialogRef.afterClosed().subscribe(response => {
        if (response) this.dialModalRef.close();
        else this.isChange = true;
      });
      return;
    }

    this.dialModalRef.close();
  }
  changeTaskType(data: MatSelect) {
    this.taskTypeSelected = { ...this.taskType.find(x => x.taskTypeId === data.value) };
    this.filterResolutionCodeByTaskType();
    // automated task:
    if (this.taskTypeSelected) {
      const taskTemplate = this.taskTemplateLst.filter(x => x.targetType == TaskTemplateTarget.taskType && parseInt(x.targetId) == this.taskTypeSelected.taskTypeId)
      if (taskTemplate.length > 0) {
        this.isAutomateTask = true;
        this.selectedTemplate = taskTemplate[0];
        this.targetType = 'Task Type';

      } else {
        this.isAutomateTask = false;
      }
    }
  }
  changeTaskTaskPriority(data: MatSelect) {
    this.taskPrioritySelected = { ...this.taskPriority.find(x => x.priorityId === data.value) };
    this.priorityImg = `assets/images/priority/${this.taskPrioritySelected.priorityId.toString()}.svg`;

  }

  getContactSelected(data: Contact) {
    this.frmTask.get('contactReminder').reset();
    this.profileSelected = null;
    this.contactSelected = data;
    this.buyerSelected = null;
  }

  getProfileSelected(data: ProfileDetailModel) {
    this.frmTask.get('contactReminder').reset();
    this.contactSelected = null;
    this.profileSelected = data;
    this.buyerSelected = null;
  }

  deletedRelationship() {
    this.frmTask.get('contactReminder').reset();
    this.profileSelected = null;
    this.buyerSelected = null;
    this.contactSelected = null;
  }

  saveData() {
    this.isLoading = true;
    this.taskType.find(x => x.taskTypeId === this.frmTask.value.taskTypeId).colorCode
    if (this.frmTask.valid) {
      const model: Task = Object.assign({}, this.frmTask.value);

      // // filter task call reminder;
      let isCaller = this.taskTypesCallReminder.findIndex(x => x?.id == model?.taskTypeId);
      if (isCaller == -1) model.contactReminder = null;

      model.assigneeTo = this.task.assigneeTo;
      if (this.relationshipType == 'none') {
        this.relationshipType = null;
        this.deletedRelationship();
      }

      model.relationshipType = this.relationshipType;
      if (this.relationshipType === 'profile' ||
        this.relationshipType === 'saleLeads' ||
        this.relationshipType === 'saleAccount' ||
        this.relationshipType === 'opportunity'
      ) {
        model.relationshipId = this.profileSelected?.profileId;
        model.relationshipType = 'profile';
      }
      else if (this.relationshipType == 'buyer') {
        model.relationshipId = this.buyerSelected.buyerId;
      }
      else {
        model.relationshipId = this.contactSelected?.contactId.toString();
      }

      if (this.modeForm == ModeAddTask.BulkAdd) {
        this.dialModalRef.close(model);
        return;
      }

      this.taskService.saveTask(model).pipe(take(1)).subscribe(resp => {
        if (resp.result && resp.result.success) {
          this.toast.success('Save successfully', 'Success');
          this.dialModalRef.close(resp.result);
        }
        this.reenableButton.emit();
        this.isLoading = false;
      }, err => {
        this.isLoading = false;

      });
    } else {
      this.isLoading = false;
    }
  }
  deleteTask() {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: 'Do you wish to delete this task?'
      }
    });
    dialogRef.afterClosed().subscribe(resp => {
      if (resp) {
        this.taskService.deleteTask(this.task.taskId).pipe(takeUntil(this.destroy$)).subscribe(result => {
          if (result.result) {
            this.toast.success(`Delete ${this.task.taskName} successfully`, 'Success');
            window.sessionStorage.setItem('modifyTask', 'true')
            this.dialModalRef.close(true);
          } else {
            this.toast.danger(`Delete ${this.task.taskName} failed, please try again...`, 'Failed');
          }
        });
      }
    });
  }
  filterResolutionCodeByTaskType() {
    if (!this.taskTypeSelected) {
      return;
    }
    // 2021-01-05 tienlm add start
    this.resolutionCodes = JSON.parse(JSON.stringify(this.originalResolutionCode));
    // tslint:disable-next-line:max-line-length
    this.resolutionCodes = this.resolutionCodes.filter(x => x.taskTypeId === null || (x.taskTypeId && x.taskTypeId === this.taskTypeSelected.taskTypeId));
    // 2021-01-05 tienlm add end
  }
  resolutionCodeChanged(change: MatSelectChange) {
    const taskTemplate = this.taskTemplateLst.filter(x => x.targetType == TaskTemplateTarget.resolutionCode && parseInt(x.targetId) == change.value)
    if (taskTemplate.length > 0) {
      this.isAutomateTask = true;
      this.selectedTemplate = taskTemplate[0];
      this.targetType = 'Resolution Code';
    }
    //check user has changed resolutionCodeId:
    if (change.value && change.value != -1) {
      this.allowChangeDueDate = false;
    } else {
      this.allowChangeDueDate = true;
    }
  }
  // 2022-02-09 tienlm add start
  applyAutomaticTask() {
    const confirmDialog = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: 'Once you confirm to use automatic template, you cannot reverse current data. Do you wish to proceed?'
      }
    });
    confirmDialog.afterClosed().subscribe(ans => {
      if (ans) {
        var taskFromTemplate = JSON.parse(this.selectedTemplate.template) as Task;
        switch (this.selectedTemplate.targetType) {
          // HANDLE FOR TASK TYPE
          case TaskTemplateTarget.taskType:
            {
              // replace
              this.replaceTask(taskFromTemplate);
            }
            break;
          case TaskTemplateTarget.resolutionCode:
            {
              // Resolution code:
              switch (this.selectedTemplate.action) {
                case 'REPLACE':
                  {
                    this.replaceTask(taskFromTemplate);
                  }
                  break;
                case 'APPENDED':
                  {
                    this.appendTask(taskFromTemplate);
                  }
                  break;
                case 'NEW':
                  {
                    this.createNewTaskByTemplate(taskFromTemplate);
                  }
                  break;
                default:
                  break;
              }
            }
          default:
            break;
        }
        this.isAutomateTask = false;
        this.selectedTemplate = null;
      }
    });
  }
  // function to replace all property from template to current task.
  replaceTask(taskFromTemplate: Task) {
    for (var prop in taskFromTemplate) {

      if (Object.prototype.hasOwnProperty.call(taskFromTemplate, prop)) {
        if (prop === 'taskId') {
          continue;
        }
        // do stuff
        if (taskFromTemplate[prop] !== undefined && taskFromTemplate[prop] != null) {
          if (typeof taskFromTemplate[prop] == 'string' && taskFromTemplate[prop] != '') {
            this.frmTask.controls[prop]?.setValue(taskFromTemplate[prop])
          } else {
            this.frmTask.controls[prop]?.setValue(taskFromTemplate[prop])
          }

          //exception:

          // taskPriority
          if (prop == 'taskPriority') {
            this.taskPrioritySelected = { ...this.taskPriority.find(x => x.priorityId === taskFromTemplate[prop]) };
            this.priorityImg = `assets/images/priority/${this.taskPrioritySelected.priorityId.toString().toLowerCase()}.svg`;
          }

          // assigneeTo
          if (prop == 'assigneeTo' && taskFromTemplate[prop] != null && taskFromTemplate[prop] !== '') {
            this.userService.getUserById(taskFromTemplate.assigneeTo).pipe(takeUntil(this.destroy$)).subscribe(res => {
              if (res.result) {
                this.getAssignee(res.result);
              }
            })
          }
          // relationship
          if (prop == 'relationshipType') {
            this.relationshipType = taskFromTemplate.relationshipType;
            switch (taskFromTemplate[prop]) {
              // Profile
              case 'profile':
                {
                  if (taskFromTemplate.relationshipId && taskFromTemplate.relationshipId != '') {
                    this.profileService.getBasicInformationOfProfileByIdAsync(taskFromTemplate.relationshipId).pipe(takeUntil(this.destroy$)).subscribe(res => {

                      if (res.result) {
                        this.getProfileSelected(res.result);
                      }
                    })
                  }
                }
                break;
              // Contact,Agent,Buyer
              default:
                {
                  if (taskFromTemplate.relationshipId && taskFromTemplate.relationshipId != '') {
                    //get contact:
                    this.contactService.getContactById(parseInt(taskFromTemplate.relationshipId)).pipe(takeUntil(this.destroy$)).subscribe(res => {
                      if (res.result) {
                        this.getContactSelected(res.result);
                      }
                    })
                  }
                }
                break;
            }
          }
        }
      }
    }
  }
  // function to add all property from template to current task when it empty.
  appendTask(taskFromTemplate: Task) {
    for (var prop in taskFromTemplate) {
      if (Object.prototype.hasOwnProperty.call(taskFromTemplate, prop)) {
        if (prop === 'taskId') {
          continue;
        }
        // do stuff
        if (taskFromTemplate[prop] !== undefined && taskFromTemplate[prop] != null) {
          if (typeof taskFromTemplate[prop] == 'string' && taskFromTemplate[prop] != '') {
            if (!this.frmTask.controls[prop]?.dirty) {
              this.frmTask.controls[prop]?.setValue(taskFromTemplate[prop])
            }
          } else {
            if (!this.frmTask.controls[prop]?.dirty) {
              this.frmTask.controls[prop]?.setValue(taskFromTemplate[prop])
            }
          }

          //exception:

          // taskPriority
          if (prop == 'taskPriority') {
            if (!this.taskPrioritySelected) {
              this.taskPrioritySelected = { ...this.taskPriority.find(x => x.priorityId === taskFromTemplate[prop]) };
              this.priorityImg = `assets/images/priority/${this.taskPrioritySelected.priorityId.toString().toLowerCase()}.svg`;
            }
          }

          // assigneeTo
          if (prop == 'assigneeTo' && taskFromTemplate[prop] != null && taskFromTemplate[prop] !== '') {
            if (!this.userChosen) {
              this.userService.getUserById(taskFromTemplate.assigneeTo).pipe(takeUntil(this.destroy$)).subscribe(res => {
                if (res.result) {
                  this.getAssignee(res.result);
                }
              })
            }
          }
          // relationship
          if (prop == 'relationshipType') {
            this.relationshipType = taskFromTemplate.relationshipType;
            switch (taskFromTemplate[prop]) {
              // Profile
              case 'profile':
                {
                  if (taskFromTemplate.relationshipId && taskFromTemplate.relationshipId != '') {
                    if (!this.profileSelected) {
                      this.profileService.getBasicInformationOfProfileByIdAsync(taskFromTemplate.relationshipId).pipe(takeUntil(this.destroy$)).subscribe(res => {
                        if (res.result) {
                          this.getProfileSelected(res.result);
                        }
                      })
                    }
                  }
                }
                break;
              // Contact,Agent,Buyer
              default:
                {
                  if (taskFromTemplate.relationshipId && taskFromTemplate.relationshipId != '') {
                    //get contact:
                    this.contactService.getContactById(parseInt(taskFromTemplate.relationshipId)).pipe(takeUntil(this.destroy$)).subscribe(res => {
                      if (res.result) {
                        this.getContactSelected(res.result);
                      }
                    })
                  }
                }
                break;
            }
          }
        }
      }
    }
  }
  // function to create new task: at new dialog
  createNewTaskByTemplate(taskFromTemplate: Task) {
    this.task.taskId = 0;
    // get relationship:
    let relationshipEntity = null;
    let buyerEntity = null;

    if (taskFromTemplate.relationshipId && taskFromTemplate.relationshipId != '') {
      switch (taskFromTemplate.relationshipType) {
        case 'profile':
          {
            if (taskFromTemplate.relationshipId && taskFromTemplate.relationshipId != '') {
              if (!this.profileSelected) {
                this.profileService.getBasicInformationOfProfileByIdAsync(taskFromTemplate.relationshipId).pipe(takeUntil(this.destroy$)).subscribe(res => {
                  if (res.result) {
                    // this.getProfileSelected(res.result);
                    relationshipEntity = res.result;
                  }
                  const dialogRef = this.dialog.open(AddEditTaskComponent, {
                    disableClose: true,
                    height: '100vh',
                    width: '600px',
                    panelClass: 'dialog-detail',
                    autoFocus: false,
                    data: {
                      action: TblActionType.Add,
                      model: taskFromTemplate,
                      profile: relationshipEntity,
                      buyer: buyerEntity
                    }
                  });
                })
              }
            }
          }
          break;
        // Contact,Agent,Buyer
        default:
          {
            if (taskFromTemplate.relationshipId && taskFromTemplate.relationshipId != '') {
              //get contact:
              this.contactService.getContactById(parseInt(taskFromTemplate.relationshipId)).pipe(takeUntil(this.destroy$)).subscribe(res => {
                if (res.result) {
                  // this.getContactSelected(res.result);
                  buyerEntity = res.result;

                }
                const dialogRef = this.dialog.open(AddEditTaskComponent, {
                  disableClose: true,
                  height: '100vh',
                  width: '600px',
                  panelClass: 'dialog-detail',
                  autoFocus: false,
                  data: {
                    action: TblActionType.Add,
                    model: taskFromTemplate,
                    profile: relationshipEntity,
                    buyer: buyerEntity
                  }
                });
              })
            }
          }
          break;
      }
    } else {
      // check relation
      const dialogRef = this.dialog.open(AddEditTaskComponent, {
        disableClose: true,
        height: '100vh',
        width: '600px',
        panelClass: 'dialog-detail',
        autoFocus: false,
        data: {
          action: TblActionType.Add,
          model: taskFromTemplate,
          profile: relationshipEntity,
          buyer: buyerEntity
        }
      });
    }
  }
  turnOffAutomaticTask() {
    this.isAutomateTask = false;
    this.selectedTemplate = null;
  }
  // 2022-02-09 tienlm add end
  getBuyerSelected(data: BuyerModel) {
    this.frmTask.get('contactReminder').reset();
    this.buyerSelected = data;
    this.profileSelected = null;
    this.contactSelected = null;
  }
  log(data) {
    console.log(data);
  }

  selectionChangeRelationship() {
    if (this.relationshipType == 'none') {
      this.deletedRelationship();
      return;
    }

    this.apiGetSearchRelationship = null;
    this.funcMapTitleSearch = null;
    this.placeholderSearch = null;
    this.configApiSearchRelationship();
  }

  configRelationshipTask() {
    if (this.profileSelected !== null) {
      switch (this.profileSelected.typeName) {
        case ShadowProfileEnum[ShadowProfileEnum.LEADS]:
        case ShadowProfileEnum[ShadowProfileEnum.LEADS_VENUE]:
          this.relationshipType = 'saleLeads';
          break;
        case ShadowProfileEnum[ShadowProfileEnum.SALEACCOUNT]:
          this.relationshipType = 'saleAccount';
          break;
        case ShadowProfileEnum[ShadowProfileEnum.OPPORTUNITY]:
          this.relationshipType = 'opportunity';
          break;
        default:
          this.relationshipType = 'profile';
          break;
      }

      this.configApiSearchRelationship();
    }
  }

  configApiSearchRelationship() {
    switch (this.relationshipType) {
      case 'saleLeads':
        this.apiGetSearchRelationship = (data) => this.saleLeadService.searchSaleLead(data);
        this.placeholderSearch = `Search ${environment.titleLead.toLowerCase()}`;
        break;
      case 'saleAccount':
        this.apiGetSearchRelationship = (data) => this.saleAccountService.searchSaleAccount(data);
        this.placeholderSearch = `Search ${environment.titleAccount.toLowerCase()}`;
        break;
      case 'opportunity':
        this.apiGetSearchRelationship = (data) => this.opportunityService.searchOpportunity(data);
        this.funcMapTitleSearch = (data: ProfileDetailModel[] = []) => {
          var result = data;
          if (result && result.length > 0) {
            result = result.map(x => Object.assign(x, {
              displayName: x.saleOpportunity.opportunityName,
              realName: x.saleOpportunity.accountName
            }));
          }
          return result;
        }
        this.placeholderSearch = `Search ${environment.titleOpportunity.toLowerCase()}`;
        break;
    }
  }

  async duplicateTask() {
    this.isLoadingDuplicate = true;
    try {
      const result = await this.taskService.duplicateTask(this.task.taskId).toPromise();
      if (result && result.result) {
        window.sessionStorage.setItem('modifyTask', 'true');
        this.dialModalRef.close(true);
        this.toast.success("Duplicate task successfully", "Success");
        const dialog = this.dialog.open(ConfirmModalComponent, {
          disableClose: true,
          data: {
            message: `<b>Task-${this.task.taskId}</b> was duplicated to <b>Task-${result.result.taskId}</b> successfully. Do you wish to open <b>Task-${result.result.taskId}</b>?`
          }
        });
        var afterCloseDialog = await dialog.afterClosed().toPromise();
        if (afterCloseDialog) {
          this.dialModalRef.close();
          //this.dialog.closeAll();

          let duplicatedTaskOption = {
            disableClose: true,
            height: (this.type == 'Normal') ? '100vh' : '65vh',
            width: '600px',
            panelClass: 'dialog-detail',
            autoFocus: false,
            hasBackdrop: (this.type == 'Normal') ? true : false,
            data: (this.type == 'Normal') ? {
              action: TblActionType.Edit,
              profile: this.profileSelected,
              model: { ...result.result },
            } : {
              type: 'customType',
              action: TblActionType.Edit,
              model: { ...result.result },
              profile: this.profileSelected,
              position: { left: '0', bottom: '0' }
            }
          }

          const taskDialog = this.dialog.open(AddEditTaskComponent, duplicatedTaskOption)
        }
      }
    }
    catch (e) {
      console.log(e);
    }
    this.isLoadingDuplicate = false;

  }
  dateChanged(data: { value: Moment, isInitial: boolean }, prop: string) {
    if (data.isInitial) {
      this.frmTask.controls[prop].setValue(data.value);
    }
  }
  openClickUp() {
    if (this.task.clickUpExtend) {
      let url = this.task.clickUpExtend.clickUpURL || `https://app.clickup.com/t/${this.task.clickUpExtend.clickUpId}`;
      window.open(url, '_blank');
    }
  }

  minimizeDialog() {
    this.isMinimize = !this.isMinimize;
    var overlay = window.document.querySelector<any>(`.dialog-detail`);
    var dialogContent = window.document.querySelector<any>(`.custom-dialog-height`);
    if (this.isMinimize) {
      dialogContent.style.display = "none"
      overlay.style.height = '';
      overlay.style.width = '300px';
      overlay.style.marginLeft = "2rem";
    }
    else {
      dialogContent.style.display = "block"
      overlay.style.height = '65vh';
      overlay.style.width = '600px';
      overlay.style.marginLeft = "0px";
    }
  }
}
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return (control && control.invalid);
  }
}

export enum ModeAddTask {
  Basic,
  BulkAdd,
}
