import { AfterViewInit, ComponentFactoryResolver, ComponentRef, Directive, Input, OnDestroy, Self, TemplateRef, ViewContainerRef } from '@angular/core';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { Subject, combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CustomerService } from 'src/app/modules/admin/customer/customer.service';
import { defaultPerk, perksProp } from 'src/app/modules/admin/customer/default-perk';
import { SettingService } from '../services/setting.service';
import { AskToUpgradeComponent } from '../components/ask-to-upgrade/ask-to-upgrade.component';

@Directive({
  selector: '[cusPerk]'
})
export class CustomerPerkDirective implements OnDestroy {
  @Input() set cusPerk([perk, venueId]: [string, string]) {
    if (perk) this.configurationPerk(perk, venueId);
    else this.viewContainer.createEmbeddedView(this.templateRef);
  }

  userLogin;
  isCustomer: boolean = false;
  private upgradeComponent: ComponentRef<AskToUpgradeComponent>;
  private destroy$ = new Subject<void>();

  constructor(
    private customerService: CustomerService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authService: NbAuthService,
    private settingService: SettingService,
    private resolver: ComponentFactoryResolver,
  ) { }

  async configurationPerk(cusPerk: string, venueId: string) {
    try {
      //If don't existed setting role customer => Request API and validate role user.
      if (!this.customerService?.customerRole) {
        const resp = await this.settingService.getSettingByKeyAndGroup("CUSTOMER_ROLE_NAME", "CUSTOMER").toPromise();
        this.customerService.customerRole = resp?.result?.value;
      }

      this.authService.onTokenChange().pipe(take(1), takeUntil(this.destroy$))
        .subscribe((token: NbAuthJWTToken) => {
          if (token.isValid()) {
            this.userLogin = token.getPayload();
            let role = this.userLogin["role"] || "";
            if (Array.isArray(role))
              this.isCustomer = role.map(x => x.trim().toLowerCase()).includes(this.customerService?.customerRole);
            else this.isCustomer = (this.customerService?.customerRole.toLowerCase() == role.toLowerCase());

            //If don't customer => create element.
            if (!this.isCustomer) this.viewContainer.createEmbeddedView(this.templateRef);

            //If customer => validate perks.
            if (this.isCustomer) {
              let subPerk = combineLatest(this.customerService.perks(), this.customerService.perkValues());

              this.customerService.validatePerk().pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                  subPerk.pipe(take(1), takeUntil(this.destroy$))
                    .subscribe({
                      next: async ([perks, perkValues]) => {
                        this.viewContainer.clear();
                        let isHasPerk: boolean = false;

                        //Perks on client side is null => request to server side.
                        if (!perks || this.isObjectEmpty(perks))
                          perks = (await this.customerService.customerPerks().toPromise())?.result;

                        //Perks customer is null => use default perks.
                        if (!perks || this.isObjectEmpty(perks))
                          perks = defaultPerk;

                        let perk = Object.entries(perks || {})?.find(x => x?.[0]?.toLowerCase() == cusPerk?.toLowerCase());
                        //Perk Values for Customer on client side is null => request to server side.
                        let perkVerify = Object.entries(perkValues || {})?.find(x => x?.[0]?.toLowerCase() == cusPerk?.toLowerCase());

                        if (!perkVerify && !(typeof perk?.[1] == 'boolean')) {
                          let readPerkValues = (await this.customerService.customerPerkValues(venueId, [cusPerk]).toPromise())?.result || {};
                          perkVerify = Object.entries(readPerkValues)?.find(x => x?.[0]?.toLowerCase() == cusPerk?.toLowerCase());
                        }

                        // 0: key, 1: value.
                        if (perk) {
                          let planValue = perk?.[1];
                          let value = perkVerify?.[1];
                          switch (typeof planValue) {
                            case 'number':
                              if (value >= 0 && value < planValue)
                                isHasPerk = true;
                              break;
                            case 'boolean':
                              if (value == true || planValue == true)
                                isHasPerk = true;
                              break;
                          }
                        }

                        if (isHasPerk)
                          this.viewContainer.createEmbeddedView(this.templateRef);
                        else {
                          const componentRef = this.getComponentRef();
                          let upgradeTemplateRef: TemplateRef<any>;
                          setTimeout(() => {
                            componentRef.instance.perk = cusPerk;
                            componentRef.instance.planValue = perk;
                            componentRef.instance.valueVerify = perkVerify;

                            switch (cusPerk) {
                              case perksProp.numberOfVenue:
                              case perksProp.numberOfPricing:
                              case perksProp.numberOfEventSpace:
                              case perksProp.numberOfMedia:
                                upgradeTemplateRef = componentRef.instance.addBtn;
                                break;
                              case perksProp.seoAudit:
                                upgradeTemplateRef = componentRef.instance.seoAuditBtn;
                                break;
                              case perksProp.seoReadability:
                                upgradeTemplateRef = componentRef.instance.readAbilityBtn;
                                break;
                              case perksProp.venueTheme:
                                upgradeTemplateRef = componentRef.instance.themeBtn;
                                break;
                              case perksProp.requestToPublish:
                                upgradeTemplateRef = componentRef.instance.requestToPublishBtn;
                                break;
                            }

                            this.viewContainer.createEmbeddedView(upgradeTemplateRef);
                          }, 0)
                        };
                      },
                      complete: () => { }
                    })
                })
            }
          }
        });
    }
    catch (ex) {
      console.error(ex);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getComponentRef() {
    if (!this.upgradeComponent) {
      const factory = this.resolver.resolveComponentFactory(AskToUpgradeComponent);
      this.upgradeComponent = this.viewContainer.createComponent(factory);
    }

    return this.upgradeComponent;
  }

  isObjectEmpty(objectName) {
    return Object.keys(objectName).length === 0
  }
}
