import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NbChatComponent, NbComponentOrCustomStatus, NbInputModule } from '@nebular/theme';
import { fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, takeUntil, throttleTime } from 'rxjs/operators';
import { Contact } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { AutomateDatastateService } from 'src/app/modules/admin/profile-management/profile-detail/automate-datastate-log/automate-datastate.service';
import { ProfileService } from 'src/app/modules/admin/profile-management/profile.service';
import { UserModel } from 'src/app/modules/admin/user-management/user-model';
import { PrimasFilterType } from 'src/app/shared/enums/primas-value-type.enum';
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { PhoneFormatPipe } from 'src/app/shared/pipes/phone-format.pipe';
import { SignalrDynamicService } from 'src/app/shared/services/signalr-dynamic.service';
import { SmsManagementService } from 'src/app/shared/services/sms-management.service';
import { Helper } from 'src/app/shared/utility/Helper';
import { environment } from 'src/environments/environment';
import { FilterConfig } from '../../dropdown-filter/filter-config';
import { MatFormField } from '@angular/material/form-field';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-primas-send-sms',
  templateUrl: './primas-send-sms.component.html',
  styleUrls: ['./primas-send-sms.component.scss']
})
export class PrimasSendSMSComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() id: string;
  @Input() referenceType: string;
  @Input() pageData: PagedData<SMSHistoryUIModel> = new PagedData<SMSHistoryUIModel>();
  @Input() outboundPhone: string;

  scrollable: ElementRef;
  chatComponent: NbChatComponent;
  @ViewChild('chatComponent', { static: false }) set contentChat(content: NbChatComponent) {
    if (content) {
      this.chatComponent = content;
      this.scrollable = content.scrollable;
    }
  }

  searchOptional: Observable<any>;
  @ViewChild('searchOptional', { static: false }) set contentSearch(content: ElementRef) {
    if (!this.searchOptional && content) {
      this.searchOptional = fromEvent(content.nativeElement, 'keyup').pipe(
        takeUntil(this.destroy$),
        debounceTime(1000),
        distinctUntilChanged(),
      );

      this.searchOptional.subscribe(() => this.search(content.nativeElement.value || ''))
    }
  }

  user: UserModel;
  smsHistory: SMSChatUIModel[] = [];
  isTabMode = Helper.checkTabMode();
  environment = environment;
  isLoading: boolean = false;
  readSMS: boolean = true;
  scrollHeight: number;
  phonesContact: FilterConfig;
  message: string;
  isOptional: boolean = false;
  optionalForm: ChatFormModel = new ChatFormModel();
  fitHeightBody: string = '41px';
  tabOptional: EnumOptionalForm = EnumOptionalForm.Templates;
  searchOptionalPlaceholder: string = "Search templates";

  formatFunc: (x: SMSHistoryUIModel) => SMSChatUIModel = (x) => Object.assign({
    id: x.smsHistoryId, type: 'text-custom', text: x.body,
    reply: x.direction.toLowerCase() == "inbound" ? true : false,
    userName: x.direction.toLowerCase() == "inbound" ? x.userName : this.phonePipe.transform(x.outboundPhone),
    date: x.dateCreated,
    customMessageData: x.dateCreated
  } as SMSChatUIModel);

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialModalRef: MatDialogRef<PrimasSendSMSComponent>,
    private signalRService: SignalrDynamicService,
    private smsService: SmsManagementService,
    private phonePipe: PhoneFormatPipe,
    private profileService: ProfileService,
    private dataFieldService: AutomateDatastateService,
    private dialog: MatDialog
  ) {
    this.id = data?.id || this.id;
    this.pageData = data?.pageData || this.pageData;
    this.referenceType = data?.referenceType || this.referenceType;
    this.outboundPhone = data?.outboundPhone || this.outboundPhone;
    this.setupValue();
  }

  ngOnInit(): void {
    if (this.dialModalRef?.componentInstance) {
      // if (this.isTabMode) this.dialModalRef.afterOpened()
      //   .pipe(takeUntil(this.destroy$))
      //   .subscribe(() => {
      //     var overlayBackdrops = window.document
      //       .querySelectorAll<any>('.cdk-overlay-backdrop.cdk-overlay-dark-backdrop.cdk-overlay-backdrop-showing');
      //     for (var i = 0; i < overlayBackdrops.length; i++)
      //       overlayBackdrops[i].classList.add('overlay-backdrop-tab-mode');
      //   })

      this.dialModalRef.updateSize('600px', '100vh');
      this.dialModalRef.updatePosition({ right: '0', bottom: '0' });
    }
  }

  ngAfterViewInit(): void {
    this.formatDataSMS();
    if (this.pageData && this.pageData.page.pageNumber == 0)
      this.refresh(true);

    if (this.scrollable) {
      const scroll = fromEvent(this.scrollable.nativeElement, 'scroll').pipe(
        takeUntil(this.destroy$),
        filter(() => this.scrollable.nativeElement.scrollTop === 0),
        distinctUntilChanged(),
      );

      scroll.subscribe(() => this.readOldSMS());
    }

    this.signalRService.messageReceived
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        const resp: SMSHistoryUIModel = JSON.parse(data?.data, Helper.toCamelCase) || {};
        if (data.dynamicDataType === 5 && this.id.startsWith(resp.referenceId))
          this.readLastSMS(resp);
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async setupValue() {
    var contacts: Contact[] = await this.getApiContactList();
    if (contacts && contacts.length > 0)
      this.phonesContact = Object.assign({
        filterType: PrimasFilterType.DropDown,
        filterValue: contacts,
        displayText: 'contactPhone',
        displayValue: 'contactPhone',
      } as FilterConfig)
  }

  formatDataSMS() {
    if (this.pageData && this.pageData.data && this.pageData.data.length > 0) {
      var cloneData: SMSHistoryUIModel[] = Helper.copyRemoveHeap(this.pageData.data);

      if (this.smsHistory.length > 0)
        cloneData = cloneData.filter(x => this.smsHistory.findIndex(y => y.id == x.smsHistoryId) == -1)

      var dataSMS: SMSChatUIModel[] = cloneData.map(x => this.formatFunc(x))

      if (dataSMS && dataSMS.length > 0) this.smsHistory.unshift(...dataSMS)
      else this.readSMS = !this.readSMS;
    }

    if (!this.smsHistory) this.smsHistory = [];
  }

  refresh(isRefresh: boolean = false) {
    this.isLoading = true;
    if (isRefresh) {
      this.readSMS = true;
      this.smsHistory = [];
      this.pageData = Object.assign({
        data: [],
        page: Object.assign({ size: 25, pageNumber: 0, } as Page)
      } as PagedData<SMSHistoryUIModel>);
    }

    this.smsService.getSMSByProfileId(this.id, this.pageData).subscribe({
      next: resp => {
        if (resp.result) {
          this.pageData = resp.result;
          this.formatDataSMS();
        }
      },
      complete: () => {
        this.isLoading = false;
        setTimeout(() => {
          if (this.readSMS && !this.chatComponent.scrollBottom)
            this.scrollable.nativeElement.scrollTop =
              this.scrollable.nativeElement.scrollHeight - this.scrollHeight;
          this.chatComponent.scrollBottom = true;
        }, 50);
      },
    })
  }

  sendSMS(content) {
    this.message = "";
    if (content?.trim()) {
      var loading: SMSChatUIModel = Object.assign({ id: -1, type: 'text', reply: true, text: content, userName: 'Sending' } as SMSChatUIModel);
      this.smsHistory.push(loading);

      const model = Object.assign({
        text: content,
        outboundPhone: this.outboundPhone,
        referenceId: this.id,
        referenceType: this.referenceType
      });

      this.smsService.sendSMSToQueue(model).subscribe({
        next: (resp) => {
          if (resp.message) this.refresh(true);
        },
        error: () => this.refresh(true),
      });
    }
  }

  async readLastSMS(resp: SMSHistoryUIModel) {
    var findIndex = this.smsHistory.findIndex(x =>
      x.userName == 'Sending' && x?.id == -1 // && x.text == resp.body
    );

    if (resp) {
      var checkExisted = this.smsHistory.findIndex(y => y.id != resp.smsHistoryId || y.id != resp['sMSHistoryId']) > 0;
      if (!checkExisted && findIndex >= 0) this.smsHistory.splice(findIndex, 1, this.formatFunc(resp));
      if (!checkExisted && findIndex < 0) this.smsHistory.push(this.formatFunc(resp));

      this.smsHistory = [...this.smsHistory];
    }
  }

  readOldSMS() {
    if (!this.isLoading && this.readSMS) {
      this.pageData.page.pageNumber++;
      this.chatComponent.scrollBottom = false;
      this.scrollHeight = this.scrollable.nativeElement.scrollHeight;
      this.refresh();
    }
  }

  async getApiContactList(): Promise<Contact[]> {
    var result: Contact[] = [];
    try {
      this.isLoading = true;
      var resultApi = await this.profileService.getAllEmailOrPhoneByProfileId(this.id, this.referenceType, 'phone').toPromise();
      this.isLoading = false;
      if (resultApi.result && resultApi.result.length > 0) {
        result = resultApi.result.map(x => Object.assign({}, x.contact || new Contact(), x, {
          contactId: x.typeObject == "CONTACT" ? x.id : 0,
          [x.type == 'Email' ? 'contactEmail' : 'contactPhone']: x.value,
          fullName: (`${x.fullName} ${x.typeContact != "Primary"
            ? ("(" + Helper.createSpaceString(x.typeContact) + ")")
            : ''}`).trim(),
        }));
      }
    } catch (ex) {
      console.warn(ex);
    }

    return result;
  }

  getTemplates() {
    if (!this.optionalForm.templates) {
      this.optionalForm.templates = new OptionalChatForm();
      this.optionalForm.templates.loading = true;
      this.smsService.getAllSMSTemplate().pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (resp) => {
            if (resp.result) this.optionalForm.templates.data = resp.result;
          },
          complete: () => {
            this.optionalForm.templates.data = this.optionalForm.templates.data || [];
            this.optionalForm.templates.dataSearch = this.optionalForm.templates.data || [];
            this.optionalForm.templates.loading = false;
          }
        })
    }
  }

  search(value: string) {
    switch (this.tabOptional) {
      case EnumOptionalForm.Templates:
        let searchTemplates = this._filter<SMSTemplateUIModel>(value, 'templates', 'body');
        this.optionalForm.templates.dataSearch = searchTemplates;
        break;
      case EnumOptionalForm.MergeTags:
        let searchMergeTags = this._filter<SMSMergeTagUIModel>(value, 'mergeTags', 'name', 'group');
        this.optionalForm.mergeTags.dataSearch = searchMergeTags;
        break;
    }
  }

  private _filter<T>(value: any, objectStr: string, prop: string, group: string = null): T[] {
    var result: T[] = [];
    var object: OptionalChatForm<T> = this.optionalForm[objectStr];

    if (object && object.data && object.data.length > 0) {
      object.loading = true;
      try {
        if (!object?.data) result = [];
        if (!value) result = object.data;
        if (object?.data && value) {
          const filterValue = value.toLowerCase();
          if (!group) result = object.data.filter(option => option && option[prop]?.toLowerCase()?.includes(filterValue));
          else {
            for (var i = 0; i < object.data.length; i++) {
              var groupData = Helper.copyRemoveHeap(object.data[i]);
              groupData[group] = [...groupData[group].filter(
                option => option && option[prop]?.toLowerCase()?.includes(filterValue)
              )];

              if (groupData[group] && groupData[group].length > 0)
                result.push(groupData);
            }
          }
        }
      } catch (ex) {
        console.warn(ex);
      }

      setTimeout(() => object.loading = false, 500);
    }

    return result;
  }

  resizeMessage(textFiled: MatFormField) {
    setTimeout(() => {
      var elem = textFiled?._elementRef?.nativeElement;
      if (elem) this.fitHeightBody = elem.clientHeight + 'px';
    }, 0)
  }

  selectTemplate(body, textFiled: MatFormField) {
    if (this.message) {
      const confirmReplace = this.dialog.open(ConfirmModalComponent, {
        data: { message: "Message will be replaced. Do you wish?" }
      })

      confirmReplace.afterClosed().pipe(first()).subscribe(resp => {
        if (body && resp) this.message = body;
        setTimeout(() => this.resizeMessage(textFiled), 0);
      });

      return;
    }

    if (body) {
      this.message = body;
      setTimeout(() => this.resizeMessage(textFiled), 0);
    }
  }

  getMergeTags() {
    if (!this.optionalForm.mergeTags) {
      this.optionalForm.mergeTags = new OptionalChatForm();
      this.optionalForm.mergeTags.loading = true;
      this.dataFieldService.requestMergeTag().pipe(takeUntil(this.destroy$))
        .subscribe({
          next: resp => {
            if (resp.result) {
              var mergeTag = { mergeTags: {} };
              for (let key in resp.result) {
                this.mapDataField(mergeTag, key, resp.result[key])
              }

              if (mergeTag?.mergeTags) {
                var data = this.formatMergeTagObjToArray(mergeTag.mergeTags);
                this.optionalForm.mergeTags.data = data || [];
              }
            }
          },
          complete: () => {
            this.optionalForm.mergeTags.data = this.optionalForm.mergeTags.data || [];
            this.optionalForm.mergeTags.dataSearch = this.optionalForm.mergeTags.data || [];
            this.optionalForm.mergeTags.loading = false;
          }
        })
    }
  }

  private mapDataField(mergeTag: any, key: string, value: string) {
    if (mergeTag && key && value) {
      let selectParent = mergeTag["mergeTags"];
      const hasParent = key.includes(".");
      var splitField = [];
      if (hasParent) {
        splitField = key.split(".");
        splitField = splitField.filter((element, index) => index < splitField.length - 1);
        this.recursiveFindParent(mergeTag["mergeTags"], splitField.join("."));
        for (var i = 0; i < splitField.length; i++) {
          selectParent = selectParent[splitField[i]]["mergeTags"];
        }
      }

      //Add field to merge tag
      var field = key;
      if (hasParent)
        field = key.split(".").pop();

      var checkField = selectParent[field];
      if (checkField == null) {
        var objField = {
          name: Helper.displayNameProp(key),
          value: value
        }

        selectParent[`${key}`] = objField;
      }
    }
  }

  private recursiveFindParent(mergeTag: any, key: string) {
    if (key) {
      var parentKey = key;
      var splitStr = key.split(".");
      //Find group parent.
      if (splitStr && splitStr.length > 1) {
        parentKey = splitStr[0];
      }

      const parentField = mergeTag[parentKey];
      if (parentField == null) {
        var createParent = {
          name: Helper.displayNameProp(parentKey),
          mergeTags: {}
        }
        mergeTag[`${parentKey}`] = createParent;
      }

      if (splitStr && splitStr.length > 1) {
        var splitParent = splitStr.filter((element, index) => index != 0);
        this.recursiveFindParent(mergeTag[parentKey].mergeTags, splitParent.join("."))
      }
    }
  }

  private formatMergeTagObjToArray(objects: any, groupName: string = 'Basic'): SMSMergeTagUIModel[] {
    var result: SMSMergeTagUIModel[] = [];
    try {
      if (objects) {
        var group: SMSMergeTagUIModel = Object.assign({ name: groupName } as SMSMergeTagUIModel)
        var arrParse = Object.values(objects);
        if (arrParse && arrParse.length > 0) {
          var arrChildren = arrParse.filter(x => !x['mergeTags']);
          if (arrChildren && arrChildren.length > 0)
            group.group = arrChildren.map(x => ({ name: x['name'], value: x['value'] } as SMSMergeTagUIModel)) || [];
          result.push(group);

          var arrParent = arrParse.filter(x => x['mergeTags']);
          if (arrParent && arrParent.length > 0)
            for (let item of arrParent) {
              result = [...result, ...this.formatMergeTagObjToArray(item['mergeTags'], item['name'])];
            }
        }
      }
    }
    catch (ex) {
      console.warn(ex)
    }

    return result;
  }

  selectMergeTag(elem, value: string) {
    if (elem && value?.trim()) {
      var startIndex = elem.selectionStart;
      var endIndex = elem.selectionEnd;
      if (startIndex >= 0 && endIndex >= 0) {
        var textBeforeIndex = elem.value.substring(0, startIndex);
        var textAfterIndex = elem.value.substring(endIndex, elem.value.length);
        value = (startIndex == endIndex ? " " : "") + value.trim();
        elem.value = textBeforeIndex + value + textAfterIndex;
        elem.value = elem.value.trim();

        //Config focus scroll.
        var scrollOffset = elem.scrollTop;
        elem.focus();
        elem.scrollTop = scrollOffset;
        elem.selectionStart = startIndex + value.length;
        elem.selectionEnd = startIndex + value.length;
      }
    }
  }

  changeOptional(placeholder: string, search: MatInput) {
    search.value = '';
    this.search('');
    this.searchOptionalPlaceholder = placeholder;
  }
}

export enum EnumOptionalForm {
  Templates,
  MergeTags,
}

export class SMSChatUIModel {
  id: number;
  type: string;
  text: string;
  reply: boolean;
  date: Date | string;
  userName: string;
  customMessageData: any;
}

export class SMSHistoryUIModel {
  smsHistoryId: number;
  body: string;
  direction: string;
  extendData: string;
  referenceType: string;
  referenceId: string;
  userName: string;
  inboundPhone: string;
  outboundPhone: string;
  dateCreated: string | Date;
}

export class OptionalChatForm<T> {
  page: Page;
  data: T[];
  dataSearch: T[];
  loading: boolean = false;
}

export class ChatFormModel {
  templates: OptionalChatForm<SMSTemplateUIModel>;
  mergeTags: OptionalChatForm<SMSMergeTagUIModel>;
}

export class SMSTemplateUIModel {
  id: number;
  body: string;
}

export class SMSMergeTagUIModel {
  name: string;
  value: string;
  group: SMSMergeTagUIModel[];
}