import { Category, KeywordMapping, KeywordMappingForm, KeywordMappingViewModel } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { ProfileSeo } from './../profile-management/profile-detail.model';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { Observable } from 'rxjs';
import { Page } from 'src/app/shared/models/paging/page';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { SeoInfo } from '../profile-management/profile-detail.model';
import { timeout } from 'rxjs/operators';
import { CreditBalanceResult, SeoAudit } from 'src/app/shared/components/seo-audit/seo-audit.model';

@Injectable({
  providedIn: 'root'
})
export class SeoInfoService {
  baseUrl = environment.apiSeoInfo;
  constructor(private http: HttpClient) { }

  getSeoInfoPaging(page: Page): Observable<ReturnResult<PagedData<SeoInfo>>> {
    return this.http.post<ReturnResult<PagedData<SeoInfo>>>(`${this.baseUrl}/get`, page);
  }
  DeleteSeoInfoAsync(SeoInfoId: number) {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/DeleteSeoInfoAsync/${SeoInfoId}`)
  }
  DeleteSeoInfosAsync(SeoInfoIds: number[]) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteSeoInfosAsync`, SeoInfoIds);
  }
  saveSeoInfo(model: SeoInfo): Observable<ReturnResult<SeoInfo>> {
    return this.http.post<ReturnResult<SeoInfo>>(`${this.baseUrl}/SaveCategorySeoInfo`, model);
  }
  getFriendlyUniqueUrl(friendlyUrl: string) {
    return this.http.get<ReturnResult<string>>(`${this.baseUrl}/FindUniqueUrl?friendlyUrl=${friendlyUrl}`);
  }
  getProfileSeoInfo(page: Page) {
    return this.http.post<PagedData<ProfileSeo>>(`${this.baseUrl}/ProfileSeoPaging`, page);
  }
  getCategorySeoInfo(page: Page) {
    return this.http.post<PagedData<Category>>(`${this.baseUrl}/CategorySeoPaging`, page);
  }
  bindingAllFfcUrl(type: string, forceAll: boolean = false) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/BindingAllFfcUrl/${type}/${forceAll}`, null).pipe(timeout(300000));
  }
  exportProfileSeoInfo(page: Page): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/ExportProfileSeoInfo`, page).pipe(
      timeout(1200000)
    );
  }
  exportCategorySeoInfo(page: Page): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/ExportCategorySeoInfo`, page).pipe(
      timeout(1200000)
    );
  }

  getSEOAuditById(id: string, type: string): Observable<ReturnResult<SeoAudit>> {
    let url = `${this.baseUrl}/GetSeoAuditById/${id}`;
    if(type) url = `${url}?type=${type}`;
    return this.http.get<ReturnResult<SeoAudit>>(url);
  }
  handleSeoAudit(id: string, type: string = null) {
    let url = `${this.baseUrl}/HandleSeoAudit/${id}`;
    if(type) url = `${url}?type=${type}`;
    return this.http.get<ReturnResult<SeoAudit>>(url);
  }

  checkCreditBalance(id: string): Observable<ReturnResult<CreditBalanceResult>> {
    return this.http.get<ReturnResult<CreditBalanceResult>>(`${this.baseUrl}/CheckCreditBalance?id=${id}`);
  }
  getKeywordMapping(page: Page): Observable<ReturnResult<PagedData<KeywordMapping>>> {
    return this.http.post<ReturnResult<PagedData<KeywordMapping>>>(`${this.baseUrl}/GetKeywordMapping`, page);
  }
  saveKeywordMapping(model: KeywordMappingForm): Observable<ReturnResult<KeywordMappingForm>> {
    return this.http.post<ReturnResult<KeywordMappingForm>>(`${this.baseUrl}/SaveKeywordMapping`, model);
  }
  deleteKeywordMapping(ids: number[]) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteKeywordMapping`, ids);
  }
  getKeywordMappingHistory(page: Page, id:number): Observable<ReturnResult<PagedData<any>>> {
    return this.http.post<ReturnResult<PagedData<any>>>(`${this.baseUrl}/GetKeywordMappingHistory/${id}`, page);
  }
  exportKeywordMapping(option: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SyncKeywordMapping/${option}`, {}).pipe(
      timeout(1200000)
    );
  }
  importKeywordMapping(formData: FormData): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/ImportKeywordMapping`, formData).pipe(
      timeout(1200000)
    );
  }
  export(page: Page): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/ExportKeywordMappingTool`, page).pipe(
      timeout(1200000)
    );
  }
  checkDuplicateKeyword(model: KeywordMappingForm): Observable<ReturnResult<KeywordMappingViewModel[]>> {
    return this.http.post<ReturnResult<KeywordMappingViewModel[]>>(`${this.baseUrl}/CheckDuplicateKeyword`, model);
  }
  saveEditInlineKeyword(model: KeywordMappingForm, updateValue: string = ""): Observable<ReturnResult<KeywordMappingForm>> {
    return this.http.post<ReturnResult<KeywordMappingForm>>(`${this.baseUrl}/SaveEditInlineKeyword?updateValue=${updateValue}`, model);
  }
  getReferenceTypeById(id: string): Observable<ReturnResult<string>> {
    return this.http.get<ReturnResult<string>>(`${this.baseUrl}/getReferenceTypeById/${id}`);
  }
  getCustomerVanueSeoInfo(id: string): Observable<ReturnResult<SeoInfo>> {
    return this.http.get<ReturnResult<SeoInfo>>(`${this.baseUrl}/getCustomerVenueSeoInfo/${id}`);
  }
  saveVenueSeoInfo(venueId: string, model: SeoInfo): Observable<ReturnResult<SeoInfo>> {
    return this.http.post<ReturnResult<SeoInfo>>(`${this.baseUrl}/SaveCustomerVenueSeoInfo/${venueId}`, model);
  }
  getKeywordMappingById(referenceId: string): Observable<ReturnResult<KeywordMapping>> {
    return this.http.get<ReturnResult<KeywordMapping>>(`${this.baseUrl}/KeywordMapping/${referenceId}`);
  }
  saveKeywordMappingById(referenceId: string): Observable<ReturnResult<KeywordMapping>> {
    return this.http.put<ReturnResult<KeywordMapping>>(`${this.baseUrl}/KeywordMapping/${referenceId}`, {});
  }
}
