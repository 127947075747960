import { Observable } from 'rxjs-compat/Observable';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { NbToastrService } from '@nebular/theme';
import { Setting } from '../../models/setting.model';
import { SettingService } from '../../services/setting.service';
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import { fromEvent, of } from 'rxjs';
import { SaleAccountService } from 'src/app/modules/admin/sale-account-management/sale-account.service';
import { ProfileDetailModel } from 'src/app/modules/admin/profile-management/profile-detail.model';

@Component({
  selector: 'app-search-edit-inline',
  templateUrl: './search-edit-inline.component.html',
  styleUrls: ['./search-edit-inline.component.scss']
})
export class SearchEditInlineComponent implements  OnInit, OnChanges, OnDestroy, AfterViewInit {

  @ViewChild('myInput') input: ElementRef;
  @Input() apiSearching: (data) => Observable<any>;
  @Input() apiSuggestValue: (data?: any) => Observable<any>;

  @Input() isShowSuggest = false;
  @Input() isCurrency = false;
  @Input() label: string;
  @Input() inputData;
  @Input() isAdding = false;
  @Input() popupComponent;
  @Input() isAccess = false;
  @Input() required: boolean = false;
  @Input() accessTo: string;
  @Input() parentPath: string;// belike /act/{{inputData}} or 
  // 2022-04-19 tienlm add start
  @Input() defaultNullLabel = 'None';
  // 2022-04-19 tienlm add end
  //2022-03-17 hmtien add start
  @Input() width: string | null;
  @Input() display: string | null;
  showInputData: any | null;
  //2022-03-17 hmtien add end
  @Input() avatarKey: string;
  @Input() readonlyInline = false;
  @Input() validatorValue = [];
  @Input() messageError: string = "Invalid";
  // 2022-08-31 tienlm add start
  // if EnterToSubmit = true then enter to submit; else nothing
  @Input() enterToSubmit = false;
  // if EscapeToCancel = true then cancel submit; else nothing
  @Input() escapeToCancel = false;
  // 2022-08-31 tienlm add end
  @Input() removePaddingBottom = true;
  @Input() accessFullWidth = true;
  @Input() usingMessageParent = false;
  @Input() displayKey : string;
  @Input() widthDisplay: string | null = null;
  @Input() externalTemplate: TemplateRef<any>;
  @Input() isNew: boolean = false;
  @Input() showRemoveButton: boolean = false;
  @Input() funcRedirect: () => void = null;
  @Input() suggestValue = [];
  
  SuggestRecentlyModify: ProfileDetailModel[] = [];
  selectedData : any;
  isEditing: boolean = false;
  accessList: Setting[];
  matcher = new MyErrorStateMatcher();
  loading = false;
  backupInput: string;
  list = [];
  isShowNotFound:boolean = false;
  defaultAvatar  ='assets/images/5.jpg'; 
  @Output() handleSave = new EventEmitter<FormControl>();
  @Output() cacheInputChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEventDeleteHandle: EventEmitter<any> = new EventEmitter<any>();
  inputControl: FormControl;
  isShowTitle = true;

  constructor(private render: Renderer2, 
    private settingService: SettingService, 
    private toast: NbToastrService,
    private accountService: SaleAccountService
    ) {
    this.inputControl = new FormControl(this.inputData, this.validatorValue);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const inputChange = changes.inputData;
    if (inputChange.currentValue !== inputChange.previousValue) {
      this.inputControl = new FormControl(this.inputData, this.validatorValue);
      if (this.inputData != null) {
        this.showInputData = this.inputData.toString().replace('https://', '');
        this.showInputData = this.showInputData.replace('http://', '');
      }
    }
    // if (this.accessTo) {
    //   this.settingService.domainList().subscribe((e: Setting[]) => {
    //     this.accessList = e;
    //   })
    // }

  }
  // getDefaultDomainData() {
  //   this.settingService.getDefaultDomainList();
  // }

  ngOnInit() {
    this.SetData();
    
    //2022-02-07 vuonglqn add start
    this.backupInput = this.inputData;
    //2022-02-07 vuonglqn add end
    //2022-03-17 hmtien add start
    if (this.width == null) {
      this.width = '100%';
    }
    if (this.inputData != null) {
      this.showInputData = this.inputData.toString().replace('https://', '');
      this.showInputData = this.showInputData.replace('http://', '');
    }

    //2022-03-17 hmtien add end

    // if (this.accessTo) {
    //   this.settingService.domainList().subscribe((e: Setting[]) => {
    //     this.accessList = e;
    //   })
    // }
  }

  inputChange() {
    if (this.cacheInputChange && this.inputControl.valid) {
      if (this.required && (this.inputControl.value == "" || this.inputControl.value == undefined || this.inputControl.value == null))
        this.cacheInputChange.emit({ data: this.inputControl.value, isRemove: true });
      else this.cacheInputChange.emit({ data: this.inputControl.value, isRemove: false });
    } else this.cacheInputChange.emit({ data: this.inputControl.value, isRemove: true });
  }

  ngOnDestroy() { }

  onBlur() {

    this.onConfirm();
  }
  goToLink(): void {
    if (this.accessTo) {
      window.open(`${this.accessTo}/${this.parentPath ?? ''}/${this.backupInput}`, "_blank");
    }
    else {
      if (this.backupInput.includes("https://"))
        window.open(this.backupInput, "_blank");
      else if (this.backupInput.includes("http://"))
        window.open(this.backupInput, "_blank");
      else window.open("https://" + this.backupInput, "_blank");
    }
  }

  focusText() {
      if (!this.input)
        setTimeout(() => { // this will make the execution after the above boolean has changed
          this.input.nativeElement.focus();
          fromEvent(this.input.nativeElement, "keyup").pipe(
            //get value in the input
            map((event: any) => {
              if(event.target.value.length > 2 ) this.isShowNotFound = true;
              else this.isShowNotFound = false;
              
              return event.target.value;
            }),
            // if character length greater then 2
            filter(res => res.length > 2 || res.length === 0),
            debounceTime(1000),
            distinctUntilChanged(),
          ).subscribe((keyword: string) => {
            //subscribe to keyword to get API
            this.searchProfile(keyword)
          })
        }, 0);
  }

  onRemove() {
    this.onEventDeleteHandle.emit(true);
  }

  onCancel() {
    this.inputControl = new FormControl(this.backupInput, this.validatorValue);
    this.setEditMode(false);
    if (this.cacheInputChange)
      this.cacheInputChange.emit({ data: this.inputControl.value, isRemove: true });
    if(this.isShowSuggest){
      this.SetData();
    }
  }

  onConfirm() {
    if (this.inputControl.valid) {
      if (this.handleSave && this.inputControl.value !== this.backupInput) {
        if (this.required) {
          if (this.inputControl.value) {
            this.handleSave.emit(this.inputControl.value);
          }
          else this.toast.warning("The field is not empty", "Invalid input")
          //this.cacheInputChange.emit({ data: this.inputControl.value.trim(), isRemove: true });
        }
        else {
          this.handleSave.emit(this.inputControl.value);
          //this.cacheInputChange.emit({ data: this.inputControl.value.trim(), isRemove: true });
        }
      }
      //2022-02-07 vuonglqn add start
      this.inputControl = new FormControl(this.backupInput, this.validatorValue);
      //2022-02-07 vuonglqn add end
      this.setEditMode(false);
      this.list = [];
      this.SetData();
    }
  }

  setEditMode(mode: boolean) {
    if (this.isAdding) {
      this.inputData = '';
      this.inputControl = new FormControl(this.inputData, this.validatorValue);
    }
    this.focusText()
    this.backupInput = this.inputData;
    this.isEditing = mode;

  }
  // 2022-08-31 tienlm add start
  resetComponent() {
    this.backupInput = '';
    this.inputData = '';
    if (this.inputControl) {
      this.inputControl.reset();
    }
    this.inputControl = new FormControl(this.inputData, this.validatorValue);
    this.ngOnInit();
  }
  // 2022-08-31 tienlm add end
  SetData(){
    if(this.isShowSuggest){
      this.isShowTitle = true;
      if(this.apiSuggestValue){
        this.apiSuggestValue().pipe(take(1))
          .subscribe({
            next: resp => {
              if (resp.result != null)
                this.list = resp.result;
            },
            complete: () => this.loading = false,
          });
        return;
      }
      else{
        this.accountService.suggestRecentlyAddedSaleAccount().subscribe({
          next:data=>{
            if(data.result){
              this.list = data.result;
            }
          }
        })
        return this.list;
      }
      
    }
    
  }
  searchProfile(data: string) {
    if (data === '') {
      if(this.isShowSuggest){
        this.SetData();
      }
      return;
    }
    this.loading = true;
    if (this.apiSearching) {
      if(this.isShowSuggest){
        this.isShowTitle = false;
      }
      this.apiSearching(data).pipe(take(1))
        .subscribe({
          next: resp => {
            if (resp.result != null)
              this.list = resp.result;
          },
          complete: () => this.loading = false,
        });
      return;
    }
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    // fromEvent(this.input.nativeElement, 'keyup').pipe(
    //   // get value in the input
    //   map((event: any) => {
    //     return event.target.value;
    //   }),
    //   // if character length greater then 2
    //   filter(res => res.length > 2 || res.length === 0),
    //   debounceTime(1000),
    //   distinctUntilChanged(),
    // ).subscribe((keyword: string) => {
    //   // subscribe to keyword to get API
    //   this.searchProfile(keyword);
    // });
  }
  public displayProperty(value) {
    if (value) {
      return value.displayName;
    }
  }
  showSelectedProfile(data) {
    setTimeout(() => {
      this.onConfirm();

    },1)
  }
}
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}