import { prop } from "@rxweb/reactive-form-validators";
import { Page } from "src/app/shared/models/paging/page";
import { PagedData } from "src/app/shared/models/paging/paged-data";
import { Contact, ProfileDetailModel } from "../profile-management/profile-detail.model";
import { CampaignInfo } from "./create-email-campaign/edit-general-info/campaign-info";

export class EmailCampaignList {
  data: EmailCampaignDetail[];
  total: number;
  aggregateResults: string;
  errors: string;
}

export class EmailCampaignDetail {
  constructor() {
    this.id = 0;
    this.status = 0;
  }
  @prop()
  id: number;
  @prop()
  campaignName: string;
  @prop()
  campaignDescription: string;
  @prop()
  auto: boolean;
  @prop()
  status: number;
  @prop()
  emailTemplate: string;
  @prop()
  smsTemplate: string;
  @prop()
  phoneTemplate: string;
  @prop()
  dateCreate: string;
  @prop()
  dateUpdated: string;
  @prop()
  statusString: string;
  @prop()
  channel: string;
  @prop()
  channelString: string;
  @prop()
  groups: string;
  @prop()
  groupsName: string;
  @prop()
  schedule: string;
  @prop()
  dontProcess: boolean;
  @prop()
  total: number;
  @prop()
  lastRun: string;
  @prop()
  currentSession: string;
  @prop()
  startDate: Date;
  @prop()
  nextScheduleToRun?: any;
  @prop()
  startTime: string;
  @prop()
  endTime?: any;
  @prop()
  liveMode: boolean;
  @prop()
  forceStart: boolean;
  @prop()
  workflowUUID: string;
  @prop()
  insertPhoneCall: boolean;
  @prop()
  phoneCallCampaignId: string;
  @prop()
  category: string;
  workflowCampaignDataName: string;
  @prop()
  testData: string;
  @prop()
  contactConfig:string;
}
export class CampaignDetailDataWithContactId extends EmailCampaignDetail {
  @prop()
  lId: { id: number; groups: string; }[];
  @prop()
  groupName: string;

  externalComponent?: boolean;
  profiles?: ProfileDetailModel[];
  pagingTreeFilter?: Page;
  isSelectAll?: boolean;
  typeName?: string;
}
export class CampaignInfoWithExistedContact extends CampaignInfo {
  @prop()
  lId: number[];
  @prop()
  groupName: string;
}

export class ContactInvalidCampaign extends Contact {
  profileId: string;
  failureReason: string;
}
export class ContactUseSecondaryEmail extends Contact {
  profileId: string;
}
export class ReviewCampaignContactResponse {
  valids: any;
  inValids: ContactInvalidCampaign[];
  pageData: PagedData<Contact>;
  contactUseSecondaryEmails: ContactUseSecondaryEmail[];
}