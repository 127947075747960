import { NbAccessChecker } from '@nebular/security';
import { filter, mergeMap, pairwise, switchMap, take, takeUntil } from 'rxjs/operators';
import { Component, HostListener, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { EventEmitter } from 'protractor';
import { ProfileRequest } from 'src/app/shared/models/request/profile-request.model';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { SeoInfoFormControl } from 'src/app/shared/models/seo-form-control.model';
import { KeyPairsValue, ProfileSeo, SeoInfo } from '../../../profile-detail.model';
import { ProfileService } from '../../../profile.service';
import { Helper } from 'src/app/shared/utility/Helper';
import { SettingService } from 'src/app/shared/services/setting.service';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { BooleanLiteral } from 'typescript';
import { SubmitIndexingDialogComponent } from '../../seo-tab-table/submit-indexing-dialog/submit-indexing-dialog.component';
import { Subject } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { countWordValidators } from 'src/app/modules/admin/seo-dashboard/keyword-mapping-tool/add-edit-keyword-mapping-tool/countWordValidator';

@Component({
  selector: 'app-add-new-seo',
  templateUrl: './add-new-seo.component.html',
  styleUrls: ['./add-new-seo.component.scss']
})
export class AddNewSeoComponent implements OnInit, OnDestroy {
  @Input() profileId: string;
  @Input() tooltipProp: KeyPairsValue[] = [];
  @Input() profileSeoInfo: SeoInfo = new SeoInfo;
  @Input() primarySeoInfo: boolean;
  @Input() action: TblActionType;

  @ViewChild('confirmData', { static: true }) confirmData: TemplateRef<any>;

  formGroup: FormGroup;
  isLoading: boolean = false;
  displayName: string;
  lengthCharacterTitle: number = 0;
  colorTitle: string = '';
  lengthCharacterDesc: number = 0;
  colorDesc: string = '';
  seoForm: SeoInfoFormControl = new SeoInfoFormControl();
  findItemByKey = Helper.findItemByKey;
  showRequestIndexing: string = '';
  isChange: boolean = false;
  maxWord: number = 6;
  isReadonlyKeyword: boolean = false;
  isNoIndexState: boolean = false;
  numberTruncateToggle: number = 15;
  private _destroy: Subject<void> = new Subject<void>();

  constructor(private frmBuilder: RxFormBuilder, private profileService: ProfileService,
    private toast: NbToastrService, public dialModalRef: MatDialogRef<AddNewSeoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private settingService: SettingService,
    public dialog: MatDialog, private access: NbAccessChecker) {
    this.profileId = this.data.id;
    this.action = data.action;
    this.displayName = this.data.displayName;
    this.seoForm.displayName = this.data.displayName;
    this.seoForm.title = this.data.displayName;
    this.seoForm.friendlyUrl = this.data.friendlyUrl;
    this.profileSeoInfo = data.profileSeoInfo;
    this.primarySeoInfo = data.primarySeoInfo;
    if (this.action == TblActionType.Edit) {
      this.seoForm.title = this.data.title;
      this.seoForm.description = data.profileSeoInfo?.description;
      this.lengthCharacterDesc = this.seoForm?.description?.length ?? 0;
      if (this.lengthCharacterDesc != 0) {
        this.changeColorCharacter(this.lengthCharacterDesc, 'description');
      }
      this.seoForm.keywords = data.profileSeoInfo?.keywords;
      this.seoForm.primaryKeyword = data.profileSeoInfo?.primaryKeyword;
      this.seoForm.secondaryKeyword = data.profileSeoInfo?.secondaryKeyword;
      this.seoForm.noIndex = data.profileSeoInfo?.noIndex;
      this.isNoIndexState = data.profileSeoInfo?.noIndex;
    }
    this.tooltipProp = this.data.tooltipProp ?? [];

    this.settingService.getSettingByKeyAndGroup("SHOW_REQUEST_INDEXING", "SEO")
      .pipe(takeUntil(this._destroy)).subscribe(resp => {
        if (resp?.result?.value) {
          this.showRequestIndexing = resp?.result?.value?.trim();
        }
      })
  }

  ngOnInit(): void {
    if (this.seoForm.friendlyUrl) {
      var pattern = /^[a-zA-Z0-9]+([- ]?[a-zA-Z0-9]+)*$/;
      if (!pattern.test(this.seoForm.friendlyUrl)) {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
          data: {
            externalTemplate: this.confirmData,
          }
        })
        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.profileService.getFriendlyUniqueUrl(this.seoForm.friendlyUrl).subscribe(resp => {
              if (resp.result) {
                this.formGroup.get('friendlyUrl').setValue(resp.result);
              }
            })
          }
        });
      }
    }
    this.setupPattern();
    this.checkKeywordPermission();
    this.numberTruncateToggle = window.innerWidth > 1440 ? 20 : 1;
  }

  checkKeywordPermission() {
    this.access.isGranted("view", "keyword-seo-information").pipe(take(1)).subscribe(isAccess => {
      this.isReadonlyKeyword = !isAccess;
    })
  }

  setUpKeywordValidator() {
    try {
      let formControlPrimaryKeyword: FormControl = this.formGroup?.get('primaryKeyword') as FormControl;
      if (formControlPrimaryKeyword) {
        formControlPrimaryKeyword.clearValidators();
        formControlPrimaryKeyword.setValidators([
          countWordValidators(this.maxWord)
        ]);
        formControlPrimaryKeyword.updateValueAndValidity();
        this.formGroup.updateValueAndValidity();
      }
    } catch (error) { }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  async setupPattern() {
    if (this.action !== TblActionType.Edit) {
      var resp = await this.settingService.getSettingByKeyAndGroup("PATTERN_TITLE_SEO", "PROFILE").toPromise();
      let pattern: string = resp?.result?.value || "[TITLE_SEO]";
      this.seoForm.title = pattern.replace("[TITLE_SEO]", this.seoForm.title) || this.seoForm.title;
    }
    this.lengthCharacterTitle = this.seoForm?.title?.length ?? 0;
    if (this.lengthCharacterTitle != 0) {
      this.changeColorCharacter(this.lengthCharacterTitle, 'title');
    }
    this.formGroup = this.frmBuilder.formGroup(SeoInfoFormControl, this.seoForm);
    if (this.formGroup) this.formGroup.markAllAsTouched();

    this.formGroup.valueChanges.pipe(
      takeUntil(this._destroy),
      pairwise(),
      filter(([prev, cur]) => JSON.stringify(prev) != JSON.stringify(cur)))
      .subscribe((event) => this.isChange = true);

    this.setUpKeywordValidator();
  }

  refreshData(isCloseDialog: boolean = false) {
    this.profileService.refreshSeoInfo(this.profileId).add(this.onCloseDialog(isCloseDialog));
  }
  countAndUpdateLengthCharacter(event, nameProp: string) {
    if (nameProp == 'title') {
      if (event.target.value) {
        this.lengthCharacterTitle = event.target?.value?.length ?? 0;
        if (this.lengthCharacterTitle != 0) {
          this.changeColorCharacter(this.lengthCharacterTitle, nameProp)
        }
      }
      else {
        this.lengthCharacterTitle = 0;
        this.colorTitle = '';
      }
    }
    if (nameProp == 'description') {
      if (event.target.value) {
        this.lengthCharacterDesc = event?.target?.value?.length ?? 0;
        if (this.lengthCharacterDesc != 0) {
          this.changeColorCharacter(this.lengthCharacterDesc, nameProp)
        }
      }
      else {
        this.lengthCharacterDesc = 0;
        this.colorDesc = '';
      }
    }
  }
  changeColorCharacter(lengthCharacter: number, prop: string) {
    switch (prop) {
      case 'title':
        if (lengthCharacter > 0 && lengthCharacter < 26) {
          this.colorTitle = '#FF6600';
        } else if (lengthCharacter > 25 && lengthCharacter < 66) {
          this.colorTitle = '#009900';
        } else {
          this.colorTitle = '#CC0000';
        }
        break;
      case 'description':
        if (lengthCharacter > 0 && lengthCharacter < 100) {
          this.colorDesc = '#FF6600';
        }
        else if (lengthCharacter > 99 && lengthCharacter < 156) {
          this.colorDesc = '#009900';
        }
        else {
          this.colorDesc = '#CC0000';
        }
        break;
    }
  }

  onCloseDialog(isRefresh: boolean = false) {
    this.dialModalRef.close(isRefresh);
  }
  async onSave() {
    if (this.formGroup.valid) {
      this.isLoading = true;
      const model: SeoInfo = Object.assign({}, this.formGroup.value);
      if (this.profileSeoInfo && this.action == TblActionType.Edit) {
        model.seoInfoId = this.profileSeoInfo.seoInfoId;
      }
      const profile: ProfileRequest = Object.assign({}, this.formGroup.value);
      var resProfile = await this.profileService.editProfile(this.profileId, profile).toPromise();
      if (resProfile.result) {
        this.profileService.refreshData(this.profileId).subscribe();
      }
      this.profileService.editSeoInfo(model, this.profileId).subscribe(async (res: ReturnResult<boolean>) => {
        if (!res.message) {
          if (res.result) {
            if (this.showRequestIndexing === "1") {
              // open request index for profile active, published and friendly url changed
              if (this.data?.profileSeo?.profile?.isActive && this.data?.profileSeo?.profile?.published
                && this.data?.primarySeoInfo && this.isChange) {

                let data = { ...model, ffcUrl: this.data.profileSeo.seoInfo?.ffcUrl };
                if (this.data?.friendlyUrl !== model?.friendlyUrl) {
                  delete data['ffcUrl'];
                }

                this.dialog.open(SubmitIndexingDialogComponent, {
                  disableClose: false,
                  width: '400px',
                  data: { seoInfo: data }
                })
              }
              await this.refreshData(true);
            }

          }
          else this.toast.danger('Failed')
        }
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
      }
      );
    }
  }
  async changeStateNoIndex(event: MouseEvent){
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmModalComponent,{
      data: { 
        message: `<h3 style="text-align:center; font-size:20px;"><i class="fa fa-exclamation-triangle" aria-hidden="true" style="color:#ffd107"></i> Warning!</h3><p>By turning this switch <b style="font-weight:900">${this.isNoIndexState ? 'Off' : 'On'}</b>, this profile will be <b style="font-weight:900">${this.isNoIndexState ? 'Visible' : 'Invisible'}</b> to Google ranking. Please confirm your action</p>`,
        yesTitle: `I understood`,
        isDisableNoButton: true
      }
    })
    var result = await dialogRef.afterClosed().toPromise();
    if(result){
      this.isNoIndexState = !this.isNoIndexState;
      this.formGroup.controls.noIndex.setValue(this.isNoIndexState);
    }

  }
  @HostListener('window:resize', ['$event'])
  onResizeTextSwitch(event) {
    if (event) {
      this.numberTruncateToggle = event.target.innerWidth > 1440 ? 20 : 1;
    }
  }
}
