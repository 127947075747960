import { Pipe, PipeTransform } from '@angular/core';
import { AutomateDataState } from '../../profile-management/profile-detail/automate-datastate-log/automate-datastate-model';
import { Helper } from 'src/app/shared/utility/Helper';

@Pipe({
  name: 'opportunitySaleReportPipe'
})
export class OpportunitySaleReportPipe implements PipeTransform {

  transform(dataStateFilterSelected: string[], selectedItem: AutomateDataState, type: string) {
    try {
      switch (type) {
        case 'dataStateFilterBtn':
          return this.dataStateFilterBtn(dataStateFilterSelected, selectedItem);
        case 'dataStateFilterIcon':
          return this.dataStateFilterIcon(dataStateFilterSelected, selectedItem);
        default: //default ICON
          return this.dataStateFilterIcon(dataStateFilterSelected, selectedItem);
      }
    }
    catch (ex) {
      console.error(ex);
    }
  }

  constructor() {

  }

  dataStateFilterBtn(dataStateFilterSelected: string[], selectedItem: AutomateDataState) {
    let result: string = '#cccccc';
    try {
      if (dataStateFilterSelected?.length > 0 && selectedItem) {
        if (dataStateFilterSelected?.includes(selectedItem?.automateDataStateId?.toString())) {
          result = selectedItem?.colorCode;
        }
      }
    }
    catch (ex) {
      console.error(ex);
    }
    return result;
  }

  dataStateFilterIcon(dataStateFilterSelected: string[], selectedItem: AutomateDataState) {
    let result: string = '#dddddd';
    try {
      if (dataStateFilterSelected?.length > 0 && selectedItem) {
        if (dataStateFilterSelected?.includes(selectedItem?.automateDataStateId?.toString())) {
          result = selectedItem?.colorCode;
        }
      }
    }
    catch (ex) {
      console.error(ex);
    }
    return result;
  }
}

@Pipe({
  name: 'opportunitySaleTeamReportPipe'
})
export class OpportunitySaleTeamReportPipe implements PipeTransform {
  monthArr = [ "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC" ];

  transform(monthNumberString: string, type: string) {
    try {
      switch (type) {
        case 'monthFormat':
          return this.monthFormat(monthNumberString);
        default: //default ICON
          return this.monthFormat(monthNumberString);
      }
    }
    catch (ex) {
      console.error(ex);
    }
  }

  constructor() {

  }

  monthFormat(monthNumberString: string) {
    let result: string = '';
    try {
      if (!Helper.isNullOrEmpty(monthNumberString)) {
        result = this.monthArr[Number.parseInt(monthNumberString) - 1];
      }
    }
    catch (ex) {
      console.error(ex);
    }
    return result;
  }
}
