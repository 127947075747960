import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-animation-arrow-scroll',
  templateUrl: './animation-arrow-scroll.component.html',
  styleUrls: ['./animation-arrow-scroll.component.scss']
})
export class AnimationArrowScrollComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
