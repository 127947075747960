import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { environment } from 'src/environments/environment';
import { AltusLocation } from './location';
import { Location, Media, ProfileDetailModel } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { Page } from 'src/app/shared/models/paging/page';
import { WPCategoryLocation, WPLocation, WPLocationBlackList } from 'src/app/modules/admin/wplocation-management/wplocation.model';
import { timeout } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient) { }
  baseUrl = environment.locationManagement;
  getLocationById(profileId: string): Observable<ReturnResult<AltusLocation[]>> {
    return this.http.get<ReturnResult<AltusLocation[]>>(`${this.baseUrl}/GetLocationByProfileId/${profileId}`);
  }
  saveLocation(
    locationLst: AltusLocation[],
    profileId: string,
    producerId: string,
    buyerId: string,
    isUpdate: boolean = false): Observable<ReturnResult<AltusLocation[]>> {
    return this.http.post<ReturnResult<AltusLocation[]>>(`${this.baseUrl}/SaveLocationList`, {
      locations: locationLst,
      profileId, isUpdate,
      producerId, buyerId
    });
  }
  getLocationByLocationId(locationId: string): Observable<ReturnResult<AltusLocation>> {
    return this.http.get<ReturnResult<AltusLocation>>(`${this.baseUrl}/${locationId}`);
  }
  unLinkLocation(location: Location, table: string, id: string) {
    return this.http.post(`${this.baseUrl}/UnlinkLocation`, {
      location, id, table
    });
  }
  getWpLocationPaging(page: Page, type: string) {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/WPLocationPaging/${type}`, page);
  }
  getDefaultLocation() {
    return this.http.get<[]>("./assets/location.json");
  }
  saveWPLocation(model: WPLocation | WPCategoryLocation) {
    return this.http.post<ReturnResult<WPLocation>>(`${this.baseUrl}/SaveWPLocation`, model);
  }
  deleteLocations(locationIds) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteWPLocations`, {ids: locationIds} );
  }
  toggleActive(id: number, active: boolean) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/ToggleActiveWPLocation/${id}?active=${active ?? false}`, {} );
  }
  getFirstMediaLocation(id: string):  Observable<ReturnResult<Media>> {
    return this.http.get<ReturnResult<Media>>(`${this.baseUrl}/GetFirstMediaLocation/${id}`);
  }
  getWPLocationByRefId(id: string):  Observable<ReturnResult<WPLocation>> {
    return this.http.get<ReturnResult<WPLocation>>(`${this.baseUrl}/GetWPLocationByRefId/${id}`);
  }
  countWPCategoryLocation(id: string) : Observable<ReturnResult<any>> {
    return this.http.get<ReturnResult<any>>(`${this.baseUrl}/CountCategoryLocation/${id}`);
  }
  getActivateLocationByRefId(page: Page, id: string, type: string) {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/GetActivateLocationByRefId/${id}/${type}`, page);
  }
  addToBlackList(model: WPLocationBlackList[]) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/AddWPLocationToBlackList`, model );
  }
  removeToBlackList(model: WPLocationBlackList[]) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeactivateCategoryLocation`, model );
  }
  importWPLocation(formData: FormData): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/ImportWPLocation`, formData).pipe(
      timeout(1200000)
    );
  }
  exportWPLocation(page: Page, type: string): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.baseUrl}/ExportWPLocation/${type}`, page)
  }
  getWPLocationById(id: number):  Observable<ReturnResult<WPLocation>> {
    return this.http.get<ReturnResult<WPLocation>>(`${this.baseUrl}/GetWPLocationById/${id}`);
  }
  getSpecialLocationContent(parent: WPLocation) {
    return this.http.post<ReturnResult<WPLocation>>(`${this.baseUrl}/GetSpecialLocation`, parent);
  }
  getProfileLocationTab(id: string) {
    return this.http.get<ReturnResult<ProfileDetailModel>>(`${this.baseUrl}/GetProfileLocationTab/${id}`);

  }
}
 