<ng-container [ngSwitch]="templateType">

    <ng-container *ngSwitchCase="'default'" [ngTemplateOutlet]="defaultTemplate">
    </ng-container>

    <ng-container *ngSwitchCase="'mobileFilter'" [ngTemplateOutlet]="mobileFilter">
    </ng-container>

</ng-container>

<ng-template #defaultTemplate>
    <mat-form-field style="width: 22px;" color="primary" class="without-arrow hidden-line">
        <mat-select (keydown.enter)="$event.stopImmediatePropagation();" [(ngModel)]="selectedFilter" (selectionChange)="onChangeFilterOperator($event.value)" [value]="selectedFilter">
            <mat-option *ngFor="let operator of filterOperators" [value]="operator" style="font-size: 14px;">
                {{operator}}
            </mat-option>
        </mat-select>
        <button matPrefix mat-icon-button>
            <mat-icon style="font-size: 24px;" fontSet="material-icons-outlined">filter_alt</mat-icon>
        </button>
    </mat-form-field>
</ng-template>

<ng-template #mobileFilter>
    <mat-form-field>
        <mat-label>Filter Operator</mat-label>
        <mat-select style="width: 100% !important;" (selectionChange)="onChangeFilterOperator($event.value)" [(ngModel)]="selectedFilter" [value]="selectedFilter">
            <mat-option style="width: 100% !important;" *ngFor="let operator of filterOperators" [value]="operator">
                {{operator}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</ng-template>