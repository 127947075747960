import { EventEmitter, Injectable } from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import { CampaignLinkscopeService } from 'src/app/modules/admin/profile-management/profile-detail/action-block/campaign-linkscope.service';
import { UserStatusService } from './user-status.service';
import { UserStatusModel } from '../models/user-status.model';
@Injectable({
  providedIn: 'root'
})
export class SignalrDynamicService {

  messageReceived = new Subject<any>();
  connectionEstablished = new EventEmitter<boolean>();
  nameHub: string;
  private connectionIsEstablished = false;
  private hubConnection: HubConnection;
  token: string;
  constructor(
    private campaignService: CampaignLinkscopeService,
  ) {
  }
  optionsSinalr: IHttpConnectionOptions = {
    accessTokenFactory: async () => {
      return this.token;
    }
  };
  connect(nameHub: string, nameCallBack: string, token?: string) {

    if (nameHub) {
      this.nameHub = nameHub;
      this.token = token;
      this.createConnection();
      this.registerOnServerEvents(nameCallBack);
      this.startConnection();
    }
  }
  getConnectionId() {
    return this.hubConnection.invoke('getConnectionId');
  }
  sendChatMessage(message: any) {
    this.hubConnection.invoke('SendMessage', message);
  }
  getData(functionName: string) {
    return this.hubConnection.invoke(functionName);
  }
  private createConnection() {

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.host}/${this.nameHub}`, this.optionsSinalr)
      .configureLogging(signalR.LogLevel.Debug)
      // .withAutomaticReconnect([2])
      .build();
    this.hubConnection.serverTimeoutInMilliseconds = 10000;
    // document.addEventListener('visibilitychange', () => {
    //   if (document.hidden) {
    //     console.log('start hidden connection');
    //     this.startConnection();
    //   }
    // })
  }

  private startConnection(): void {
    if (this.hubConnection == null) {
      return;
    }
    this.hubConnection.onclose(() => {
      setTimeout(() => {
        this.startConnection();
      }, 5000);
    });
    Object.defineProperty(WebSocket, 'OPEN', { value: 1, });
    var stateConnect = ["Connecting", "Connected"];
    console.log(this.hubConnection.state);
    if (!stateConnect.includes(this.hubConnection.state)) {
      this.hubConnection
        .start()
        .catch(err => {
          console.log('Error while establishing connection, retrying...' + err);
          this.connectionIsEstablished = true;
          setTimeout(() => {
            this.startConnection();
          }, 5000);
        })
        .then(() => {
          this.connectionIsEstablished = true;
          console.log('Hub connection started');
          this.connectionEstablished.emit(true);
        });
    }


    // this.hubConnection.
  }
  onDispose() {
    this.hubConnection.stop();
    this.hubConnection = null;
    this.connectionIsEstablished = false;
  }

  private registerOnServerEvents(nameCallBack): void {
    this.hubConnection.on(nameCallBack, (data: any) => {
      if (data?.dynamicDataType === 2 && data?.data) {
        var activityLogId = window.localStorage.getItem('activityLogCall');
        if (activityLogId) {
          var modelCall = JSON.parse(data?.data);
          console.log(modelCall);
          this.campaignService.updateCallEventActivityLog(+activityLogId, modelCall.CallEvent).subscribe(res => { });
          if (modelCall.CallEvent == "CALL_ENDED" || modelCall.CallEvent == "FAILED" || modelCall.CallEvent == "NO_ANSWER" || modelCall.CallEvent == "BUSY"
            || modelCall.CallEvent == "AGENT_BUSY" || modelCall.CallEvent == "AGENT_NO_ANSWER" || modelCall.CallEvent == "AGENT_FAILED") {
            window.localStorage.removeItem('activityLogCall');
          }
        }
      }
      this.messageReceived.next(data);
    });
  }
  getUserState(userId: string) {
    if (this.connectionEstablished != null) {
      this.connectionEstablished.subscribe(data => {
        if (data) {
          this.hubConnection.invoke('GetUserState', userId);
        }
      })
    }
  }
  setUserState(userState: UserStatusModel) {
    if (this.connectionEstablished != null) {
      this.connectionEstablished.subscribe(data => {
        if (data) {
          this.hubConnection.invoke('SetUserState', JSON.stringify(userState));
        }
      });
    }
  }
  invokeFunction(methodName: string, payload: any) {
    if (this.connectionIsEstablished) {
      this.hubConnection.invoke(methodName, payload);
    }
  }
  setUserStateByHub(data: UserStatusModel) {
    if (this.connectionIsEstablished) {
      this.hubConnection.invoke('SetUserState', JSON.stringify(data));
    }
  }
  private isWindowHidden(): boolean {
    return document.hidden || document.visibilityState !== 'visible';
  }
}
