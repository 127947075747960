import { DashboardService } from './../../../modules/admin/dashboard/dashboard.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { formatDate } from '@angular/common';
import { weekdayEN } from './locale/weekdaysEN';
import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { mounthsEN } from './locale/MounthsEN';
import { yearsLabel } from './locale/Years';
import { EventDataCalendar } from './interface/event-data-calendar';
import { NbPosition, NbTrigger } from '@nebular/theme';
import { EditInlineTaskComponent } from 'src/app/modules/admin/task-management/edit-inline-task/edit-inline-task.component';
import { MatDialog } from '@angular/material/dialog';
import { TblActionType } from '../../enums/tbl-action-type.enum';

@Component({
  selector: 'app-custom-ngx-event-calendar',
  templateUrl: './custom-ngx-event-calendar.component.html',
  styleUrls: ['./custom-ngx-event-calendar.component.scss'],
  animations: [
    trigger(
      'switchAnimation', [
      transition('void => prev', [
        style({ transform: 'translateX(-100%)', opacity: 0, zIndex: 2 }),
        animate('250ms', style({ transform: 'translateX(0)', opacity: 1 }))
      ]),
      transition('prev => void', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('250ms', style({ transform: 'translateX(0)', opacity: 0 }))
      ]),
      transition('void => next', [
        style({ transform: 'translateX(100%)', opacity: 0, zIndex: 2 }),
        animate('250ms', style({ transform: 'translateX(0)', opacity: 1 }))
      ]),
      transition('next => void', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('250ms', style({ transform: 'translateX(0)', opacity: 0 }))
      ]),
      transition('void => clickChange', [
        style({ transform: 'translateX(0)', opacity: 0, zIndex: 2 }),
        animate('250ms', style({ transform: 'translateX(0)', opacity: 1 }))
      ]),
      transition('clickChange => void', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('250ms', style({ transform: 'translateX(0)', opacity: 0 }))
      ])
    ]),
  ]
})
export class CustomNgxEventCalendarComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() dataSource: EventDataCalendar[];
  @Input() showAddButton: boolean;
  @Input() language: string;
  @Input() isViewFull: boolean = false;
  //Defind popover component
  position = NbPosition.RIGHT;
  trigger = NbTrigger.CLICK
  //end

  @Output() dayEvents = new EventEmitter();
  @Output() newEvent = new EventEmitter();
  @Output() viewSeenCalendarEvent = new EventEmitter();
  @Output() refreshData = new EventEmitter();

  constructor(
    private dashboardService: DashboardService,
  ) {
    this.today = new Date();
    this.currentMonth = this.today.getMonth();
    this.currentYear = this.today.getFullYear();
    this.years = yearsLabel;
  }
  isSmall = false;

  today;
  currentMonth;
  currentYear;
  firstDay;
  daysInMonth;
  daysInLastMonth;
  actDay;
  lastMonth;
  actMonth;
  months: any;
  weekdays: any;
  years = [];
  actFullDate;
  actDate: any;
  arrTest = [];
  arrCalendar = [];
  eventsData: any = [];
  actYear;
  showChangeDate = false;
  btnAddShow: boolean;
  isSwitchMonth: boolean = false;
  actionSwitchAnimation: string = 'prev'; // prev or next or click change
  isScrollCalendar: boolean = true;
  sTimeOutScroll: any; //Set timeout flag scroll
  isFullView: boolean = false;

  ngOnInit(): void {
    this.actFullDate = formatDate(new Date(), 'yyyy. MMMM. dd', 'en');
    this.actDate = formatDate(new Date(), 'yyyy. MMMM', 'en');
    this.actDay = formatDate(new Date(), 'dd', 'en');
    this.actMonth = formatDate(new Date(), 'MM', 'en');
    this.actYear = formatDate(new Date(), 'yyyy', 'en');
    this.eventsData = this.dataSource;
    this.btnAddShow = this.showAddButton;
    // this.dashboardService.getTaskEventCalendar(new Date("2022-01-01"), new Date("2022-04-01")).subscribe(e => {
    //   if(!e.message) this.eventsData = e.result;
    // })
  }

  ngAfterViewInit(): void {
    this.checkHeightCalendarShowDot();
    this.setShowHiddenArrowScroll();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.eventsData = this.dataSource;
    this.changeLanguage();
    this.createCalendar();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkHeightCalendarShowDot();
    this.setShowHiddenArrowScroll();
  }

  //Set show/ hidden arrow scroll.
  setShowHiddenArrowScroll() {
    const height = document.getElementById('cont')?.offsetHeight;
    const width = document.getElementById('cont')?.offsetWidth;

    if (height >= 670) {
      this.isScrollCalendar = false;
    } else {
      this.isScrollCalendar = true;
    }
  }

  checkHeightCalendarShowDot() {
    const height = document.getElementById('cont')?.offsetHeight;
    const width = document.getElementById('cont')?.offsetWidth;

    // TODO: if small only show badges not all the events
    if (height <= 400 || width <= 576) {
      this.isSmall = true;
    } else {
      this.isSmall = false;
    }
  }

  createCalendar() {
    this.arrTest = [];
    this.arrCalendar = [];
    this.firstDay = new Date(this.currentYear, this.currentMonth).getUTCDay();
    this.daysInMonth = this.getDaysInMonth(this.currentMonth, this.currentYear);
    this.daysInLastMonth = this.getDaysInMonth(
      this.currentMonth - 1,
      this.currentYear
    );

    const lmd = this.daysInLastMonth - (this.firstDay); //custom index column header day

    // Last month days
    for (let index = lmd; index <= this.daysInLastMonth; index++) {
      this.arrTest.push({
        day: index,
        month: this.currentMonth - 1,
        year: this.currentYear,
      });
    }

    // Actual month
    for (let index = 1; index <= this.daysInMonth; index++) {
      const filterEvents = this.eventsData?.filter(event => {
        return (
          event.startDate <
          new Date(
            this.currentYear,
            this.currentMonth,
            index + 1
          ).getTime() &&
          event.endDate >=
          new Date(this.currentYear, this.currentMonth, index).getTime()
        );
      });

      this.arrTest.push({
        day: index,
        month: this.currentMonth,
        year: this.currentYear,
        events: filterEvents
      });

    }

    for (let i = this.arrTest.length, j = 1; i < 42; i++, j++) {
      this.arrTest.push({
        day: j,
        month: this.currentMonth + 1,
        year: this.currentYear,
      });
    }

    for (let i = 0; i < 6; i++) {
      const arrWeek = this.arrTest.splice(0, 7);
      this.arrCalendar.push(arrWeek);
    }

  }

  getDaysInMonth(iMonth, iYear) {
    return 32 - new Date(iYear, iMonth, 32).getDate();
  }

  previousMonthButtonClick() {
    this.actionSwitchAnimation = 'prev';
    if (this.currentMonth === 0) {
      this.currentYear -= 1;
      this.currentMonth = 11;
    } else {
      this.currentMonth -= 1;
    }

    this.actDate = this.creatActMonthYear();
    this.animationSwitchMonth();
    this.refreshData.emit(this.actDate);

    //this.createCalendar();
  }

  nextMonthButtonClick() {
    this.actionSwitchAnimation = 'next';
    if (this.currentMonth === 11) {
      this.currentYear += 1;
      this.currentMonth = 0;
    } else {
      this.currentMonth += 1;
    }

    this.actDate = this.creatActMonthYear();
    this.animationSwitchMonth();
    this.refreshData.emit(this.actDate);
    //this.createCalendar();
  }

  animationSwitchMonth() {
    this.isSwitchMonth = true;
    setTimeout(() => {
      this.isSwitchMonth = false;
    }, 300);
  }
  // Dialog test
  // TODO: return the selected value
  openDialog(event) {
    this.dayEvents.next(event);
  }

  changeLanguage() {
    this.months = mounthsEN;
    this.weekdays = weekdayEN;
  }

  onYearChange(event) {
    this.actionSwitchAnimation = 'clickChange';
    this.currentYear = Number(event.value);

    this.actDate = this.creatActMonthYear();
    this.animationSwitchMonth();
    this.refreshData.emit(this.actDate);

    // this.createCalendar();
  }

  onMonthChange(event) {
    this.actionSwitchAnimation = 'clickChange';
    this.currentMonth = Number(event.value);

    this.actDate = this.creatActMonthYear();
    this.animationSwitchMonth();
    this.refreshData.emit(this.actDate);

    // this.createCalendar();
  }

  creatActMonthYear() {
    const actDate = formatDate(
      new Date(this.currentYear, this.currentMonth),
      'yyyy. MMMM',
      'en'
    );

    return actDate;
  }

  addEventClicked() {
    const testMessage = `${this.currentYear}-${this.currentMonth}-${this.actDay}`;
    this.newEvent.next(testMessage);
  }

  onScroll(event) {
    if (event) {
      const height = document.getElementById('cont')?.offsetHeight;
      var e = event.target;
      let scroll = e.scrollTop;
      if (this.isScrollCalendar && +scroll > 0) {
        this.isScrollCalendar = false;
      }

      clearTimeout(this.sTimeOutScroll);
      this.sTimeOutScroll = setTimeout(() => {
        if (+scroll == 0 && height < 670)
          this.isScrollCalendar = true;
        else
          this.isScrollCalendar = false;
      }, 200)
    }
  }

  clickChangeView() {
    this.isFullView = !this.isFullView;
    this.viewSeenCalendarEvent.next(this.isFullView);
  }

  clickDays(event) {
    console.log(event);
  }
}
