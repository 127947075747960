import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddExternalActivityLogComponent } from '../components/add-external-activity-log/add-external-activity-log.component';
import { TblActionType } from '../enums/tbl-action-type.enum';

@Directive({
  selector: '[appAddActivityLogBtn]'
})
export class AddActivityLogBtnDirective {

  @Input() id: string;
  @Input() type: string;

  @Output() onRefresh: EventEmitter<void> = new EventEmitter();
  constructor(
    private dialog: MatDialog
  ) { }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.id && this.type) {
      const dialogRef = this.dialog.open(AddExternalActivityLogComponent, {
        disableClose: true,
        height: '100vh',
        width: '600px',
        position: { right: '0' },
        panelClass: 'dialog-detail',
        autoFocus: false,
        data: {
          actionDialog: TblActionType.Add,
          objId: this.id,
          objName: this.type,
        }
      })

      dialogRef.afterClosed().subscribe(res => {
        if (res) this.onRefresh.emit();
      })
    }
  }
}