<mat-autocomplete (optionSelected)="choiceSelected($event)">
  <ng-container *ngIf="(filterObservable | async)?.length > 0; else noItemsTemplate">
    <mat-option *ngFor="let item of filterObservable | async" [value]="item[valueProperty]">
      <span>
        <span>{{ item[keyProperty] }}</span>
      </span>
    </mat-option>
  </ng-container>
  <ng-template #noItemsTemplate>
    <mat-option *ngIf="isLoading == false" [disabled]="true">
      <b [ngClass]="titleSearchEmpty != '' ? 'message-search-result' : '' " class="font-weight:500"
          (click)="dataIsEmpty()">{{titleSearchEmpty != '' ? titleSearchEmpty :'No results found'}}</b>
    </mat-option>
  </ng-template>
</mat-autocomplete>