import { digit, numeric, prop } from "@rxweb/reactive-form-validators";

export class ContactTypeModel {
    constructor() {
        this.contactTypeId = 0;
        this.parentId = 0;
    }
    @prop()
    contactTypeId: number | null;
    @prop()
    contactTypeName: string | null;
    @prop()
    contactTypeColorCode: string | null;
    deleted: boolean;
    dateDeleted: Date | string | null;
    totalUse: number | null;
    @prop()
    subContact: string | null;
    @prop()
    display: boolean | null = true;
    @prop()
    @digit()
    parentId: number = 0;
    @prop()
    dataType: string;
}
export class ListDataContactType{
    activityLogId:number;
    contactContactTypeId:number;
    contactTypeName: string;
    contactTypeColorCode: string;
} 
export class EmailRelationship{
    id: string;
    type: string;
    displayName: string;
    lastModified: string;
    primary: boolean = false;
    colorCode?: string = null;
    displayTypeName?: string = null;
    typeRedirect?:string = null;
}
export class LookupEmailRelationship{
    email:string;
    contactId: number;
    relationship: EmailRelationship[] = [];
}
export class LookUpListEmailOutlook{
    contacts: LookupEmailRelationship[];
    domainLookup: EmailRelationship;
}
export class ListEmailContact{
    emails:string[] = [];
}