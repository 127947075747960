import { AutomateDataStateDataField } from './../profile-management/profile-detail/automate-datastate-log/automate-datastate-model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page } from 'src/app/shared/models/paging/page';
import { environment } from 'src/environments/environment';
import { Subject, Observable, ReplaySubject, BehaviorSubject } from 'rxjs';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { Console } from 'console';
import { Datastate, DatastateChain, DatastateDatafield } from './datastate-management.model';

@Injectable({
  providedIn: 'root'
})
export class DatastateManagementService {
  baseUrl = environment.apiDatastateManagement;
  private _automateDatastateDatafield: ReplaySubject<Property[]> = new ReplaySubject<Property[]>(1);
  private _datastateList$: ReplaySubject<Datastate[]> = new ReplaySubject<Datastate[]>(1);
  private _dataFieldList$: ReplaySubject<AutomateDataStateDataField[]> = new ReplaySubject<AutomateDataStateDataField[]>(1);
  constructor(private http: HttpClient) {
  }

  //Automate Datastate
  getPagingAutomateDataState(page: Page): Observable<ReturnResult<PagedData<Datastate>>> {
    return this.http.post<ReturnResult<PagedData<Datastate>>>(`${this.baseUrl}/GetPagingAutomateDataState`, page);
  }
  saveAutomateDatastate(model: Datastate): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveAutomateDataState`, model);
  }

  deleteAutomateDatastate(id: number): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/DeleteAutomateDataState/${id}`);
  }

  deleteListAutomateDatastate(listId: number[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteListAutomateDataState`, listId);
  }
  datastateList(): Observable<Datastate[]> {
    return this._datastateList$.asObservable();
  }
  allDataFieldList() {
    return this._dataFieldList$.asObservable();
  }
  getAllDatastateList() {
    this.http.get<ReturnResult<Datastate[]>>(`${this.baseUrl}/GetDatastateList`).subscribe(resp => {
      if (resp.result) {
        this._datastateList$.next(resp.result);
      }
    });
  }
  getAutomateDataState(type: string = null) {
    return this.http.get<ReturnResult<Datastate[]>>( !type ? `${this.baseUrl}/GetDatastateList` : `${this.baseUrl}/GetDatastateList?type=${type}` );
  }
  //Automate Datastate Datafield
  getPagingAutomateDataStateDataField(page: Page): Observable<ReturnResult<PagedData<DatastateDatafield>>> {
    return this.http.post<ReturnResult<PagedData<DatastateDatafield>>>(`${this.baseUrl}/GetPagingAutomateDataStateDataField`, page);
  }
  saveAutomateDataStateDataField(model: DatastateDatafield): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveAutomateDataStateDataField`, model);
  }
  //2022-05-19 thoainda add start
  // add list
  saveListAutomateDataStateDataField(model: DatastateDatafield): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/saveListAutomateDataStateDataField`, model);
  }
  //2022-05-19 thoainda add end
   deleteAutomateDataStateDataField(id: number): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/DeleteAutomateDataStateDataField/${id}`);
  }

  deleteListAutomateDataStateDataField(listId: number[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteListAutomateDataStateDataField`, listId);
  }

  //datastate chain
  getPagingDataStateChain(page: Page): Observable<ReturnResult<PagedData<DatastateChain>>> {
    return this.http.post<ReturnResult<PagedData<DatastateChain>>>(`${this.baseUrl}/GetPagingDataStateChain`, page);
  }
  saveDataStateChain(model: DatastateChain): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveDataStateChain`, model);
  }

  deleteDataStateChain(id: number): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/DeleteDataStateChain/${id}`);
  }

  deleteListDataStateChain(listId: number[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteListDataStateChain`, listId);
  }
  unautomateDatastateDataField () : Observable<Property[]>{
    return this._automateDatastateDatafield.asObservable();
  }
  
}
class Property { key: string; value: string; children?: any[] }
