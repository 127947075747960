import { AutomateDatastateService } from 'src/app/modules/admin/profile-management/profile-detail/automate-datastate-log/automate-datastate.service';
import { NumberFormatPipe } from './../../../../shared/pipes/number-format.pipe';
import { Clipboard } from '@angular/cdk/clipboard';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { NbPopoverDirective, NbToastrService } from '@nebular/theme';
import { async, NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators';
import { QuillEditorComponent } from 'ngx-quill';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { Note, NoteDetails } from 'src/app/shared/components/note-management/noteManagement.model';
import { NoteTabComponent } from 'src/app/shared/components/note-tab/note-tab.component';
import { QuillConfiguration } from 'src/app/shared/components/stand-alone-component/rich-inline-edit/rich-inline-edit.component';
import { EntityColorEnums } from 'src/app/shared/enums/entity-color.enums';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { CallService } from 'src/app/shared/services/call.service';
import { Helper } from 'src/app/shared/utility/Helper';
import { Contact, KeyPairsValue, ProfileAdditionDetail, ProfileDetailModel, RecommendContactInfo } from '../../profile-management/profile-detail.model';
import { LinkscopeEmailComponent } from '../../profile-management/profile-detail/action-block/linkscope-email/linkscope-email.component';
import { AddEditTaskComponent } from '../../task-management/add-edit-task/add-edit-task.component';
import { TaskBoardService } from '../../task-management/task-board/task-board.service';
import { ConvertSaleLeadComponent } from '../convert-sale-lead/convert-sale-lead.component';
import { SaleLeadService } from '../sale-lead.service';
import { CallStatusModalComponent } from '../../task-management/call-status-modal/call-status-modal.component';
import { SignalrDynamicService } from 'src/app/shared/services/signalr-dynamic.service';
import { Setting } from 'src/app/shared/models/setting.model';
import { SettingService } from 'src/app/shared/services/setting.service';
import { permissionGridNoteSaleLead, permissionSaleLeadFile } from 'src/app/shared/contances/permission';
import { DatastateManagementService } from '../../datastate-management/datastate-management.service';
import { OverlayNoteDetailsComponent } from 'src/app/shared/components/note-management/overlay-note-details/overlay-note-details.component';
import { NoteManagementService } from 'src/app/shared/components/note-management/note-management.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ShadowProfileEnum } from 'src/app/shared/enums/shadow-profile.enum';
import { UserModel } from '../../user-management/user-model';
import { UserService } from '../../user-management/user.service';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { DynamicContentModel } from 'src/app/shared/models/dynamic-content.model';
import { DynamicContentService } from 'src/app/shared/services/dynamic-content.service';
import { UserUpload } from '../../user-upload-management/user-upload.model';
import { UserUploadManagementService } from '../../user-upload-management/user-upload-management.service';
import { HotToastService } from '@ngneat/hot-toast';
import { ProfileFileTabComponent } from 'src/app/shared/components/stand-alone-component/profile-file-tab/profile-file-tab.component';
import { LocationComponent } from 'src/app/shared/components/stand-alone-component/location/location.component';
import { LocationGridComponent } from '../../sale-account-management/sale-account-details/location-grid/location-grid.component';
import { TaskGridByProfileIdComponent } from './task-grid-by-profile-id/task-grid-by-profile-id.component';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
// import { AdminTabModeService } from 'src/app/shared/components/stand-alone-component/admin-tab-mode/admin-tab-mode.service';
// import { TabTagIndexModel } from 'src/app/shared/components/stand-alone-component/admin-tab-mode/tab-mode.model';
// import { ChangeUrlObjectsDirective } from 'src/app/shared/directives/change-url-objects.directive';
import { SettingPoolService } from 'src/app/shared/services/setting-pool.service';
import { environment } from 'src/environments/environment';
import { PrimasSendSMSComponent } from 'src/app/shared/components/template-management/primas-send-sms/primas-send-sms.component';
import { UploadUrlComponent } from 'src/app/shared/components/stand-alone-component/profile-file-tab/upload-url/upload-url.component';
import { UserExperienceTrackingService } from 'src/app/shared/components/user-experience-tracking/user-experience-tracking.service';
import { ProfileType } from '../../opportunity-management/opportunity-details/opportunity-details.component';
import { MicrosoftOffice365Service } from '../../../../shared/services/microsoft-office-365.service';
import { PhoneExtensionPipe } from 'src/app/shared/pipes/phone-extension.pipe';
import { ProfileAdditionDetailService } from 'src/app/shared/services/profile-addition-detail.service';

@Component({
  selector: 'app-sale-lead-details',
  templateUrl: './sale-lead-details.component.html',
  styleUrls: ['./sale-lead-details.component.scss'],
})
export class SaleLeadDetailsComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @Input() id: string = null;
  @Input() optionalTaskId: string;
  @Input() tooltipProp: KeyPairsValue[] = [];
  @Input() creatableTask = true;
  @Input() openByDialog = true;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();
  @Output() onAdvancedImport: EventEmitter<any> = new EventEmitter();
  @Output() refreshTask: EventEmitter<void> = new EventEmitter();
  @Input() onNoteInlineChanged: EventEmitter<boolean>;

  @ViewChild('popupEditor') popupEditor: TemplateRef<any>;
  quillEditor: QuillEditorComponent;
  @ViewChild('quillEditor', { static: false }) set setQuillEditor(content: QuillEditorComponent) { if (content) this.quillEditor = content };
  noteTab: NoteTabComponent;
  @ViewChild('gridNoteTab', { static: false }) set setGridNoteTab(content: NoteTabComponent) { if (content) this.noteTab = content };
  fileTab: ProfileFileTabComponent;
  @ViewChild('profileFileTab', { static: false }) set setProfileFileTab(content: ProfileFileTabComponent) { if (content) this.fileTab = content }
  locationGrid: LocationGridComponent;
  @ViewChild('locationGrid', { static: false }) set setLocationGrid(content: LocationGridComponent) { if (content) this.locationGrid = content };

  nbMailPopover: NbPopoverDirective;
  @ViewChild('sendMailBtn', { static: false }) set setSendMailBtn(content: NbPopoverDirective) { if (content) this.nbMailPopover = content }

  makeCallPopover: NbPopoverDirective;
  @ViewChild('makeCallBtn', { static: false }) set setMakeCallBtn(content: NbPopoverDirective) { if (content) this.makeCallPopover = content }

  sendSMSPopover: NbPopoverDirective;
  @ViewChild('sendSMSBtn', { static: false }) set setSendSMSBtn(content: NbPopoverDirective) { if (content) this.sendSMSPopover = content }

  taskGrid: TaskGridByProfileIdComponent;
  @ViewChild('taskGrid', { static: false }) set setTaskGrid(content: TaskGridByProfileIdComponent) { if (content) this.taskGrid = content };

  // @ViewChild(ChangeUrlObjectsDirective) changeUrlObjectDir: ChangeUrlObjectsDirective;

  nbFilesPopover: NbPopoverDirective;
  @ViewChild('nbFilesBtn', { static: false }) set setFilesPopoverBtn(content: NbPopoverDirective) { if (content) this.nbFilesPopover = content }

  environment = environment;
  saleLeadModel: ProfileDetailModel;
  isLoading: boolean = false;
  isLoadingEdit: boolean = false;
  isRefreshing: boolean = false;
  isSaleLead: boolean = false;
  saluationList = ['Mr.', 'Mrs.', 'Ms.', 'Miss.', 'Dr.'];
  editorOptions = QuillConfiguration;
  dialogQuillEditor: MatDialogRef<any, any>;
  contentQuillEditor = "";
  user;
  isCalling = false;
  isShowNoteDetails: boolean = false;
  listNote: Note[] = [];
  popUpSetting: Setting;
  isCallLoading = false;
  prefixCurrency: string;
  addNewTask = AddEditTaskComponent;
  public get TypeEditModelSaleLead(): typeof TypeEditModelSaleLead { return TypeEditModelSaleLead; }
  validProperties = {
    //contactName: { valid: [RxwebValidators.required()], message: "This field is required" },
    //contactLastName: { valid: [RxwebValidators.required()], message: "This field is required" },
    contactEmail: { valid: [RxwebValidators.email()], message: "Email is not valid" },
    //contactPhone: { valid: [RxwebValidators.pattern({ expression: { phone: /^[(]?(\d|\+)\s?(?:[\d-.x\s()]*)$/ } })], message: "Phone is not valid" },
    annualRevenue: {
      valid: [
        RxwebValidators.required(),
        RxwebValidators.numeric({
          acceptValue: NumericValueType.Both,
          allowDecimal: true
        })
      ],
      message: "Annual revenue is not valid",
    },
    phoneExt: { valid: [RxwebValidators.maxLength({ value: 6 }), RxwebValidators.digit()], message: "Phone Extension is not valid" },
  }
  selectTab: TabSaleLeads = TabSaleLeads.Address;
  colorCode = EntityColorEnums;
  isCopy = false;
  isTestingModel: boolean;
  theLastAsteriskCallStatus = null;
  private routeSub: Subscription;
  isShowButton: boolean = false;
  resource = permissionGridNoteSaleLead;
  stageLst = [];
  overlayNoteDetails: OverlayNoteDetailsComponent;
  lastNote: NoteDetails;
  ownerModel: UserModel;
  apiGetSearchOwner: (data) => void = (data) => this.userService.searchUser(data);
  isTabMode = Helper.checkTabMode();
  dynamicContentLeadSourceAPI: Observable<ReturnResult<DynamicContentModel[]>>
    = this.dynamicContentService.getDynamicContentByType('opportunity-leadsource');

  dynamicContentPhoneNumber: Observable<ReturnResult<DynamicContentModel[]>>
    = this.dynamicContentService.getDynamicContentByType('general_phone');

  dynamicContentWebsite: Observable<ReturnResult<DynamicContentModel[]>>
    = this.dynamicContentService.getDynamicContentByType('general_webpage');

  dynamicContentEmail: Observable<ReturnResult<DynamicContentModel[]>>
    = this.dynamicContentService.getDynamicContentByType('general_email');

  dynamicContentCompanyAPI: Observable<ReturnResult<DynamicContentModel[]>>;
  private destroy$: Subject<void> = new Subject<void>();
  showHeaderUrl: string[] = ['/configuration/email-history', '/configuration/sale-lead'];
  uploadedFile: any;
  uploading: boolean = false;
  fileResource = permissionSaleLeadFile;
  contacts: Contact[] = null;
  resetContacts: () => void = () => this.contacts = null;

  //THIS IS A TOPIC DATA TO VERIFY THE SALESLEAD URL
  topic = 'sale-lead';
  qualifiedTypeId: number = null;
  listChange = []; //this list is for the new logic placeholder value
  smsHistory: any[] = [];
  checkingOneDriveDataSubscription: Subscription;
  isDoingCheckingOneDriveData: boolean = false;
  isCRMMode: string = "0";
  lastContact: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialModalRef: MatDialogRef<SaleLeadDetailsComponent>,
    private saleLeadService: SaleLeadService,
    private dialog: MatDialog,
    private toast: NbToastrService,
    private callService: CallService,
    private signalrService: SignalrDynamicService,
    private authService: NbAuthService,
    private router: Router,
    private taskService: TaskBoardService,
    private clipboard: Clipboard,
    public numberPipe: NumberFormatPipe,
    public automatService: DatastateManagementService,
    private settingsService: SettingService,
    private settingPoolService: SettingPoolService,
    private route: ActivatedRoute,
    public noteService: NoteManagementService,
    private userService: UserService,
    private dynamicContentService: DynamicContentService,
    private userUploadService: UserUploadManagementService,
    private hotToast: HotToastService,
    // private tabMode: AdminTabModeService,
    private userExperienceTrackingService: UserExperienceTrackingService,
    private microsoftOffice365Service: MicrosoftOffice365Service,
    public phoneExtPipe: PhoneExtensionPipe,
    private profileAdditionDetailService: ProfileAdditionDetailService,
  ) {
    if (data.model) {
      this.id = data.model.profileId;
      //if (this.router.url.includes('configuration/sale-lead')) Helper.cancelNavigate(`configuration/sale-lead/${this.id}`);
      if (this.data.model.topic) {
        this.topic = this.data.model.topic;
      }
    }
    this.id = this.data?.model?.profileId ?? this.id;
    this.isLoading = true;
    if (this.id) {
      this.refreshData();
    }

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload();
        }
      });
    if (this.router.url.includes('/configuration/task') && this.router.url.includes('/configuration/dashboard-crm')) {
      this.creatableTask = false;
    }
    this.dynamicContentCompanyAPI = this.dynamicContentService.getDynamicContentByType('general_company');
    if (this.router.url.includes('/configuration/sale-lead') ||
      this.router.url.includes('/configuration/email-history') ||
      this.router.url.includes('/configuration/email-inbox') ||
      this.router.url.includes('/configuration/history-data') ||
      this.router.url.includes('/configuration/email-campaign') ||
      this.router.url.includes('/configuration/dashboard-contact-map') ||
      this.router.url.includes('/configuration/sale-convert-history') ||
      this.router.url.includes('/configuration/import-contact') ||
      this.router.url.includes('/configuration/activity-log')
    ) {
      this.isShowButton = true;
    }
    this.settingsService.getSettingByKeyAndGroup("IS_CRM_MODE", "SYSTEM").subscribe(resp => {
      if (resp?.result){
        this.isCRMMode = resp.result.value.toString();
      }
    })
  }

  ngOnInit(): void {
    this.userService.getAllUser();
    if (this.openByDialog && this.dialModalRef?.componentInstance) {
      if (this.isTabMode) this.dialModalRef.afterOpened().subscribe(() => {
        var overlayBackdrops = window.document
          .querySelectorAll<any>('.cdk-overlay-backdrop.cdk-overlay-dark-backdrop.cdk-overlay-backdrop-showing');
        for (var i = 0; i < overlayBackdrops.length; i++)
          overlayBackdrops[i].classList.add('overlay-backdrop-tab-mode');
      })

      this.dialModalRef.updatePosition({ right: '0', bottom: '0' });
      if (this.data && !this.data.advancedImport) {
        this.dialModalRef.updateSize(this.openByDialog ? '1200px' : '1600px', Helper.heightDialog());
      }
      else {
        this.dialModalRef.updateSize(this.openByDialog ? '1400px' : '1600px', Helper.heightDialog());
      }
    }

    // this.taskService.getAllTaskStatus();
    // this.taskService.getAllPriority();
    // this.taskService.getAllTaskType();
    this.getAutomateDataState();
    this.formatCurrencyPrefix();
    this.getLastNote();
    // 2023-01-02 ducqm start add
    // this.settingsService.getSettingByKeyAndGroup("QUALIFIED_TYPE_ID", "SALE_CONFIG").pipe(takeUntil(this.destroy$)).subscribe(res => {
    //   if (res.result && res.result.value && parseInt(res.result.value) != NaN) {
    //     this.qualifiedTypeId = parseInt(res.result.value);
    //   }
    // });
    this.settingPoolService.getSettingByKeyAndGroup("QUALIFIED_TYPE_ID", "SALE_CONFIG").pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res.result && res.result.value && !Number.isNaN(parseInt(res.result.value))) {
        this.qualifiedTypeId = parseInt(res.result.value);
      }
    });
    // 2023-01-02 ducqm end add
  }

  ngAfterViewInit(): void {
    this.overlayNoteDetails = this.noteService.overlayNoteDetailsComponent;

    if (this.router.url.includes('/configuration/sale-lead')) {
      // get cache tab index in local storage:
      let tag = this.getCacheTab();
      let patternRegex = /#([\d]+)/;
      if (!Helper.isNullOrEmpty(tag)) {
        this.selectTab = parseInt(tag.replace('#', ''));
        Helper.handleTabChangedUrl(this.selectTab, '/configuration/sale-lead');
      } else
        // if end with #tabIndex then move to that tab
        if (patternRegex.test(this.router.url)) {
          let matchTabIndex = this.router.url.match(patternRegex).length > 0 ? Number.parseInt(this.router.url.match(patternRegex)[0].replace('#', '')) : TabSaleLeads.Address;
          this.selectTab = matchTabIndex;

          this.userExperienceTrackingService.saveTagIndexChangeInUrl(matchTabIndex);
        }
    } else {
      this.selectTab = this.openByDialog ? TabSaleLeads.Address : TabSaleLeads.ActivityLog;
    }
    this.saleLeadService.getSaleLeadById()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: resp => { if (resp.result) this.setupSaleLead(resp.result); },
        complete: () => this.isLoading = false,
      });

    this.saleLeadService.isRefreshSaleGrid$.asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((isRefresh: boolean) => this.isRefreshing = isRefresh);

    this.overlayNoteDetails?.onRefreshed()?.pipe(
      takeUntil(this.destroy$),
      debounceTime(2000)
    ).subscribe(resp => {
      this.overlayNoteDetails.completedLoading();
      if (resp && this.noteTab) this.noteTab.refreshData();
      this.getLastNote(resp?.id, resp?.isDeleted);
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    const changeId = changes.id;
    if (changeId && changeId.currentValue != changeId.previousValue)
      this.refreshData();
  }

  ngOnDestroy(): void {
    if (this.routeSub != null)
      this.routeSub.unsubscribe();

    if (this.saleLeadService.saleLeadDetail$)
      this.saleLeadService.saleLeadDetail$.next(new ReturnResult<any>());

    this.destroy$.next();
    this.destroy$.complete();
  }

  refreshData() {
    this.saleLeadService.refreshSaleLeadById(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: resp => { if (resp.result) this.setupSaleLead(resp.result); },
        complete: () => {
          this.isLoading = false
          this.getOwner();
        },
      });
  }

  setupSaleLead(data: ProfileDetailModel) {
    if (data) {
      this.saleLeadModel = data;
      this.saleLeadModel.tagList = this.saleLeadModel.tags !== '' && this.saleLeadModel.tags != null
        ? this.saleLeadModel.tags.split(',') : [];
      this.lastContact = this.saleLeadModel?.profileAdditionDetail?.lastContact || "Never Contact";
      // var currentStage;
      // if (this.saleLeadModel.automateDataStateId)
      //   currentStage = this.stageLst.find(x =>
      //   parseInt(x.automateDataStateId) == this.saleLeadModel?.automateDataStateId ?? 0
      // );

      // this.listChange.forEach(item => {
      //   //get the first index in the list
      //   var index = this.listChange.findIndex(x => x.order == item.order)
      //   //get the first index of the placeholder value
      //   var placeholderIndex = this.listChange.findIndex(x => x.order == item.order && !x.automateDataStateId)

      //   if (currentStage?.order) {
      //     if (item.order > currentStage?.order) {
      //       if (placeholderIndex > index) {
      //         //swap placeholder value and the first item in the same order
      //         [this.listChange[index]] = this.listChange.splice(placeholderIndex, 1, this.listChange[index])
      //       }
      //     }
      //     else if (item.order < currentStage?.order) {
      //       if (placeholderIndex == index) {
      //         //swap placeholder value and the value behind it
      //         [this.listChange[index]] = this.listChange.splice(index + 1, 1, this.listChange[index])
      //       }
      //     }
      //   }
      // })
      // //re-render the progress bar
      // this.stageLst = [...this.listChange]
    }
  }

  async editAccountContact(value: any, prop: string, typeModel: TypeEditModelSaleLead = TypeEditModelSaleLead.SaleLead) {
    if (!prop) {
      this.toast.danger('Failed');
      return;
    }

    let saleLeadRequest: ProfileDetailModel = Object.assign({}, this.saleLeadModel, { [prop]: value });
    let contactRequest: Contact = Object.assign({}, this.saleLeadModel.saleLeadsContact, { [prop]: value }, { profileParentId: this.id });

    const isEnableCoreRemoting = (await this.settingsService.getSettingByKeyAndGroup("IS_ENABLE_CORE_REMOTING", "OFFICE_365_SSO").toPromise())?.result?.value.toString();
    this.isLoadingEdit = true;
    this.saleLeadService.editSaleLead({
      id: typeModel == TypeEditModelSaleLead.SaleLead
        ? this.saleLeadModel.profileId
        : this.saleLeadModel.saleLeadsContact.contactId.toString(),
      saleLeadModel: typeModel == TypeEditModelSaleLead.SaleLead
        ? saleLeadRequest : null,
      contactModel: typeModel == TypeEditModelSaleLead.Contact
        ? contactRequest : null,
    }).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: resp => {
          if (resp.result) {
            if (prop == "displayName" && isEnableCoreRemoting != null && parseInt(isEnableCoreRemoting) == 0)
              this.microsoftOffice365Service.updateNameFolder(
                this.saleLeadModel.profileId,
                this.saleLeadModel.typeName,
                this.saleLeadModel.displayName, value).subscribe();

            if (prop != 'displayName')
              this.toast.success(`Edit ${this.environment.titleLead} success!`, "Success");

            if (prop == 'contactName' || prop == 'contactLastName')
              this.specialEditPropDisplayName(contactRequest);

            if (prop == "ownerId") this.ownerModel = null;
            this.refreshData();
          }
        },
        complete: () => this.isLoadingEdit = false
      });
  }
  //When edit contact name or contact last name need update display name.
  specialEditPropDisplayName(contactRequest: Contact) {
    let value = `${contactRequest.contactName ?? ""} ${contactRequest.contactLastName ?? ""}`
    value = value.trim() ? value : "Unknown";
    this.editAccountContact(value, 'displayName', TypeEditModelSaleLead.SaleLead);
  }

  async closeDialog() {
    if (this.dialModalRef?.componentInstance)
      this.dialModalRef.close(this.isRefreshing);
    this.onClose.emit(this.isRefreshing);

    if (Helper.checkUrlDetailObj('sale-lead')) {
      // var urlBack = Helper.popBackURL() || `/configuration/sale-lead`;
      // this.router.navigate([urlBack]);

      var urlBack = Helper.popBackURL() || `/configuration/sale-lead`;
      this.userExperienceTrackingService.navigatePrevious(`/configuration/sale-lead`);
    }
  }

  openComponentEditor(model: string, prop: string) {
    this.dialogQuillEditor = this.dialog.open(this.popupEditor, {
      disableClose: true,
      autoFocus: false,
      width: '60vw',
      data: this.saleLeadModel[model][prop]
    });

    this.dialogQuillEditor.afterClosed().subscribe(resp => {
      if (resp) this.editAccountContact(resp, prop, TypeEditModelSaleLead.Contact)
    });
  }

  setFocus(editor, data) {
    if (this.dialogQuillEditor) {
      const width = this.dialogQuillEditor._containerInstance._config.width;
      let toolbarEditor = this.quillEditor.elementRef.nativeElement.getElementsByClassName("ql-toolbar ql-snow")[0];
      let containerEditor = this.quillEditor.elementRef.nativeElement.getElementsByClassName("ql-container ql-snow")[0];
      toolbarEditor.style = `position: absolute; width: calc(${width} - 48px); background-color: snow; z-index: 100;`;
      containerEditor.style = `padding-top: 2.625rem; margin-bottom: 1px;`;
      this.contentQuillEditor = data;
      editor.focus();
    }
  }

  saveEditor() {
    if (this.dialogQuillEditor) {
      if (this.contentQuillEditor && this.contentQuillEditor !== "")
        this.closeDialogEditor(this.contentQuillEditor);
      else this.toast.danger("Content error or empty!", "Error");
    }
  }

  closeDialogEditor(data?: string) {
    if (this.dialogQuillEditor) this.dialogQuillEditor.close(data);
  }

  convertHtmlToNormalStyle(data: string) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = data;
    return tmp.textContent || tmp.innerText || "";
  }

  copyToClipboard() {
    const url = window.location.href.split('configuration')[0] + `configuration/sale-lead/${this.id}`;
    this.clipboard.copy(url);
    this.toast.info(`Copied this ${this.environment.titleLead} url to clipboard`, 'Success');
  }

  sendMailClick(model: ProfileDetailModel = null) {
    const dialogRef = this.dialog.open(LinkscopeEmailComponent, {
      disableClose: true,
      autoFocus: false,
      data: {
        profileModel: model ?? this.saleLeadModel,
        entity: 'profile',
        optionTaskId: this.optionalTaskId,
        isShowSenderId: true,
        lastSentEmailEntity: this.environment.titleLead
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response != null) {
        // return when closed
      }
    });
  }

  async onClickCall(contact: Contact = null) {
    this.isCallLoading = true;
    await this.callService.makeACall(
      contact ?? this.saleLeadModel.contact,
      this.saleLeadModel.profileContacts,
      this.user.nameid,
      this.saleLeadModel.profileId,
      this.creatableTask,
      this.optionalTaskId ? parseInt(this.optionalTaskId) : null,
      this.isCallLoading,
      this.toast,
      this.isCalling,
      'profile'
    );
    setTimeout(() => {
      this.isCallLoading = false;
    }, 3000);
  }

  createTask() {
    if (this.addNewTask) {
      const dialogRef = this.dialog.open(this.addNewTask, {
        disableClose: true,
        height: '100vh',
        width: '600px',
        panelClass: 'dialog-detail',
        autoFocus: false,
        data: {
          action: TblActionType.Add,
          profile: this.saleLeadModel,
        }
      });
      dialogRef.afterClosed().subscribe(response => {
        if (response) {
          // this.toast.success('Create task success!', 'Success');
          this.refreshData();
          if (this.taskGrid) this.taskGrid.refreshData();
        }
      });
    }
  }
  copyGUIDToClipboard() {
    this.clipboard.copy(this.id);
    this.toast.info(`Copied this ${this.environment.titleLead} ID to clipboard`, 'Success');
    this.isCopy = true;
    setTimeout(() => {
      this.isCopy = false;
    }, 2000);
  }

  clickAddNote(data?: any) {
    if (!this.listNote) this.listNote = [];
    let newNote: Note = data
      ? Object.assign(data)
      : Object.assign({ id: 0, title: 'Untitled', } as Note)

    this.listNote.push(newNote);
    this.listNote = [...this.listNote];
    this.isShowNoteDetails = true;
  }

  openConvertSaleLeadModal() {
    let dialogRef = this.dialog.open(ConvertSaleLeadComponent, {
      disableClose: true,
      width: '50vw',
      data: {
        model: this.saleLeadModel,
      }
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        if (this.dialModalRef?.componentInstance) {
          // this.changeUrlObjectDir.isClose = true;
          // this.changeUrlObjectDir.href = window.location.pathname;
          // this.changeUrlObjectDir.clickCloseElement(this.changeUrlObjectDir.href);
          this.closeDialog();
        }

      }
    })
  }

  async getAutomateDataState() {
    try {
      const data = await this.automatService.getAutomateDataState("LEAD").toPromise();
      if (data.result) {
        this.stageLst = data.result;

        // var currentStage;
        // if (this.saleLeadModel.automateDataStateId)
        //   currentStage = this.stageLst.find(x =>
        //   parseInt(x.automateDataStateId) == this.saleLeadModel?.automateDataStateId ?? 0
        // );

        // //check if already added the placeholder value in the list
        // var alreadyAddFlag = []
        // this.stageLst.forEach(x => {
        //   //get current order
        //   var order = x.order;

        //   //get the list has the same order as curret order
        //   var sameOrderList = this.stageLst.filter(x => x.order == order);

        //   //check if the list not added the placeholder value in the list
        //   if (alreadyAddFlag.filter(x => x == order)?.length <= 0) {
        //     if (sameOrderList?.length > 1) {
        //       //get index from the list has the same order or placeholder value
        //       var index = this.stageLst.findIndex(x => x.order == order || x.groupPlaceholder == sameOrderList[0].groupPlaceholder);

        //       if (this.stageLst[index]) {
        //         //if order < current order, it will add right befind the first item has the same order
        //         if (order < currentStage?.order) {
        //           index += 1;
        //         }
        //         //add the placeholder in the list
        //         //note: check the displayText in <app-chevron-progress-bar /> in html, if it has then u must add it as key for the placeholder value
        //         this.stageLst.splice(index, 0, { dataStateName: this.stageLst[index]?.groupPlaceholder, order: this.stageLst[index]?.order })
        //         //push the order already add the placeholder in the list
        //         alreadyAddFlag.push(this.stageLst[index]?.order);
        //       }
        //     }
        //   }
        // })
        // this.listChange = [...this.stageLst];
      }
    }
    catch (ex) {
      console.log(ex);
    }
  }

  async editStage(data) {
    this.editAccountContact(data.automateDataStateId, 'automateDataStateId', TypeEditModelSaleLead.SaleLead);
    if (data.automateDataStateId == this.qualifiedTypeId) {
      let confirmDialog = this.dialog.open(ConfirmModalComponent, {
        data: {
          message: `Do you wish to convert this ${this.environment.titleLead}?`,
          falseTitle: 'Convert later',
        }
      });
      let isClosed = await confirmDialog.afterClosed().toPromise();
      if (isClosed) {
        this.openConvertSaleLeadModal();
      }
    }
  }
  tabChanged(tabIndex: number): void {
    if (this.router.url.includes('/configuration/sale-lead')) {
      Helper.handleTabChangedUrl(tabIndex, '/configuration/sale-lead');
      this.userExperienceTrackingService.saveTagIndexChangeInUrl(tabIndex);
    }
  }
  formatCurrencyPrefix() {
    this.prefixCurrency = `<div class="currency-color">${localStorage.getItem("currency") ?? ""}</div>`
  }

  getLastNote(resp?: number, isDelete: boolean = false) {
    if (this.lastNote && this.lastNote.id == resp && isDelete)
      this.lastNote = null;

    if ((this.lastNote && this.lastNote.id <= resp && this.lastNote.referenceId == this.id) || !this.lastNote)
      this.noteService.lastNoteByReferenceId(this.id, ShadowProfileEnum[ShadowProfileEnum.LEADS])
        .subscribe({
          next: resp => {
            if (resp.result)
              this.lastNote = Object.assign(resp.result, {
                innerTextNote: Helper.removeStyleHtml(resp.result.note),
                ownerNote: (`${resp.result.user?.firstName || ''} ${resp.result.user?.lastName || ''}`).trim() || resp.result.user?.userName || 'Unknown',
                avatarNote: resp.result.user?.pictureURL?.replace(/\\/g, '/'),
                //referenceName: this.saleLeadModel.displayName
              })
          },
          //complete: () => this.overlayNoteDetails.completedLoading()
        });
  }

  getOwner() {
    if (!this.saleLeadModel && Helper.isNullOrEmpty(this.saleLeadModel.ownerId) || Helper.isEmptyOrSpaces(this.saleLeadModel.ownerId))
      this.ownerModel = Object.assign({ fullName: "Unknown" } as UserModel);

    if (!this.ownerModel && this.saleLeadModel.ownerId)
      this.userService.getUserById(this.saleLeadModel.ownerId).pipe(takeUntil(this.destroy$))
        .subscribe(
          resp => {
            if (resp.result) {
              this.ownerModel = resp.result;
              let fullName = `${this.ownerModel.firstName} ${this.ownerModel.lastName}`;
              this.ownerModel.fullName = fullName.trim() ? fullName : "Unknown";
              this.ownerModel.pictureURL = Helper.userURL(this.ownerModel.pictureURL);
            };
          },
          err => this.ownerModel = Object.assign({ fullName: "Unknown" } as UserModel));
  }

  async addSaleLeadFile(fileInputEvent: any) {
    this.uploading = true;
    this.uploadedFile = fileInputEvent.target.files[0];
    let acceptanceExtension = ["doc", "docx", "xls", "xlsx", "ppt", "pdf", "html", "zip", "csv", "txt"];
    let extension = Helper.getFileExtension(fileInputEvent.target.files[0].name) ? Helper.getFileExtension(fileInputEvent.target.files[0].name)[0] : null;
    // let IsAccept = extension ? (acceptanceExtension.indexOf(extension) == -1 ? false : true) : false;
    let IsAccept = true // Accept all extensions
    if (IsAccept) {
      let submitModel = new UserUpload();
      submitModel.fileName = this.uploadedFile.name;
      submitModel.referenceType = this.saleLeadModel.typeName;
      submitModel.referenceId = this.saleLeadModel.profileId;
      submitModel.owners = this.user.nameid;

      var toastRef = this.hotToast.loading('Uploading file...', { autoClose: false });
      try {
        const isEnableCoreRemoting = (await this.settingsService.getSettingByKeyAndGroup("IS_ENABLE_CORE_REMOTING", "OFFICE_365_SSO").toPromise())?.result?.value.toString();
        const uploadFile = await this.userUploadService.uploadFile(this.uploadedFile, submitModel).toPromise();
        if (uploadFile?.result) {
          this.fileTab?.refreshData(true);
          toastRef.close();
          if (isEnableCoreRemoting != null && parseInt(isEnableCoreRemoting) == 0) {
            toastRef = this.hotToast.loading('The attachment is waiting to be uploaded to OneDrive', { duration: 2000 });
            try {
              // submitModel.userUploadId = uploadFile.result?.[0]?.userUploadId;
              // submitModel.fileName = uploadFile.result?.[0]?.fileName;
              // const syncFile = await this.microsoftOffice365Service.uploadFileOffice(this.uploadedFile, submitModel).toPromise();
              const userUploadId = uploadFile.result?.[0]?.userUploadId;
              this.microsoftOffice365Service.uploadFileOneDrive(userUploadId).subscribe(res => {
                if (res.result) {
                  this.hotToast.success('The attachment is successfully added to queue for uploading to OneDrive', { duration: 2000 });
                  toastRef.close();
                }
              });
            }
            catch (ex) {
              toastRef.close();
              this.hotToast.error('An error occurred while syncing...', { duration: 2000 });
            }
          }
          else {
            this.hotToast.success('The attachment is successfully added to queue for uploading to OneDrive', { duration: 2000 });
          }
        }
        else {
          toastRef.close();
        }
      }
      catch (ex) {
        console.log(ex);
        toastRef.close();
        this.hotToast.error('An error occurred while uploading...', { duration: 2000 });
      }

    } else {
      this.toast.warning('Please select file with these extensions: doc,docx,xls,xlsx,ppt,pdf,html,zip,csv,txt', "Warning");
    }
    this.nbFilesPopover.hide();
  }
  openLocationDialog() {
    const locationDialogRef = this.dialog.open(LocationComponent, {
      disableClose: true,
      height: '80vh',
      width: '55vw',
      panelClass: 'dialog-detail',
      autoFocus: false,
      data: {
        profileId: this.id,
        isUpdateAction: false,
        selfGetApi: false,
        isDialog: true,
        activeSetPrimary: false,
        closeDialogEvent: (result) => {
          if (locationDialogRef) {
            locationDialogRef.close(result);
          }
        }
      }
    });
    locationDialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.refreshData();
        this.locationGrid.refreshData(true);

      }
    });
  }

  getSelectedContactMail(contact: Contact) {
    if (contact) {
      let newProfileModel = Object.assign(this.saleLeadModel, {});
      newProfileModel.contact = contact;
      // select other contact:
      this.sendMailClick(newProfileModel);
    } else {
      // skip mode (select primary contact)
      this.sendMailClick(null);
    }
  }
  getSelectedContactCall(contact: Contact) {
    if (contact) {
      let newProfileModel = Object.assign(this.saleLeadModel, {});
      newProfileModel.contact = contact;
      // select other contact:
      this.onClickCall(newProfileModel.contact);
    } else {
      // skip mode (select primary contact)
      this.onClickCall(null);
    }
  }
  get checkNumberOfContact() {
    return this.saleLeadModel?.profileContacts?.length ?? 0;
  }
  onClickAddNewTask() {
    const dialogRef = this.dialog.open(AddEditTaskComponent, {
      disableClose: true,
      height: '100vh',
      width: '600px',
      panelClass: 'dialog-detail',
      autoFocus: false,
      data: {
        action: TblActionType.Add,
        tooltipProp: this.tooltipProp
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.toast.success('Create new task successfully', 'Success');
        if (this.taskGrid) this.taskGrid.refreshData();
      }
    });
  }

  confirmSendMailClick() {
    let confirmClick = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to send email to ${Helper.getEmailFromContact(this.saleLeadModel?.saleLeadsContact) ?? 'Unknown'} ?`
      }
    });
    confirmClick.afterClosed().subscribe(res => {
      if (res) this.sendMailClick();
    });
  }

  confirmMakeACall() {
    let confirmClick = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to make a call to number ${Helper.getPhoneFromContact(this.saleLeadModel?.saleLeadsContact) ?? 'Unknown'} ?`
      }
    });
    confirmClick.afterClosed().subscribe(res => {
      if (res) this.onClickCall();
    });
  }

  async onClickAdvanceImport(action: string) {
    if (action == "Skip") {
      const dialogConfirm = this.dialog.open(ConfirmModalComponent, {
        data: {
          message: `Do you wish to skip 1 item(s)?`
        }, disableClose: true,
      })
      const result = await dialogConfirm.afterClosed().toPromise();
      if (result) {
        this.onAdvancedImport.emit(action);
        this.dialModalRef.close();
      }
    }
    else
      this.onAdvancedImport.emit(action);
  }

  getCacheTab() {
    let tag: string = this.userExperienceTrackingService.getTagIndexInUrl();
    return tag;
  }

  openSMS(contact: Contact) {
    if (contact) {
      const dialogSMS = this.dialog.open(PrimasSendSMSComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'custom-dialog-SMS',
        data: {
          id: this.id,
          referenceType: this.saleLeadModel.typeName || ProfileType[ProfileType.LEADS],
          outboundPhone: contact.contactPhone,
        },
      })
    }
  }
  nbPopoverCLick() {
    this.nbFilesPopover.show();
  }
  openURLDialogRef() {
    const dialogRef = this.dialog.open(UploadUrlComponent, {
      width: '30vw',
      maxWidth: '30vw',
      data: {
        profileId: this.saleLeadModel.profileId,
        nameId: this.user.nameid,
        referenceType: this.saleLeadModel.typeName || ProfileType[ProfileType.LEADS]
      }
    });
    dialogRef.afterClosed().subscribe(resp => {
      if (resp) {
        this.nbFilesPopover.hide();
        this.fileTab?.refreshData(true);
      }
    });
  }
  onRefresh(bool) {
    this.refreshData();
  }
  async openOneDriveFolderUrl() {
    var toastRef = this.hotToast.loading('Redirecting to Microsoft OneDrive folder of this Prospect...', { autoClose: false });
    try {
      const getOneDriveFolderData = await this.microsoftOffice365Service.getOneDriveFolderData(this.id, 'LEADS').toPromise();
      if (getOneDriveFolderData.result) {
        this.handleAuthorizeOpenFolderUrl(getOneDriveFolderData.result.id, getOneDriveFolderData.result.webUrl, toastRef);
      }
      else {
        this.poolingCheckOneDriveDataByProfileId(this.id, toastRef);
      }
    } catch (error) {
      console.log(error);
      toastRef.close();
      this.hotToast.error('An error occurred while opening OneDrive folder url...', { duration: 2000 });
    }
  }
  poolingCheckOneDriveDataByProfileId(profileId: string, toastRef: any) {
    if (this.isDoingCheckingOneDriveData) {
      return;
    }
    try {
      this.isDoingCheckingOneDriveData = true;
      const source = timer(0, 3000);
      if (this.checkingOneDriveDataSubscription != null) {
        this.checkingOneDriveDataSubscription.unsubscribe();
      }
      this.checkingOneDriveDataSubscription = source.subscribe(val => {
        this.microsoftOffice365Service.checkOneDriveDataByProfileId(profileId)
          .subscribe(async resp => {
            if (resp?.result?.oneDriveData != null) {
              this.checkingOneDriveDataSubscription.unsubscribe();
              await this.handleAuthorizeOpenFolderUrl(resp.result.oneDriveData.oneDriveFolderId, resp.result.webUrl, toastRef);
            }
          })
      })
    } catch (error) {
      if (this.checkingOneDriveDataSubscription != null) {
        this.checkingOneDriveDataSubscription.unsubscribe();
      }
    }
    finally {
      this.isDoingCheckingOneDriveData = false;
    }
  }
  async handleAuthorizeOpenFolderUrl(folderId: string, folderWebUrl: string, toastRef: any) {
    try {
      const isUserExistOrganization = await this.userService.checkUserExistOrganization(this.user.nameid).toPromise();
      if (isUserExistOrganization && isUserExistOrganization.result) {
        const isGrantPermission = await this.userService.grantPermissionOpenFileOrFolder(this.user.nameid, folderId).toPromise();
        if (isGrantPermission && isGrantPermission.result) {
          if (folderWebUrl != null || folderWebUrl != "") {
            toastRef.close();
            window.open(`${folderWebUrl}`, '_blank');
          }
          else {
            toastRef.close();
            this.toast.warning("The Microsoft OneDrive folder URL of this Prospect is unavailable.", 'Warning');
          }
        }
        else {
          toastRef.close();
          this.toast.warning("Cannot grant permission to access this folder.", 'Warning');
        }
      } else {
        toastRef.close();
        this.toast.warning("You don't have permission to access this folder.", 'Warning');
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  async refreshNoteTab() {
    if (this.noteTab)
      this.noteTab.refreshData();
  }
  saveIndividualProfileAdditionDetail(data: string, type: string) {
    // if (data) {
    if (Helper.isSpacesOnly(data)) {
      if (data.length > 0) {
        this.toast.danger("Please input a valid data", 'Invalid Input');
        return;
      }
    }

    // save:
    this.isLoadingEdit = true;
    let model: ProfileAdditionDetail = new ProfileAdditionDetail();
    model[type] = data;
    model.profileId = this.id;
    this.profileAdditionDetailService.saveSingleProfileAdditionByPropName(type, model).pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res.result) {
        this.toast.success(`Edit ${this.environment.titleLead} success`, 'Successfully');
        this.refreshData();
        this.isLoadingEdit = false;
      }
    })
    // }
  }
}

export enum TypeEditModelSaleLead {
  SaleLead,
  Contact
}

export enum TabSaleLeads {
  Address,
  ExtendData,
  Files,
  Note,
  Task,
  ActivityLog
}
