import { Pipe, PipeTransform } from '@angular/core';
import { ActivityLog } from 'src/app/modules/admin/campaign-management/campaign-tracking.model';
import { Helper } from '../../utility/Helper';
import { EmailCampaignTemplate } from 'src/app/modules/admin/profile-management/profile-detail/action-block/campaign-linkscope-email.model';
import { LinkScopeCampaignLst } from 'src/app/modules/admin/profile-management/create-campaign-model/create-campaign.model';
import { Contact, KeyPairsValue, ProfileContact, ProfileDetailModel } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { EntityNameEnums } from '../../enums/entity-color.enums';
import { ListDataProfile } from 'src/app/modules/admin/profile-management/profile-model';
import { ProfileService } from 'src/app/modules/admin/profile-management/profile.service';
import { Task } from 'src/app/modules/admin/task-management/task-board/task-board-lane/task/task.model';
import { ConvertSaleLeadNotificationComponent } from 'src/app/modules/admin/sale-lead-management/convert-sale-lead/convert-sale-lead-notification/convert-sale-lead-notification.component';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { ContactActionLog } from 'src/app/modules/admin/dashboard-money-maker/contact-activity-table/contact-activity.model';

@Pipe({ name: 'activityTimeline' })
export class ActivityTimelinePipe implements PipeTransform {
  subjectProfile: string = "Artist";
  emailsCX: EmailCampaignTemplate;
  campaignsCX: LinkScopeCampaignLst;
  primaryContact: Contact;
  tooltipProp: KeyPairsValue[] = [];
  environment = environment;
  user: any;

  constructor(
    private datePipe: DatePipe,
    private profileService: ProfileService,
    private authService: NbAuthService,
  ) {
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.user = token.getPayload();
      }
    });
  }

  transform(value: ActivityLog,
    type: string = '',
    emailsCX: EmailCampaignTemplate = null,
    campaignsCX: LinkScopeCampaignLst = null,
    model: ProfileDetailModel = null,
    tooltipProp: KeyPairsValue[] = []
  ): unknown {
    try {
      this.subjectProfile = Helper.toUpperCaseFirstLetter(window.localStorage.getItem("subject_profile") || "artist");
      this.emailsCX = emailsCX || this.emailsCX;
      this.campaignsCX = campaignsCX || this.campaignsCX;
      this.tooltipProp = tooltipProp || this.tooltipProp;
      if (model) this.primaryContact = model?.contact || model?.profileContacts[0]?.contact;
      switch (type) {
        case 'email':
          return this.contentEmail(value);
        case 'call':
          return this.contentCall(value);
        case 'outreach':
          return this.contentOutreach(value);
        case 'smshistory':
          return this.contentSmshistory(value);
        case 'common':
          return this.contentCommon(value, model);
        case 'cfcall':
          var log: ContactActionLog = Object.assign({ ...value });
          return Helper.updateDataForPhoneCall(log, this.user, true);
        default:
          return this.contentDefault(value);
      }
    }
    catch (ex) {
      console.error(ex);
    }
  }

  //#region email logs
  contentEmail(activityLog: ActivityLog) {
    try {
      if (activityLog) {
        var strLogInfo2 = activityLog.logInfo2?.split(";") || [];
        var referenceLink = strLogInfo2[1];
        var campaignId = strLogInfo2.findIndex(x => x == "campaign_id") || -1;
        var campaignTemplate = this.campaignsCX?.data?.find(x => x.id == Number.parseInt(strLogInfo2[campaignId + 1]));
        var getOutboundReference = strLogInfo2[strLogInfo2.findIndex(x => x == 'outbound_reference') + 1]
        //Handle if user add activity log
        if (activityLog?.subObjName?.toLowerCase() == "externalemail") {
          if (activityLog?.subAction?.toLowerCase() == "addins") {
            return this.displayMailBoxAddIns(activityLog);
          }

          switch (activityLog?.action?.toLowerCase()) {
            case "send":
            case "scansend":
            case 'read':
            case 'reply':
              var action = activityLog.action.toLowerCase().includes("send")
                ? 'sent'
                : activityLog.action.toLowerCase() == "read"
                  ? "read"
                  : 'replied';
              var title = activityLog.logInfo1?.split(';')[1] || '';
              var emailSend = activityLog.logInfo1?.split(';')[3] || '';
              var emailReceived = activityLog.logInfo2?.split(';')[1] || '';
              if (action?.toLowerCase() == "read") {
                return `<b>${activityLog.logInfo1 || ''}</b> ${action} the email <i>${(referenceLink != '' ? referenceLink : this.removeUnknownLink(activityLog)) || ''}`;
              }
              else {
                return `<b>${emailReceived || this.subjectProfile}</b> ${action} email to <i>${(emailSend || this.removeUnknownLink(activityLog)) || ''}</i>
                <br>Subject: ${title || this.removeUnknownLink(activityLog)}`;

              }
            case 'click':
              return `<b>${activityLog.logInfo1 || this.subjectProfile}</b> ${activityLog.action.toLowerCase()} the link: <i>${(getOutboundReference != '' ? this.formatLink(getOutboundReference) : this.removeUnknownLink(activityLog)) || ''}</i>`;
            case 'unsubscribed':
              return `<b>${activityLog.logInfo1 || ""}</b> ${activityLog.action.toLowerCase()} with message <i>${(referenceLink != '' ? referenceLink : this.removeUnknownLink(activityLog)) || ''}</i>`;
            case 'bounce':
              return `<b>Email:</b> ${activityLog.logInfo1 || ""} was bounced`;
            case 'replydetail': {
              var title = activityLog.logInfo1?.split(';')[1] || '';
              var emailSend = activityLog.logInfo1?.split(';')[3] || '';
              var emailReceived = activityLog.logInfo2?.split(';')[1] || '';

              return `<b>${emailSend || this.subjectProfile}</b> replied the email <i>${emailReceived || this.removeUnknownLink(activityLog)}</i>
                <br>Subject: ${title}`;
            }
            case 'deferred':
              return `<b>${activityLog.logInfo1}</b> ${activityLog.action.toLowerCase()} with message <i>${(referenceLink != '' ? referenceLink : this.removeUnknownLink(activityLog)) || ''}</i>`;
          }
        }
        else {
          switch (activityLog.action.toLowerCase()) {
            case "send":
            case "scansend":
            case 'read':
            case 'reply':
              var action = activityLog.action.toLowerCase().includes("send")
                ? 'sent'
                : activityLog.action.toLowerCase() == "read"
                  ? "read"
                  : 'replied';

              if (activityLog.userName == "CampaignUser") {
                if (campaignTemplate) {
                  var titleEmailTemplate = JSON.parse(campaignTemplate.emailTemplate);
                  return `<b>${action == 'sent' ? (Helper.isEmptyOrSpaces(activityLog.userName) ? 'We' : activityLog.userName) : this.subjectProfile}</b> ${action} <i>${titleEmailTemplate['Title'] || this.removeUnknownLink(activityLog)}</i>
                <br><span style="font-size: 12px">Campaign: ${campaignTemplate.campaignName || this.removeUnknownLink(activityLog)}</span>`;
                } else {
                  return this.removeUnknownLink(activityLog);
                }
              }
              else {
                var title = activityLog.logInfo1?.split(';')[1] || '';
                var email = activityLog.logInfo1?.split(';')[3] || '';
                if (activityLog.subObjId) {
                  var emailTemplate = this.emailsCX.data.find(x => x.id == Number.parseInt(activityLog.subObjId));
                  if (emailTemplate) {
                    title = emailTemplate.title || title;
                  }
                  if (activityLog.subObjId && activityLog.subObjId.length > 0 && action == 'sent') {
                    return `<b>${action == 'sent' ? (Helper.isEmptyOrSpaces(activityLog.userName) ? 'We' : activityLog.userName) : this.subjectProfile}</b> <b> <i>(${activityLog.subObjId || this.removeUnknownLink(activityLog)})</i> </b> ${action} email to <b><i>${email || this.removeUnknownLink(activityLog)}</i></b>
                    <br>Subject: ${title || this.removeUnknownLink(activityLog)}`;
                  }
                  else {
                    return `<b>${action == 'sent' ? (Helper.isEmptyOrSpaces(activityLog.userName) ? 'We' : activityLog.userName) : this.subjectProfile}</b> ${action} email to <i>${email || 'this profile'}</i>
                    <br>Subject: ${title || this.removeUnknownLink(activityLog)}`;
                  }
                }
                else {
                  return `<b>${action == 'sent' ? (Helper.isEmptyOrSpaces(activityLog.userName) ? 'We' : activityLog.userName) : this.subjectProfile}</b> ${action} email to <i>${email || this.removeUnknownLink(activityLog)}</i>
                  <br>Subject: ${title || this.removeUnknownLink(activityLog)}`;
                }
              }
            case 'replydetail': {
              var splitLog1 = activityLog.logInfo1?.split(";") || [];
              var splitLog2 = activityLog.logInfo2?.split(";") || [];
              var email = this.subjectProfile;
              var title = "";
              if (splitLog1 != null && splitLog1.length >= 4 && splitLog1[2] == "email") email = splitLog1[3];
              if (splitLog2 != null && splitLog2.length >= 2 && splitLog2[0] == "title") title = splitLog2[1];

              return `<b>${Helper.isEmptyOrSpaces(email) ? this.subjectProfile : email}</b> replied the email ${(activityLog.userName == "Unknown" ? '' : `<i>to ${activityLog.userName}</i>`)}
            <br>Subject: ${title}`;
            }
            case "click":
              return `<b>${this.subjectProfile}</b> ${activityLog.action.toLowerCase()} the link: <i>${getOutboundReference != '' ? this.formatLink(getOutboundReference) : this.removeUnknownLink(activityLog)}</i>`;
            case 'unsubscribed':
              return `<b>${this.subjectProfile}</b> ${activityLog.action.toLowerCase()} with message <i>${referenceLink != '' ? referenceLink : this.removeUnknownLink(activityLog)}</i>`;
            case 'bounce':
              if (activityLog.userName == "CampaignUser") {
                if (campaignTemplate) {
                  return `<b>Campaign:</b> ${campaignTemplate.campaignName || this.removeUnknownLink(activityLog)} was bounced`;
                }
                return this.removeUnknownLink(activityLog);
              } else {
                return `<b>Email:</b> ${activityLog.subAction || this.removeUnknownLink(activityLog)} was bounced`;
              }
            default:
              return `${activityLog.action.toLowerCase()}`;
          }
        }
      } else {
        return 'Waiting load campaign...';
      }
    }
    catch (ex) {
      console.error(ex);
    }
  }

  displayMailBoxAddIns(log: ActivityLog): string {
    var htmlLog: string = "";
    try {
      let model = this.getContentEmailAddIns(log);
      if (model)
        switch (log?.action?.toLowerCase()) {
          case "send":
            htmlLog = `<b>${model.from || this.subjectProfile}</b> sent email to <i>${model.to || this.removeUnknownLink(log)}</i>
          <br>Subject: ${model.title || this.removeUnknownLink(log)}`
            break;
          case "replydetail":
            htmlLog = `<b>${model.to || this.removeUnknownLink(log)}</b> received the email <i>${model.from || this.subjectProfile}</i>
          <br>Subject: ${model.title || this.removeUnknownLink(log)}`
            break;
        }
    }
    catch (ex) {
      console.log(ex);
    }

    return htmlLog;
  }

  getContentEmailAddIns(log: ActivityLog): any {
    var model: {} = null;
    try {
      var to: string = "";
      var from: string = "";
      var title: string = "";
      var body: string = "";

      var splitLog1: string[] = log?.logInfo1?.split(';') || [];
      var splitLog2: string[] = log?.logInfo2?.split(';') || [];
      if (splitLog1?.length > 1) from = splitLog1[1];
      if (splitLog1?.length > 3) to = splitLog1[3];
      if (splitLog2?.length > 1) title = splitLog2[1];
      if (splitLog2?.length > 3) body = splitLog2[3];

      model = Object.assign({}, { title: title, body: body, to: to, from: from });

    } catch (ex) {
      console.log(ex);
    }

    return model;
  }

  removeUnknownLink(activityLog: ActivityLog) {
    if (activityLog) {
      //this.events = [...this.events.filter(x => JSON.stringify(x.activityLog) != JSON.stringify(activityLog))];
    }
  }
  //#endregion

  //#region call logs
  contentCall(activityLog: ActivityLog) {
    let result: string = '';

    try {
      if (activityLog) {
        switch (activityLog?.action?.toLowerCase()) {
          case 'call':
          case 'amdcall':
          case 'leftvoicemail':
            result = this.descriptionCall(activityLog);
            break;
          case 'leavemessage':
            result = this.descriptionLeaveMessage(activityLog);
            break;
          case 'inboundcall':
            result = this.callBackDescriptionCall(activityLog);
            break;
        }
      }
    }
    catch (ex) {
      console.error(ex);
    }

    return result;
  }

  descriptionCall(i: ActivityLog): string {
    if (i.userName !== 'UserCampaign') {
      var sliptCallEvent = i.logInfo1?.split(";") || [];
      var sliptActivityLog = i.logInfo2?.split(";") || [];
      if ((i.activityType.toLowerCase() == "external" || i.activityType.toLowerCase() == "pcall") && i.subObjName.toLowerCase() == "externalcall") {
        return `<b>${sliptCallEvent[1] || i.userName}</b> started calling ${(i.subObjName.toLowerCase() == "externalcall"
          ? i.subObjId : this.primaryContact?.contactPhone)
          || this.messageError('unknown contact phone')}
          ${sliptCallEvent[1] ? `<br/><b>${Helper.isEmptyOrSpaces(sliptCallEvent[1]) ? 'We' : sliptCallEvent[1]}</b> hung up phone call. Call result: <b>${this.formatCallEvent(sliptCallEvent[3])}</b>` : ''} ${sliptCallEvent[5] ? `(${sliptCallEvent[5]})` : ""}`;
      } else {
        //2022-05-20 gnguyen start mod
        return `<b>${Helper.isEmptyOrSpaces(i.userName) ? 'We' : i.userName}</b> started calling ${(i.subObjName == "PhoneNumber"
          ? i.subObjId : this.primaryContact?.contactPhone)
          || this.messageError('unknown contact phone')} from ${i.task ? `<b>Task-${i.task.taskId}-${i.task.taskName}</b>` : 'profile'}
            ${sliptCallEvent[4] ? `<br/><b>${Helper.isEmptyOrSpaces(i.userName) ? 'We' : i.userName}</b> hung up phone call. Call result: <b>${this.formatCallEvent(sliptCallEvent[4])}</b>` : ''} ${sliptCallEvent[6] ? `(${sliptCallEvent[6]})` : ''}`;
      }
    } else {
      return `This profile has been called using phone call from outside`;
    }
  }

  descriptionLeaveMessage(i: ActivityLog): string {
    try {
      return `${Helper.isEmptyOrSpaces(i.userName) ? 'We' : i.userName} left message to ${(i.subObjName == "PhoneNumber"
        ? i.subObjId : this.primaryContact?.contactPhone)
        || this.messageError('unknown contact phone')
        }`
    }
    catch (ex) {
      console.error(ex);
    }
  }

  descriptionOutreach(i: ActivityLog, isSentText = false): string {
    if (i.userName !== 'UserCampaign') {
      var referenceInfo = i.logInfo2.split(";");
      var sliptCallEvent = i.logInfo1.split(";");
      var userName = `${Helper.isEmptyOrSpaces(i.userName) ? 'We' : i.userName}`;
      var action = `${isSentText ? "sent sms to" : "called"}`;
      var phoneNumber = referenceInfo[1];
      var ref = "";
      var progress = "";
      if (referenceInfo.length >= 3) {
        ref = referenceInfo[3];
      }
      if (sliptCallEvent.length >= 2) {
        progress = sliptCallEvent[1];
      }
      var result = sliptCallEvent[0]
      return `<b>${userName}</b> ${action} ${phoneNumber}</br>Status: <b>${result}</b> ${progress != null && progress.length > 0 ? `. Progress <b>${progress}</b>` : ""} ${(ref != null && ref.length > 0) ? ref : ""}`

    } else {
      return `This profile has been called using phone call from outside`;
    }
  }

  callBackDescriptionCall(i: ActivityLog): string {
    // call from outside to profile
    var splitCallEvent = i.logInfo1?.split(";") || [];
    return `<b>${this.subjectProfile}</b>(${Helper.isEmptyOrSpaces(i.subObjId) ? 'Unknown' : i.subObjId}) called to <b>${i.userName}</b>
    ${splitCallEvent[4] ? `</br> <b>${Helper.isEmptyOrSpaces(i.userName) ? 'We' : i.userName}</b> hung up phone call with call result: <b>${this.formatCallEvent(splitCallEvent[4])}</b>` : ''} ${splitCallEvent[6] ? `(${splitCallEvent[6]})` : ''}`

  }

  formatCallEvent(eventName) {
    let caption = '';
    switch (eventName) {
      case 'AGENT_BUSY':
        caption = 'Agent phone BUSY';
        break;
      case 'AGENT_NO_ANSWER':
        caption = 'Agent phone NO ANSWER';
        break;
      case 'AGENT_FAILED':
        caption = 'Agent phone FAILED';
        break;
      case 'AGENT_ANSWERED':
        caption = 'Agent answered';
        break;
      case 'CALLING':
        caption = `Calling ${this.subjectProfile}...`;
        break;
      case 'CALLING_AGENT':
        caption = 'Calling agent...';
        break;
      case 'BUSY':
        caption = `${this.subjectProfile} phone BUSY`;
        break;
      case 'NO_ANSWER':
        caption = `${this.subjectProfile} phone NO ANSWER`;
        break;
      case 'FAILED':
        caption = `${this.subjectProfile} phone FAILED`;
        break;
      case 'ANSWERED':
        caption = 'Talking...';
        break;
      case 'CALL_ENDED':
        caption = 'Call ended';
        break;
      case 'VOICEMAIL':
        caption = 'VOICEMAIL';
        break;
      // CallBack case:
      default:
        caption = eventName;
        break;
    }
    return caption.toUpperCase();
  }
  //#endregion

  //#region common logs
  contentCommon(i: ActivityLog, model: ProfileDetailModel) {
    enum subObjName {
      reason = 'reason',
      bio_rewrite = 'bio_rewrite',
      contact = 'contact',
      location = 'location',
      media = 'media'
    }

    let result: string = '';
    let des: string = '';

    try {
      switch (i.propertyName) {
        case 'IsActive':
          des = 'Active';
          break;
        case 'FavoriteArt':
          des = 'Favorite Art';
          break;
        case 'IsPopularAct':
          des = 'Popular Act';
          break;
        case 'IsTopAct':
          des = 'Top Act';
          break;
        case 'ProducerTypeId':
          des = 'Producer Type Id';
          break;
        case 'SetList':
          des = 'Set List';
          break;
        case 'PreviousClients':
          des = 'Previous Clients';
          break;
        case 'RealName': {
          des = 'Real Name';
          var name = Helper.findItemByKey(this.tooltipProp, 'realName');
          if (name) des = name;
        }
          break;
        case 'DisplayName':
          {
            des = 'Display Name';
            var name = Helper.findItemByKey(this.tooltipProp, 'displayName');
            if (name) des = name;
          }
          break;
        case 'PrimaryImage':
          des = 'Edit Image';
          break;
        case 'SeoInfoId':
          des = 'SEO Info Id';
          break;
        case 'PrimarySeoInfo':
          des = 'Primary Seo Info';
          break;
        case 'HasAttachments':
          des = 'file(s)'
          break;
        case 'Type':
          des = 'Type';
          break;
        case "Probability":
          des = 'Probability';
          break;
        case "LeadSource":
          des = 'Lead Source';
          break;
        case "Amount":
          // des = 'Amount';
          des = `${environment.titleEstimate}`;
          break;
        case "CloseDate":
          // des = 'Close Date';
          des = `${environment.titleClosedDate}`;
          break;
        case "SecondaryCloseDate":
          des = `End Date`;
          break;
        case "OneTimeProduct":
          des = `${environment.title1xProduct}`;
          break;
        case "OneTimeService":
          des = `${environment.title1xService}`;
          break;
        case "TotalValue":
          des = `${environment.titleTotalValue}`;
          break;
        case i.propertyName: {
          if (i.propertyName != null) {
            des = i.propertyName;
            var name = Helper.findItemByKey(this.tooltipProp, i.propertyName);
            if (name) des = name;
            if (des) {
              des = Helper.displayNameProp(des, false, true);
            }
          }
        }
          break;
      }
      switch (i.subObjName) {
        case 'bio_rewrite':
          des = 'bio';
          break;
        case 'seo_info':
          des = 'seo';
          break;
        case 'profile_location':
          des = 'location';
          break;
        case 'profile_contact':
          des = 'contact';
          break;
        case 'profile_seo':
          des = 'profile SEO';
          break;
        case 'contact_contact_type':
          des = 'Client type'
        case 'sale_opportunity': {
          switch (i.propertyName) {
            case "Probability":
              des = 'Probability';
              break;
            case "LeadSource":
              des = 'Lead Source';
              break;
            case "Amount":
              // des = 'Amount';
              des = `${environment.titleEstimate}`;
              break;
            case "CloseDate":
              des = `${environment.titleClosedDate}`;
              break;
            case "SecondaryCloseDate":
              des = `End Date`;
              break;
            case "ReferenceId":
              des = 'Reference';
              break;
            case "OpportunityName":
              des = 'Opportunity Name';
              break;
            case "Type":
              des = 'Type';
              break;
            case 'DisplayName':
              {
                des = 'Display Name';
                var name = Helper.findItemByKey(this.tooltipProp, 'displayName');
                if (name) des = name;
              }
              break;
          }
        }
        case "contact": {
          switch (i.propertyName) {
            case 'Saluation':
              des = 'Salutation';
              break;
            case 'ContactLastName':
              des = 'Contact Last Name';
              break;
            case 'ContactName':
              des = 'ContactName';
              break;
            case 'Description':
              des = 'Description';
              break
            case 'ContactEmail':
              des = 'ContactEmail';
              break
            case 'ContactPhone':
              des = 'ContactPhone';
              break
          }
        }
        case 'location': {
          switch (i.propertyName) {
            case 'Country':
              des = 'Country';
              break;
            case 'State':
              des = 'State';
              break;
          }
        }
        case 'profile_addition_detail':
          {
            if (des) {
              if (des == 'HasAttachments') des = 'Files ';
              else
                des = Helper.displayNameProp(des);
            } else if (!i.propertyName) {
              des = 'profile additional detail';
            }
            else {
              des += `${Helper.displayNameProp(i.propertyName || '')}`;
            }
            // des = 'profile additional detail'
          }
          break;
        case i.subObjName:
          if (i.subObjName != null) {
            des = i.subObjName;
            var name = Helper.findItemByKey(this.tooltipProp, i.subObjName);
            if (name) des = name;
          }
          break;
      }
      switch (i.action) {
        case 'Edit':
          // var object = {} as CustomNgxTimelineEvent.
          if (i.subObjName != null && i.subAction === 'Add') {
            result = `${i.userName} add new ${des}`;
          }
          else if (i.propertyName != null && i.logInfo2 === 'True') {
            result = i.userName + ' has edit ' + des + ' from disable to enable';
          } else if (i.propertyName != null && i.logInfo2 === 'False') {
            result = i.userName + ' has edit ' + des + ' from enable to disable';
          } else if (i.logInfo1 === null && i.logInfo2 != null) {
            result = i.userName + ' has edit from unknown to ' + i.logInfo2;
          }
          else if (i.propertyName != null && (i.propertyName == 'CloseDate' || i.propertyName == "SecondaryCloseDate") && i.subObjName == 'sale_opportunity') {
            if (i.logInfo1 || i.logInfo2) {
              i.logInfo1 = this.datePipe.transform(i.logInfo1) || 'unknown';
              i.logInfo2 = this.datePipe.transform(i.logInfo2) || '';
            }
            result = i.userName + ' has edit ' + des + ' from ' + i.logInfo1 + ' to ' + i.logInfo2;
          }
          else if ((i.propertyName != null && i.logInfo1 === null) || (i.propertyName != null && i.logInfo1 === '')) {
            result = i.userName + ' has add ' + des + ' from unknown to ' + i.logInfo2;
          }
          else if (i.propertyName != null && i.propertyName !== 'OpportunityName') {
            result = i.userName + ' has edit ' + des + ' from ' + i.logInfo1 + ' to ' + i.logInfo2;
          }
          else if (i.logInfo1 != null && i.logInfo2 != null && i.propertyName !== 'OpportunityName') {
            result = i.userName + ' has ' + i.subAction + ' ' + des + ' from ' + i.logInfo1 + ' to ' + i.logInfo2;
          }
          else if (i.subObjName === subObjName.media || i.subAction === 'Delete') {
            result = i.userName + ' has ' + i.subAction + ' ' + des;
          } else if (i.subObjName != null && i.subAction === 'Edit' && i.subObjName !== 'sale_opportunity') {
            result = i.userName + ' has edit ' + des;
          }
          break;
        case 'Add':
          if (i.subObjName === subObjName.reason
            || i.subObjName === subObjName.contact ||
            i.subObjName === subObjName.location) {
            result = i.userName + ' add new ' + des;
          }
          break;
        case 'Convert':
          {
            if (i.propertyName == 'OrgLead') {
              result = `This ${this.getEntity(model)} has been converted by ${i.userName}`;
            } else {
              result = `This ${this.getEntity(model)} has been converted from lead: <b>${i.logInfo2 || 'Unknown'}</b> by ${i.userName}`;
            }
          }
          break;
      }
    }
    catch (ex) {
      console.error(ex);
    }

    return result;
  }
  //#endregion

  //#region Outreach
  contentOutreach(activityLog: ActivityLog) {
    try {
      function descriptionOutreach(i: ActivityLog, isSentText = false) {
        if (i.userName !== 'UserCampaign') {
          var referenceInfo = i.logInfo2.split(";");
          var splitCallEvent = i.logInfo1.split(";");
          var userName = `${Helper.isEmptyOrSpaces(i.userName) ? 'We' : i.userName}`;
          var action = `${isSentText ? "sent sms to" : "called"}`;
          var phoneNumber = referenceInfo[1];
          var ref = "";
          var progress = "";
          if (referenceInfo.length >= 3) {
            ref = referenceInfo[3];
          }
          if (splitCallEvent.length >= 2) {
            progress = splitCallEvent[1];
          }
          var result = splitCallEvent[0]
          return `<b>${userName}</b> ${action} ${phoneNumber}</br>Status: <b>${result}</b> ${progress != null && progress.length > 0 ? `. Progress <b>${progress}</b>` : ""} ${(ref != null && ref.length > 0) ? ref : ""}`

        } else {
          return `This profile has been called using phone call from outside`;
        }
      }

      if (activityLog) {
        switch (activityLog.action.toLowerCase()) {
          case 'sentsms':
            return descriptionOutreach(activityLog, true);
          case 'call':
            return descriptionOutreach(activityLog);
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }
  //#endregion

  //#region SMS History
  contentSmshistory(activityLog: ActivityLog) {
    try {
      if (activityLog) {
        switch (activityLog.action.toLowerCase()) {
          case 'inbound':
            return `<b><i>${activityLog.userName ?? ''}</i></b> sent SMS for <i>${activityLog.sessionId}</i> with content: <i>${activityLog.logInfo1}</i>`;
          case 'outbound':
            return `<b><i>${activityLog.userName ?? ''}</i></b> received SMS from <i>${activityLog.sessionId}</i> with content: <i>${activityLog.logInfo1}</i>`;
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }
  //#endregion

  //#region default logs (Other, Proposal, Form)
  contentDefault(activityLog: ActivityLog) {
    try {
      if (activityLog) {
        if (activityLog.action.toLowerCase() == "other") {
          return `${activityLog.logInfo1 || ""}`;
        } else if (activityLog.activityType.toLowerCase() == "form") {
          return `<b>${this.subjectProfile}</b> submited ${activityLog.logInfo1.toLowerCase()}`;
        } else if(activityLog.activityType.toLowerCase() == "comment"){
          return `<b>${activityLog.subObjId == this.user.nameid ? 'Me' : activityLog.userName}</b> commented: ${activityLog.logInfo1}`
        } else {
          //Proposal
          return `<b>Client Viewer</b> has opened the proposal </br> Revision Id: ${activityLog.objId}. IP: ${activityLog.remoteIP}`;
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }
  //#endregion

  formatLink(message: string) {
    return `<span style="color: #307abd;"><u>${message}</u></span>`
  }

  messageError(message: string) {
    return `<b><span style="color: red;">${message}</span></b>`;
  }

  getEntity(model: ProfileDetailModel) {
    let result: string = 'Profile';
    if (model) {
      switch (model.typeName) {
        case EntityNameEnums.SaleLead:
          result = environment.titleLead;
        case EntityNameEnums.SaleAccount:
          result = environment.titleAccount;
        case EntityNameEnums.Opportunity:
          result = environment.titleOpportunity;
        default:
          result = 'Profile';
      }
    }

    return result;
  }
}

@Pipe({ name: 'activityEmail' })
export class ActivityEmailPipe implements PipeTransform {

  transform(value: ActivityLog, type: string = 'icon', activityType?: string): unknown {
    try {
      let external = false;
      if (activityType) external = activityType.toLowerCase().includes('external');
      switch (type) {
        case 'icon':
          return this.icon(value.action, external);
        case 'colorIcon':
          return this.colorIcon(value.action);
        case 'color':
          return this.color(value.action);
        case 'class':
          return this.class(value.action);
        case 'classClick':
          return this.classClick(value);
      }
    }
    catch (ex) {
      console.error(ex);
    }
  }

  icon(value: string, external: boolean = false) {
    let result = 'mail';
    if (value) {
      try {
        switch (value.toLowerCase()) {
          case 'read':
            result = 'mark_email_read';
            break;
          case 'reply':
          case 'replydetail':
            result = external ? 'outlook-reply' : 'forward_to_inbox';
            break;
          case 'send':
            result = external ? 'outlook-send' : 'forward_to_inbox';
            break;
          case 'unsubscribed':
            result = 'unsubscribe';
            break;
        }
      }
      catch (ex) {
        console.error(ex);
      }
    }
    return result;
  }

  colorIcon(value: string) {
    let result = '#cfcfcf';
    if (value) {
      try {
        switch (value.toLowerCase()) {
          case 'read':
            result = '#cfcfcf';
            break;
          case 'send':
            result = '#e3e70d';
            break;
          case 'reply':
          case 'replydetail':
            result = '#73d44c';
            break;
          case 'unsubscribed':
            result = '#fc6363';
            break;
        }
      }
      catch (ex) {
        console.error(ex);
      }
    }
    return result;
  }

  color(value: string) {
    let result = '#F7F7F7';
    if (value) {
      try {
        switch (value.toLowerCase()) {
          case 'read':
            result = '#F7F7F7';
            break;
          case 'send':
            result = '#feffce';
            break;
          case 'reply':
          case 'replydetail':
            result = '#d9fdd9';
            break;
          case 'unsubscribed':
            result = '#fdc7c7';
            break;
        }
      }
      catch (ex) {
        console.error(ex);
      }
    }
    return result;
  }

  class(value: string) {
    let result = '';
    if (value) {
      try {
        switch (value.toLowerCase()) {
          case 'reply':
          case 'replydetail':
            result = 'flip-icon';
            break;
        }
      }
      catch (ex) {
        console.error(ex);
      }
    }
    return result;
  }

  classClick(value: ActivityLog) {
    let result = '';

    if (value) {
      try {
        let action = value.action.toLowerCase();

        if (action.includes("send")) {
          result = "cursor-pointer";
        } else {
          switch (action) {
            case "convert":
            case "submit":
            case "click":
              result = "cursor-pointer"
              break;
            case "reply":
            case "replydetail":
              if (value.subObjName?.toLowerCase() != "externalemail" && value.subAction?.toLowerCase() != "addins") {
                result = "cursor-pointer"
                break;
              }
          }
        }
      }
      catch (ex) {
        console.error(ex)
      }
    }
    return result;
  }
}

@Pipe({ name: 'activityCall' })
export class ActivityCallPipe implements PipeTransform {

  transform(value: string, type: string = 'icon'): unknown {
    switch (type) {
      case 'icon':
        return this.icon(value);
      case 'colorIcon':
        return this.colorIcon(value);
      case 'color':
        return this.color(value);
      case 'class':
        return this.class(value);
    }
  }

  icon(value: string) {
    let result = 'phone';
    if (value) {
      try {
        switch (value.toLowerCase()) {
          case 'call':
          case 'cfcall':
          case 'leftvoicemail':
            result = 'phone'
            break;
          case 'amdcall':
          case 'leavemessage':
            result = 'mic';
            break;
          case 'comment':
              result = 'comment';
          case 'inboundcall':
            break;
        }
      }
      catch (ex) {
        console.error(ex);
      }
    }
    return result;
  }

  colorIcon(value: string) {
    let result = '#e1e1e1';
    if (value) {
      try {
        switch (value.toLowerCase()) {
          case 'call':
          case 'leftvoicemail':
            result = '#f5da65'
            break;
          case 'amdcall':
          case 'leavemessage':
            result = '#f5da65';
            break;
          case 'inboundcall':
            result = '#73d44c';
            break;
          case 'agent_answered':
          case 'answered':
            result = '#99ccff';
            break;
          case 'no_make_call':
            result = '#cc99ff';
            break;
          case 'agent_no_answer':
          case 'no_answer':
            result = '#ff6666';
            break;
          case 'busy':
          case 'agent_busy':
            result = '#ffff66';
            break;
          case 'unavailable':
          case 'agent_unavailable':
            result = '#b3b3b3';
            break;
          case 'congestion':
          case 'agent_congestion':
            result = '#FF9900';
            break;
          case 'call_ended':
          case 'leave_voicemail':
            result = '#77f377';
            break;
        }
      }
      catch (ex) {
        console.error(ex);
      }
    }
    return result;
  }

  color(value: string) {
    let result = '#e1e1e1';
    if (value) {
      try {
        switch (value.toLowerCase()) {
          case 'call':
          case 'cfcall':
          case 'leftvoicemail':
            result = '#feffce'
            break;
          case 'amdcall':
          case 'leavemessage':
            result = '#feffce';
            break;
          case 'inboundcall':
            result = '#d9fdd9'
            break;
          case 'agent_answered':
          case 'answered':
            result = '#99ccff';
            break;
          case 'no_make_call':
            result = '#cc99ff';
            break;
          case 'agent_no_answer':
          case 'no_answer':
            result = '#ff6666';
            break;
          case 'busy':
          case 'agent_busy':
            result = '#ffff66';
            break;
          case 'unavailable':
          case 'agent_unavailable':
            result = '#b3b3b3';
            break;
          case 'congestion':
          case 'agent_congestion':
            result = '#FF9900';
            break;
          case 'call_ended':
          case 'leave_voicemail':
            result = '#77f377';
            break;
            result = '#FF99CC';
            break;
        }
      }
      catch (ex) {
        console.error(ex);
      }
    }
    return result;
  }

  class(value: string) { }
}

@Pipe({ name: 'activityDefault' })
export class ActivityDefaultPipe implements PipeTransform {
  //Other -> Form -> Proposal

  transform(value: ActivityLog, type: string = 'icon'): unknown {
    switch (type) {
      case 'icon':
        return this.icon(value);
      case 'color':
        return this.color(value);
      case 'class':
        return this.class(value);
      case 'background':
        return this.background(value);
      case 'classClick':
        return this.classClick(value);
    }
  }

  icon(value: ActivityLog) {
    let result = 'text_snippet';
    if (value) {
      try {
        if (value.action.toLowerCase() == "other") {
          result = "list_alt"
        } else if (value.activityType.toLowerCase() == "form") {
          result = "assignment"
        }
      }
      catch (ex) {
        console.error(ex);
      }
    }
    return result;
  }

  color(value: ActivityLog) {
    let result = '#28a745';
    if (value) {
      try {
        if (value.action.toLowerCase() == "other") {
          result = "#28a745"
        } else if (value.activityType.toLowerCase() == "form") {
          result = "#37a0ff"
        }else if(value.activityType.toLowerCase() == "comment"){
          result = "#a6f1fb"
        }
      }
      catch (ex) {
        console.error(ex);
      }
    }
    return result;
  }

  background(value: ActivityLog) {
    let result = '#fdfdfd;';
    if (value) {
      try {
        if (value.action.toLowerCase() == "other") {
          result = "#fdfdfd;"
        } else if (value.activityType.toLowerCase() == "form") {
          result = "#FFF3CD"
        } else if(value.activityType.toLowerCase() == "comment"){
          result = "#a6f1fb"
        }
      }
      catch (ex) {
        console.error(ex);
      }
    }
    return result;
  }

  classClick(value: ActivityLog) {
    let result = '';
    if (value) {
      try {
        if (value.activityType.toLowerCase() == "proposal") {
          result = "cursor-pointer";
        }
      }
      catch (ex) {
        console.error(ex);
      }
    }
    return result;
  }

  class(value: ActivityLog) { }
}

@Pipe({ name: 'activitySMSHistory' })
export class ActivitySMSHistoryPipe implements PipeTransform {
  transform(value: string, type: string = ''): unknown {
    switch (type) {
      case 'background':
        return this.background(value);
      default:
        return "";
    }
  }

  background(value: string) {
    let result = '#ffefdb';

    if (value) {
      try {
        if (value.toLowerCase() == 'outbound') {
          result = "#ffe4c4";
        }
      }
      catch (ex) {
        console.error(ex);
      }
    }
    return result;
  }
}

@Pipe({ name: 'activityTask' })
export class ActivityTaskPipe implements PipeTransform {
  transform(value: Task, type: string = '', doneTaskId: number = null): unknown {
    switch (type) {
      case 'duedateColor':
        return this.duedateColor(value, doneTaskId);
      case 'priorityColor':
        return this.priorityColor(value.taskPriorities.priorityOrder);
      default:
        return "";
    }
  }

  duedateColor(value: Task, doneTaskId: number) {
    let result = 'black';

    function isOverdate(date) {
      try {
        const timestamp = new Date(date);
        return Date.now() - new Date(timestamp.getFullYear(), timestamp.getMonth() + 1, timestamp.getDate()).getTime() < 0;
      }
      catch (ex) {
        console.error(ex);
      }
    }

    if (value) {
      try {
        if (value?.taskStartDate && ((value?.taskStatusId).toString() !== doneTaskId.toString()) && isOverdate(value?.taskStartDate)) {
          result = "red";
        }
      }
      catch (ex) {
        console.error(ex);
      }
    }
    return result;
  }

  priorityColor(value: number) {
    let result = '';
    if (value) {
      try {
        //urgent, highest, high, medium, low, lowest
        let colorCode = ["#B50000", "#FF5630", "#FF7452", "#FFAB00", "#0065FF", "#2684FF"];
        if (value && value > 0 && value <= colorCode.length) {
          return colorCode[value - 1];
        }
      }
      catch (ex) {
        console.error(ex);
      }
    }
    return result;
  }
}

@Pipe({ name: 'activityCommon' })
export class ActivityCommonPipe implements PipeTransform {
  transform(value: string, type: string = ''): unknown {
    switch (type) {
      case 'backgroundColor':
        return this.backgroundColor(value);
      case 'classClick':
        return this.classClick(value);
      default:
        return "";
    }
  }

  backgroundColor(value: string) {
    let result = '';
    if (value) {
      let action = value.toLowerCase();
      try {
        switch (action) {
          case "convert":
            result = "#93df93";
            break;
          default:
            break;
        }
      }
      catch (ex) {
        console.error(ex);
      }
    }
    return result;
  }

  classClick(value: string) {
    let result = '';
    if (value) {
      let action = value.toLowerCase();
      try {
        if (action == "convert") {
          result = "cursor-pointer";
        }
      }
      catch (ex) {
        console.error(ex);
      }
    }
    return result;
  }
}