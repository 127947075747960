import { prop } from "@rxweb/reactive-form-validators";


export enum RevisionStatus {
  None = -1,
  WaitingForReview,
  InReview,
  Rejected,
  Approved,
  Recovery,
}

export const ListRevisionStatusEnum: number[] = [
  RevisionStatus.WaitingForReview,
  RevisionStatus.InReview,
  RevisionStatus.Rejected,
  RevisionStatus.Approved,
  RevisionStatus.Recovery,
]

export class RVRevisionMeta {
  revisionId: number;
  description: string;
  objectType: string;
  objectId: string;
  createdBy: string;
  reviewedBy: string;
  deleted: boolean;
  dateDeleted: string | null;
  dateModified: string | null;
  dateCreated: string | null;
  extendData: string;
  status: RevisionStatus;
  isPublish: boolean;
  friendlyUrl: string;
  reviewedDate: string | null;
  publishedDate: string | null;

  profile: any;
  category: any;
  submitter: any;
  reviewer: any;
}

export enum RevisionEntity {
  Profile = "Profile",
  Category = "Category",
}

export const ListRevisionEntityEnum: string[] = [
  RevisionEntity.Profile,
  RevisionEntity.Category,
]

export class RevisionExtendData {
  OverallScore: string;
  ReadabilityScore: string;
  RejectReasonReferenceId: string;
  RestoreNote: string;
  RestoreKey: string;
}

export class RestoreRevisionViewModel {
  revisionId: number;
  timestamp?: string;
  objectType?: string;
  
  @prop()
  isSnapshot: boolean = true;
  @prop()
  note: string;
}