import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { environment } from 'src/environments/environment';
import { UserModel } from '../user-management/user-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PricingData } from './customer-venue-management/pricing-management/pricing-data.model';
import { EventSpace, VenueChart, VenueModel } from './customer-venue-management/customer-venue-detail/venue-detail.model';
import { CustomerPlan, CustomerPlanCoupon, CustomerSubscription } from './customer-plan-management/customer-plan.model';
import { LagoCustomer } from './customer-dashboard/lago-customer.model';
import { defaultPerk, perksProp } from './default-perk';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  basedUrl = environment.apiCustomerManagement;
  private _customerRole: string;
  set customerRole(value: string) {
    this._customerRole = value;
  }

  get customerRole(): string {
    return this._customerRole;
  }

  private _validatePerk: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  private _perkValues: { [key: string]: any } = {};
  private _subjectPerks: BehaviorSubject<{ [key: string]: any }> = new BehaviorSubject<{ [key: string]: any }>(null);
  private _subjectPerkValues: BehaviorSubject<{ [key: string]: any }> = new BehaviorSubject<{ [key: string]: any }>(null);

  validatePerk(): Observable<void> {
    return this._validatePerk.asObservable();
  }

  clearPerkValue(perk: string, cleanAll = false) {
    try {
      if (cleanAll) {
        this._perkValues = {};
      }
      else {
        if (!this._perkValues) this._perkValues = {};
        if (this._perkValues) delete this._perkValues[perk];
      }

      this._subjectPerkValues.next(this._perkValues);
      this._validatePerk.next();
    }
    catch (ex) {
      console.error(ex);
    }
  }

  perkValues(): Observable<{ [key: string]: any }> {
    return this._subjectPerkValues.asObservable();
  }

  clearPerks() {
    try {
      this._subjectPerks.next(null);
      this._validatePerk.next();
    }
    catch (ex) {
      console.error(ex);
    }
  }

  perks(): Observable<{ [key: string]: any }> {
    return this._subjectPerks.asObservable();
  }


  constructor(private http: HttpClient) { }

  getVenuePaging(page: Page): Observable<ReturnResult<PagedData<any>>> {
    return this.http.post<ReturnResult<PagedData<any>>>(`${this.basedUrl}/GetVenuePaging`, page);
  }
  getPricingData(page: Page, venueId: string): Observable<ReturnResult<PagedData<PricingData>>> {
    return this.http.post<ReturnResult<PagedData<any>>>(`${this.basedUrl}/GetVenuePricingPaging/${venueId}`, page);
  }
  savePricingData(model: PricingData, venueId: string): Observable<ReturnResult<PricingData>> {
    return this.http.post<ReturnResult<PricingData>>(`${this.basedUrl}/SavePricingData/${venueId}`, model)
      .pipe(tap(x => this.clearPerkValue(perksProp.numberOfPricing)));
  }
  deletePricing(ids: number[], venueId: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.basedUrl}/DeletePricing/${venueId}`, ids)
      .pipe(tap(x => this.clearPerkValue(perksProp.numberOfPricing)));
  }
  getEventSpaceData(page: Page, venueId: string): Observable<ReturnResult<PagedData<EventSpace>>> {
    return this.http.post<ReturnResult<PagedData<EventSpace>>>(`${this.basedUrl}/GetVenueEventPaging/${venueId}`, page);
  }
  saveEventSpaceData(model: EventSpace, venueId: string): Observable<ReturnResult<EventSpace>> {
    return this.http.post<ReturnResult<EventSpace>>(`${this.basedUrl}/SaveEventSpace/${venueId}`, model)
      .pipe(tap(x => this.clearPerkValue(perksProp.numberOfEventSpace)));
  }
  deleteEventSpace(ids: number[], venueId: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.basedUrl}/DeleteEventSpace/${venueId}`, ids)
      .pipe(tap(x => this.clearPerkValue(perksProp.numberOfEventSpace)));
  }
  deleteVenue(ids: string[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.basedUrl}/DeleteVenue`, ids)
      .pipe(tap(x => this.clearPerkValue(perksProp.numberOfVenue)));
  }
  customerPaging(page: Page): Observable<ReturnResult<PagedData<any>>> {
    return this.http.post<ReturnResult<PagedData<any>>>(`${this.basedUrl}/CustomerPaging`, page);
  }
  getCheckOutUrl(): Observable<ReturnResult<string>> {
    return this.http.get<ReturnResult<string>>(`${this.basedUrl}/CheckOut`);
  }
  getAllPlan(): Observable<ReturnResult<CustomerPlan[]>> {
    return this.http.get<ReturnResult<CustomerPlan[]>>(`${this.basedUrl}/GetAllPlan`)
  }
  getLagoCustomerInformation(): Observable<ReturnResult<LagoCustomer>> {
    return this.http.get<ReturnResult<LagoCustomer>>(`${this.basedUrl}/GetLagoCustomerInfo`);
  }
  createSubscription(planCode: string, couponCode: string): Observable<ReturnResult<CustomerSubscription>> {
    return this.http.post<ReturnResult<CustomerSubscription>>(`${this.basedUrl}/CreateSubscription?planCode=${planCode}&couponCode=${couponCode}`, null);
  }
  getCurrentPlan(): Observable<ReturnResult<CustomerSubscription>> {
    return this.http.get<ReturnResult<CustomerSubscription>>(`${this.basedUrl}/GetSubscription`);
  }
   getLagoPlans(): Observable<ReturnResult<any>> {
     return this.http.get<ReturnResult<any>>(`${this.basedUrl}/GetAllPlansLago`);
   }
  validateCouponAndPlan(couponCode: string, planCode: string): Observable<ReturnResult<CustomerPlanCoupon>> {
    return this.http.post<ReturnResult<CustomerPlanCoupon>>(`${this.basedUrl}/CheckCouponValid?couponCode=${couponCode}&planCode=${planCode}`, null)
  }
  applyCoupon(couponCode: string): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.basedUrl}/ApplyCoupon?couponCode=${couponCode}`, null)
  }
  getPaymentStatus(): Observable<ReturnResult<any>> {
    return this.http.get<ReturnResult<any>>(`${this.basedUrl}/GetMyInvoiceStatus`);
  }
  getAmountAvailable(): Observable<ReturnResult<any>> {
    return this.http.get<ReturnResult<any>>(`${this.basedUrl}/GetAmountAvailable`);
  }

  customerPerks(): Observable<ReturnResult<{ [key: string]: any }>> {
    return this.http.get<ReturnResult<{ [key: string]: any }>>(`${this.basedUrl}/CustomerPerk`)
      .pipe(tap(x => this._subjectPerks.next(x?.result)));
  }

  customerPerkValues(venueId: string, perks: string[]): Observable<ReturnResult<{ [key: string]: any }>> {
    return this.http.post<ReturnResult<{ [key: string]: any }>>(`${this.basedUrl}/CustomerPerkValues?venueId=${venueId}`, perks)
      .pipe(tap(x => {
        try {
          if (!this._perkValues) this._perkValues = {};
          for (let perk of perks) {
            try {
              this._perkValues[perk] = x?.result?.[perk];
            } catch (ex) {
              console.error(ex);
            }
          }

          this._subjectPerkValues.next(this._perkValues);
        }
        catch (ex) {
          console.error(ex);
        }
      }));
  }

  getInvoicePaging(): Observable<ReturnResult<PagedData<any>>> {
    return this.http.post<ReturnResult<PagedData<any>>>(`${this.basedUrl}/ListInvoices`, null);
  }
  getInvoiceURL(lagoId: string, invoiceName: string): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<any>>(`${this.basedUrl}/GetInvoiceURL?invoiceId=${lagoId}&invoiceName=${invoiceName}`, null);
  }
  retryPayment(invoiceId: string): Observable<ReturnResult<any>> {
    return this.http.get<ReturnResult<any>>(`${this.basedUrl}/RetryPayment/${invoiceId}`);
  }
  // downloadInvoice(invoiceURL: string) {
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Accept', 'application/pdf');
  //   return this.http.get(invoiceURL, { headers: headers, responseType: 'blob' });
  // }
  cancelPlan(): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.basedUrl}/CancelPlan`, null);
  }
  disconnectPaymentMethod(): Observable<ReturnResult<LagoCustomer>> {
    return this.http.post<ReturnResult<LagoCustomer>>(`${this.basedUrl}/DisconnectPaymentMethod`, null);

  }

  chartVenueVisitor(filter: any): Observable<ReturnResult<VenueChart[]>> {
    return this.http.post<ReturnResult<VenueChart[]>>(`${this.basedUrl}/ChartVenueVisitor`, filter);
  }

  deleteCustomers(idArr: string[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.basedUrl}/DeleteCustomers`, idArr);
  }
}
