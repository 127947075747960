<mat-toolbar *nbIsGranted="['view', resource.toolbar]" fxLayout="row" fxLayoutAlign="space-between center"
  style="background-color: white;">
  <span fxLayout="row" fxLayoutAlign="start center">
    <ng-container *cusPerk="cusPerkTable">
      <button *nbIsGranted="['view', resource.add]" mat-raised-button color="primary" (click)="onClickAddNew()"
        [disabled]="disableAddNewButton">
        <mat-icon>add</mat-icon>New
      </button>
    </ng-container>
    <button *nbIsGranted="['view', resource.import]" type="button" mat-button (click)="onClickImport()">
      <mat-icon class="mr">file_download</mat-icon>Import
    </button>
    <button *nbIsGranted="['view', resource.export]" [nbSpinner]="btnExportState" [disabled]="btnExportState"
      type="button" mat-button (click)="onClickExport()">
      <mat-icon class="mr">file_upload</mat-icon>Export
    </button>
    <ng-container *ngIf="!hiddenDeleteAllButton">
      <button type="button" color="warn" mat-button (click)="onClickAllFilter()"
        [hidden]="(selectedCout || selectedCout != 0)" *nbIsGranted="['view', 'delete-all']">
        <mat-icon class="mr">delete</mat-icon>Delete All
      </button>
    </ng-container>
    <!-- Check table resource for bulk delete -->

    <ng-container *ngIf="tableResource;else normalDelete">
      <ng-container *nbIsGranted="['view', tableResource.remove]">
        <button type="button" mat-button (click)="onClickDeletes()" *ngIf="selectedCout>0 && hiddenDeleteButton">
          <mat-icon class="mr">delete</mat-icon>Delete
        </button>
      </ng-container>
    </ng-container>
    <ng-template #normalDelete>
      <button type="button" mat-button (click)="onClickDeletes()" *ngIf="selectedCout>0 && hiddenDeleteButton">
        <mat-icon class="mr">delete</mat-icon>Delete
      </button>
    </ng-template>
    <!-- 2021-29-11 tienlm add start -->
    <span>
      <ng-container *ngIf="isEnablecustomButtonFunction" [ngTemplateOutlet]="customSelectsFunction"
        [ngTemplateOutletContext]="table.selected">
      </ng-container>
    </span>
    <!-- 2021-29-11 tienlm add end -->
    <span>
      <ng-container *ngIf="customSelectsFunction2" [ngTemplateOutlet]="customSelectsFunction2"
        [ngTemplateOutletContext]="table.selected">
      </ng-container>
    </span>
    <!-- 2021-29-11 tienlm add start -->

    <span>
      <ng-container *ngIf="customSelectsFunction3" [ngTemplateOutlet]="customSelectsFunction3"
        [ngTemplateOutletContext]="table.selected">
      </ng-container>
    </span>
    <!-- 2021-30-03 tienlm add end -->

    <!-- 2022-04-28 gnguyen start add -->
    <span>
      <ng-container *ngIf="customSelectsFunction4" [ngTemplateOutlet]="customSelectsFunction4"
        [ngTemplateOutletContext]="table.selected">
      </ng-container>
    </span>

    <!-- 2022-04-28 gnguyen end add -->
    <!--2022-08-25 thoainna add start-->
    <span>
      <ng-container *ngIf="customSelectsFunction5" [ngTemplateOutlet]="customSelectsFunction5"
        [ngTemplateOutletContext]="table.selected">
      </ng-container>
    </span>
    <!--2022-08-25 thoainna add end-->
  </span>

  <span>
    <ng-container *ngIf="centerTemplate" [ngTemplateOutlet]="centerTemplate" [ngTemplateOutletContext]="table.selected">
    </ng-container>
  </span>

  <div style="width: 100%;" class="float-right" fxLayout="row" fxLayoutAlign="end center">
    <ng-container *ngIf="isAlgoliaSearch" [ngTemplateOutlet]="algoliaSearch">
    </ng-container>
  </div>
  <div class="float-right" fxLayout="row" fxLayoutAlign="end center">
    <!-- search Input -->
    <ng-container *ngIf="isCustomSearch">
      <ng-container *nbIsGranted="['view', 'search-profile-block']" [ngTemplateOutlet]="customSearch">
      </ng-container>
    </ng-container>
    <!-- end search Input -->

    <mat-form-field appearance="standard" *ngIf="isShowEntitiesLimit">
      <mat-label>Entities per page</mat-label>
      <mat-select [(value)]="currentPageLimit" (selectionChange)="changeLimitEntities($event)">
        <mat-option *ngFor="let option of pageLimitOptions" [value]="option">
          {{option == -1 ? 'ALL' : option}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <span *ngIf="!customView" style="margin-left: 16px">
      <ng-container *ngIf="enableCustomViewButton">
        <button type="button" mat-icon-button title="Columns" [matMenuTriggerFor]="table.columnMenu" *ngIf="columns">
          <i class="fas fa-columns"></i>
        </button>
      </ng-container>
      <button type="button" mat-icon-button title="Clear Filter" [disabled]="table.isLoading == 1" debouncedClick
        throttleTime="1000" (throttleClick)="configClearFilter()">
        <i class="fas fa-redo"></i>
      </button>
    </span>
  </div>
</mat-toolbar>
<mat-divider></mat-divider>
<app-primas-custom-view *ngIf="customView" [resource]="resource.customView" [table]="table" [columns]="columns"
  (afterClearFilter)="invokeClearFilter()">
</app-primas-custom-view>

<ng-template #confirmDeleteMode>
  <div class="confirmDeleteMode">
    <mat-form-field appearance="standard">
      <p>Type <b style="color:#F44336">DELETE ALL</b> to confirm delete</p>
      <input matInput (input)="onDeleteFormChange($event.target.value)" autocomplete="off" trim="blur" required
        cdkFocusInitial>
    </mat-form-field>
  </div>
</ng-template>
