import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Helper } from "src/app/shared/utility/Helper";

export function countWordValidators(maxNumber: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const text = control.value || "";
    var result = Helper.isValidMaximumWords(text, maxNumber);
    return result ? null : {maxWordsErrorMessage: `Maximum word is ${maxNumber} words.`};
  }
}
