<div mat-dialog-content>
  By clicking Submit now button, this URL <a href="{{ffcUrl?.url}}" target="_blank">{{ffcUrl?.url}}</a> 
  will be submitted to Google for request {{ffcUrl?.state === 'INDEXED' ? 'to fetch' : 'index'}}. 
  Do you want to proceed ?
</div>
<div mat-dialog-actions align="end">
  <button mat-icon-button style="color: red;" [nbSpinner]="!resetDate" 
  matTooltip="Reset date: {{resetDate | date: 'M/dd/y, h:mm:ss aaa'}}"
  matTooltipPosition="above" *ngIf="dailyIndexCounter?.LastIndexDate">
    <mat-icon>help_outline</mat-icon>
  </button>

  <button mat-button [disabled]="(currentDate.getTime() < resetDate?.getTime() && dailyIndexCounter?.IndexQPD >= indexDailyLimit) || (!dailyIndexCounter?.IndexQPD && !indexDailyLimit) || isIndexing"
  [nbSpinner]="(!dailyIndexCounter?.IndexQPD && !indexDailyLimit) || isIndexing" (click)="submitIndexing()">
    {{ffcUrl?.state === 'INDEXED' ? 'Fetch now' : 'Request now'}}
    <span *ngIf="dailyIndexCounter?.IndexQPD != null && indexDailyLimit != null">
      ({{dailyIndexCounter?.IndexQPD}} /  {{indexDailyLimit}})
    </span>
  </button>

  <button mat-button mat-dialog-close>Cancel</button>
</div>
