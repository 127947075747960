import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Category } from 'src/app/modules/admin/profile-management/profile-detail.model';
import { ContactService } from '../../services/contact.service';
import { AutoCompleteCategoriesService } from '../stand-alone-component/auto-complete-categories/auto-complete-categories.service';
import { CategoryFilterService } from './category-filter.service';
import { PagingTreeFilter } from './paging-profile-filter';
import { Helper } from '../../utility/Helper';

export class TreeNode {
  id: string;
  name: string;
  parentId: string;
  children?: TreeNode[];

  // 2023-03-28 vunh add start
  disabled?: boolean = false;
  // 2023-03-28 vunh add end
}

export class TreeFlatNode {
  expandable: boolean;
  id: string;
  name: string;
  level: number;
  parentId: string;

  // 2023-03-28 vunh add start
  disabled?: boolean = false;
  // 2023-03-28 vunh add end
}

export class TreeSelected {
  id: string;
  parentId: string;
  flagSelected: boolean;
  children?: TreeSelected[];
}

@Component({
  selector: 'app-tree-structure-filter',
  templateUrl: './tree-structure-filter.component.html',
  styleUrls: ['./tree-structure-filter.component.scss']
})

export class TreeStructureFilterComponent implements OnInit, OnChanges {

  @Input() page: PagingTreeFilter;
  //2022-02-14 vuonglqn add start
  @Input() arrayFilterCategory: TreeSelected[] = [];
  //2022-02-14 vuonglqn add end

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TreeFlatNode>(true /* multiple */);
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TreeFlatNode, TreeNode>();
  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TreeNode, TreeFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TreeFlatNode | null = null;

  treeControl: FlatTreeControl<TreeFlatNode>;

  treeFlattener: MatTreeFlattener<TreeNode, TreeFlatNode>;

  dataSource: MatTreeFlatDataSource<TreeNode, TreeFlatNode>;

  //arrayFilterCategory: string[] = [];
  //arrayFilterParentCategory: string[] = [];
  arrayBackupFilter: TreeSelected[] = [];
  isWattingReloadSelected: boolean = false;

  @Output() onRefresh = new EventEmitter<any>();
  @Output() onRefreshMatChip = new EventEmitter<any>();

  constructor() {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TreeFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  ngOnInit(): void { }

  transformer = (node: TreeNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.id === node.id
      ? existingNode
      : new TreeFlatNode();
    flatNode.name = node.name;
    flatNode.level = level;
    flatNode.parentId = node.parentId;
    flatNode.expandable = !!node.children?.length;
    flatNode.id = node.id;
    flatNode.disabled = node.disabled;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  refreshTable(reset: boolean = false) {
    this.arrayBackupFilter = JSON.parse(JSON.stringify(this.arrayFilterCategory)) as TreeSelected[];
    this.onRefresh.emit(reset);
  }

  isExpandable = (node: TreeFlatNode) => node.expandable;
  getChildren = (node: TreeNode): TreeNode[] => this.dataSource.data.find(x => x.id == node.id).children;
  hasChild = (_: number, node: TreeFlatNode) => node.expandable;
  getLevel = (node: TreeFlatNode) => node.level;
  hasNoContent = (_: number, _nodeData: TreeFlatNode) => _nodeData.name === '';

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TreeFlatNode): void {
    this.checklistSelection.toggle(node);
    this.getExistSelected(node);
    this.checkAllParentsSelection(node);
    this.updateFilterCategory();
    if (!this.isWattingReloadSelected)
      this.refreshTable(true);

  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TreeFlatNode): void {
    let parent: TreeFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TreeFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TreeFlatNode): TreeFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TreeFlatNode): void {
    this.checklistSelection.toggle(node);
    //2022-02-15 vuonglqn add start
    this.getExistSelected(node);
    //2022-02-15 vuonglqn add end
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
    this.updateFilterCategory();
    if (!this.isWattingReloadSelected)
      this.refreshTable(true);
  }

  updateFilterCategory() {
    const selected = this.checklistSelection.selected;//.filter(x => x.expandable == false);
    this.disableNode(selected);

    if (!this.page) this.page = new PagingTreeFilter();
    if (this.page) this.page.treeFilters = [...selected.map(x => x.id).filter(Helper.onlyUnique)];
    if (!this.isWattingReloadSelected)
      this.onRefreshMatChip.emit({ flag: true, filterCate: this.arrayFilterCategory });
  }

  disableNode(selected: TreeFlatNode[]) {
    // selected list has id none, disable everything except none
    if (selected.findIndex(item => item.id === "default-id-none-category") !== -1) {
      this.dataSource.data = [...this.dataSource.data.map(item => {
        item.disabled = item.id !== "default-id-none-category";
        item.children = [...item.children.map(x => Object.assign(x, { disabled: x.id !== "default-id-none-category" }))];
        return item;
      })]
    }
    // selected list does not have none
    else {
      // selected list has item, disable none
      if (selected?.length > 0) {
        this.dataSource.data = [...this.dataSource.data.map(item => {
          item.disabled = item.id === "default-id-none-category";
          item.children = [...item.children.map(x => Object.assign(x, { disabled: x.id === "default-id-none-category" }))];
          return item;
        })]
      }
      // selected list empty, enable everything
      else {
        this.dataSource.data = [...this.dataSource.data.map(item => {
          item.disabled = false;
          item.children = [...item.children.map(x => Object.assign(x, { disabled: false }))];
          return item;
        })]
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const inputChanges = changes.arrayFilterCategory;
    if (inputChanges && !inputChanges.firstChange) {
      if (JSON.stringify(this.arrayBackupFilter) !== JSON.stringify(this.arrayFilterCategory)) {
        this.reloadChangeBehavior();
      }
    }
  }

  reloadChangeBehavior(isFirst: boolean = false) {
    //Clean data not change in filter array
    const listParent = this.arrayFilterCategory.filter(x => this.arrayBackupFilter.find(y => (y.id == x.id && y.children?.length > 0 && x.children?.length > 0)));

    var childChange = [];
    if (listParent.length > 0)
      listParent.forEach(x => {
        childChange.push(...this.getItemChildrenDiffrent(this.arrayBackupFilter.find(y => y.id == x.id), this.arrayFilterCategory.find(z => z.id == x.id)));
      })

    const removeDataNotChange = [
      ...this.arrayFilterCategory.filter(x => this.arrayBackupFilter.find(y => (y.id == x.id && y.flagSelected != x.flagSelected))), // Check filter existed but change flag
      ...this.arrayFilterCategory.filter(x => !this.arrayBackupFilter.map(y => y.id).includes(x.id)), //add new filter category
      ...childChange
    ];

    //Get data TreeFlatNode with data need change.
    const changeBehaviorSelect = this.dataSource._flattenedData.value.filter(x => removeDataNotChange.map(x => x.id).includes(x.id)).filter(x => x.name !== '!this!');

    this.arrayBackupFilter = JSON.parse(JSON.stringify(this.arrayFilterCategory)) as TreeSelected[];

    this.isWattingReloadSelected = !isFirst;
    const checkDuplicateParentAndChild = this.checkDuplicateUpdateParentAndChild(changeBehaviorSelect);

    checkDuplicateParentAndChild.forEach(x => {
      if (x.expandable) {
        this.todoItemSelectionToggle(x);
      } else {
        this.todoLeafItemSelectionToggle(x);
      }
    });
    this.isWattingReloadSelected = false;
    //this.refreshTable(true);
  }

  getExistSelected(node: TreeFlatNode) {
    if (!this.isWattingReloadSelected) {
      //check parent existed
      var checkParent = this.arrayFilterCategory.find(x => x.id === node.parentId);
      if (checkParent != null) {
        var checkChild = checkParent.children.find(x => x.id === node.id);
        checkChild.flagSelected = !checkChild.flagSelected;
        checkParent.flagSelected = this.checkFullItemChildOfParent(checkParent);
      } else {
        var existSelected = this.arrayFilterCategory.find(x => x.id === node.id);
        if (existSelected == null) {
          this.arrayFilterCategory.push(
            {
              id: node.id,
              flagSelected: true,
              parentId: node.parentId,
              children: node.parentId == null ? this.dataSource._flattenedData.value
                .filter(x => x.parentId === node.id && x.parentId !== x.id)
                .map(x => {
                  return { id: x.id, flagSelected: true, parentId: x.parentId } as TreeSelected;
                }) : null,
            });

          if (node.parentId == null) {
            existSelected = this.arrayFilterCategory.find(x => x.id === node.id);
            var findChildOutside = this.arrayFilterCategory.filter(x => x.parentId === existSelected.id);
            this.arrayFilterCategory = [...this.arrayFilterCategory.filter(x => !findChildOutside.includes(x))]
          } else {
            var parentCurrentNode = this.dataSource.data.find(x => x.id == node.parentId);
            if (parentCurrentNode) {
              var countNodeHaveParentEqual = this.arrayFilterCategory.filter(x => x.parentId == parentCurrentNode.id);
              if (parentCurrentNode.children?.length == countNodeHaveParentEqual.length) {
                var findChildOutside = this.arrayFilterCategory.filter(x => x.parentId === parentCurrentNode.id);
                this.arrayFilterCategory = [...this.arrayFilterCategory.filter(x => !findChildOutside.includes(x))];
                this.arrayFilterCategory.push(
                  {
                    id: parentCurrentNode.id,
                    flagSelected: true,
                    parentId: parentCurrentNode.parentId,
                    children: parentCurrentNode.parentId == null ? this.dataSource._flattenedData.value
                      .filter(x => x.parentId === parentCurrentNode.id && x.parentId !== x.id)
                      .map(x => {
                        return { id: x.id, flagSelected: true, parentId: x.parentId } as TreeSelected;
                      }) : null,
                  });
              }
            }
          }
        }
        else {
          if (existSelected.children?.length > 0) {
            existSelected.children.forEach(x => x.flagSelected = !existSelected.flagSelected);
          }

          existSelected.flagSelected = !existSelected.flagSelected;
        }
      }
    }
  }

  checkFullItemChildOfParent(parent: TreeSelected) {
    var getChildFlagFalse = parent.children.filter(x => !x.flagSelected);
    if (getChildFlagFalse.length > 0)
      return false;
    else
      return true;
  }

  getItemChildrenDiffrent(backup: TreeSelected, current: TreeSelected): TreeSelected[] {
    var result = [] as TreeSelected[];
    if (backup.children?.length > 0 && current.children?.length > 0)
      result = current.children.filter(x => backup.children.find(y => (y.id == x.id && y.flagSelected != x.flagSelected)))
    return result;
  }
  //2022-02-15 vuonglqn add end

  //Check update Parent and child at a moment
  checkDuplicateUpdateParentAndChild(listNodes: TreeFlatNode[]) {
    if (listNodes && listNodes.length > 0) {
      var listParent = listNodes.filter(x => x.expandable && x.level == 0);
      if (listParent && listParent.length > 0) {
        listNodes = [...listNodes.filter(x => !listParent.map(x => x.id).includes(x.parentId))]
      }
    }

    return listNodes;
  }

  //Get Item TreeFlatNode with input category
  getItemTreeFlatNode(cate: Category) {
    return this.dataSource._flattenedData.value.find(x => cate.categoryId == x.id && x.name !== '!this!');
  }

  getItemTreeFlatNodeDynamic<T>(item: T, key: string) {
    return this.dataSource._flattenedData.value.find(x => item[key] == x.id && x.name !== '!this!');
  }
}