<div class="d-flex flex-wrap" mat-dialog-title style="margin: unset;padding: 1px 10px 1px 28px;"
  [nbSpinner]="isLoading">
  <button mat-button color="primary" (click)="saveData(true)" [disabled]="!form.valid">
    <mat-icon fontSet=" material-icons-outlined">save</mat-icon>Save
  </button>
  <button mat-button color="primary" (click)="closeDialog()">
    <mat-icon>clear</mat-icon>Cancel
  </button>
  <button mat-icon-button aria-label="Close the dialog" (click)="closeDialog()" class="ml-auto">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="form" class="container" id="custom-view-form" (ngSubmit)="saveData()" (keypress)="listenEventKeyPressEnter($event)"
    style="padding-top: 25px;padding-right: 50px;padding-left: 50px;">
    <div class="row">
      <div class="col-12 d-flex">
        <label class="mr-3">Scope</label>
        <mat-radio-group formControlName="isPrivate">
          <mat-radio-button color="primary" [value]=false class="mr-2">Public</mat-radio-button>
          <mat-radio-button color="primary" [value]=true>Private</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="col-12">

        <mat-form-field appearance="standard">
          <mat-label>View Name</mat-label>
          <input matInput formControlName="viewName" autocomplete="off" required trim="blur">
          <mat-error
            *ngIf="form.controls.viewName.errors && (form.controls.viewName.dirty || form.controls.viewName.touched)">
            {{form.controls.viewName['errorMessage']}}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-12">
        <ngx-datatable #table class="material ignoreborder" [rows]="rows" [columns]="columns"
          [columnMode]="ColumnMode.force" [footerHeight]="0" [headerHeight]="50" [rowHeight]="50" [selected]="selected"
          [selectionType]="SelectionType.checkbox" [selectAllRowsOnPage]="true" [reorderable]="false"
          [swapColumns]="false" (select)="onSelect($event)">
        </ngx-datatable>
        <ng-template #columnName let-value="value">
          <div class="text-limit" [matTooltip]="value">{{value}}</div>
        </ng-template>
        <ng-template #customFilter let-row="row" let-value="value" let-rowIndex="rowIndex"
          let-filterType="row.filterType" let-filterOperator="column.filterOprator">

          <mat-form-field fxFlex="90" *ngIf="filterType == null || filterType==0 || filterType == 1">
            <input matInput [type]=" filterType == 1 ? 'number' : 'text'" [value]="value" (change)="onChangeFilter($event.target.value, row)" />
          </mat-form-field>

          <!-- <app-fromdate-todate fxFlex="85" *ngIf="filterType && filterType==2" [value]="value"
            (onFilter)="onFilterDateTime($event,row)"></app-fromdate-todate> -->
          <app-datetime-filter fxFlex="90" *ngIf="filterType && filterType==2" [columnName]=""
            [value]="row.filterValue??null" (onFilter)="onFilterDateTime($event, row)" [operationMode]="row.filterOperator">
          </app-datetime-filter>

          <app-date-filter fxFlex="90" *ngIf="filterType && filterType==5" [columnName]=""
            [value]="row.filterValue??null" (onFilter)="onFilterDateTime($event, row)" [operationMode]="row.filterOperator">
          </app-date-filter>

          <app-boolean-filter fxFlex="90" *ngIf="filterType && filterType==4" [filter]="row.filter" [value]="value"
          (onFilter)="onFilterBoolean($event,row)">
          </app-boolean-filter>

          <app-dropdown-filter fxFlex="90" *ngIf="filterType && filterType==3" [filter]="row.filter" [value]="value"
            (onFilter)="onFilterDropDown($event,row)"></app-dropdown-filter>

            <app-dropdown-filter fxFlex="90" *ngIf="filterType && filterType==6" [filter]="row.filter" [value]="value"
            (onFilter)="onFilterDropDown($event,row)"></app-dropdown-filter>

            <app-dropdown-filter fxFlex="90" *ngIf="filterType && filterType==7" [filter]="row.filter" [value]="value"
            (onFilter)="onFilterDropDown($event,row)"></app-dropdown-filter>

            <!--Filter Mail Action-->
            <app-mail-log-filter fxFlex="90" *ngIf="filterType && filterType==8" [columnName]=""
            [value]="row.filterValue ?? null" (onFilterText)="onChangeFilterHeader($event, row)"
            [operationMode]="row.filterOperator" (onEmailActionFilter)="onEmailActionFilter($event,row)">
          </app-mail-log-filter>

            <!--Filter Operator-->
          <app-dropdown-filter-operator fxFlex="10" *ngIf="row.filter || filterType" [column]="row"
            (onFilter)="onFilterOperator($event, row)">
          </app-dropdown-filter-operator>

        </ng-template>
      </div>


    </div>
    <div class="row">
      <div class="col-12">
        <ngx-datatable #tableSort class="material ignoreborder" [columns]="columnsSort" [columnMode]="ColumnMode.force"
          [rows]="rowsSort" [footerHeight]="0" [headerHeight]="50" [rowHeight]="50" [reorderable]="false"
          [swapColumns]="false" [messages]="message">
        </ngx-datatable>

        <ng-template #removeSortRow let-row="row" let-value="value" let-rowIndex="rowIndex"
          let-filterType="row.filterType">
          <button *ngIf="rowsSort.length > 1" mat-icon-button type="button" class="fix-width-button"
            (click)="removeSort(row)">
            <mat-icon>close</mat-icon>
          </button>

        </ng-template>

        <ng-template #customSortField let-row="row" let-value="value" let-rowIndex="rowIndex"
          let-filterType="row.filterType">
          <mat-form-field>
            <mat-select [value]="value" (selectionChange)="onChangeColumnSort($event.value, value, row)">
              <mat-option *ngFor="let column of selected; let i = index" [value]="column.prop"
                [disabled]="column.selected">
                {{column.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </ng-template>
        <ng-template #customSortOrder let-row="row" let-value="value" let-rowIndex="rowIndex"
          let-filterType="row.filterType">
          <mat-form-field>
            <mat-select [value]="value" (selectionChange)="onChangeSort($event.value, row)">
              <mat-option [value]="0">ASC</mat-option>
              <mat-option [value]="1">DESC</mat-option>
            </mat-select>
          </mat-form-field>

        </ng-template>
      </div>

      <div class="col-12 text-right">
        <button mat-button color="primary" type="button" (click)="addMoreSort()">
          <mat-icon>add</mat-icon>Add more
        </button>

      </div>
    </div>

    <button type="submit" style="display: none;"></button>
  </form>
</mat-dialog-content>
