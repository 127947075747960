import { Component, Inject, Input, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { OnBoardingFormAction, OnBoardingFormEnums } from 'src/app/shared/contances/on-boarding-form-action';
import { Helper } from 'src/app/shared/utility/Helper';
import { ProfileDetailModel } from '../../../profile-detail.model';
import { MergeChangesService } from './merge-changes.service';
import { DynamicAddedChange, DynamicUpdatedChange, ProfileUpdateInformationViewModel, UpdateInformation } from './update-information.model';
import { async } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-merge-changes',
  templateUrl: './merge-changes.component.html',
  styleUrls: ['./merge-changes.component.scss']
})
export class MergeChangesComponent implements OnInit, OnDestroy {

  @Input()
  profileDetails: ProfileDetailModel;
  profileUpdateInfomation: ProfileUpdateInformationViewModel;
  subscription: Subscription;
  loading = false;
  AddChangesList: any[] = [];
  UpdateChangesList: any[] = [];
  declineAllLoading = false;
  constructor(
    private updateInformationService: MergeChangesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: NbToastrService,
    private dialog: MatDialog,
    public dialModalRef: MatDialogRef<MergeChangesComponent>) {
    this.profileDetails = data.profile ?? null;
    this.subscription = this.updateInformationService.getUpdateInformationByProfileId().subscribe(resp => {
      if (resp) {
        this.profileUpdateInfomation = resp;
        console.log(resp);
        this.loading = false;
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    // this.dialModalRef.updateSize('63vw', '100vh');
    this.loading = true;
    this.updateInformationService.refreshUpdateInformationByProfileId(this.profileDetails.profileId);
  }

  filterCurrentProfileFromResult() {

  }
  getEntityByUpdateAct(updateInformation: UpdateInformation) {
    if (updateInformation) {
      switch (updateInformation.entityTypeId) {
        case OnBoardingFormEnums.ACT:
          {
            if (updateInformation.entityId === this.profileDetails.profileId) {
              return this.profileDetails;
            } else {
              const profileLst = this.profileUpdateInfomation.profileToUpdate.filter(x => x.profileId === updateInformation.entityId);
              if (profileLst.length > 0) {
                return profileLst[0];
              } else {
                return this.profileDetails;
              }
            }
          }
        case OnBoardingFormEnums.ARTIST_DETAILS: {
          if (updateInformation.profileId === this.profileDetails.profileId) {
            return this.profileDetails;
          } else {
            const profileLst = this.profileUpdateInfomation.profileToUpdate.filter(x => x.profileId === updateInformation.profileId);
            if (profileLst.length > 0) {
              return profileLst[0];
            } else {
              return null;
            }
          }
        }
        case OnBoardingFormEnums.CONTACT: {
          // tslint:disable-next-line:radix
          const contactLst = this.profileUpdateInfomation.contactToUpdate.filter(x => x.contactId === parseInt(updateInformation.entityId));
          if (contactLst.length > 0) {
            return contactLst[0];
          } else {
            return null;
          }
        }
          break;
        case OnBoardingFormEnums.LOCATION:
          {
            if (this.profileUpdateInfomation.locationToUpdate) {
              return this.profileUpdateInfomation.locationToUpdate;
            } else {
              return null;
            }
          }
          // tslint:disable-next-line:align
          break;
        default:
          break;
      }
    }
  }
  reloadData() {
    this.loading = true;
    this.updateInformationService.refreshUpdateInformationByProfileId(this.profileDetails.profileId);
  }
  addToList(input: { type: string, model: any }) {
    switch (input.type) {
      case OnBoardingFormAction.ADDED:
        {
          const index = this.AddChangesList.findIndex(x => x.updateInformation.id === input.model.updateInformation.id);
          if (index === -1) {
            console.log('Add: ');
            this.AddChangesList.push(input.model);
            console.log(this.AddChangesList);
          }
          else {
            console.log('Update: ');
            console.log(this.UpdateChangesList);
            this.AddChangesList.splice(index, 1);
            this.AddChangesList.push(input.model);
          }
        }
        break;
      case OnBoardingFormAction.MODIFIED:
        {
          const index = this.UpdateChangesList.findIndex(x => x.updateInformation.id === input.model.updateInformation.id);
          if (index === -1) {
            console.log('Add: ');
            this.UpdateChangesList.push(input.model);
            console.log(this.UpdateChangesList);
          } else {
            console.log('Update: ');
            this.UpdateChangesList.splice(index, 1);
            this.UpdateChangesList.push(input.model);
          }

        }
        break;
    }
  }
  removeFromLst(input: { type: string, model: any }) {
    switch (input.type) {
      case OnBoardingFormAction.ADDED:
        {
          const index = this.AddChangesList.findIndex(x => x.updateInformation.id === input.model.updateInformation.id);
          if (index !== -1) {
            this.AddChangesList.splice(index, 1);
            console.log(this.AddChangesList);
          }
        }
        break;
      case OnBoardingFormAction.MODIFIED:
        {
          const index = this.UpdateChangesList.findIndex(x => x.updateInformation.id === input.model.updateInformation.id);
          if (index !== -1) {
            this.UpdateChangesList.splice(index, 1);
            console.log(this.UpdateChangesList);
          }
        }
        break;
    }
  }
  // accept all changes:
  async acceptAllChanges() {
    this.declineAllLoading = true;
    let addedFormatList: DynamicAddedChange[] = this.AddChangesList?.map(item => {
      // format props to correct model
      item["type"] = item.table;
      item[item.table] = item.entity;

      // change props to camelCase
      return Helper.toCamelCase(null, item);
    })
    let modifiedFormatList: DynamicUpdatedChange[] = this.UpdateChangesList?.map(item => {
      // format props to correct model
      item["type"] = item.table;
      item[item.table] = item.entity;

      // change props to camelCase
      return Helper.toCamelCase(null, item);
    })
    for (let res of modifiedFormatList){
      if(res.updateInformation?.actionId == 'MODIFIED' && res.updateInformation?.entityTypeId == 'ARTIST_DETAILS' 
      && res.updateInformation?.propertyName == 'DisplayName' && res.updateInformation?.propertyValue){
        var updateDisplayName = await this.updateInformationService.acceptChangesAdded(res.updateInformation,this.getEntityByUpdateAct(res.updateInformation),'Profile').toPromise();
        if(updateDisplayName.result){
          const indexToRemove  = modifiedFormatList.findIndex(x=>x == res);
            if(indexToRemove !== -1){
              modifiedFormatList.splice(indexToRemove,1);
            }
        }
      }
    }
    let deletedFormatList: DynamicUpdatedChange[] = this.profileUpdateInfomation.deleted?.map(item => {
      return {
        type: item.actionId,
        updateInformation: item,
      } as DynamicUpdatedChange;
    })

    modifiedFormatList = modifiedFormatList.filter(item => this.profileUpdateInfomation.modified.some(e => e.id == item.updateInformation.id));
    addedFormatList = addedFormatList.filter(item => this.profileUpdateInfomation.added.some(e => e.id == item.updateInformation.id));

    this.updateInformationService.acceptAll([...addedFormatList, ...modifiedFormatList, ...deletedFormatList]).subscribe(resp => {
      this.declineAllLoading = true;
      if (resp.result) {
        this.toast.success("Accept All Changes successfully", "Success");
        this.dialModalRef.close(true);
      }
      this.reloadData();
      this.declineAllLoading = false;
    })
  }
  declineAll() {
    var updateInformation = [...this.profileUpdateInfomation.added, ...this.profileUpdateInfomation.modified, ...this.profileUpdateInfomation.deleted]
    var dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to reject all changes?`
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.declineAllLoading = true;
        this.updateInformationService.rejectAll(updateInformation).subscribe(res => {
          if (res.result) {
            this.toast.success('Decline All Changes successfully', 'Success');
            this.loading = true;
            this.updateInformationService.refreshUpdateInformationByProfileId(this.profileDetails.profileId);
            this.dialModalRef.close(true);
          }
          this.declineAllLoading = false;
        });
      }
    })
  }
  getDeleteEntity(updateInformation: UpdateInformation) {
    if (updateInformation) {
      switch (updateInformation.entityTypeId) {
        case OnBoardingFormEnums.ACTBLOCK:
          {
            let prof = this.profileUpdateInfomation.profileToDelete.find(x => x.profileId == updateInformation.entityId);
            if (prof) {
              return prof;
            }
            return null;
          }
          break;
        case OnBoardingFormEnums.CONTACTBLOCK:
          {
            let con = this.profileUpdateInfomation.contactToDelete.find(x => x.contactId == parseInt(updateInformation.entityId))
            if (con) {
              return con;
            }
            return null;
          }
          break;
        default:
          return null;
          break;
      }
    }
  }
}
