import { EventEmitter, Inject, Input, Output, OnChanges, SimpleChanges, ViewChild, AfterViewInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, map, startWith, finalize, pairwise, skip } from 'rxjs/operators';
import { Contact, SimpleCovertContact } from './contact.model';
import { ContactTypeFilter, countries } from './contact-type';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { ContactService } from 'src/app/shared/services/contact.service';
import { NbToastrService } from '@nebular/theme';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CountryObject } from 'src/app/shared/models/country-object.model';
import { Contact as DetailContact, KeyPairsValue, KeyPairsValueObjectAPI, ProfileDetailModel } from '../../../../modules/admin/profile-management/profile-detail.model';
import { QuillConfiguration } from '../rich-inline-edit/rich-inline-edit.component';
import { Helper } from 'src/app/shared/utility/Helper';
import { SearchTypeObjectsComponent } from '../../search-type-objects/search-type-objects.component';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { TypeEditModelSaleLead } from 'src/app/modules/admin/sale-lead-management/sale-lead-details/sale-lead-details.component';
import { DynamicContentModel } from 'src/app/shared/models/dynamic-content.model';
import { DynamicContentService } from 'src/app/shared/services/dynamic-content.service';
import { SaleAccountService } from 'src/app/modules/admin/sale-account-management/sale-account.service';
import { SimpleConvertContactComponent } from './simple-convert-contact/simple-convert-contact.component';
import {Location} from '@angular/common';
import { Router } from '@angular/router';

export const saluationList = [
  'Mr.',
  'Mrs.',
  'Ms.',
  'Miss.',
  'Dr.'
];

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})


export class ContactComponent implements OnInit, OnChanges, AfterViewInit {

  constructor(
    public matDialogRef: MatDialogRef<ContactComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: ContactService,
    private frmBuilder: RxFormBuilder,
    private toast: NbToastrService,
    private dialog: MatDialog,
    private dynamicContentService: DynamicContentService,
    private saleAccountService: SaleAccountService,
    private cdref: ChangeDetectorRef,
    private _location: Location,
    private router: Router,
  ) {
    this.contactLst = ContactComponent.parseContactFromDialogInput(data.contactLst) ?? this.contactLst;
    this.profileId = data.profileId ?? this.profileId;
    this.selfGetApi = data.selfGetApi ?? this.selfGetApi;
    this.isDialog = data.isDialog ?? false;
    this.includeLocation = data.includeLocation ?? this.includeLocation;
    this.closeDialogEvent = data.closeDialogEvent ? data.closeDialogEvent : null;
    this.isUpdateAction = data.isUpdateAction ?? this.isUpdateAction;
    this.isAddMoreBtn = data.isAddMoreBtn ?? this.isAddMoreBtn;
    this.producerId = data.producerId ?? this.producerId;
    this.buyerId = data.buyerId ?? this.buyerId;
    this.contactIndex = data.contactIndex ?? this.contactIndex;
    this.isSetPrimaryable = data.isSetPrimaryable ?? this.isSetPrimaryable;
    this.isDupplicatable = data.isDupplicatable ?? this.isDupplicatable;
    this.isOwnerMode = data.isOwnerMode ?? this.isOwnerMode;
    this.readonlyContact = data.readonlyContact ?? this.readonlyContact;
    // 2021-11-3 HMTien add start
    this.saveErrorNotification = data.saveNotification ?? this.saveErrorNotification;
    // 2021-11-3 HMTien add en
    this.dynamicContentService.getDynamicContentByType('general_company').subscribe((resp) => {
      if (resp.result) {
        this.getDynamicContent = resp.result;
        this.filteredCompany.next(this.getDynamicContent);
      }
    });
    this.typeDynamicAPI = this.dynamicContentService.getDynamicContentByType('general_department');
    this.sourceDynamicAPI = this.dynamicContentService.getDynamicContentByType('contact_source');
  }
  @Input()
  closeDialogEvent: (result) => boolean;
  @Input() readonlyContact = false;
  @Input() contactLst: Contact[] = [];
  @Input() profileId = '';
  @Input() includeLocation = true;
  @Input() isAddMoreBtn = true;
  @Input() isUpdateAction = false;
  @Input() contactIndex: string;
  @Input() producerId = '';
  @Input() buyerId: string = '';
  @Input() isSetPrimaryable = true;
  // on/off duplicate
  @Input() isDupplicatable = true;
  // 2022-02-10 tienlm add start
  @Input() bypassPermissions = false;
  // 2022-02-10 tienlm add end
  @Input() isLastSave = true;
  /**
   *  this mode is for contact management only: it will create/update only contact
   *  true to activate and otherwise
   */
  @Input() isOwnerMode = false;
  @Output() refreshTask: EventEmitter<void> = new EventEmitter();

  relationshipToObjComponent: QueryList<SearchTypeObjectsComponent>;
  @ViewChildren('relationshipToObj') set setRelationshipToObjComponent(content: QueryList<SearchTypeObjectsComponent>) {
    if (content) {
      this.relationshipToObjComponent = content;
      if (!window.location.pathname.includes("configuration/contact") && !this.isUpdateAction) {
        this.relationshipToObjComponent.map(x => {
          if (x) {
            x.element.nativeElement.remove();
            x.ngOnDestroy();
          }
        });
      }
    }
  };

  countriesObservable = new Observable<CountryObject[]>();
  // use for location country
  countries = [...countries];
  public get TypeEditModelSaleLead(): typeof TypeEditModelSaleLead { return TypeEditModelSaleLead; }
  dynamicContentCompanyAPI: Observable<ReturnResult<DynamicContentModel[]>>;
  // is dialog or not
  @Input() isDialog = false;
  loading = false;
  formContactLst: FormGroup[] = [];
  deletedContact: Contact[] = [];
  howFarLst = [
    { key: 'National', value: 'National' },
    { key: 'International', value: 'International' },
    { key: '0 to 100 (hours)', value: '0TO100' },
    { key: '100 to 250 (hours)', value: '100TO250' },
    { key: 'Case-by-case', value: 'CaseByCase' },

  ];
  @Input() selfGetApi = false;
  arrayContactType: ContactTypeFilter[];
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onRefresh = new EventEmitter<any>();

  // 2021-11-3 HMTien add start
  saveErrorNotification = false;
  saveErrorMessage: string;
  // 2021-11-3 HMTien add end
  //2022-09-12 tienlm add start
  saluationList = saluationList;
  editorOptions = QuillConfiguration
  saleAccount: ProfileDetailModel;

  //2022-11-16 thoainna add start
  filteredCompany: Subject<DynamicContentModel[]> = new Subject<DynamicContentModel[]>();
  filterArray: DynamicContentModel[] = [];
  getDynamicContent: DynamicContentModel[] = [];
  typeDynamicAPI;
  typeLoading = false;
  sourceDynamicAPI;
  sourceLoading = false;
  //2022-11-16 thoainna add end
  //2022-09-12 tienlm add end
  apiGetSelectedObjects: Observable<ReturnResult<KeyPairsValueObjectAPI[]>> = null;
  isChange: boolean = false;
  stateOptions = [];
  disableConvertBtn = false;

  // this function will parse contact from contact model to this model's contact
  static parseContactFromDialogInput(contacts: DetailContact[]): Contact[] {
    const parsedContact: Contact[] = [];
    if (contacts !== undefined && contacts.length > 0) {
      contacts.forEach(item => {
        // have location:
        const tempContact = {
          contactId: item.contactId,
          contactPhone: item.contactPhone,
          contactName: item.contactName,
          contactLastName: item.contactLastName,
          relationship: item.relationship,
          secondaryPhone: item.secondaryPhone,
          contactEmail: item.contactEmail,
          secondaryEmail: item.secondaryEmail,
          cellPhone: item.cellPhone,
          facebook: item.facebook,
          skype: item.skype,
          whatsApp: item.whatsApp,
          tiktok: item.tiktok,
          locationId: item.locationId,
          primaryContact: item.primaryContact,
          deleted: item.deleted,
          ownerId: item.ownerId,
          website: item.website,
          jobTitle: item.jobTitle,
          tags: item.tags,
          // 2022-12-09 tienlm add start
          saluation: item.saluation,
          accountName: item.accountName,
          department: item.department,
          birthDate: item.birthDate,
          description: item.description,
          // 2022-12-09 tienlm add end
          // map array contact contact type to array int
          listContactTypeId: item.contactContactTypes?.map(x => x.contactTypeId) ?? null,
          linkedIn: item.linkedIn,
          source: item.source,
          primaryPhoneExt: item.primaryPhoneExt,
          secondaryPhoneExt: item.secondaryPhoneExt,
          cellPhoneExt: item.cellPhoneExt,
        } as Contact;
        if (item.location !== null) {
          tempContact.city = item.location.city;
          tempContact.country = item.location.country;
          tempContact.zipcode = item.location.zipcode;
          tempContact.address = item.location.address;
          tempContact.address2 = item.location.address2;
          tempContact.region = item.location.region;
          tempContact.state = item.location.state;
          tempContact.primaryLocation = item.location.primaryLocation;
          tempContact.nearestBigCity = item.location.nearestBigCity;
          tempContact.willTravel = false;
          tempContact.howFar = item.location.howFar;
          tempContact.tags = item.tags;
          tempContact.jobTitle = item.jobTitle;
        }
        parsedContact.push(tempContact);
      });
    }
    return parsedContact;
  }
  ngOnInit() {    

    this.getStateDynamicContent();
    this.filteredCompany.asObservable().subscribe({
      next: data => {
        this.filterArray = data;
      }
    });

    if (this.isUpdateAction) {
      this.loading = true;
      this.apiGetSelectedObjects = this.service.getRelationshipContactToObjects(this.contactLst[0].contactId)
        .pipe(finalize(() => this.loading = false));
    }
    this.service.GetAllContactTypeAsync().subscribe(res => {
      if (res.result != null) {
        this.arrayContactType = res.result;
        if (this.arrayContactType && this.arrayContactType.length > 0) {
          if (this.contactLst.length > 0 && this.formContactLst.length > 0) {
            this.formContactLst = this.formContactLst.map(x => {
              if (this.data?.showCompanyName) {
                if (x.controls.relationship.value == null || x.controls.relationship.value.length == 0) {
                  x.controls.relationship.setValue(this.data.showCompanyName);
                }
              }
              if (x.controls.listContactTypeId.value == null || x.controls.listContactTypeId.value.length == 0)
                x.controls.listContactTypeId.setValue([this.arrayContactType[0].contactTypeId]);
              return x;
            });

            this.contactLst = this.contactLst.map(x => {
              if (x.listContactTypeId == null || x.listContactTypeId.length == 0)
                x.listContactTypeId.push(this.arrayContactType[0].contactTypeId)
              return x;
            });
          }
        }

      }
    });

    // self get api through selfGetApi flag = false
    if (!this.selfGetApi) {
      this.configCountriesDynamic();
      return;
    }
    // self get api through flag = true
    this.service.getContactByProfileId(this.profileId).subscribe(item => {
      this.contactLst = item.result;
      console.log(this.contactLst);
      if (this.contactLst.length === 0) {
        this.contactLst.push(new Contact());
        this.formContactLst.push(this.frmBuilder.formGroup(Contact, this.contactLst[0]));
      } else {
        this.contactLst.forEach(contact => {
          this.formContactLst.push(this.frmBuilder.formGroup(Contact, contact));
        });
      }
    });


  }
  ngOnChanges(changes: SimpleChanges): void {
    const inputChange = changes.contactLst;
    if (inputChange?.previousValue !== undefined) {
      this.formContactLst = [];
      this.deletedContact = [];
      this.ngOnInit();
    }
  }

  ngAfterViewInit(): void {
    if (this.formContactLst) {
      var skipChange = this.isUpdateAction ? 0 : 1;
      this.formContactLst.map(x =>
        x.valueChanges.pipe(skip(skipChange), pairwise(), take(1))
          .subscribe(([pre, cur]) => {
            this.isChange = true;
          })
      );
    }
    this.cdref.detectChanges();
  }

  private async configCountriesDynamic() {
    // NEW function
    if (this.contactLst.length === 0)
      this.contactLst.push(new Contact());

    if (this.contactLst && this.contactLst.length > 0) {
      this.contactLst.map((item, index: number) =>
        this.formContactLst.push(this.frmBuilder.formGroup(Contact, item))
      );
    }

    var dataCountries = await this.dynamicContentService.getDynamicContentByType("country").toPromise();
    if (dataCountries.result && dataCountries.result.length > 0)
      this.countries = dataCountries.result.map(item => Object.assign({}, {
        key: item.content,
        value: item.content
      }));
    else this.countries = [];

    if (this.contactLst && this.contactLst.length > 0) {
      this.contactLst = this.contactLst.map((item, index: number) => {
        item.countryObservable = this.initObservableForCountry(this.formContactLst[index].get('country'));
        return item;
      });
    }

    // OLD function 
    // if (this.contactLst.length === 0) {
    //   this.contactLst.push(new Contact());
    //   this.formContactLst.push(this.frmBuilder.formGroup(Contact, this.contactLst[0]));
    //   // inut observable for current contact

    //   this.contactLst[0].countryObservable = this.initObservableForCountry(this.formContactLst[0].get('country'));
    // } else {
    //   this.contactLst.forEach(contact => {
    //     this.formContactLst.push(this.frmBuilder.formGroup(Contact, contact));
    //   });
    //   this.contactLst.forEach((item, index: number) => {
    //     item.countryObservable = this.initObservableForCountry(this.formContactLst[index].get('country'));
    //   });
    // }
  }

  private _filter(value: string): { key: string, value: string }[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter(country => country.value.toLowerCase().includes(filterValue));
  }
  onAddContactBtn(): void {
    this.contactLst.push(new Contact());
    this.formContactLst.push(this.frmBuilder.formGroup(Contact, this.contactLst[this.contactLst.length - 1]));
    this.contactLst[this.contactLst.length - 1].countryObservable =
      this.initObservableForCountry(this.formContactLst[this.formContactLst.length - 1].get('country'));

    const checkRelationshipExist = this.formContactLst[this.formContactLst.length - 1]?.controls?.relationship?.value;
    const checklistContactTypeExist = this.formContactLst[this.formContactLst.length - 1]?.controls?.listContactTypeId?.value;
    if (!checkRelationshipExist) {
      if (this.data?.showCompanyName) {
        this.formContactLst[this.formContactLst.length - 1].controls.relationship.setValue(this.data.showCompanyName);
      }
    }
    if (!checklistContactTypeExist) {
      if (this.arrayContactType && this.arrayContactType.length > 0) {
        this.formContactLst[this.formContactLst.length - 1].controls.listContactTypeId.setValue([this.arrayContactType[0].contactTypeId]);
        if (this.contactLst && this.contactLst.length > 0) {
          this.contactLst[this.contactLst.length - 1].listContactTypeId.push(this.arrayContactType[0].contactTypeId);
        }
      }
    }
  }
  // init observable
  initObservableForCountry(control: AbstractControl): Observable<CountryObject[]> {
    return control.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.key),
      map(name => name ? this._filter(name) : this.countries.slice()),
      map(x => x.map(y => y.value)
        .sort(Helper.alphabetically(true, false))
        .map(y => Object.assign({ key: y, value: y }))
      ));
  }
  // delete contact on UI
  onDeleteContactBtn(key: number = null): void {
    if (key) {
      // if delete item is available in db ==> then move it to deleted array in order to set flag deleted to true
      if (this.formContactLst[key].get('contactId').value !== null) {
        this.formContactLst[key].setValue({
          ...this.formContactLst[key].value,
          deleted: true,
        });
        this.deletedContact.push(this.contactLst[key]);
      }
      this.contactLst.splice(key, 1);
      this.formContactLst.splice(key, 1);
      return;
    }
    this.contactLst.pop();
    this.formContactLst.pop();
  }
  trackByFn(index, item) {
    return index;
  }
  onSaveBtn(): void {

  }
  setPrimaryContact(index: number): void {
    this.formContactLst.forEach(contactFrm => contactFrm.setValue({ ...contactFrm.value, primaryContact: false }));
    this.formContactLst[index].setValue({
      ...this.formContactLst[index].value,
      primaryContact: true
    });
  }
  removePrimaryContact(index: number): void {
    this.formContactLst[index].setValue({
      ...this.formContactLst[index].value,
      primaryContact: false
    });
  }

  // onsave pressed
  onSave(): void {
    this.loading = !this.loading;
    // mark ok flag
    let isValidflag = true;
    let errorMsg = 'Fail at: ';
    this.formContactLst.forEach((form, index: number) => {
      console.log('form: ', form);
      if (!form.valid) {
        form.markAllAsTouched();
        errorMsg += `Contact ${index + 1} format error: `;
        Object.keys(form.controls).forEach(key => {
          const controlErrors: ValidationErrors = form.get(key).errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              errorMsg += `property: ${key}, `;
            });
          }
        });
        isValidflag = false;
        return false;
      }
    });

    if (!isValidflag) {
      this.saveErrorNotification = !isValidflag;
      this.saveErrorMessage = errorMsg;
      this.toast.danger(`Submit failed, please check your input again.`, 'Error', { duration: 7000 });
      this.loading = !this.loading;
      return;
    }
    this.formContactLst.forEach(item => { item.disable(); });
    const contactFromForm = this.formContactLst.map(item => {
      item.value.willTravel = false;
      return item.value;
    });
    let contactToSave;
    if (this.deletedContact.length > 0) {
      contactToSave = [...contactFromForm];
      this.deletedContact.forEach(item => {
        item.deleted = true;
        contactToSave.push(item);
      });
    } else {
      contactToSave = contactFromForm;
    }

    if (this.relationshipToObjComponent) {
      contactToSave = contactToSave.map((item, index) => {
        item.objectsRelationship = this.relationshipToObjComponent.get(index).selectedObjects
          .map(x => { return { key: x.key, value: x.id } as KeyPairsValue });
        return item;
      });
    }

    if (this.isOwnerMode) {
      // owner mode
      this.service.saveContactByOwner(contactToSave[0], this.isUpdateAction).subscribe(resp => {
        this.loading = !this.loading;
        if (resp.result) {
          // successfully
          if (this.isLastSave) {
            this.contactLst = [];
            this.formContactLst = [];
            this.deletedContact = [];
            this.contactLst.push(new Contact());
            this.formContactLst.push(this.frmBuilder.formGroup(Contact, this.contactLst[0]));
            // inut observable for current contact

            this.contactLst[0].countryObservable = this.initObservableForCountry(this.formContactLst[0].get('country'));
            // this.contactLst.forEach(contact => {
            //   this.formContactLst.push(this.frmBuilder.formGroup(Contact, contact));
            // });
            this.toast.success('Save contact(s) successfully!', 'Success');
            // 2021-11-4 HMTien add start
            this.saveErrorNotification = false;
            // 2022-11-4 HMTien add end
            this.refreshTable();
            if (this.isDialog) {
              this.closeDialog(true);
            }
          }
          else {
            this.toast.success('Save contact(s) successfully!', 'Success');
          }
        }
      });
    } else {
      this.service.saveContact(contactToSave, this.profileId, this.producerId, this.buyerId, this.isUpdateAction).subscribe(
        item => {
          if (this.isLastSave) {
            this.loading = !this.loading;
            this.contactLst = item.result;
            this.formContactLst = [];
            this.deletedContact = [];
            // this.contactLst.forEach(contact => {
            //   this.formContactLst.push(this.frmBuilder.formGroup(Contact, contact));
            // });
            this.toast.success('Save contact(s) successfully!', 'Success');
            // 2021-11-4 HMTien add start
            this.saveErrorNotification = false;
            // 2022-11-4 HMTien add end
            this.refreshTable();
            if (this.isDialog) {
              this.closeDialog(true);
            }
          }
          else {
            this.toast.success('Save contact(s) successfully!', 'Success');
          }
        }
      );
    }

    this.formContactLst.forEach(item => { item.enable(); });
  }

  closeDialog(result: boolean = false): void {
    if (this.isChange && !result) {
      this.isChange = false;
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          message: "Do you wish to close this popup? You will lose your unsaved data."
        }
      });

      dialogRef.afterClosed().subscribe(response => {
        if (response) this.closeDialogEvent(result);
        else this.isChange = true;
      });
      return;
    }

    this.closeDialogEvent(result);
  }

  duplicate(formInstance: FormGroup): void {
    const newDuplicate = { ...formInstance.value, primaryContact: false } as Contact;
    this.contactLst.push(newDuplicate);
    this.formContactLst.push(this.frmBuilder.formGroup(Contact, this.contactLst[this.contactLst.length - 1]));
  }
  getLocationIdFromLocationBlock(locationId: string, index: number) {
    if (this.includeLocation) {

    }
  }
  checkCountryMatchOnBlur(formInstance: FormGroup): void {
    // check if in array
    if (formInstance.get('country').value) {
      const valueToCheck = formInstance.get('country').value.toLowerCase().trim();
      if (this.countries.find(c => c.value.toLowerCase().includes(valueToCheck)) === undefined) {
        formInstance.setValue({ ...formInstance.value, country: '' });
      } else {
        formInstance.setValue(
          { ...formInstance.value, country: this.countries.find(c => c.value.toLowerCase().includes(valueToCheck)).value });
      }
    }
  }
  checkContactMatchOnBlur(formInstance: FormGroup): void {

  }

  refreshTable(reset: boolean = false) {
    this.onRefresh.emit(reset);
  }

  // 2021-11-2 HMTien add start
  // close the SaveErrorMessage
  closeSaveErrorMessage(): void {
    this.saveErrorNotification = false;
  }
  // 2021-11-3 HMTien add end
  selectTagContact(tagList: string, formInstance: FormGroup) {

    formInstance.setValue({ ...formInstance.value, tags: tagList });
  }

  private _filterCompany(value: string): DynamicContentModel[] {
    const filterValue = value.toLowerCase();

    return this.getDynamicContent.filter(option => option.content.toLowerCase().includes(filterValue));
  }
  onChangesCompany(event: any) {
    let value = event.target.value;
    if (value) {
      this.filteredCompany.next(this._filterCompany(value));

    } else {
      return this.filteredCompany.next(this.getDynamicContent.slice());
    }
  }
  getLoadingValueType(value: boolean) {
    this.typeLoading = value;
  }
  getLoadingValueSource(value: boolean) {
    this.sourceLoading = value;
  }
  async getStateDynamicContent() {
    try {
      const data = await this.dynamicContentService.getDynamicContentByType("location_state").toPromise();
      if (data.result) this.stateOptions = data.result;
    }
    catch (ex) {
      console.log(ex);
    }
  }
  entitiesChanged(value: number) {
    if (value && value > 0) this.disableConvertBtn = true;
    else this.disableConvertBtn = false;
  }

  get disableConvert() {
    if (this.loading || this.disableConvertBtn) return true;
    else return false;
  }
  onConvertClick() {
    let modelData: SimpleCovertContact = {
      contactId: this.contactLst[0]?.contactId,
      contact: this.contactLst[0]
    };
    this.dialog.open(SimpleConvertContactComponent, {
      disableClose: true,
      width: '65vw',
      data: modelData
    }).afterClosed().subscribe(res => {
      if (res)
        this.closeDialog(true);
    });
  }

  modifyContentNonASCII(value, form: FormGroup, prop: string) {
    if (value && form) {
      let content = Helper.removeNonAscii(value);
      form.controls[prop].setValue(content);
    }
  }
  backClicked() {
    if(!this.isDialog) {
      if (Helper.checkUrlDetailObj('contact')) {
        var urlBack = Helper.popBackURL() || `/configuration/contact`;
        this.router.navigate([urlBack]);
      }
    }else {
      this.matDialogRef.close();
    }
  }
}

