import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs-compat/Observable';
import { environment } from "src/environments/environment";
import { ReturnResult } from '../../models/return-result';
import { RealtimeNotificationComponent } from './realtime-notification.component';
import { RealtimeNotificationQueue, ResponseRealtimeNotification } from './responseRealtimeNotification.model';

@Injectable({
  providedIn: 'root'
})
export class RealtimeNotificationService {
  baseUrl = environment.realtimeNotification;
  notificationComponent: RealtimeNotificationComponent;
  
  constructor(private http: HttpClient) { }

  receiveNotification(data: ResponseRealtimeNotification): Observable<ReturnResult<string>> {
    console.log(data);
    return this.http.post<ReturnResult<string>>(`${this.baseUrl}/ReceiveNotification`, data);
  }

  getNotificationQueueById(id: number):Observable<ReturnResult<RealtimeNotificationQueue>>{
    return this.http.get<ReturnResult<RealtimeNotificationQueue>>(`${this.baseUrl}/GetNotificationQueueById/${id}`);
  }

}