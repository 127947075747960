<div class="d-flex flex-wrap" mat-dialog-title style="margin: unset;" [nbSpinner]="isLoading"
  style="margin: unset;padding: 1px 10px 1px 63px;">
  <button mat-button color="primary" (click)="stepper.previous()" *ngIf="stepper.selectedIndex==1">
    Back
  </button>
  <button mat-button color="primary" (click)="stepper.next();" [disabled]="!formDoc.valid"
    *ngIf="stepper.selectedIndex==0">
    Next
  </button>
  <button mat-button color="primary" (click)="importFile()" [disabled]="!formDoc.valid"
    *ngIf="stepper.selectedIndex==1">
    Import
  </button>
  <button mat-icon-button aria-label="Close the dialog" (click)="closeDialog()" class="ml-auto">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="formDoc" style="padding-top: 25px;padding-right: 50px;padding-left: 50px;">
    <mat-horizontal-stepper labelPosition="bottom" linear #stepper (selectionChange)="onStepChange($event)">
      <mat-step label=" UPLOAD" [completed]="formDoc.valid" state="upload">
        <mat-form-field>
          <ngx-mat-file-input (click)="$event.target.value=null" formControlName="requiredfile" placeholder="Upload your file"
            valuePlaceholder="No file selected" required [accept]="'.xlsx'"></ngx-mat-file-input>
          <mat-icon matSuffix>folder</mat-icon>
          <mat-error *ngIf="formDoc.get('requiredfile').hasError('required')">
            Please select a file
          </mat-error>
          <mat-error *ngIf="formDoc.get('requiredfile').hasError('maxContentSize')">
            The total size must not exceed {{formDoc.get('requiredfile')?.getError('maxContentSize').maxSize |
            byteFormat}} ({{formDoc.get('requiredfile')?.getError('maxContentSize').actualSize
            | byteFormat}}).
          </mat-error>
        </mat-form-field>
        <small class="text-muted row" *ngIf="templateFile">
          <em class="col-12"><a class="float-left" href="{{templateFile}}">Click here to download the
              excel
              template.</a></em>
        </small>
      </mat-step>
      <mat-step label="MAP" state="map" [completed]="isComplete">
        <p class="text-center">Map columns in your file to item properties.</p>
        <ngx-datatable style="height: inherit;" #table class="material" [rows]="rows" [columns]="columns"
          [columnMode]="ColumnMode.force" [footerHeight]="0" [headerHeight]="50" [rowHeight]="50" [scrollbarV]="true"
          [scrollbarH]="true" [reorderable]="false" [swapColumns]="false">
        </ngx-datatable>
      </mat-step>
      <mat-step label="RESULT" state="result">
        <nb-alert status="danger" *ngIf="systemError"> Sorry, the system has an unexpect ed technical issue.</nb-alert>
        <nb-card *ngIf="!systemError">
          <nb-card-header>Import Complete</nb-card-header>
          <nb-card-body>
            <p *ngIf="totalError==0">Import complete with no problems or warnings</p>
            <p>{{totalSuccess}} of {{totalRow}} items were successfully imported

            </p>
            <button *ngIf="fileError" type="button" mat-button style="padding-left: unset;padding-right: 4px;"
              (click)="downloadFileError()">
              <mat-icon class="mr">file_download</mat-icon>Error Records
            </button>
          </nb-card-body>
        </nb-card>
      </mat-step>
    </mat-horizontal-stepper>
  </form>
</mat-dialog-content>

<ng-template #importRequired let-row="row" let-value="value">
  <p [ngClass]="{'asterix-after':row.importRequired}">{{value}}</p>
</ng-template>

<ng-template #headerFromFile let-row="row" let-value="value" let-rowIndex="rowIndex">
  <mat-select [value]="value" (selectionChange)="onChangeColumn($event,value,rowIndex)">
    <mat-option [value]="-1">Ignore</mat-option> <!--Not map-->
    <mat-option [value]="-2">Type input</mat-option> <!--Type input-->
    <mat-option *ngFor="let column of  columnsFile; let i = index" [value]="column.value" [disabled]="column.selected">
      {{column.name}}
    </mat-option>
  </mat-select>
</ng-template>

<ng-template #reviewInfo let-row="row" let-value="value" let-rowIndex="rowIndex">
  <p *ngIf="row.columnFile > -1">{{value}}</p>
  <input matInput *ngIf="row.columnFile == -2" style="border-bottom: 1px solid #307abd;" type="text"
    placeholder="Typing value" (input)="inputProperty(rowIndex, $event.target.value)">
</ng-template>