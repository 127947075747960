import { ElementRef, TemplateRef } from '@angular/core';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { NbToastrService } from '@nebular/theme';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { da } from 'date-fns/locale';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/@core/mock/users.service';
import { AddEditUserComponent } from 'src/app/modules/admin/user-management/add-edit-user/add-edit-user.component';
import { UserModel } from 'src/app/modules/admin/user-management/user-model';
import { PrimasMailActionFilterOperator } from 'src/app/shared/enums/primas-email-action-filter-operator';
import { PrimasFilterType } from 'src/app/shared/enums/primas-value-type.enum';
import { SortOrderType } from 'src/app/shared/enums/sort-order-type.enum';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { ColumnMapping } from 'src/app/shared/models/column-mapping';
import { CustomView } from 'src/app/shared/models/custom-view';
import { CustomViewService } from 'src/app/shared/services/custom-view.service';
import { Helper } from 'src/app/shared/utility/Helper';

@Component({
  selector: 'app-add-edit-custom-view',
  templateUrl: './add-edit-custom-view.component.html',
  styleUrls: ['./add-edit-custom-view.component.scss']
})
export class AddEditCustomViewComponent implements OnInit {
  @ViewChild('customFilter', { static: true }) customFilter: TemplateRef<any>;
  @ViewChild('columnName', { static: true }) columnName: TemplateRef<any>;
  @ViewChild('customSortField', { static: true }) customSortField: TemplateRef<any>;
  @ViewChild('customSortOrder', { static: true }) customSortOrder: TemplateRef<any>;
  @ViewChild('removeSortRow', { static: true }) removeSortRow: TemplateRef<any>;

  @ViewChild('multiSelectColumn', { static: true }) multiSelectColumn: MatSelect;
  action: TblActionType;
  customViewModel: CustomView;
  columnMapping: ReplaySubject<ColumnMapping[]> = new ReplaySubject<ColumnMapping[]>(1);;
  form: FormGroup;
  isLoading = false;
  isIndeterminate = false;
  columnFilterCtrl: FormControl = new FormControl();
  isChecked = false;
  message = {
    emptyMessage: ""
  }
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  rows = new Array<any>();
  rowsSort = new Array<any>();
  columns = [];
  columnsSort = [];
  selected: any[] = [];

  isSaveEnter = false;
  constructor(
    public dialModalRef: MatDialogRef<AddEditCustomViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: CustomViewService,
    private frmBuilder: RxFormBuilder,
    private toastr: NbToastrService,
    private cdref: ChangeDetectorRef,
  ) {
    this.action = data.action;
    this.columnMapping = data.columns;
    if (this.action == TblActionType.Edit && data.model) {
      this.customViewModel = data.model;
    } else {
      this.customViewModel = new CustomView();
      this.customViewModel.viewIdentify = data.viewIdentify;
    }
    if (this.customViewModel.columnMapping == null) {
      this.customViewModel.columnMapping = [];
    }
    //25-08-2021 VuongLe Start
    if (this.customViewModel.orderMapping == null) {
      this.customViewModel.orderMapping = [];
    }
    //25-08-2021 VuongLe End
  }
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  ngOnInit() {
    const customViewFormElement = document.getElementById('custom-view-form');
    const totalPaddingWidth = 100; // 100px;
    const formWidth = customViewFormElement.getBoundingClientRect().width - totalPaddingWidth;

    const actionButtonWidth = 30;
    const columnNameWidth = Math.min(Math.round((formWidth - actionButtonWidth) * 0.4), 176);
    const filterValueWidth = formWidth - actionButtonWidth - columnNameWidth;

    this.columns = [
      {
        width: actionButtonWidth,
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizeable: false,
        headerCheckboxable: true,
        checkboxable: true
      },
      {
        name: 'Column Name',
        prop: 'name',
        resizeable: false,
        width: columnNameWidth,
        cellTemplate: this.columnName,
        canAutoResize: false,
        sortable: false
      },
      {
        name: 'Filter Value',
        prop: 'filterValue',
        resizeable: false,
        width: filterValueWidth,
        canAutoResize: false,
        cellTemplate: this.customFilter,
        sortable: false
      },
    ];
    this.columnsSort = [
      {
        width: actionButtonWidth,
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizeable: false,
        cellTemplate: this.removeSortRow,
        cellClass: 'remove-pading'
      },
      {
        name: 'Sort Field',
        prop: 'sort',
        resizeable: false,
        width: columnNameWidth,
        canAutoResize: false,
        cellTemplate: this.customSortField,
      },
      {
        name: 'Sort Order',
        prop: 'sortDir',
        width: filterValueWidth,
        canAutoResize: false,
        cellTemplate: this.customSortOrder
      },
    ]
    if (this.columnMapping) {
      this.columnMapping.forEach((column: any) => {
        var propData = this.customViewModel.columnMapping.find(x => x.prop == column.prop);
        var sortData = this.customViewModel.orderMapping.find(x => x.sort == column.prop);
        this.rows.push({
          name: column.name ?? column.prop,
          prop: column.prop,
          filterValue: propData != null ? propData.filterValue : null,
          filterType: column?.filter?.filterType,
          filter: column?.filter,
          //25-8-2021 VuongLe Start
          filterOperator: propData != null ? propData.filterOperator : null,
          selected: column.prop == sortData?.sort ? true : false,
        })
        //25-8-2021 VuongLe End
      })
    }
    if (this.customViewModel.columnMapping.length > 0) {
      this.selected = [... this.rows.filter(x => this.customViewModel.columnMapping.find(y => y.prop == x.prop) != null)];
    } else {
      this.selected = [...this.rows];
    }
    //25-8-2021 VuongLe Start
    if (this.customViewModel.orderMapping.length > 0) {
      this.rowsSort = [... this.customViewModel.orderMapping];
    } else {
      this.rowsSort = [
        {
          sort: null,
          sortDir: SortOrderType.ASC
        }
      ]
    }
    //25-8-2021 VuongLe End
    this.form = this.frmBuilder.formGroup(CustomView, this.customViewModel);
    this.dialModalRef.updatePosition({ right: '0' });

  }
  onSelect({ selected }) {
    const rowSorts = [... this.rowsSort.filter(x => selected.find(y => x.sort == y.prop) != null)];
    if (rowSorts.length > 0) {
      const rowNotSort = [...selected.filter(x => rowSorts.find(y => y.sort == x.prop) == null)];
      this.rowsSort = [...rowSorts];
      rowNotSort.forEach(row => {
        selected.find(x => x.prop == row.prop).selected = false;
      });
    } else {
      this.rowsSort = [{ sort: null, sortDir: SortOrderType.ASC }];
      selected.forEach(row => {
        row.selected = false;
      });
    }
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  onChangeFilter(value, row) {
    row.filterValue = value;
  }
  onFilterDateTime(value, row) {
    const filterDatetime = value.datetime;
    row.filterValue = filterDatetime;
  }
  onFilterBoolean(value, row) {
    row.filterValue = value;
  }
  onFilterDropDown(value, row) {
    row.filterValue = value;
  }
  onFilterOperator(value, row) {
    // if (row.filterType === PrimasFilterType.MailActions) {
    //   if (value.filterOperator != PrimasMailActionFilterOperator.EmailActions) {
    //     row.filterType = PrimasFilterType.Text;
    //   }
    // }
    row.filterOperator = value.filterOperator;
  }
  closeDialog() {
    this.dialModalRef.close();
  }
  getRowHeight(row) {
    if (!row) {
      return 50;
    }
    if (row.filterType === undefined) {
      return 50;
    }
    if (row.filterType && row.filterType == 2) {
      return 150;
    }
    return row.height;
  }
  addMoreSort() {
    this.rowsSort.push({ sort: null, sortDir: SortOrderType.ASC })
    this.rowsSort = [...this.rowsSort];
  }

  //25-8-2021 VuongLe Start
  removeSort(row) {
    this.rowsSort.forEach((element, index) => {
      if (element.sort == row.sort) this.rowsSort.splice(index, 1);
    });
    let oldSelected = this.selected.find(x => x.prop == row.sort);
    if (oldSelected) {
      oldSelected.selected = false;
    }
    this.rowsSort = [...this.rowsSort];
  }

  onChangeColumnSort(event, oldValue, row) {
    if (event != null) {
      let selected = this.selected.find(x => x.prop == event)
      if (selected) {
        selected.selected = true;
        row.sort = event;
      }
    }
    let oldSelected = this.selected.find(x => x.prop == oldValue);
    if (oldSelected) {
      oldSelected.selected = false;
    }
    this.selected = [...this.selected];
  }

  onChangeSort(event, row) {
    if (row != null) {
      row.sortDir = event;
    }
  }
  //25-8-2021 VuongLe End
  saveData(isCheck?: boolean) {
    if (this.form.valid && (this.isSaveEnter || isCheck)) {
      this.isLoading = !this.isLoading;
      const model: CustomView = Object.assign({}, this.form.value);
      model.columnMapping = [];
      this.selected.map((x) => {
        x.active = true;
        if (x.filter)
          x.filterOperator = Helper.splitSpaceString(x.filterOperator);
        //2022-02-18 gnguyen start mod
        //model.columnMapping.push(x);
        model.columnMapping.push({
          active: x.active,
          filterOperator: x.filterOperator,
          filterType: x.filterType,
          filterValue: x.filterValue,
          prop: x.prop
        });
        //2022-02-18 gnguyen end mod
      });

      //25-8-2021 VuongLe Start
      model.orderMapping = [];
      this.rowsSort.map((x) => {
        if (x.sort != null)
          model.orderMapping.push(x);
      })
      //25-8-2021 VuongLe End

      this.service.saveCustomView(model).subscribe(resp => {
        if (resp) {
          this.dialModalRef.close(resp);
          this.toastr.success((model.viewId === 0) ? 'Save new customview succesfully': 'Edit customview successfully', 'Success')
        }
      })
    }
  }

  listenEventKeyPressEnter(event) {
    if (event.keyCode === 13)
      this.isSaveEnter = true;
  }
  onChangeFilterHeader(filterText, row) {
    if (row.filterType === PrimasFilterType.MailActions) {
      if (row.filterOperator != PrimasMailActionFilterOperator.EmailActions) {
        row.filterType = PrimasFilterType.Text;
      }
    }
    row.filterValue = filterText;
  }
  onEmailActionFilter(event, row) {
    row.filterValue = event ? event : [];
  }
}
