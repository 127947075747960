<div *ngIf="error" mat-dialog-title [ngStyle]="{'min-width': '40vw'}">
  An error occurred
</div>
<mat-dialog-content style="padding-bottom: 10px;">
  <!-- Replied Email -->
  <div *ngIf="!loading; else skeleton" [nbSpinner]="loading">
    <mat-card *ngIf="!error; else displayError">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <!-- title -->
        <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <!-- <mat-icon class="title-icon"matTooltip="Title">title</mat-icon> -->
          <div class="replied-title" [matTooltip]="email?.title.length > 80? email?.title : null">
            {{email?.title | truncateText: [80,'...']}}
          </div>
        </span>
        <div *ngIf="action == 'reply'">
          <button mat-stroked-button matTooltip="Reply" (click)="onClickedReply()">
            <mat-icon>
              reply
            </mat-icon>
          </button>
        </div>
      </div>
      <br>
      <!-- Date Replied -->
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-icon class="time-icon" matTooltip="Time replied">schedule</mat-icon>
        <div class="replied-time">
          {{email?.timestamp | date: 'medium'}}
        </div>
      </div>
      <br>
      <!-- Email template -->
      <div fxLayout="row">
        <!-- <mat-icon matTooltip="Body">email</mat-icon> -->
        <p class="iframe-holder" bind-innerHTML="email?.body | safeHtml"></p>
      </div>
      <mat-divider></mat-divider>
      <br>
      <div *ngIf="(attachments && attachments.length > 0) || attachments == null" [nbSpinner]="attachments == null"
        fxLayout="row wrap" fxLayoutAlign="flex-start" style="width: -webkit-fill-available; min-height: 30px;">
        <mat-card *ngFor="let attach of attachments; let i = index" style="margin: 0 10px 10px 0;"
          fxFlex="0 1 calc(25% - 10px)" fxFlex.lt-lg="0 1 calc(33.3% - 10px)" fxFlex.md="0 1 calc(50% - 10px)"
          fxFlex.sm="100%" fxFlex.lt-sm="100%" (click)="openAttachment(attach)" [matTooltip]="attach.attachmentUrl"
          matTooltipPosition="above">
          <div fxLayout="column" fxLayoutAlign="center center">
            <div [ngSwitch]="attach.extensionUrl">
              <mat-icon *ngSwitchCase="'pdf'" color="warn">picture_as_pdf</mat-icon>
              <mat-icon *ngSwitchCase="'gif'">gif</mat-icon>
              <mat-icon *ngSwitchCase="typeDoc.includes(attach.extensionUrl)
                ? attach.extensionUrl :'' " color="primary">
                description
              </mat-icon>
              <mat-icon *ngSwitchCase="typeExcel.includes(attach.extensionUrl)
                ? attach.extensionUrl :'' " style="color: green">
                description
              </mat-icon>
              <div style="min-height: 30px"
                *ngSwitchCase="typeImage.includes(attach.extensionUrl) ? attach.extensionUrl : '' ">
                <img class="img-background" [src]="attach.attachmentUrl" alt="">
              </div>
              <mat-icon *ngSwitchDefault>attach_email</mat-icon>
            </div>
            <br>
            <mat-card-subtitle [ngClass]="typeImage.includes(attach.extensionUrl) ? 'sub-title-attachment':''">
              {{attach.nameUrl}}
            </mat-card-subtitle>
          </div>
        </mat-card>
      </div>
    </mat-card>
  </div>

  <!-- Email Editor -->
  <!-- <div class="col-lg-12" [ngStyle]="{'display': replyMode ? 'inline' : 'none'}">
    <app-editor-email #editorEmail [classicEditor]='true'>

    </app-editor-email>
  </div> -->
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close (click)="closeDialog()">Close</button>
</mat-dialog-actions>
<ng-template #skeleton>
  <ngx-skeleton-loader count="5" appearance="line"></ngx-skeleton-loader>
</ng-template>

<ng-template #displayError>
  <div fxLayout="column" fxLayoutAlign="start center">
    <img src="assets/images/error-gif.gif" alt="error" style="width: 340px;
  height: auto;">
    <div style="font-size: 18px;
  font-weight: 800;
  color: red;">
      {{ 'We can not find the email content. ErrorCode ACID_'
      + activityLogId || 'Fail to load the reply mail. Please try again..'}}
    </div>
  </div>
</ng-template>