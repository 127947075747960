import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-primas-confirm-pop',
  templateUrl: './primas-confirm-pop.component.html',
  styleUrls: ['./primas-confirm-pop.component.scss']
})
export class PrimasConfirmPopComponent implements OnInit {

  @Output() onConfirm = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

}
