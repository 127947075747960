import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DropDownGroups } from 'src/app/shared/interfaces/dropdown-group';
import { Category } from 'src/app/shared/models/category';
import { ProfileCategoryView } from 'src/app/shared/models/profile-category';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { environment } from 'src/environments/environment';
import { TreeNode } from '../../tree-structure-filter/tree-structure-filter.component';
import { CategoryModel } from 'src/app/shared/models/request/category-seo-request.model';
import { HyperDatas } from 'src/app/modules/admin/category-management/add-edit-category/add-category.model';

@Injectable({
  providedIn: 'root'
})
export class AutoCompleteCategoriesService {
  private baseUrl = environment.apiCategory;
  chosenCategory$ = new Subject<{ key: string, value: string }[]>();
  // list of chosen categories
  chosenCategoryLst: { key: string, value: string }[] = [];
  constructor(private http: HttpClient) { }
  profileCategoryView$: Subject<ProfileCategoryView>;
  categoryList$: ReplaySubject<Category[]> = new ReplaySubject<Category[]>(1);
  getProfileCategoryId(profileId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/ProfileCategory/${profileId}`);
  }

  getCategoryProfile() {
    return this.categoryList$.asObservable();
  }

  getCategoryByProfileId(profileId: string) {
    this.http.get<ReturnResult<Category[]>>(`${this.baseUrl}/GetCategoryByProfileId?profileId=${profileId}`).subscribe(e => {
      if (e.result) {
        this.categoryList$.next(e.result);
      }
    });
  }

  getChosenCategory(): { key: string, value: string }[] {
    return this.chosenCategoryLst;
  }
  SetChosenCategory(chosenCategory): void {
    this.chosenCategoryLst = chosenCategory;
  }
  saveCategory(newCategory: string, profileId: string, categoryLst: Category[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveCategoryToProfile`, {
      profileCategoryLst: [newCategory],
      profileId, categoryLst
    });
  }
  saveVBulkCategory(categoryNewLst: string[], profileId: string, categoryLst: Category[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveCategoryProfileMulti`, {
      profileCategoryLst: categoryNewLst,
      profileId
    });
  }
  removeCategoryFromProfile(categoryId: string, profileId: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/RemoveCategoryFromProfile`, {
      categoryId, profileId
    });
  }
  getAllCategoryAsync(): Observable<ReturnResult<ProfileCategoryView>> {
    return this.http.get<ReturnResult<ProfileCategoryView>>(`${this.baseUrl}/GetAllCategoryAsync`);
  }

  getTreeCategory(): Observable<ReturnResult<TreeNode[]>> {
    return this.http.get<ReturnResult<TreeNode[]>>(`${this.baseUrl}/GetTreeCategoryStructure`);
  }

  getCategoriesNotParent(): Observable<ReturnResult<ProfileCategoryView>> {
    return this.http.get<ReturnResult<ProfileCategoryView>>(`${this.baseUrl}/GetCategoriesNotParent`);
  }
  SearchCategoryById(id: string): Observable<ReturnResult<Category>> {
    return this.http.get<ReturnResult<Category>>(`${this.baseUrl}/SearchCategoryById/${id}`);
  }
  getCategoryById(id: string): Observable<ReturnResult<Category>> {
    return this.http.get<ReturnResult<Category>>(`${this.baseUrl}/getCategoryById/${id}`);
  }
  getHyperDataByReferenceId(id: string) {
    return this.http.get<ReturnResult<HyperDatas[]>>(`${this.baseUrl}/GetHyperDataByReferenceId/${id}`);
  }
}
