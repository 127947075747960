import { HostListener, Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UserStatusModel } from 'src/app/shared/models/user-status.model';
import { SignalrDynamicService } from './signalr-dynamic.service';
import { UserStatusEnums } from '../enums/user-status.enum';
import { UserModel } from 'src/app/modules/admin/user-management/user-model';

@Injectable({
  providedIn: 'root'
})
export class UserStatusService {
  userId: string;
  user: UserModel = null;
  idleTimeout: number = 120000; // 5 minutes in milliseconds
  idleTimer: any;
  UserActiveSubject: Subject<number> = new Subject<number>();
  // model of current user
  currentUserStatus: UserStatusModel = new UserStatusModel();
  // status of current user
  _userActive: number = UserStatusEnums.Online;
  _usersStatus: UserStatusModel[];
  _userStatusSubject: BehaviorSubject<UserStatusModel[]> = new BehaviorSubject<UserStatusModel[]>([]);
  _uniqueStatusChange: BehaviorSubject<number> = new BehaviorSubject<number>(UserStatusEnums.Busy);
  isLogout: boolean = false;
  constructor(
    private hotToast: HotToastService,
    private signalR: SignalrDynamicService
  ) {
    this.resetIdleTimer();
    this._uniqueStatusChange.subscribe(res => {
      this.setCurrentUserStatusSignalR(res);
    })
  }

  public set setUserActive(state: number) {
    this._userActive = state;
    this.currentUserStatus.status = UserStatusEnums.Online;
    this.UserActiveSubject.next(this._userActive);
    // set unique status
    if (this._uniqueStatusChange.value !== state) {
      this._uniqueStatusChange.next(state);
    }
  }
  public get getUserActiveObservable(): Observable<number> {
    return this.UserActiveSubject.asObservable();
  }
  get userActive(): number {
    return this._userActive;
  }
  public resetIdleTimer() {
    clearTimeout(this.idleTimer);
    this.idleTimer = setTimeout(() => {
      // Do something when user is idle for the specified time
      this._userActive = UserStatusEnums.Idle;
      this.UserActiveSubject.next(this._userActive);
      this.currentUserStatus.status = UserStatusEnums.Idle;
      if (this._uniqueStatusChange.value !== UserStatusEnums.Idle) {
        this._uniqueStatusChange.next(UserStatusEnums.Idle);
      }
    }, this.idleTimeout);
  }
  get userStatus() {
    return this._usersStatus;
  }
  set userStatus(state: UserStatusModel[]) {
    this._usersStatus = state;
    this._userStatusSubject.next(state);
  }
  userStatusChangeNotifier() {
    return this._userStatusSubject.asObservable();
  }
  setCurrentUserStatusSignalR(state: number) {
    if (!this.userId) return;
    this.currentUserStatus.status = state;
    this.currentUserStatus.userId = this.userId;
    this.currentUserStatus.user = this.user;
    this.signalR.setUserStateByHub(this.currentUserStatus);
    if (state === UserStatusEnums.Offline) this.userId = null;
  }
  public get uniqueStatusChange() {
    return this._uniqueStatusChange.asObservable();
  }
  getUserStatusSignalR(userId: string = null) {
    let finalUserIdData: string;
    try {
      finalUserIdData = userId ? userId : this.userId;
      this.signalR.invokeFunction('GetUserState', finalUserIdData);
    } catch (error) {
      console.log(error);
    }
  }
}
