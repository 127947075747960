<div [ngClass]="projection? 'parent-container': ''">
  <ng-container *ngIf="projection">
    <ng-content></ng-content>
  </ng-container>
  <mat-form-field appearance="standard" [ngClass]="projection? 'stackup': ''"
    [style.visibility]="projection? 'hidden':'visible'">
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [rangePicker]="picker" [formGroup]="range">
      <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="emitResult($event,'start')">
      <input matEndDate formControlName="end" placeholder="End date" (dateChange)="emitResult($event,'end')">
    </mat-date-range-input>
    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker>
    </mat-date-range-picker>
  </mat-form-field>
</div>