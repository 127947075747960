import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { NbToastrService, NbWindowRef } from '@nebular/theme';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Subject } from 'rxjs';
import { TableEntity } from 'src/app/shared/enums/table-entities.enum';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { Helper } from 'src/app/shared/utility/Helper';
import { CampaignLinkscopeService } from '../../profile-management/profile-detail/action-block/campaign-linkscope.service';
import { Task } from '../task-board/task-board-lane/task/task.model';
import { TaskBoardService } from '../task-board/task-board.service';
import { BuyerVoiceMailDataModel, ProfileVoiceMailDataModel } from './call-status-modal.model';
import { CallStatusModalService } from './call-status-modal.service';

@Component({
  selector: 'app-call-status-modal',
  templateUrl: './call-status-modal.component.html',
  styleUrls: ['./call-status-modal.component.css']
})
export class CallStatusModalComponent implements OnInit {
  model = null;
  message = 'Are you sure?';
  voiceMailLoading = false;
  user: any;
  voiceMailInformation: ProfileVoiceMailDataModel;
  // 2022-05-10 tienlm add start
  buyervoiceMailInformation: BuyerVoiceMailDataModel;
  entity = 'profile';
  // 2022-05-10 tienlm add end
  currentTask: Task;
  frmTask: FormGroup;
  private ngUnsubscribe = new Subject();
  subjectProfile: string = "Artist";
  currentRoute: string;
  constructor(
    private dialogRef: MatDialogRef<CallStatusModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: NbAuthService,
    private service: CallStatusModalService,
    private campaignLinkscopeService: CampaignLinkscopeService,
    private toastService: NbToastrService,
    private frmBuilder: RxFormBuilder,
    private taskService: TaskBoardService,
    private router: Router
  ) {
    /**
     {
    "CallEvent": "ANSWERED",
    "UserName": "tienlm",
    "UserId": "4afda1aa-7743-4017-963a-2a6ece52b900",
    "TaskId": 46
}
     */
    this.subjectProfile = Helper.toUpperCaseFirstLetter(window.localStorage.getItem("subject_profile") ?? "artist");
    this.model = data.model;
    this.entity = data.entity ?? this.entity;
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload();
        }
      });

    this.currentRoute = this.router.url;
  }
  formatCallEvent(eventName) {
    let caption = '';
    switch (eventName) {
      case 'AGENT_BUSY':
        caption = 'Agent phone BUSY';
        break;
      case 'AGENT_NO_ANSWER':
        caption = 'Agent phone NO ANSWER';
        break;
      case 'AGENT_FAILED':
        caption = 'Agent phone FAILED';
        break;
      case 'AGENT_ANSWERED':
        caption = 'Agent answered';
        break;
      case 'CALLING':
        caption = `Calling ${this.subjectProfile}...`;
        break;
      case 'CALLING_AGENT':
        caption = 'Calling agent...';
        break;
      case 'BUSY':
        caption = `${this.subjectProfile} phone BUSY`;
        break;
      case 'NO_ANSWER':
        caption = `${this.subjectProfile} phone NO ANSWER`;
        break;
      case 'FAILED':
        caption = `${this.subjectProfile} phone FAILED`;
        break;
      case 'ANSWERED':
        caption = 'Talking...';
        break;
      case 'CALL_ENDED':
        caption = 'Call ended';
        break;
    }
    return caption.toUpperCase();
  }
  ngOnInit() {
    // this.frmTask = this.frmBuilder.formGroup(Task, this.currentTask);
    this.voiceMailLoading = true;
    this.dialogRef.updateSize('300px', '204px')
    this.dialogRef.addPanelClass('custom-dialog-container')

    console.log(this.model);
    // this.dialogRef.updatePosition({ right: '0', bottom: '0' });
    this.service.getVoiceMailDataByTaskId(this.model.TaskId).subscribe(data => {
      if (data.result) {
        this.entity = data.result.entity;
        // split 2 entity: profile and buyer:
        if (data.result.entity == TableEntity.Buyer.toLowerCase()) {
          this.buyervoiceMailInformation = data.result;
          this.voiceMailLoading = false;
          // convert to voiceMailInformation
          this.voiceMailInformation = {
            voiceMailDomainUrl: this.buyervoiceMailInformation.voiceMailDomainUrl,
            profilePhoneNumber: this.buyervoiceMailInformation.buyerPhoneNumber,
            profileId: this.buyervoiceMailInformation.buyerId,
            endcallDomainUrl: this.buyervoiceMailInformation.endcallDomainUrl
          }
        } else if (data.result.entity == TableEntity.Profile.toLowerCase()) {
          this.voiceMailInformation = data.result;
          this.voiceMailLoading = false;
        }
      }
    })
    // if (this.entity == 'buyer') {
    //   this.service.getBuyerVoiceMailDataAsync(this.model.TaskId).subscribe((data) => {
    //     if (data.result) {
    //       console.log(data.result);
    //       this.buyervoiceMailInformation = data.result;
    //       this.voiceMailLoading = false;
    //       // convert to voiceMailInformation
    //       this.voiceMailInformation = {
    //         voiceMailDomainUrl: this.buyervoiceMailInformation.voiceMailDomainUrl,
    //         profilePhoneNumber: this.buyervoiceMailInformation.buyerPhoneNumber,
    //         profileId: this.buyervoiceMailInformation.buyerId,
    //         endcallDomainUrl: this.buyervoiceMailInformation.endcallDomainUrl
    //       }
    //       // this.voiceMailInformation.voiceMailDomainUrl = this.buyervoiceMailInformation.voiceMailDomainUrl;
    //       // this.voiceMailInformation.profilePhoneNumber = this.buyervoiceMailInformation.buyerPhoneNumber;
    //       // this.voiceMailInformation.profileId = this.buyervoiceMailInformation.buyerId;
    //       // this.voiceMailInformation.endcallDomainUrl = this.buyervoiceMailInformation.endcallDomainUrl;
    //     }
    //   });
    // } else {
    //   this.service.getProfileVoiceMailDataAsync(this.model.TaskId).subscribe((data) => {
    //     if (data.result) {
    //       console.log(data.result);
    //       this.voiceMailInformation = data.result;
    //       this.voiceMailLoading = false;
    //     }
    //   });
    // }
  }
  onClickVoiceMail() {
    this.voiceMailLoading = true;
    // tslint:disable-next-line:no-debugger
    this.makeAMDCall();

  }
  onHidePopUp() {
    this.dialogRef.close({ hide: true });
  }
  makeAMDCall() {
    const tokenid = window.sessionStorage.getItem('tokenid') || window.localStorage.getItem('tokenid') || '0';
    // get user phone number:
    let userPhone = window.sessionStorage.getItem('phonenumber') || '';

    if (!userPhone && window.localStorage.getItem('phonenumber')) {
      userPhone = window.localStorage.getItem('phonenumber');
      window.localStorage.removeItem('phonenumber');
    }

    if (!userPhone) {
      userPhone = this.voiceMailInformation?.profilePhoneNumber;
    }
    if (!userPhone || userPhone === '') {
      this.toastService.danger('This Task Not Related With any Phone number. please add a phone number for this profile first', 'Fail');
      this.voiceMailLoading = false;
      return;
    }
    const finalUrl = `${this.voiceMailInformation.voiceMailDomainUrl}`;
    let outBoundCallUrl = this.getFinalUrl(finalUrl, tokenid, this.trimUserPhoneNumber(userPhone));
    // finalUrl += tokenid && tokenid !== '' ? `tokenid=${tokenid}` : '';
    // finalUrl += + userPhone && userPhone !== '' ? `&phoneNumber=${userPhone}` : '';
    if (this.model.TaskId) {
      outBoundCallUrl += `&taskid=${this.model.TaskId}&username=${this.user.userName}&userid=${this.user.nameid}`;
      // call automate Task:
      this.taskService.executeAutoMateTask(this.model.TaskId).subscribe((resp: ReturnResult<any>) => {
        if (resp.result) {
          this.toastService.info('Run automate task successfully', 'Info');
        }
      })
    }
    // tslint:disable-next-line:max-line-length
    // use entity given to check if it run on profile or buyer:
    if (this.entity == 'buyer') {
      this.campaignLinkscopeService.makeAMDCallAltBuyerId(outBoundCallUrl, this.voiceMailInformation.profileId, 'AMDCall', this.user.userName, this.model?.TaskId ?? 0).subscribe(resp => {
        this.toastService.success('Left a voice mail for buyer', 'Success');
        this.voiceMailLoading = false;
      });
    } else {
      this.campaignLinkscopeService.makeAMDCallAltProfileId(outBoundCallUrl, this.voiceMailInformation.profileId, 'AMDCall', this.user.userName, this.model?.TaskId ?? 0).subscribe(resp => {
        this.toastService.success('Left a voice mail', 'Success');
        this.voiceMailLoading = false;
      });
    }
  }

  makeAnEndCall() {
    this.voiceMailLoading = true;
    const tokenid = window.sessionStorage.getItem('tokenid') || window.localStorage.getItem('tokenid') || '0';
    // get user phone number:
    let userPhone = window.sessionStorage.getItem('phonenumber') || '';
    
    if (!userPhone && window.localStorage.getItem('phonenumber')) {
      userPhone = window.localStorage.getItem('phonenumber');
      window.localStorage.removeItem('phonenumber');
    }

    if (!userPhone) {
      userPhone = this.voiceMailInformation?.profilePhoneNumber;
    }
    if (!userPhone || userPhone === '') {
      this.toastService.danger('This Task Not Related With any Phone number. please add a phone number for this profile first', 'Fail');
      this.voiceMailLoading = false;
      return;
    }
    const finalUrl = `${this.voiceMailInformation.endcallDomainUrl}`;
    let outBoundCallUrl = this.getFinalUrl(finalUrl, tokenid, this.trimUserPhoneNumber(userPhone));
    // finalUrl += tokenid && tokenid !== '' ? `tokenid=${tokenid}` : '';
    // finalUrl += + userPhone && userPhone !== '' ? `&phoneNumber=${userPhone}` : '';
    if (this.model.TaskId) {
      outBoundCallUrl += `&taskid=${this.model.TaskId}&username=${this.user.userName}&userid=${this.user.nameid}`;

    }

    if (this.entity == 'buyer') {
      // tslint:disable-next-line:max-line-length
      this.campaignLinkscopeService.makeAMDCallAltBuyerId(outBoundCallUrl, this.voiceMailInformation.profileId, 'EndCall', this.user.userName, this.model?.TaskId ?? 0).subscribe(resp => {
        this.toastService.success('Ended a phone call', 'Success');
        this.voiceMailLoading = false;
        this.dialogRef.close(true);
      });
    } else {
      this.campaignLinkscopeService.makeAMDCallAltProfileId(outBoundCallUrl, this.voiceMailInformation.profileId, 'EndCall', this.user.userName, this.model?.TaskId ?? 0).subscribe(resp => {
        this.toastService.success('Ended a phone call', 'Success');
        this.voiceMailLoading = false;
        this.dialogRef.close(true);
      });
    }
  }
  trimUserPhoneNumber(phone: string): string {
    // tslint:disable-next-line:prefer-const
    let newPhone = phone;
    newPhone = newPhone = newPhone.trim();
    newPhone = newPhone.replace(' ', '');
    newPhone = newPhone.replace('+', '');
    newPhone = newPhone.replace('-', '');
    newPhone = newPhone.replace('(', '');
    newPhone = newPhone.replace(')', '');
    newPhone = newPhone.replace('.', '');
    return newPhone;
  }

  getFinalUrl(finalUrl: string, tokenid: string, userPhone: string) {
    let newUrl = finalUrl;
    if (!finalUrl.includes('tokenid')) {
      if (newUrl.includes('?') && newUrl.endsWith('?')) {
        newUrl += tokenid && tokenid !== '' ? `tokenid=${tokenid}` : '';
      } else {
        newUrl += tokenid && tokenid !== '' ? `&tokenid=${tokenid}` : '';
      }
    }
    if (!finalUrl.includes('userPhone')) {
      newUrl += userPhone && userPhone !== '' ? `&phoneNumber=${userPhone}` : '';
    }
    return newUrl;
  }

  // Automate task function:
  replaceTask(taskFromTemplate: Task, frmTask: FormGroup) {
    for (var prop in taskFromTemplate) {

      if (Object.prototype.hasOwnProperty.call(taskFromTemplate, prop)) {
        if (prop === 'taskId') {
          continue;
        }
        // do stuff
        if (taskFromTemplate[prop] !== undefined && taskFromTemplate[prop] != null) {
          if (typeof taskFromTemplate[prop] == 'string' && taskFromTemplate[prop] != '') {
            frmTask.controls[prop]?.setValue(taskFromTemplate[prop])
          } else {
            frmTask.controls[prop]?.setValue(taskFromTemplate[prop])
          }

          //exception:

          // taskPriority
          if (prop == 'taskPriority') {
            frmTask.controls[prop]?.setValue(taskFromTemplate[prop])
          }

          // assigneeTo
          if (prop == 'assigneeTo' && taskFromTemplate[prop] != null && taskFromTemplate[prop] !== '') {
            frmTask.controls[prop]?.setValue(taskFromTemplate[prop])
          }
          // relationship
          if (prop == 'relationshipType') {
            frmTask.controls[prop]?.setValue(taskFromTemplate[prop])
            frmTask.controls.relationshipId.setValue(taskFromTemplate.relationshipId)
          }
        }
      }
    }
  }

  // function to add all property from template to current task when it empty.
  appendTask(taskFromTemplate: Task, frmTask: FormGroup) {
    for (var prop in taskFromTemplate) {
      if (Object.prototype.hasOwnProperty.call(taskFromTemplate, prop)) {
        if (prop === 'taskId') {
          continue;
        }
        // do stuff
        if (taskFromTemplate[prop] !== undefined && taskFromTemplate[prop] != null) {
          if (typeof taskFromTemplate[prop] == 'string' && taskFromTemplate[prop] != '') {
            frmTask.controls[prop]?.setValue(taskFromTemplate[prop])
          } else {
            if (frmTask.controls[prop].pristine == null) {
              frmTask.controls[prop]?.setValue(taskFromTemplate[prop])
            }
          }
        }
      }
    }
  }
  log(data) {
    console.log(data);
  }
}

