import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SMSHistoryPaging } from 'src/app/modules/admin/sms-history/sms-history-model';
import { SMSTemplate } from 'src/app/modules/admin/sms-template-management/sms-template-model';
import { environment } from 'src/environments/environment';
import { SMSHistoryUIModel, SMSTemplateUIModel } from '../components/template-management/primas-send-sms/primas-send-sms.component';
import { Page } from '../models/paging/page';
import { PagedData } from '../models/paging/paged-data';
import { ReturnResult } from '../models/return-result';

@Injectable({
  providedIn: 'root'
})
export class SmsManagementService {
  baseUrl = environment.apiSMSManagement;
  constructor(private http: HttpClient) { }
  GetSMSHistoryPaging(page: Page): Observable<ReturnResult<PagedData<SMSHistoryPaging>>> {
    return this.http.post<ReturnResult<PagedData<SMSHistoryPaging>>>(`${this.baseUrl}/GetSMSHistoryPaging`, page);
  }

  getSMSByProfileId(profileId: string, pageData: PagedData<SMSHistoryUIModel>): Observable<ReturnResult<PagedData<SMSHistoryUIModel>>> {
    return this.http.post<ReturnResult<PagedData<SMSHistoryUIModel>>>(`${this.baseUrl}/GetSMSByProfileId?profileId=${profileId}`, pageData);
  }

  sendSMSToQueue(model): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SendSMSToQueue`, model);
  }

  getAllSMSTemplate(): Observable<ReturnResult<SMSTemplateUIModel[]>> {
    return this.http.get<ReturnResult<SMSTemplateUIModel[]>>(`${this.baseUrl}/GetAllSMSTemplate`);
  }

  getSMSTemplatePaging(page: Page): Observable<ReturnResult<PagedData<SMSTemplateUIModel>>> {
    return this.http.post<ReturnResult<PagedData<SMSTemplateUIModel>>>(`${this.baseUrl}/GetSMSTemplatePaging`, page);
  }

  deleteOneSMSTemplate(id: number): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/${id}`);
  }

  deleteMultipleSMSTemplate(idList: number[]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/Delete`, idList);
  }

  saveSMSTemplate(model: SMSTemplate): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/Save`, model);
  }
}
