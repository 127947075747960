<div class="d-flex flex-wrap" mat-dialog-title style="margin: unset;padding: 1px 10px 1px 28px;">
  <button mat-icon-button aria-label="Close the dialog" class="ml-auto" (click)="closeDialog(false)">
    <mat-icon>close</mat-icon>
  </button>
</div>
<ng-container *ngIf="!modelLoading; else bodyLoading">
  <mat-dialog-content style="height: 85%;" class="no-scroll">
    <div class="col-lg-12">
      <form class="column " [formGroup]="opportunityForm">
        <mat-card key="i">
          <!-- container -->
          <div class="row col-lg-12">
            <!-- Opportunity name -->
            <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
              <mat-label>{{environment.titleOpportunity}} Name</mat-label>
              <input matInput autocomplete="off" required formControlName="opportunityName" trim="blur">
              <mat-error
                *ngIf="opportunityForm.controls.opportunityName.errors && (opportunityForm.controls.opportunityName.dirty || opportunityForm.controls.opportunityName.touched)">
                {{opportunityForm.controls.opportunityName['errorMessage']}}</mat-error>
            </mat-form-field>
            <!-- Account Name -->
            <!-- <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
              <mat-label>Account Name</mat-label>
              <input matInput autocomplete="off" formControlName="accountName" trim="blur">
            </mat-form-field> -->
            <!-- probability -->
            <mat-form-field *nbIsGranted="['view', 'probability-opportunity']" appearance="standard"
              class="col-lg-4 col-md-6"
              [matTooltip]="opportunityForm?.controls?.probability?.disabled ? 'Can not modify the field because of the ' + automateCtrl?.value?.dataStateName + ' state' : ''">
              <mat-label>Probability</mat-label>
              <input matInput autocomplete="off" formControlName="probability" (blur)="onNumberUnFocus('probability')"
                (focus)="onNumberFocus('probability')">
              <mat-error
                *ngIf="opportunityForm.controls.probability.errors && (opportunityForm.controls.probability.dirty || opportunityForm.controls.probability.touched)">
                {{opportunityForm.controls.probability['errorMessage']}}</mat-error>
            </mat-form-field>
            <!-- Type -->
            <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
              <mat-label>Type</mat-label>
              <input #typeInput matInput formControlName="type" [matAutocomplete]="typeAutoComplete?.autocomplete">
              <mat-spinner *ngIf="typeLoading" matSuffix [diameter]="18" style="float: right; margin-left: 8px">
              </mat-spinner>
              <app-primas-auto-complete #typeAutoComplete="primasAutoComplete" [input]="typeInput"
                [searchAPI]="typeDynamicAPI" [searchProperty]="'content'" [keyProperty]="'content'"
                [valueProperty]="'content'" (toggleLoading)="getLoadingValueType($event)">
              </app-primas-auto-complete>
            </mat-form-field>

            <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
              <mat-label>Lead Source</mat-label>
              <input #leadSourceInput matInput [matAutocomplete]="leadSourceAuto.autocomplete"
                formControlName="leadSource">
              <mat-spinner *ngIf="leadSourceLoading" matSuffix [diameter]="18" style="float: right; margin-left: 8px">
              </mat-spinner>
              <app-primas-auto-complete #leadSourceAuto="primasAutoComplete" [input]="leadSourceInput"
                [searchAPI]="leadSourceDynamicAPI" [searchProperty]="'content'" [keyProperty]="'content'"
                [valueProperty]="'content'" (toggleLoading)="getLoadingValueLeadSource($event)">
              </app-primas-auto-complete>
            </mat-form-field>

            <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
              <!-- <mat-label>Estimate</mat-label> -->
              <mat-label>{{environment.titleEstimate}}</mat-label>
              <input matInput formControlName="amount" autocomplete="off" (blur)="onNumberUnFocus('amount')"
                (focus)="onNumberFocus('amount')" />
              <mat-error
                *ngIf="opportunityForm.controls.amount.errors && (opportunityForm.controls.amount.dirty || opportunityForm.controls.amount.touched)">
                {{opportunityForm.controls.amount['errorMessage']}}</mat-error>
            </mat-form-field>
            <!-- Closed date -->
            <mat-form-field appearance="standard" class="col-lg-4 col-md-6" trim="blur">
              <mat-label>{{environment.titleClosedDate}}</mat-label>
              <input matInput [matDatepicker]="closeDatePicker" formControlName="closeDate">
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matSuffix [for]="closeDatePicker">
                <mat-icon matDatepickerToggleIcon>calendar_month</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #closeDatePicker disabled="false"></mat-datepicker>
              <mat-error *ngIf="opportunityForm.controls.closeDate.errors ">
                {{opportunityForm.controls.closeDate['errorMessage'] ||
                'Please enter valid format: MM/DD/YYYY. Ex:12/21/2022'}}
              </mat-error>
            </mat-form-field>
            <!--Secondary Close date-->
            <mat-form-field appearance="standard" class="col-lg-4 col-md-6" trim="blur" *nbIsGranted="['view','end-date-opportunity']">
              <mat-label>End Date (Optional)</mat-label>
              <input matInput [matDatepicker]="secondaryCloseDatePicker" formControlName="secondaryCloseDate">
              <mat-hint>MM/DD/YYYY</mat-hint>
              <button mat-icon-button matSuffix (click)="resetSecondaryValue()" style="color: rgba(0, 0, 0, 0.54);"><mat-icon>close</mat-icon></button>
              <mat-datepicker-toggle matSuffix [for]="secondaryCloseDatePicker">
                <mat-icon matDatepickerToggleIcon>calendar_month</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #secondaryCloseDatePicker disabled="false"></mat-datepicker>
              <mat-error *ngIf="opportunityForm.controls.secondaryCloseDate.errors ">
                {{opportunityForm.controls.secondaryCloseDate['errorMessage'] ||
                'Please enter valid format: MM/DD/YYYY. Ex:12/21/2022'}}
              </mat-error>
            </mat-form-field>
            <!-- Company -->
            <!-- <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
              <mat-label>Company</mat-label>
              <input #companyInput matInput [matAutocomplete]="companyAuto.autocomplete" formControlName="company">
              <mat-spinner *ngIf="companyLoading" matSuffix [diameter]="18" style="float: right; margin-left: 8px">
              </mat-spinner>
              <app-primas-auto-complete #companyAuto="primasAutoComplete" [input]="companyInput"
                [searchAPI]="companyDynamicAPI" [searchProperty]="'content'" [keyProperty]="'content'"
                [valueProperty]="'content'" (toggleLoading)="getLoadingValueCompany($event)">
              </app-primas-auto-complete>
            </mat-form-field> -->
            <!-- Stage -->
            <mat-form-field appearance="standard" class="col-lg-12 col-md-12">
              <mat-select required (openedChange)="changeValue($event)" [formControl]="automateCtrl"
                placeholder="Select Stage *" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Stage..." [formControl]="automateFilterCtrl"
                    noEntriesFoundLabel="No Stage found">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option style="z-index: 2 !important;" *ngFor="let automate of filteredAutomate | async"
                  [value]="automate">
                  <mat-chip-list>
                    <mat-chip [style.background-color]="automate.colorCode" selected style="z-index: -1 !important;">
                      {{automate.dataStateName}}
                    </mat-chip>
                  </mat-chip-list>
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="opportunityForm.controls.stageId.errors && (opportunityForm.controls.stageId.dirty || opportunityForm.controls.stageId.touched)">
                {{opportunityForm.controls.stageId['errorMessage']}}</mat-error>
            </mat-form-field>
          </div>
        </mat-card>
      </form>
    </div>
  </mat-dialog-content>
  <div mat-dialog-actions>
    <mat-toolbar fxLayout="row" fxLayoutAlign="end center" style="background-color: white;">
      <button mat-raised-button color="primary" class="float-right" [nbSpinner]="loading" [disabled]=" loading"
        nbSpinnerStatus="primary" [nbSpinner]="loading" [disabled]="!opportunityForm.valid || loading" debouncedClick
        (throttleClick)="onClickSave()">
        <mat-icon fontSet=" material-icons-outlined">save</mat-icon>Save
      </button>
    </mat-toolbar>
  </div>
</ng-container>


<ng-template #bodyLoading>
  <ngx-skeleton-loader count="15" appearance="line" [theme]="{ width: '70vw' }"></ngx-skeleton-loader>
</ng-template>