import { AfterViewInit, Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { CustomerPlan } from './customer-plan.model';
import { CustomerService } from '../customer.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppliedCouponComponent } from './applied-coupon/applied-coupon.component';
import { NbToastrService } from '@nebular/theme';
import { MatHorizontalStepper, MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-customer-plan-management',
  templateUrl: './customer-plan-management.component.html',
  styleUrls: ['./customer-plan-management.component.scss']
})
export class CustomerPlanManagementComponent implements OnInit, AfterViewInit {

  private stepper: MatStepper;
  @ViewChild('stepper', { static: false }) set content(content: MatStepper) {
    if (content) {
      this.stepper = content;
      this.runToNextStep();
    }
  }

  currentPlan;
  completePayment: boolean = true;
  chosenPlan: CustomerPlan;
  listPlan: CustomerPlan[] = [];
  selectedPlanIndex: number = -1;
  isLoadingPlan: boolean = false;
  isEditableSteps: boolean = true;

  constructor(
    private customerService: CustomerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialModalRef: MatDialogRef<CustomerPlanManagementComponent>,
  ) {
    this.currentPlan = { plan: data.plan };
    this.completePayment = (data?.completePayment) ?? this.completePayment;
  }
  ngAfterViewInit(): void {
    this.runToNextStep();
  }
  runToNextStep() {
      const chosenPlan = window.localStorage.getItem("chosenPlan");
      if (chosenPlan) {
        this.chosenPlan = JSON.parse(chosenPlan);    
        if (this.stepper.selectedIndex == 0) {
          window.localStorage.removeItem("chosenPlan");
          this.stepper.selected.completed = true;
          this.stepper.next();
        }
      };
  }

  ngOnInit(): void {
    this.getAllPlan();
  }

  async getAllPlan() {
    this.isLoadingPlan = true;

    const result = await this.customerService.getAllPlan().toPromise();
    if (result && result.result?.length > 0) {
      this.listPlan = result.result.map(plan => {
        plan.amount_cents = plan.amount_cents / 100;
        plan.description = plan.description.replace(/\n/g, "<br/>");
        return plan;
      }).sort((a, b) => {
        return (a.amount_cents - b.amount_cents)
      });
    }

    // Add ContactUs Block
    this.listPlan.push({
      name: "CONTACT US",
      code: "CONTACT_US",
      description: "Request Now for Best Offer"
    } as CustomerPlan)

    this.isLoadingPlan = false;
  }

  async updatePlan(e) {
    this.stepper.selected.completed = true;
    this.chosenPlan = e;
    window.localStorage.setItem("chosenPlan", JSON.stringify(e));
    this.stepper.next();
    if (this.stepper.selectedIndex === 1) this.stepper.selected.completed = false;
  }

  proceedPlan(e) {
    window.localStorage.removeItem("chosenPlan");

    if (this.stepper.selectedIndex === 1) {
      this.stepper.selected.completed = true;
      this.stepper.next();
    }
    if (this.stepper.selectedIndex === 2) this.isEditableSteps = e;
  }

  closeDialog() {
    window.localStorage.removeItem("chosenPlan");
    this.dialModalRef.close(true);
  }
}
