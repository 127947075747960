<!-- <app-primas-toolbar #primasToolbar (onAfterAddFinish)="refreshData(true)"
 [customView]="false" [table]="primasTable"
    [columns]="columnsGridAll" [resource]="resource.toolbar"
    [addEditComponet]="addEditComponent">
</app-primas-toolbar> -->

<app-primas-table #primasTable (onRefresh)="refreshData($event)" [columnsTable]="columnsGridAll"
    [resource]="resource.table" [usingCustomEdit]="true" (customEditFunction)="onClickEdit($event)"
    [customAction3]="customAction3" [tableAction]="true" [allowSelectRow]="false" [optionHeight]="maxHeight"
    [rowClass]="rowClass" [disableTableAction]="readonlyGrid">
</app-primas-table>

<ng-template #customAction3 let-row="row">
    <button mat-menu-item (click)="openRemoveConfirmPop(row)"
        *ngIf="allowDeleteContact && row.profileId == relationshipId">
        <mat-icon color='warn'>delete</mat-icon>
        <span>Delete</span>
    </button>
    <button mat-menu-item (click)="openConfirmDialog(row.contact)" *ngIf="row.profileId == relationshipId"
        [disabled]="row.primaryContact">
        <mat-icon [color]="row.primaryContact ? 'basic' : 'warn'">link_off</mat-icon>
        <span>Unlink</span>
    </button>
</ng-template>

<ng-template #primaryContact let-row="row">
    <div *ngIf="row.primaryContact === true && row.profileId == relationshipId;">
        <mat-icon aria-hidden="false" aria-label="check_circle" class="success-icon-table large">check_circle</mat-icon>
    </div>
</ng-template>

<ng-template #name let-row="row">
    <div (click)="onClickEdit({row, rowIndex: null})">
        <b class="pointer">{{row.contact.contactName}}</b>
    </div>
</ng-template>
<ng-template #lName let-row="row">
    <div (click)="onClickEdit({row, rowIndex: null})">
        <b class="pointer">{{row.contact.contactLastName}}</b>
    </div>
</ng-template>
<ng-template #contactEmail let-row="row">
    <ng-container [ngTemplateOutlet]="inlineContact" [ngTemplateOutletContext]="{
        row: row,
        value: row?.contact?.contactEmail ?? '',
        property: 'contactEmail',
        validProp: contactValidEmail
      }"></ng-container>
</ng-template>
<ng-template #contactPhone let-row="row">
    <ng-container [ngTemplateOutlet]="inlineContact" [ngTemplateOutletContext]="{
        row: row,
        value: row?.contact?.contactPhone ?? '',
        property: 'contactPhone',
        pipe: phonePipe
      }"></ng-container>
</ng-template>

<ng-template #phoneExtTemp let-row="row">
    <ng-container [ngTemplateOutlet]="inlineContact" [ngTemplateOutletContext]="{
        row: row,
        value: row?.contact?.primaryPhoneExt || '',
        property: 'primaryPhoneExt',
        pipe: phoneExtPipe,
        validProp: phoneExtValid.valid,
        messageError: phoneExtValid.message
      }"></ng-container>
</ng-template>


<!-- <ng-template #lName let-row="row">
    <div (click)="onClickEdit({row, rowIndex: null})">
        <b class="pointer">{{row.contact.contactLastName}}</b>
    </div>
</ng-template> -->

<ng-template #inlineContact let-row="row" let-value="value" let-property="property" let-pipe="pipe"
    let-validProp="validProp" let-messageError="messageError">
    <app-inline-edit fxFlex="1 1 calc(100%-30px)" [inputData]="value"
        (handleSave)="editInlineRow($event, property, row)" [removePaddingBottom]="true" [widthDisplay]="'100%'"
        [usingMessageParent]="true" [validatorValue]="validProp" [messageError]="messageError" [enterToSubmit]="true"
        [escapeToCancel]="true" [pipe]="pipe" isAscii="true" [readonlyInline]="readonlyGrid">
    </app-inline-edit>
</ng-template>

<ng-template #birthDate let-row="row">
    {{row.contact.birthDate | dateFormat}}
</ng-template>
<ng-template #contactEmail let-row="row">
    <span [ngStyle]="{'color': changeColor(row)}"> {{row?.contact?.contactEmail}}</span>
</ng-template>

<ng-template #contactType let-row="row">
    {{row?.contact?.showContactType}}
</ng-template>

<!-- confirm modal: -->
<ng-template #confirmRemoveContactPop>
    <app-primas-confirm-pop>
        <div content>
            <ng-container *ngIf="!isLoading; else skeletonPopup">
                <ng-container *ngIf="removeDataInfo">
                    <ng-container
                        *ngIf="removeDataInfo.profileEntities.length == 0 && removeDataInfo.buyerEntities.length == 0 && removeDataInfo.profilePrimaryEntities.length == 0 && removeDataInfo.buyerPrimaryEntities.length == 0;else warningContact">
                        <div style="FONT-WEIGHT: 500;
                    font-size: 19px;
                    text-align: center;">Do you wish to delete this contact?</div>
                    </ng-container>
                    <ng-template #warningContact>
                        <div class="alert alert-warning" role="alert">
                            Do you wish to delete this contact?
                        </div>
                    </ng-template>
                    <div>
                        <div *ngIf="removeDataInfo.primaryRelated">
                            <h4> This contact had been <b style="color:green">set primary</b> by these entities:</h4>
                            <nb-list>
                                <nb-list-item *ngFor="let item of removeDataInfo.profilePrimaryEntities">
                                    <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
                                        <div fxLayout="row" fxLayoutAlign="space-around center" style="gap: 10px;">
                                            <ng-container [ngTemplateOutlet]="showIcon"
                                                [ngTemplateOutletContext]="{type:item.typeName}">
                                            </ng-container>
                                            {{item.displayName | autoPlaceholder: 'No Name'}}
                                        </div>
                                        <button mat-icon-button matTooltip="Open this entity"
                                            (click)="openRemovedNewTab(item.profileId,item.typeName)">
                                            <mat-icon>open_in_new</mat-icon>
                                        </button>
                                    </div>
                                </nb-list-item>
                                <nb-list-item *ngFor="let item of removeDataInfo.buyerPrimaryEntities">
                                    <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
                                        <div fxLayout="row" fxLayoutAlign="space-around center" style="gap: 10px;">
                                            <mat-icon class="icon-hover" matTooltip="Buyer"
                                                style="transform: scale(1.5);" [ngStyle]="{'color':entityColor.Buyer}">
                                                {{entityIcon.Buyer}}
                                            </mat-icon>
                                            {{item.companyName | autoPlaceholder: 'No Name'}}
                                        </div>
                                        <button mat-icon-button style="visibility: hidden;"
                                            matTooltip="Open this entity">
                                            <mat-icon>open_in_new</mat-icon>
                                        </button>
                                    </div>
                                </nb-list-item>
                            </nb-list>
                        </div>
                        <div
                            *ngIf="removeDataInfo.profileEntities.length > 0 || removeDataInfo.buyerEntities.length > 0">
                            <h4 class="my-2">This contact has been linked to these entities:</h4>
                            <nb-list>
                                <nb-list-item *ngFor="let item of removeDataInfo.profileEntities">
                                    <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
                                        <div fxLayout="row" fxLayoutAlign="space-around center" style="gap: 10px;">
                                            <ng-container [ngTemplateOutlet]="showIcon"
                                                [ngTemplateOutletContext]="{type:item.typeName}">
                                            </ng-container>
                                            {{item.displayName | autoPlaceholder: 'No Name'}}
                                        </div>
                                        <button mat-icon-button matTooltip="Open this entity"
                                            (click)="openRemovedNewTab(item.profileId,item.typeName)">
                                            <mat-icon>open_in_new</mat-icon>
                                        </button>
                                    </div>
                                </nb-list-item>
                                <nb-list-item *ngFor="let item of removeDataInfo.buyerEntities">
                                    <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
                                        <div fxLayout="row" fxLayoutAlign="space-around center" style="gap: 10px;">
                                            <mat-icon class="icon-hover" matTooltip="Buyer"
                                                style="transform: scale(1.5);" [ngStyle]="{'color':entityColor.Buyer}">
                                                {{entityIcon.Buyer}}
                                            </mat-icon>
                                            {{item.companyName | autoPlaceholder: 'No Name'}}
                                        </div>
                                        <button mat-icon-button style="visibility: hidden;">
                                            <mat-icon>open_in_new</mat-icon>
                                        </button>
                                    </div>
                                </nb-list-item>
                            </nb-list>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>

        <!-- button -->
        <div actions>
            <button mat-button (click)="closeRemoveDialog(false)">
                No
            </button>
            <button mat-button (click)="closeRemoveDialog(true)">
                Yes
            </button>
        </div>
    </app-primas-confirm-pop>
</ng-template>

<ng-template #showIcon let-type="type">
    <span [ngSwitch]="type">
        <mat-icon class="icon-hover" [matTooltip]="environment.titleOpportunity" style="transform: scale(1.5);"
            *ngSwitchCase="entityName.Opportunity" [ngStyle]="{'color':entityColor.Opportunity}">
            {{entityIcon.Opportunity}}
        </mat-icon>
        <mat-icon class="icon-hover" [matTooltip]="environment.titleAccount" style="transform: scale(1.5);"
            *ngSwitchCase="entityName.SaleAccount" [ngStyle]="{'color':entityColor.SaleAccount}">
            {{entityIcon.SaleAccount}}
        </mat-icon>
        <mat-icon class="icon-hover" matTooltip="Profile" style="transform: scale(1.5);" *ngSwitchDefault
            [ngStyle]="{'color':entityColor.Profile}">
            {{entityIcon.Profile}}
        </mat-icon>
    </span>
</ng-template>

<ng-template #skeletonPopup>
    <ngx-skeleton-loader count="8" animation="progress" [theme]="{height: '50px'}"></ngx-skeleton-loader>
</ng-template>