import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from './../../../shared/models/paging/paged-data';
import { ReturnResult } from './../../../shared/models/return-result';
import { Observable } from 'rxjs-compat/Observable';
import { environment } from './../../../../environments/environment';
import { Injectable } from "@angular/core";
import { ReplaySubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Note, NoteDetails } from './noteManagement.model';
import { OverlayNoteDetailsComponent } from './overlay-note-details/overlay-note-details.component';
import { UserNote } from 'src/app/modules/admin/user-note-management/user-note-model';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class NoteManagementService {
  listNote: NoteDetails[] = [];
  listNoteObservable: ReplaySubject<NoteDetails[]> = new ReplaySubject<NoteDetails[]>(1);
  overlayNoteDetailsComponent: OverlayNoteDetailsComponent = null;

  baseUrl = environment.apiPageNote;
  constructor(private http: HttpClient) { }

  getNotePaging(page: Page, keyword: string, OwnerId: string): Observable<ReturnResult<PagedData<NoteDetails>>> {
    return this.http.post<ReturnResult<PagedData<NoteDetails>>>(`${this.baseUrl}/GetNoteByProfileId/${OwnerId}?keyword=${keyword}`, page)
      .pipe(map(x => {
        x.result.data = x?.result?.data?.map(y => {
          y.lastUserId = y.lastUserId == null ? y.ownerId : y.lastUserId;
          y.lastUser = y.lastUser == null ? y.user : y.lastUser;
          return y;
        })
        return x;
      }));
  }
  saveNote(note: Note, OwnerId: string): Observable<ReturnResult<number>> {
    return this.http.post<ReturnResult<number>>(`${this.baseUrl}/SaveUserNote/${OwnerId}`, note);
  }
  deleteNote(noteId: number, OwnerId: string): Observable<ReturnResult<PagedData<Note>>> {
    return this.http.delete<ReturnResult<PagedData<Note>>>(`${this.baseUrl}/DeleteUserNote/${OwnerId}/${noteId}`);
  }

  multipleDeleteNote(listNote: number[], OwnerId: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteUserNotes/${OwnerId}`, listNote)
  }

  deleteAllExcept(listNote: number[], OwnerId: string): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteAllButExcept/${OwnerId}`, listNote)
  }

  getNoteByReferenceId(id: string, referenceType: string): Observable<ReturnResult<NoteDetails[]>> {
    return this.http.get<ReturnResult<NoteDetails[]>>(`${this.baseUrl}/GetNoteByReferenceId?id=${id}&referenceType=${referenceType}`);
  }

  listNoteChanged() {
    return this.listNoteObservable.asObservable();
  }

  setNoteDetails(notes: NoteDetails[] = []) {
    this.listNote = [...notes];
  }

  pushNoteDetails(data?: any, referenceName?: string) {
    if (!this.listNote) this.listNote = [];

    let newNote: NoteDetails = data
      ? Object.assign(data, data.id
        ? { referenceName: referenceName }
        : { id: 0, title: 'Untitled', } as NoteDetails)
      : Object.assign({ id: 0, title: 'Untitled', } as NoteDetails)

    this.listNote.push(newNote);
    if (this.listNote && this.listNote.length > 3)
      this.listNote = this.listNote.slice(-3)

    this.listNote = [...this.listNote];
    this.listNoteObservable.next(this.listNote);
  }

  completedNoteService() {
    this.listNote = [];
    this.listNoteObservable.complete();
  }

  getReferenceNameLinkNote(id: string, type: string): Observable<ReturnResult<string>> {
    return this.http.get<ReturnResult<string>>(`${this.baseUrl}/GetReferenceNameLinkNote?id=${id}&referenceType=${type}`);
  }

  lastNoteByReferenceId(id: string, type: string): Observable<ReturnResult<NoteDetails>> {
    return this.http.get<ReturnResult<NoteDetails>>(`${this.baseUrl}/LastNoteByReferenceId?id=${id}&referenceType=${type}`);
  }

  getUserNotePaging(page: Page): Observable<ReturnResult<PagedData<UserNote>>> {
    return this.http.post<ReturnResult<PagedData<UserNote>>>(`${this.baseUrl}/GetUserNotePaging`, page);
  }
  saveUserNoteModel(ownerId: string, model: UserNote): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveUserNoteModel/${ownerId}`, model);
  }
  deleteUserNoteModel(id: number) {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/DeleteUserNoteModel/${id}`);
  }
  deleteListUserNote(ids: number[]) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteListUserNote`, ids);
  }

  userNoteDetailPaging(page: Page): Observable<ReturnResult<PagedData<NoteDetails>>> {
    return this.http.post<ReturnResult<PagedData<NoteDetails>>>(`${this.baseUrl}/UserNoteDetailPaging`, page)
      .pipe(map(x => {
        x.result.data = x?.result?.data?.map(y => {
          y.lastUserId = y.lastUserId == null ? y.ownerId : y.lastUserId;
          y.lastUser = y.lastUser == null ? y.user : y.lastUser;
          return y;
        })
        return x;
      }));
  }

  getNoteById(noteId: number): Observable<ReturnResult<NoteDetails>> {
    return this.http.get<ReturnResult<NoteDetails>>(`${this.baseUrl}/GetNoteById/${noteId}`);
  }
}
