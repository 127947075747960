import { NbMenuItem } from "@nebular/theme";

/* -------------------------- START - CONSTANT VALUE -------------------------- */
// parentUrl -> PARENT url
// parentUrl/{ID} -> CHILDREN url

//
export const detailIdKey = "This-Is-A-Test-Detail-Id-79230481-TestDetail-1235";

export const customTabRelationshipUrls: string[][][] = [
  //relationshipArr -> an 2D array with parentArray & childrenArray
  //Index 0 is parentUrls
  //Index 1 is childrenUrls
  //ALWAYS specify default value is an EMPTY ARRAY!!!!

  //Profile Management
  [
    ["/configuration/profile"],
    [`/configuration/profile/${detailIdKey}`]
  ],

  //Opportunity Management
  [
    ["/configuration/opportunity"],
    [`/configuration/opportunity/${detailIdKey}`, `/configuration/opportunity-crm/${detailIdKey}`]
  ],

  //Sale Account Management
  [
    ["/configuration/sale-account"],
    [`/configuration/sale-account/${detailIdKey}`, `/configuration/sale-account-crm/${detailIdKey}`]
  ],

  //Leads Management, Venues Leads Management
  [
    ["/configuration/sale-lead", "/configuration/sale-venue-lead"],
    [`/configuration/sale-lead/${detailIdKey}`],
  ],

  //Task Management
  [
    ["/configuration/task"],
    [`/configuration/task/${detailIdKey}`]
  ],

  //Contact Management
  [
    ["/configuration/contact"],
    [`/configuration/contact/${detailIdKey}`],
  ],

  //Venue management
  [
    ["/configuration/venue"],
    [`/configuration/venue/${detailIdKey}`],
  ],
];

//Tabs that you don't want it becopme CHILDREN, put it in childrenIgnoreCases Array
export const childrenIgnoreCases: string[] = [
  //Case add contact
  "/configuration/contact/-1",
]

export enum TabTypeEnums {
  PARENT = "PARENT",
  CHILDREN = "CHILDREN",
}

export enum IndexRelationship {
  PARENT = 0,
  CHILDREN = 1,
}

export enum ExtendDataKeyEnums {
  PARENT_KEY = "parentId",
}
/* -------------------------- END - CONSTANT VALUE -------------------------- */


export class UserCustomizableTabModel {
  constructor() {
    this.tabId = 0;
    this.isActive = false;
    this.children = [];
  }
  tabId: number;
  userId: string;
  tabName: string;
  tabUrl: string;
  extendData?: string | null;
  queryParams?: any | null;
  deleted?: boolean | null;
  tabOrders?: any | null;
  isActive: boolean = false;
  tabType: TabTypeEnums | null = TabTypeEnums.PARENT;
  dateDeleted?: Date | string | null;
  dateModified?: Date | string | null;
  dateCreated?: Date | string | null;
  children: UserCustomizableTabModel[] = [];

  currentUrl?: string;
}

export class ExtendNbMenuItem extends NbMenuItem {
  allowOpenTab?: boolean = false;
  title: string;
  icon?: string;
  link?: string;
  children?: ExtendNbMenuItem[];
  data?: { permission?: string; resource?: string; }
}