import { prop, required } from "@rxweb/reactive-form-validators";

export class IndustryScript{
    industryScriptId: string;
    @required()
    @prop()
    industryName: string;
    @prop()
    body: string;
    dateCreated: string;
    dateModified:string;
}