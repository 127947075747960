<div class="d-flex flex-wrap align-items-center mb-0" mat-dialog-title>
  Call Transcription
  <div fxLayout="row" fxLayoutAlign="space-between center" class="ml-auto">
    <button mat-icon-button aria-label="Close the dialog" (click)="closeDialog()" class="ml-auto">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div fxLayout="column" class="mb-2" *ngIf="!isLoading; else skeleton">
  <p class="mb-0"><b><i>{{phoneCallLog?.dateCreated | date: 'EEEE, MMMM dd, y, hh:mm a'}}</i></b></p>
  <p class="mb-0"><b><i>{{phoneCallDuration}}</i></b></p>
</div>

<mat-dialog-content class="border border-left-0 border-right-0 pb-3">
  <mat-tab-group mat-stretch-tabs>
    <mat-tab label="Transcription">
      <ng-template matTabContent>
        <ng-container [ngTemplateOutlet]="conversationBlock"></ng-container>
      </ng-template>
    </mat-tab>
    <mat-tab label="AI Summary">
      <ng-template matTabContent>
        <ng-container [ngTemplateOutlet]="summaryTextBlock"></ng-container>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="closeDialog()">Close</button>
</mat-dialog-actions>


<ng-template #conversationBlock>
  <div class="conversation-container">
    <ng-container *ngFor="let item of conversation">
      <div class="w-100">
        <div class="message mb-2" [ngClass]="(item.sender == userA) ? 'callerA ml-auto' : 'callerB'">
          <div class="py-2 px-3 d-flex flex-column">
            <span class="mb-0 message-sender" [ngClass]="(item.sender == userA) ? 'ml-auto' : ''">
              {{(item.sender == userA) ? userA : (item.sender | phoneFormat)}}
            </span>
            <p class="mb-0" [ngClass]="(item.sender == userA) ? 'text-right ml-auto' : ''">
              {{item.message}}
            </p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #summaryTextBlock>
  <div class="summay-container">
    <div [innerHtml]="summaryText | safeHtml" class="mb-2"></div>
  </div>
</ng-template>

<ng-template #skeleton>
  <ngx-skeleton-loader count="2" appearance="line"></ngx-skeleton-loader>
</ng-template>
