import { greaterThanEqualTo, maxLength, minNumber, numeric, NumericValueType, prop, propArray, propObject, range, required, unique } from "@rxweb/reactive-form-validators";
import { ReasonRequest } from "src/app/shared/models/request/reason-request.model";
import { BioRewrite, BioRewriteViewModel, ReasonCategory } from "./profile-detail/bio-n-reason/bio-reason-category.model";
import { MergeTestimonial, Testimonial } from './profile-detail/bio-n-reason/testimonial-input/testimonial.model';
import { Media as NewMedia } from '../profile-management/profile-detail/multi-media/multi-media.model';
import { MediaResize } from "../media-resize-management/media-resize.model";
import { SaleOpportunity } from "../opportunity-management/opportunity.model";
import { BuyerModel } from "src/app/shared/models/buyer.model";
import { UserModel } from "../user-management/user-model";
import { AutomateDataState } from "./profile-detail/automate-datastate-log/automate-datastate-model";
import { FFCUrl } from "../indexing/indexing.model";
import { FormArray } from "@angular/forms";
import { RevisionStatus } from "../revision-version-group-management/revision-version.model";
import { IndustryScript } from "../industry-management/industry.model";

export class ProfileDetailModel {
  profileId: string;
  @prop()
  displayName: string | null;
  referenceLink: string | null;
  @prop()
  realName: string = null;
  @prop()
  webPage: string = null;
  bio: string | null;
  @prop()
  notes: string | null;
  @prop()
  rider: string | null;
  @prop()
  travel: string | null;
  consent: boolean | null;
  published: boolean | null;
  dataState: number;
  dateModified: string | null;
  datecreated: string | null;
  tags: string = '';
  lastUser: string;
  @prop()
  facebook: string;
  @prop()
  setList: string;
  @prop()
  testimonials: string;
  @prop()
  previousClients: string;
  parentID: string;
  signature: string;
  isMaster: boolean | null;
  isActive: boolean | null;
  locationId: number | null;
  contactId: number | null;
  deleted: boolean;
  dateDeleted: string | null;
  @prop()
  tiktok: string | null;
  @prop()
  instagram: string | null;
  @prop()
  skype: string | null;
  @prop()
  youtube: string | null;
  location: Location | null;
  contact: Contact | null;
  profileCategories: ProfilesCategory[];
  medias: Media[];
  profileContacts: ProfileContact[];
  reasons: Reason[];
  bioRewrites: [];
  @prop()
  twitter: string | null;
  profileLocations: ProfileLocation[];
  tagList: string[] = [];
  producer: Producer;
  seoInfo: SeoInfo;
  autoDataState: AutomateDataState;

  // 2021-09-07 tien add start
  primaryImage: number;
  primaryVideo: number;
  // 2021-09-07 tien add end

  media: Media;  //check null

  //2021-10-18 vuonglqn add start
  favoriteArt: boolean;
  isPopularAct: boolean;
  isTopAct: boolean;
  //2021-10-18 vuonglqn add end
  //2021-11-1 vuonglqn add start
  ownerId?: string;
  //2021-11-1 vuonglqn add end
  // 2021-12-16 tienlm add start
  riderAttachment?: string;
  // 2021-12-16 tienlm add end
  relationship: number;
  //2022-03-24 tienlm add start
  actGroupId?: string | null;
  //2022-03-24 tienlm add end
  automateDataStateId: number | null;

  // 2022-05-17 gnguyen start add
  extendData?: string;
  // 2022-05-17 gnguyen end add
  // 2022-05-25 tienlm add start
  @numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true })
  maxPrice: number | null;
  @numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true })
  minPrice: number | null;
  @prop()
  price: string | null;
  // 2022-05-25 tienlm add end

  // 2022-06-07 gnguyen start add
  profileCode: string | null;
  // 2022-06-07 gnguyen end add

  // 2022-08-02 tienlm add start
  @prop()
  isVirtualAct: boolean;
  @prop()
  officiateWedding: boolean;
  @prop()
  gender: string;
  // 2022-08-02 tienlm add end
  @prop()
  saleLeadsContact: Contact | null;
  @prop()
  annualRevenue: number;
  @prop()
  industry: string;
  @prop()
  noOfEmployee: number;
  @prop()
  company1: string;
  @prop()
  typeName: string;
  @prop()
  masterParent: ProfileDetailModel | null;
  @prop()
  saleOpportunity: SaleOpportunity | null;
  accountContactId: number;
  @prop()
  leadSource: string;
  @propObject()
  profileAdditionDetail: ProfileAdditionDetail | null;
  @propObject(IndustryScript)
  industryScript: IndustryScript | null;

  contactActionType: string;

  //this is a title of ProfileAdditionalDetail
  title: string;
  company?: string;
  seoInfoId: number;
  showLocalBusiness: boolean;
}
export class Reason {
  @prop()
  reasonId: number;
  @prop()
  profileId: string;
  @prop()
  @maxLength({ value: 3000 })
  reason1: string | null;
  @prop()
  @maxLength({ value: 1000 })
  order: string | null;
  @prop()
  @maxLength({ value: 50 })
  source: string | null;
  reasonCategory: ReasonCategory[];
  @prop()
  @required()
  published: boolean;
  @prop()
  @range({ minimumNumber: 0, maximumNumber: 100 })
  priority: number | null;
}
export class ProfilesCategory {
  categoryId: string
  category: Category
  profileId: string
  profileCategoryId: number
}

export class Category {
  categoryId: string
  categoryName: string | null
  parentId: string
  media?: Media | null;
  seoInfo?: SeoInfo | null;
}

export class ProfileContact {
  contactId: number;
  contact: Contact;
  profileId: string;
  profileContactId: number;
  primaryContact: boolean;
  deleted: boolean;
  dateDeleted: string | null;
  profile: ProfileDetailModel;
  lastCommunicationAction: string | null;
  lastCommunicationDate: string | null;
  lastCommunicationLogId: number | null;
}

export class Contact {
  contactId: number;
  contactPhone: string;
  contactName: string = '';
  contactEmail: string;
  contactLastName: string = '';
  relationship: string;
  secondaryPhone: string;
  cellPhone: string;
  secondaryEmail: string;
  facebook: string;
  skype: string;
  whatsApp: string;
  tiktok: string;
  primaryContact: boolean;
  locationId: number;
  location: Location;
  deleted: boolean;
  dateDeleted: string | null;
  profileContacts: ProfileContact[];
  ownerId?: string | null;
  contactContactTypes?: ContactContactType[];
  // 2021-01-04 tienlm add start
  website: string | null;
  // 2021-01-04 tienlm add end
  tags: string | null;
  jobTitle: string | null;
  saluation: string;
  accountName: string;
  department: string;
  birthDate: string;
  description: string;
  objectsRelationship?: KeyPairsValue[] = [];
  dateModified?: string | null;
  dateCreated?: string | null;
  ownerUser?: UserModel;
  linkedIn: string;
  source: string;
  primaryPhoneExt: string;
  secondaryPhoneExt: string;
  cellPhoneExt: string;
}

export class Media {
  @prop()
  mediaId: number;
  @prop()
  profileId: string;
  @prop()
  media1: string;
  @prop()
  mediaTypeId: number;
  deleted: boolean;
  dateDeleted: string | null;
  mediaResizes: MediaResize[] | null;
  @prop()
  title: string | null;
  profile: ProfileDetailModel
}

export class ProfileLocation {
  profileLocationId: number;
  profileId: string;
  locationId: number;
  primaryLocation: boolean;
  location: Location;
  deleted: boolean;
  dateDeleted: string | null;
}

export class Location {
  locationId: number;
  city: string;
  country: string;
  zipcode: string;
  address: string;
  address2: string;
  region: string;
  primaryLocation: boolean;
  type: string;
  state: string;
  deleted: boolean;
  dateDeleted: string;
  nearestBigCity: string;
  willTravel: boolean;
  howFar: string;
  majorCity: string;
  majorCountry: string;
  majorZipcode: string;
  majorState: string;
}
export class Producer {
  producerId: string;
  producerName: string = '';
  isActive: boolean | null;
  dateModified: string | null;
  dateCreated: string | null;
  deleted: boolean;
  dateDeleted: string | null;
  contactId: number | null;
  locationId: number | null;
  producerType: number;
  location: Location;
}
export interface ProfileSeo {
  profileSeoInfoId: number;
  profileId: string;
  seoInfoId: number;
  seoInfo: SeoInfo;
  primarySeoInfo: boolean;
  profile: ProfileDetailModel;
  colorDisplayDesc:string|'';
  colorDisplayTitle:string|'';
}
export class SeoInfo {
  seoInfoId: number;
  title: string;
  description: string;
  keywords: string;
  friendlyUrl: string;
  objectType: string;
  additionalTags: string;
  keywordForSearch: string;
  deleted: boolean;
  dateDeleted: string | null;
  seoCategories: SeoCategory[];
  ffcUrl: FFCUrl
  overallScore: number;
  primaryKeyword: string;
  secondaryKeyword: string;
  readabilityScore: number;
  noIndex: boolean;
  aiScore: string;
  orgScore: string;
}
export class SeoCategory {
  seoCategoryId: number;
  seoInfoId: number | null;
  seoInfo: SeoInfo;
  categoryId: string;
  categories: Category;
}

export class ContactContactType {
  contactId: number | null;
  contactTypeId: number | null;
  contactType: ContactType | null;
}

export class ContactType {
  contactTypeId: number | null;
  contactTypeName: string | null;
  contactTypeColorCode: string | null;
  display: boolean | null;
}

export class RepliedEmailData {
  ativityId: number;
  title: string;
  body: string;
  timestamp: string;
  error: string;
  message: string;
}

export class ProfileSaveChangeModel {
  @prop()
  displayName: string | null;
  @prop()
  realName: string = null;
  @prop()
  webPage: string = null;
  @prop()
  notes: string | null;
  @prop()
  rider: string | null;
  @prop()
  travel: string | null;
  @prop()
  facebook: string;
  @prop()
  setList: string;
  @prop()
  previousClients: string;
  @prop()
  tiktok: string | null;
  @prop()
  instagram: string | null;
  @prop()
  skype: string | null;
  @prop()
  youtube: string | null;
  @prop()
  twitter: string | null;
  @prop()
  bio: string | null;
  reason: ReasonRequest[] | null;
  bios: BioRewriteViewModel[] | null;

}

export class MergeProfileModel {
  /**
   *
   */
  actGroupId: string | null;
  bio: string | null;
  bioRewrites: BioRewrite[] = [];
  company: string | null;
  consent: boolean | null;
  contact: Contact | null;
  contactId: string | null;
  contactList: Contact[] = [];
  dataState: number;
  displayName: string | null;
  facebook: string | null;
  favoriteArt: boolean;
  instagram: string | null;
  isActive: boolean | null;
  isMaster: boolean | null;
  isPopularAct: boolean | null;
  isTopAct: boolean | null;
  location: Location | null;
  locationId: number | null;
  locationList: Location[] = [];
  media: Media | null;
  medias: NewMedia[] = [];
  previousClients: string | null;
  primaryImage: number | null;
  primaryVideo: number;
  producer: Producer | null;
  profileCategories: ProfilesCategory[] = [];
  profileContacts: ProfileContact[] = [];
  profileId: string;
  profileLocations: ProfileLocation[] = [];
  published: boolean | null;
  realName: string | null;
  reasons: Reason[] = [];
  referenceLink: string | null;
  relationship: number;
  rider: string | null;
  riderAttachment: string | null;
  setList: string | null;
  signature: string | null;
  skype: string | null;
  tags: string | null;
  testimonials: string | null;
  tiktok: string | null;
  travel: string | null;
  twitter: string | null;
  webPage: string | null;
  youtube: string | null;
  testimontialObj: MergeTestimonial[] | null;
  videos: NewMedia[] = [];
  images: NewMedia[] = [];
  automateDataStateId: number | null;
}
// displayNameProp

export class SelectMerge {
  selectMergeFirst: KeyPairsValue[] = [];
  selectMergeSecond: KeyPairsValue[] = [];
}

export class KeyPairsValue {
  key: string;
  value: any;
}

export class KeyPairsValueSelectDropDown extends KeyPairsValue {
  color: string;
  tooltip: string;
  order: number;

  // vunh start 29/12/2022
  groupPlaceholder: string | null;
}
export class RemoveAtionIdList {
  idList: number[];
}

export class KeyPairsValueTree extends KeyPairsValue {
  parent: KeyPairsValueTree;
  parentId: string;
  children: KeyPairsValueTree[];
}

export class KeyPairsValueTypeObjectAPI extends KeyPairsValueSelectDropDown {
  displayType: string;
  iconType: string;
  propId: string;
  propName: string;
}

export class KeyPairsValueObjectAPI extends KeyPairsValueSelectDropDown {
  id: string;
  isRemove: boolean = false;
}

export class ContactReferenceEntitiesViewModel {
  primaryRelated: boolean;
  profileEntities: ProfileDetailModel[];
  buyerEntities: BuyerModel[];
  profilePrimaryEntities: ProfileDetailModel[];
  buyerPrimaryEntities: BuyerModel[];
}

export class ProfileAdditionDetail {
  @prop()
  profileId: string;
  @prop()
  channel: string;
  @prop()
  primaryReseller: string;
  @prop()
  oneTimeProduct: number;
  @prop()
  oneTimeService: number;
  @prop()
  arr: number;
  @prop()
  termOfContract: number;
  @prop()
  artistFeesToClient: number;
  //
  totalClientFee: number;
  nonArtistIncome: number | null;
  nonArtistExpense: number | null;

  totalValue: number;
  deleted: boolean;
  dateModified?: string | null;
  dateCreated?: string | null;
  primaryResellerName: string | null;
  hasAttachments: number;
  primaryRelationship: string | null;
  primaryRelationshipName: string | null;

  title: string;
  totalTask: number;
  totalContact:number;
  showLocalBusiness: boolean;
  isLocked: boolean;
  generalCount: string | null;
  relationships: string | null;
  totalNumberFile?: number;
  totalNumberTask?: number;
  companySize: string;
  lastContact?: string | null;
}

export class ProfileAdditionDetailForOpportunity {
  @prop()
  profileId: string;
  @prop()
  oneTimeProduct: number;
  @prop()
  oneTimeService: number;
  @prop()
  arr: number;
  @prop()
  termOfContract: number | string;
  @prop()
  companySize: string;
  deleted: boolean;
  dateModified?: string | null;
  dateCreated?: string | null;
}

export class RecommendContactInfo {
  id: string;
  fullName: string;
  type: string;
  value: string;
  typeContact: string;
  typeObject: string;
  key: string;
  contact: Contact;
  phoneExt: string;
}

export class RecommendContact extends Contact {
  id: string;
  fullName: string;
  type: string;
  value: string;
  contactName: string;
  contactLastName: string;
  typeContact: string;
  typeObject: string;
  key: string;
  phoneExt: string;
}

export class KeywordMapping  {

  keywordMappingId: number;
  name: string;
  extendData: string;
  status: number;
  deleted: boolean;
  dateDeleted: string | null;
  dateModified: string | null;
  dateCreated: string | null;
  rawExtendData: string | null;
  automateStatus: AutomateDataState | null;
}

export class KeywordField
{
  @prop()
  @unique({ message: "The keyword must be unique." })
  Keyword: string;
  @prop()
  SearchVolume:number
  @prop()
  KwDifficulty: number;
  @prop()
  IsPrimary: boolean = false;
}
export class KeywordMappingForm  {

  @prop()
  KeywordMappingId: number = 0;
  @prop()
  @required()
  Name: string;
  @prop()
  Status: number;
  @prop()
  ContentType: string
  @prop()
  SearchIntent: string
  @prop()
  @required()
  TargetUrl: string
  TotalVolume: number = 0;
  @propArray(KeywordField)
  KeywordLst: KeywordField[] = [];
  deleted: boolean;
  dateDeleted: string | null;
  dateModified: string | null;
  dateCreated: string | null;
  rawExtendData: any
  @prop()
  ReferenceType: string;
  @prop()
  ReferenceId: string;
}

export class KeywordMappingViewModel {
  keywordMappingId: number;
  name: string;
  extendData: string;
  status: number | null;
  contentType: string;
  searchIntent: string;
  targetUrl: string;
  referenceId: string;
  totalVolume: number | null;
  referenceType: string;
  keywordLst: KeywordFieldViewModel[] = [];
}

export class KeywordFieldViewModel {
  keyword: string;
  searchVolume: number | null;
  kwDifficulty: number | null;
  isPrimary: boolean;
}
export class GetGeneralCountByProfileId{
  typeName:string;
  totalNumber: number;
}
export class SeoInfoPrimaryNoIndex{
  seoInfoId: number = 0;
  noIndex: boolean = false;
}
export class MaintainNoIndexWithNewSeoPrimary{
    oldSeoInfoId: number;
    newSeoInfoId: number;
}
