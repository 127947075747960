import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { AccountOpportunityResult, ReturnResult } from 'src/app/shared/models/return-result';
import { environment } from 'src/environments/environment';
import { ProposalNonArtistFee, ProposalArtists, SaleOpportunity, SaleOpportunityProfile, SaleOpportunityViewModel, ProposalRevision, ProposalWhiteLabelData, AddProfileVendorViewModel } from './opportunity.model';
import { tap, timeout } from 'rxjs/operators';
import { ProfileDetailModel } from '../profile-management/profile-detail.model';
import { SaleOpportunityReport } from './opportunity-sale-report/opportunity-sale-report.component';
import { ForecastReport } from './opportunity-sale-report/opportunity-sale-forecast-report/opportunity-sale-forecast-report.component';
import { ProductReportData } from './opportunity-sale-report/opportunity-sale-product-report/opportunity-sale-product-report.component';
import { TemplateModel } from './opportunity-details/proposal-artist-template/proposal-artist-template.component';
import { ProposalTemplateName } from '../proposal-template-management/proposal-template.model';
import { DataUploadProposal, UploadProposal } from '../proposal-template/proposal-template.component';
import { SaleOpportunityReportV2 } from './opportunity-sale-report/opportunity-sale-report-v2/opportunity-sale-report-v2.component';
import { ForecastReportV2 } from './opportunity-sale-report/opportunity-sale-forecast-report-v2/opportunity-sale-forecast-report-v2.component';
import { SaleTeamReport, SaleTeamUserList } from './opportunity-sale-report/opportunity-sale-team-report/opportunity-sale-team-report.component';

@Injectable({
  providedIn: 'root'
})
export class OpportunityManagementService {
  baseUrl = environment.apiSaleOpportunity;

  saleOpportunity: BehaviorSubject<SaleOpportunity[]> = new BehaviorSubject<SaleOpportunity[]>(undefined);
  opportunityDetail$: ReplaySubject<ReturnResult<ProfileDetailModel>> = new ReplaySubject<ReturnResult<ProfileDetailModel>>(1);
  isRefreshOpportunityGrid$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  searchOpportunity$: ReplaySubject<ReturnResult<ProfileDetailModel[]>> = new ReplaySubject<ReturnResult<ProfileDetailModel[]>>(1);
  countAmountSubject: Subject<number> = new Subject<number>();

  constructor(private http: HttpClient) { }

  getOpportunityPaging(page: Page): Observable<ReturnResult<PagedData<SaleOpportunityProfile>>> {
    return this.http.post<ReturnResult<PagedData<SaleOpportunityProfile>>>(`${this.baseUrl}/GetOpportunityPaging`, page);
  }

  saveOpportunity(model: SaleOpportunity): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveOpportunity`, model);
  }

  deleteOpportunity(id: string): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/delete/${id}`)
  }

  deleteOpportunities(id: string[], isDellAll: boolean = false, typeName: string = null, isTemporary: boolean = false, isArchive: boolean = false, isDisconnectCRM: boolean = false): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteOpportunities?isDeletedAll=${isDellAll}&typeName=${typeName}&isTemporary=${isTemporary}&archive=${isArchive}&isDisconnectCRM=${isDisconnectCRM}`, id);
  }

  import(formData: FormData): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/Import`, formData).pipe(
      timeout(1200000)
    );
  }

  export(page: Page, isExportContact: boolean = false, isExportSaleAccount: boolean = false): Observable<ReturnResult<any>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/Export?isExportContact=${isExportContact}&isExportSaleAccount=${isExportSaleAccount}`, page).pipe(
      timeout(1200000)
    );
  }
  // id: sale account id
  getSaleAccountOpportunity(id: string): Observable<ReturnResult<SaleOpportunity[]>> {
    return this.http.get<ReturnResult<SaleOpportunity[]>>(`${this.baseUrl}/GetSaleAccountOpportunity/${id}`,).pipe(timeout(1200000));
  }
  getSaleAccountOpportunityLst(): Observable<SaleOpportunity[]> {
    return this.saleOpportunity.asObservable();
  }
  refreshGetSaleAccountOpportunity(id: string, event: (loading: boolean) => void) {
    if (event) {
      event(true);
    }
    this.http.get<ReturnResult<SaleOpportunity[]>>(`${this.baseUrl}/GetSaleAccountOpportunity/${id}`,).pipe(timeout(1200000)).subscribe({
      next: (data) => {
        if (data.result) {
          this.saleOpportunity.next(data.result);
        }
      },
      complete: () => {
        if (event) {
          event(false);
        }
      }
    });

  }
  SaveAccountOpportunity(model: SaleOpportunityViewModel) {
    return this.http.post<ReturnResult<string>>(`${this.baseUrl}/SaveAccountOpportunity`, model).pipe(timeout(1200000));
  }

  unlinkOpportunitySaleAccount(id: number): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/UnlinkOpportunitySaleAccount/${id}`).pipe(timeout(1200000));
  }

  refreshOpportunityById(id: string): Observable<ReturnResult<ProfileDetailModel>> {
    return this.http.get<ReturnResult<ProfileDetailModel>>(`${this.baseUrl}/Opportunity?id=${id}`)
      .pipe(tap(resp => this.opportunityDetail$.next(resp)));
  }

  getOpportunityById(): Observable<ReturnResult<ProfileDetailModel>> {
    return this.opportunityDetail$.asObservable();
  }

  editOpportunity(model: {
    id: string,
    saleOpportunityProfileModel?: ProfileDetailModel,
    saleOpportunityModel?: SaleOpportunity,
    opportunityId?: string
  }): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/Opportunity/Update?id=${model.id}`, model)
      .pipe(tap(resp => this.isRefreshOpportunityGrid$.next(resp.result)));
  }

  getSearchOpportunity() {
    return this.searchOpportunity$.asObservable();
  }

  searchOpportunity(keyword: string): Observable<ReturnResult<ProfileDetailModel[]>> {
    return this.http.get<ReturnResult<ProfileDetailModel[]>>(`${this.baseUrl}/Search?keyword=${keyword}`)
      .pipe(tap(resp => this.searchOpportunity$.next(resp)));
  }
  deleteProfileByFilter(model) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteAllAsync`, model);
  }
  countAmountOpportunityProfilePaging(pagingFilter: Page) {
    return this.http.post<ReturnResult<number>>(`${this.baseUrl}/CountAmountOpportunityProfilePaging`, pagingFilter).pipe(tap(x => {
      if (x.result || x.result == 0) {
        this.countAmountSubject.next(x.result);
      }
    }));
  }
  getTotalAmount(): Observable<number> {
    return this.countAmountSubject.asObservable();
  }
  listQuarter
  bulkAddTag(page: Page, tags: string): Observable<ReturnResult<number>> {
    return this.http.post<ReturnResult<number>>(`${this.baseUrl}/BulkAddTags?tags=${tags}`, page);
  }

  addOpportunityToAccount(accountId: string, opportunityId: string) {
    return this.http.post<AccountOpportunityResult<any>>(`${this.baseUrl}/AddOpportunityToAccount`, { accountId, opportunityId });
  }
  addAccountOpportunityToRelationship(accountId: string, opportunityId: string) {
    return this.http.post<AccountOpportunityResult<any>>(`${this.baseUrl}/AddAccountOpportunityToRelationship`, { accountId, opportunityId });
  }
  getOpportunityReport(data: SaleOpportunityReport) {
    return this.http.post<AccountOpportunityResult<any>>(`${this.baseUrl}/GetOpportunityReport`, data);
  }
  getAllOpportunityClosedDateYear() {
    return this.http.get<ReturnResult<[]>>(`${this.baseUrl}/GetAllOpportunityClosedDateYear`);
  }
  getForecastReport(data: ForecastReport) {
    return this.http.post<ReturnResult<any[]>>(`${this.baseUrl}/GetForecastReport`, data);
  }
  getProductReport(data: ForecastReport) {
    return this.http.post<ReturnResult<ProductReportData[]>>(`${this.baseUrl}/GetProductReport`, data);
  }
  getNonArtistPaging(page: Page, proposalId: string, className: string): Observable<ReturnResult<PagedData<ProposalNonArtistFee>>> {
    return this.http.post<ReturnResult<PagedData<ProposalNonArtistFee>>>(`${this.baseUrl}/GetProposalNonArtistPaging?proposalId=${proposalId}&className=${className}`, page);
  }
  deleteProposalNonArtist(id: number) {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/DeleteProposalNonArtist/${id}`);
  }
  deleteListProposalNonArtist(ids: number[]) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteListProposalNonArtist`, ids);
  }
  saveProposalNonArtistFee(model: ProposalNonArtistFee) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveProposalNonArtistFee`, model);
  }

  getProposalArtistPaging(page: Page, profileId: string): Observable<ReturnResult<PagedData<ProposalArtists>>> {
    return this.http.post<ReturnResult<PagedData<ProposalArtists>>>(`${this.baseUrl}/GetProposalArtistPaging/${profileId}`, page);
  }
  saveProposalArtist(model: ProposalArtists): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveProposalArtist/`, model);
  }
  deleteProposals(ids: [any]): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/DeleteProposalArtists/`, { ids });
  }
  savePropProposalArtist(propName: string, model: ProposalArtists): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SavePropProposalArtist`, {
      propName,
      proposalArtists: model
    });
  }

  generateArtistProposal(id: string, inputMergeTag: any, sections: TemplateModel[]): Observable<ReturnResult<ProposalRevision>> {
    return this.http.post<ReturnResult<ProposalRevision>>(`${this.baseUrl}/GenerateArtistProposal?id=${id}`, { inputMergeTag, sections });
  }

  setUpArtistProposal(id: string, sections: TemplateModel[], bodies: TemplateModel[]): Observable<ReturnResult<{ string, any }>> {
    return this.http.post<ReturnResult<{ string, any }>>(`${this.baseUrl}/SetUpArtistProposal?id=${id}`, { sections, bodies });
  }

  proposalTemplate(): Observable<ReturnResult<{ string, any }>> {
    return this.http.get<ReturnResult<{ string, any }>>(`${this.baseUrl}/ProposalTemplate`);
  }

  contentSectionTemplate(sections: TemplateModel[], inputMergeTag: any): Observable<ReturnResult<string>> {
    return this.http.post<ReturnResult<string>>(`${this.baseUrl}/ContentSectionTemplate`, { sections, inputMergeTag });
  }

  getProposalRevisionPaging(page: Page, id: string): Observable<ReturnResult<PagedData<ProposalRevision>>> {
    return this.http.post<ReturnResult<PagedData<ProposalRevision>>>(`${this.baseUrl}/GetProposalEventPaging/${id}`, page);
  }

  deleteProposalRevision(id: string): Observable<ReturnResult<boolean>> {
    return this.http.delete<ReturnResult<boolean>>(`${this.baseUrl}/DeleteProposalRevision/${id}`);
  }

  getProposalRevisionById(id: string): Observable<ReturnResult<ProposalRevision>> {
    return this.http.get<ReturnResult<ProposalRevision>>(`${this.baseUrl}/GetProposalRevisionById?id=${id}`);
  }
  getSubFolder(): Observable<ReturnResult<string[]>> {
    return this.http.get<ReturnResult<string[]>>(`${this.baseUrl}/GetSectionTemplate`);
  }
  getAllFileProposalTemplate(): Observable<ReturnResult<ProposalTemplateName[]>> {
    return this.http.get<ReturnResult<ProposalTemplateName[]>>(`${this.baseUrl}/GetAllFileProposalTemplate`)
  }
  saveProposalTemplate(model: DataUploadProposal, files: File) {
    var bodyFormData = new FormData();
    bodyFormData.append('model', JSON.stringify(model));
    bodyFormData.append('files', files);
    return this.http.post<ReturnResult<Boolean>>(`${this.baseUrl}/SaveProposalTemplate`, bodyFormData);
  }

  getBodyTemplateById(id: string, model: any) {
    return this.http.post<ReturnResult<ProposalRevision>>(`${this.baseUrl}/GetProposalTemplateByArtistId/${id}`, model);
  }

  getOpportunityReportWithDataStateFilter(data: SaleOpportunityReportV2) {
    let clientOffset: number = - new Date().getTimezoneOffset();
    data.offset = clientOffset;

    return this.http.post<AccountOpportunityResult<any>>(`${this.baseUrl}/GetOpportunityReportWithDataStateFilter`, data);
  }

  getForecastReportWithDataStateFilter(data: ForecastReportV2) {
    let clientOffset: number = - new Date().getTimezoneOffset();
    data.offset = clientOffset;

    return this.http.post<ReturnResult<any[]>>(`${this.baseUrl}/GetForecastReportWithDataStateFilter`, data);
  }

  getSaleTeamReportWithDataStateFilter(data: SaleTeamReport) {
    let clientOffset: number = - new Date().getTimezoneOffset();
    data.offset = clientOffset;

    return this.http.post<ReturnResult<any[]>>(`${this.baseUrl}/GetSaleTeamReportWithDataStateFilter`, data);
  }

  getSaleTeamUserList() {
    return this.http.get<ReturnResult<SaleTeamUserList[]>>(`${this.baseUrl}/getSaleTeamUserList`);
  }

  getCloseWonPieChart(data: SaleTeamReport) {
    let clientOffset: number = - new Date().getTimezoneOffset();
    data.offset = clientOffset;

    return this.http.post<ReturnResult<any[]>>(`${this.baseUrl}/GetCloseWonPieChart`, data);
  }

  getCloseWonStackedBarChart(data: SaleTeamReport) {
    let clientOffset: number = - new Date().getTimezoneOffset();
    data.offset = clientOffset;

    return this.http.post<ReturnResult<any[]>>(`${this.baseUrl}/GetCloseWonStackedBarChart`, data);
  }
  changeVideoGroup(id: string, option: string) {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/ChangeVideoProject`, {ProposalId: id, Option: option} );
  }
  getProposalWhiteLabelData(id: string) {
    return this.http.get<ReturnResult<ProposalWhiteLabelData>>(`${this.baseUrl}/GetProposalWhiteLabelData/${id}`);
  }
  updateWhiteLabelUrl(model: ProposalWhiteLabelData) {
    return this.http.post<ReturnResult<ProposalWhiteLabelData>>(`${this.baseUrl}/UpdateWhiteLabelUrl`, model);
  }
  createVendor(model: AddProfileVendorViewModel): Observable<ReturnResult<boolean>> {
    return this.http.post<ReturnResult<boolean>>(`${this.baseUrl}/SaveVendor`, model);
  }
}
