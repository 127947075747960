<div class="d-flex flex-wrap" mat-dialog-title style="margin: unset; padding: 1px 10px 1px 28px" mat-dialog-title>
  <div fxFlex fxLayout="column" fxLayoutAlign="center center">
    <h3 [ngStyle.sm]="{'font-size': '18px'}" [ngStyle.gt-md]="{'font-size': '28px'}">Plan Option</h3>
    <p [ngStyle.sm]="{'font-size': '10px'}" [ngStyle.gt-sm]="{'font-size': '14px'}">Select plan where to lead</p>
  </div>
  <button mat-icon-button aria-label="Close the dialog" (click)="closeDialog()" class="ml-auto">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <ng-container *ngIf="!isLoadingPlan; else skeleton">
    <mat-horizontal-stepper [linear]="true" labelPosition="bottom" #stepper>
      <mat-step label="Choose Plan" [completed]="false" [editable]="isEditableSteps">
        <ng-container *ngIf="stepper?.selectedIndex == null || stepper?.selectedIndex == 0">
          <app-choose-plan [currentPlan]="currentPlan" [listPlan]="listPlan"
            (onChangePlan)="updatePlan($event)" [completePayment]="completePayment"></app-choose-plan>
        </ng-container>
      </mat-step>
      <mat-step label="Confirm & Payment" [editable]="isEditableSteps">
        <ng-container *ngIf="stepper?.selectedIndex == 1">
          <div class="d-flex justify-content-center">
            <app-applied-coupon [currentPlan]="currentPlan" [chosenPlan]="chosenPlan" [ngStyle.gt-md]="{'width': '50%'}"
              [stepper]="stepper" (changeEditableSteps)="proceedPlan($event)"></app-applied-coupon>
          </div>
        </ng-container>
      </mat-step>
      <mat-step label="Validation">
        <ng-container *ngIf="stepper?.selectedIndex == 2">
          <div class="d-flex justify-content-center">
            <app-waiting-payment></app-waiting-payment>
          </div>
        </ng-container>
      </mat-step>
    </mat-horizontal-stepper>
  </ng-container>
</mat-dialog-content>

<ng-template #skeleton>
  <ngx-skeleton-loader count="10" appearance="line"></ngx-skeleton-loader>
</ng-template>
