<nb-card>
    <nb-card-header>
        <ng-container *ngIf="titleHeader == null">
            {{contextInput.month + 1 == 0 ? contextInput.year - 1 : contextInput.year}}/{{contextInput.month
            + 1
            == 0 ? '12' : contextInput.month + 1 }}/{{contextInput.day}} ({{events?.length}})
        </ng-container>
        <ng-container *ngIf="titleHeader !== null">
            {{titleHeader}}
        </ng-container>
    </nb-card-header>
    <nb-card-body class="body-card">
        <app-task *ngFor="let event of events" [task]="event.task" [userId]="event.task.assigneeTo"
                [isDisplayAsList]="true" [truncateTaskName]="18" [isOnlyMyIssues]="true"
                [taskStatusList]="taskStatusList" [taskPriority]="priority" [isDragable]="false"
                [isIndependable]="false" [removeAssigneeTaskList]="true" [funcClickDetail]="funcClickDetail"
                [matTooltip]="event.desc | truncateText: [100, '...']" (closeDialog)="openDialogTaskInline($event)">
                <div class="cdk-drag-placeholder" *cdkDragPlaceholder>
                    <div class="issue-wrap" style="padding: 0 15px 0px 15px;margin:0;">
                        <div class="issue">
                            <p class="pb-3 text-15 text-textDarkest">
                            </p>
                        </div>
                    </div>
                </div>
            </app-task>
    </nb-card-body>
</nb-card>