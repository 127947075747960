import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'formatDisplaySaleTitle'
})
export class FormatDisplaySaleTitlePipe implements PipeTransform {
  transform(value: string): unknown {
    var displayName = '';

    if(value?.toLowerCase()?.includes('lead')){
      displayName = value.replace( /lead/gi, environment.titleLead);
    }else if(value?.toLowerCase()?.includes('opportunity')){
      displayName = value.replace( /opportunity/gi, environment.titleOpportunity);
    }else if(value?.toLowerCase()?.includes('account')){
      displayName = value.replace( /account/gi, environment.titleAccount);
    }else {
      displayName = value;
    }
    return displayName;
  }

}
