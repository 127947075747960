<mat-dialog-content>
  <ng-content select="[content]"></ng-content>
</mat-dialog-content>
<mat-dialog-actions align="right" class="float-right">
  <!-- <button mat-button mat-dialog-close>
    <ng-content select="[cancel]">
    </ng-content>
  </button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>
    <ng-content select="[confirm]">
    </ng-content>
  </button> -->
  <ng-content select="[actions]">

  </ng-content>
</mat-dialog-actions>