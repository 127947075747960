import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NbAuthService } from '@nebular/auth';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ReturnResult } from '../../models/return-result';
import { HttpClient } from '@angular/common/http';
import { Helper } from '../../utility/Helper';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserCustomizableTabBarService } from '../user-customizable-tab-bar/user-customizable-tab-bar.service';

export const NAVIGATION_STACK = "Navigation_Stack_URL";

@Injectable({
  providedIn: 'root'
})
export class UserExperienceTrackingService {
  userBaseUrl: string = environment.apiUser;

  constructor(
    private http: HttpClient,
    private router: Router,
    private location: Location,
    private nbAuthService: NbAuthService,
    private userCustomizableTabBarService: UserCustomizableTabBarService,
  ) { }

  /* ----------------- START - Tag Index for details screen (for ex: #3) ----------------- */
  saveTagIndexChangeInUrl(tagIndex: number): void {
    try {
      // get userToken payload
      const token = window.localStorage.getItem('auth_app_token');
      const tokenJson = JSON.parse(token);
      const jwtHelper = new JwtHelperService();
      const decodedToken = jwtHelper.decodeToken(tokenJson.value);

      let currentUrl: string = this.router?.url;
      if (currentUrl) {
        let urlArrSplit: string[] = currentUrl.split("#");
        currentUrl = urlArrSplit[0]?.charAt(urlArrSplit[0].length - 1) == "/" ? urlArrSplit[0] = urlArrSplit[0]?.slice(0, -1) : urlArrSplit[0];
        let newUrl: string = `${currentUrl}#${tagIndex != null ? tagIndex : 0}`;
        this.location.go(newUrl);
        this.pushUserLastUrl(newUrl, decodedToken?.userName || "");
      } else {
        console.warn("URL isn't exist!");
      }
    }
    catch (ex) {
      console.error(ex);
    }
  }

  getTagIndexInUrl(): string {
    let result: string = "0";
    try {
      let currentUrl: string = this.router?.url;
      let urlArrSplit: string[] = currentUrl.split("#");
      if (urlArrSplit && urlArrSplit?.length > 1) {
        result = urlArrSplit[1]?.toString();
      }
    } catch (ex) {
      console.error(ex);
    }
    return result;
  }
  /* ----------------- END - Tag Index for details screen (for ex: #3) ----------------- */

  /* ----------------- START - Handle NAVIGATION in Source code ----------------- */
  initNavigationStack(): void {
    try {
      let stack = this.getNavigationStack();
      if (!stack) {
        this.setNavigationStack([]);
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  checkIgnoreRemoveStackListWithTheCurrentURL(): boolean {
    //If the the current URL is in the ignore URL list, DO NOT remove clean the Navigation Stack after Reload or SignOut/SignIn
    let result: boolean = false;
    let urlIgnoreRemoveNavigationStack: string[] = [
      "/configuration/permission", //Because we need to know that what screen we should go back, User or Role ?
    ]
    try {
      let currentUrl: string = this.getCurrentUrl();
      if (urlIgnoreRemoveNavigationStack.findIndex((x) => x == currentUrl) != -1) {
        return true;
      }
    } catch (ex) {
      console.error(ex);
    }
    return result;
  }

  private getCurrentUrl(): string {
    let result: string = "";
    try {
      let currentUrl: string = this.router.url;
      let baseUrl: string = `${window.location.protocol}//${window.location.host}`
      result = currentUrl.replace(baseUrl, "");
    } catch (ex) {
      console.error(ex);
    }
    return result;
  }

  private getNavigationStack(): string[] {
    let result: string[] = [];
    try {
      result = JSON.parse(sessionStorage.getItem(NAVIGATION_STACK));
    } catch (ex) {
      console.error(ex);
    }
    return result;
  }

  private setNavigationStack(stack: string[]): void {
    try {
      if (stack) {
        sessionStorage.setItem(NAVIGATION_STACK, JSON.stringify(stack));
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  emptyNavigationStack(): void {
    try {
      this.setNavigationStack([]);
    } catch (ex) {
      console.error(ex);
    }
  }

  private pushNavigationStack(url: string) {
    try {
      let stack: string[] = this.getNavigationStack();

      //Generate Stack when it was empty
      if (!stack) {
        stack = [];
      }

      stack.push(url);
      this.setNavigationStack(stack);
    } catch (ex) {
      console.error(ex);
    }
  }

  private popNavigationStack(): string {
    let result: string = "";
    try {
      let stack: string[] = this.getNavigationStack();
      if (stack) {
        result = stack.pop();
        this.setNavigationStack(stack);
      }
    } catch (ex) {
      console.error(ex);
    }
    return result;
  }

  navigateNext(url: string, params?: any, emptyStack?: boolean, detailLogic?: boolean, skipPushStack?: boolean) {
    //All the navigation in the Header Tab need to be clear the Navigation Stack.
    var currentUrl = this.getCurrentUrl();
    currentUrl = currentUrl?.split('?')[0];
    currentUrl = currentUrl?.split('#')[0];
    currentUrl = currentUrl?.split(';')[0];
    try {
      if (!Helper.isNullOrEmpty(url)) {
        //Handle logic save Tab Child when click into any details screen
        if (detailLogic) {
          this.userCustomizableTabBarService.detailClickedIntoTab(url);
        }

        //Skip pushing data into Navigation Stack, for ex: "abc?query=123", "xyz?test=312"
        if(!skipPushStack) {
          // Handle pushing the same URL onto the Navigation Stack.
          if(currentUrl === url && !emptyStack) return;
        }

        if (emptyStack) {
          this.emptyNavigationStack();
        } else {
          this.pushNavigationStack(this.getCurrentUrl());
        }

        if (params) {
          this.router.navigate([url], { queryParams: params });
        }
        else {
          this.router.navigateByUrl(url);
        }

      }
    } catch (ex) {
      console.error(ex);
    }
  }

  navigatePrevious(alternativeURL?: string) {
    try {
      let url: string = this.popNavigationStack();
      //Navigate back to the URL in the stack
      if (!Helper.isNullOrEmpty(url)) {
        this.router.navigateByUrl(url);
      }
      else {
        //If the stack doesn't have any URL, back to the AlternativeURL
        if (!Helper.isNullOrEmpty(alternativeURL)) {
          this.router.navigateByUrl(alternativeURL);
        }
        else {
          //Navigate to default screen
          let defaultScreen: string = "";
          const token = window.localStorage.getItem('auth_app_token');
          if (token) {
            const tokenJson = JSON.parse(token);
            const helper = new JwtHelperService();
            const decodedToken = helper.decodeToken(tokenJson.value);
            defaultScreen = decodedToken?.defaultScreen;
          }

          if (defaultScreen) {
            this.router.navigateByUrl(defaultScreen);
          }
          //If the token doesn't have any defaultScreen -> back ti the history stack manage by browser
          else {
            this.location.back();
          }
        }
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  pushUserLastUrl(url: string, userName: String) {
    try {
      if (userName) {
        url = decodeURIComponent(url);
        var allUserUrls = JSON.parse(localStorage.getItem('user_last_url'));
        if (!allUserUrls) allUserUrls = {};
        allUserUrls[`${userName}`] = url;
        localStorage.setItem('user_last_url', JSON.stringify(allUserUrls));
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  getUserLastUrl(userName: string): string {
    var result = "";
    try {
      var allUserUrls = JSON.parse(localStorage.getItem('user_last_url'));
      if (allUserUrls) {
        result = allUserUrls[`${userName}`] || "";
        result = decodeURIComponent(result);
      }
    } catch (ex) {
      console.log(ex);
    }
    return result;
  }

  validateUrl(targetUrl: string, allRoutes: any) {
    try {
      targetUrl = decodeURIComponent(targetUrl);
      allRoutes = allRoutes as Object[];
      if (!Helper.isNullOrEmpty(targetUrl) && targetUrl != '/') {
        if (targetUrl[0] === '/') targetUrl = targetUrl.substring(1);
        let standardURL = targetUrl;
        // filter out query parameters:
        standardURL = standardURL.split('?')[0];
        standardURL = standardURL.split('#')[0];
        standardURL = standardURL.split(';')[0];
        if (allRoutes.slice().filter(x => x && !Helper.isNullOrEmpty(x?.path) && x?.path?.includes(standardURL)).length > 0) {
          return true;
        }
        return false;
      }
    } catch (err) {
      return false;
    }
  }

  getCurrentSessionPage() {
    var result = null;
    try {
      result = JSON.parse(window.sessionStorage.getItem("page"));
    } catch(error) {
      console.error(error);
    }
    return result;
  }
  /* ----------------- END - Handle NAVIGATION in Source code ----------------- */

  getDefaultScreenFromUserId(userId: string): Observable<ReturnResult<string>> {
    return this.http.get<ReturnResult<string>>(`${this.userBaseUrl}/GetUserDefaultScreen/${userId}`);
  }
}