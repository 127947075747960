<div fxLayout="column" class="border rounded" fxLayoutAlign="start start">
    <div fxFlex="row" fxLayoutAlign="start center" class="pl-2 w-100" style="background: #ebecec;">
        <mat-select class="custom-input-activity" style="max-width: 150px;" [(ngModel)]="filters.key"
            (selectionChange)="clickRefresh()" [disabled]="isLoading">
            <mat-option value="communication">Communication</mat-option>
            <mat-option value="profile">All</mat-option>
            <ng-container *nbIsGranted="['view', resourceTab.proposalView]">
                <mat-option *ngIf="isOnlyDisplayProposal" value="proposal">Opens</mat-option>
            </ng-container>
            <mat-option [disabled]="isLoading" *ngIf="model?.actGroupId && !model?.isMaster && parentActId"
                [disabled]="isLoading || !parentActId" value="parentCommunication">Parent Communication
            </mat-option>
        </mat-select>

        <mat-date-range-input [rangePicker]="picker" class="ml-auto mr-2 custom-input-activity"
            style="max-width: 175px;" [disabled]="isLoading">
            <input matStartDate [(ngModel)]="filters.dateFilter[0]" placeholder="Start date" [readonly]="true"
                (click)="picker.open()" (dateChange)="dateChange($event)">
            <input matEndDate [(ngModel)]="filters.dateFilter[1]" placeholder="End date" [readonly]="true"
                (click)="picker.open()" (dateChange)="dateChange($event)">
        </mat-date-range-input>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <button mat-icon-button color="primary" [disabled]="isLoading" (click)="clickRefresh()">
            <mat-icon>refresh</mat-icon>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="menuAction">
            <mat-icon>more_vert</mat-icon>
        </button>
    </div>

    <div class="container" [style.--max-height-activity]="maxHeight" infiniteScroll (scrolled)="onScroll()"
        [infiniteScrollDistance]="scrollDistance" [infiniteScrollUpDistance]="infiniteScrollUpDistance"
        [infiniteScrollThrottle]="throttle" [scrollWindow]="false" [immediateCheck]="true" [alwaysCallback]='true'>
        <ng-container *ngIf="dataLogs.length != 0; else blankActivityLog">
            <ng-container *ngFor="let item of dataLogs">
                <ng-container [ngSwitch]="item.type">
                    <!--Email logs-->
                    <ng-container *ngSwitchCase="'email'" [ngTemplateOutlet]="emailTemp"
                        [ngTemplateOutletContext]="{data: item.data}">
                    </ng-container>

                    <!--Task logs-->
                    <ng-container *ngSwitchCase="'Task'" [ngTemplateOutlet]="taskTemp"
                        [ngTemplateOutletContext]="{data: item.data}">
                    </ng-container>

                    <!--Call logs-->
                    <ng-container *ngSwitchCase="'pcall'" [ngTemplateOutlet]="callTemp"
                        [ngTemplateOutletContext]="{data: item.data}">
                    </ng-container>

                    <!-- Outreach -->
                    <ng-container *ngSwitchCase="'outreach'" [ngTemplateOutlet]="outreachTemp"
                        [ngTemplateOutletContext]="{data: item.data}">
                    </ng-container>

                    <!-- SMS History -->
                    <ng-container *ngSwitchCase="'smshistory'" [ngTemplateOutlet]="smshistoryTemp"
                        [ngTemplateOutletContext]="{data: item.data}">
                    </ng-container>

                    <!--Common logs-->
                    <ng-container *ngSwitchCase="'common'" [ngTemplateOutlet]="commonTemp"
                        [ngTemplateOutletContext]="{data: item.data, desc: item.description}">
                    </ng-container>

                    <!--cfCall logs-->
                    <ng-container *ngSwitchCase="'cfcall'" [ngTemplateOutlet]="callFlowCallTemplate"
                        [ngTemplateOutletContext]="{data: item.data}">
                    </ng-container>
                    <!--Comment-->
                    <ng-container *ngSwitchCase="'comment'" [ngTemplateOutlet]="commentTemplate"
                        [ngTemplateOutletContext]="{data: item.data}">
                    </ng-container>
                    <!--Default logs-->
                    <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultTemp"
                        [ngTemplateOutletContext]="{data: item.data}">
                    </ng-container>

                </ng-container>
            </ng-container>
        </ng-container>

        <div *ngIf="!isLoading; else loadingActivityEnd"></div>
    </div>
</div>
<mat-dialog-actions *ngIf="(dialModalRef?._containerInstance && enableCloseButton)" align="end">
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>

<ng-template #taskTemp let-data="data">
    <div fxLayout="row" class="w-100" fxLayoutGap="0.5rem">
        <div fxLayout="column" fxLayoutAlign="start center">
            <mat-icon svgIcon="task-type" [style.color]="data?.taskType?.colorCode"></mat-icon>
            <mat-divider [vertical]="true" class="h-100"></mat-divider>
        </div>
        <div fxLayout="column" fxLayoutAlign="start start" class="w-100">
            <div class="fs-7">
                <b>{{(data?.taskStartDate || data?.dateCreated) | date: 'dd MMM YYYY'}}</b> {{(data?.taskStartDate ||
                data?.dateCreated) | date: 'h:mm a'}}
            </div>
            <nb-card class="custom-card cursor-pointer mb-2 w-100" size="tiny" (click)="openTask(data)">
                <div *ngIf="!(data?.type == 'Task')" class="task-highlight"
                    [style.backgroundColor]="data | activityTask: 'priorityColor'"></div>
                <nb-card-body>
                    <div fxLayout="row">
                        <div fxLayout="column" fxLayoutGap="0.15rem" class="task-content fs-7">
                            <div class="d-flex justify-content-between">
                                <span>
                                    <b>Task: </b>{{data?.taskId}} - {{data?.taskName}}
                                </span>
                                <button class="custom-status-button ml-3" mat-flat-button
                                    [style.backgroundColor]="data?.taskStatus?.colorCode">
                                    {{data?.taskStatus?.taskStatusName}}
                                </button>
                            </div>
                            <span>
                                <b>Type: </b>{{data?.taskType?.taskTypeName}} <img class="task-img"
                                    [src]="getTaskPriorityImg(data.taskPriorities)"
                                    alt="img">{{data?.taskPriorities?.priorityName}}
                            </span>
                            <span><span [style.color]="data | activityTask : 'duedateColor' : doneTaskId"><b>Due:
                                    </b>{{(data?.taskStartDate | date: 'MM/dd/YYYY') || "Unknown"}}</span> - <span
                                    *ngIf="data?.assigneeTo ; else unassign"><b>Assign to:
                                    </b>{{data?.user?.userName}}</span>
                                <ng-template #unassign><b>Unassigning</b></ng-template>
                            </span>
                        </div>
                    </div>
                </nb-card-body>
            </nb-card>
        </div>
    </div>
</ng-template>

<ng-template #emailTemp let-data="data">
    <ng-container *ngIf="(data | activityTimeline: 'email' : emailsCX : campaignsCX) as content">
        <ng-container *ngIf="!content; else bodyEmail"></ng-container>
        <ng-template #bodyEmail>
            <div fxLayout="row" class="w-100" fxLayoutGap="0.5rem">
                <div fxLayout="column" fxLayoutAlign="start center">
                    <!--External email icon-->
                    <ng-container *ngIf="(data?.subAction | lowercase) == 'addins' else defaultEmailIcon">
                        <mat-icon [style.color]="data | activityEmail: 'colorIcon'"
                            [svgIcon]="data | activityEmail: 'icon' : data?.activityType">
                        </mat-icon>
                    </ng-container>

                    <!--Default email icon-->
                    <ng-template #defaultEmailIcon>
                        <mat-icon [style.color]=" data | activityEmail: 'colorIcon'"
                            [ngClass]=" data | activityEmail: 'class'">
                            {{data | activityEmail}}
                        </mat-icon>
                    </ng-template>

                    <mat-divider [vertical]=" true" class="h-100"></mat-divider>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start" class="w-100">
                    <div class="fs-7">
                        <b>{{data?.dateCreated | date: 'dd MMM YYYY'}}</b> {{data?.dateCreated | date: 'h:mm a'}}
                    </div>
                    <nb-card class="custom-card mb-2 w-100" size="tiny" (click)="emailClicked(data)"
                        [ngClass]="data | activityEmail: 'classClick'">
                        <nb-card-body [style.backgroundColor]="data | activityEmail: 'color'"
                            [ngStyle]="data.action == 'Click' ? {'word-break': 'break-all'} : {}">
                            <div [innerHTML]="content | safeHtml"></div>
                        </nb-card-body>
                    </nb-card>
                </div>
            </div>
        </ng-template>
    </ng-container>
</ng-template>

<ng-template #callTemp let-data="data">
    <ng-container *ngIf="(data | activityTimeline: 'call': null: null: model) as content">
        <ng-container *ngIf="!content; else bodyCall"></ng-container>
        <ng-template #bodyCall>
            <div fxLayout="row" class="w-100" fxLayoutGap="0.5rem">
                <div fxLayout="column" fxLayoutAlign="start center">
                    <mat-icon [style.color]="data?.action | activityCall: 'colorIcon'">
                        {{data?.action | activityCall}}
                    </mat-icon>
                    <mat-divider [vertical]="true" class="h-100"></mat-divider>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start" class="w-100">
                    <div class="fs-7">
                        <b>{{data?.dateCreated | date: 'dd MMM YYYY'}}</b> {{data?.dateCreated | date: 'h:mm a'}}
                    </div>
                    <nb-card class="custom-card mb-2 w-100" size="tiny">
                        <nb-card-body [style.backgroundColor]="data?.action | activityCall: 'color'">
                            <div [innerHTML]="content | safeHtml"></div>
                        </nb-card-body>
                    </nb-card>
                </div>
            </div>
        </ng-template>
    </ng-container>
</ng-template>

<ng-template #commonTemp let-data="data" let-desc="desc">
    <ng-container *ngIf="(desc || (data | activityTimeline: 'common': null: null: model: tooltipProp)) as content">
        <ng-container *ngIf="!content; else bodyCommon"></ng-container>
        <ng-template #bodyCommon>
            <div fxLayout="row" class="w-100" fxLayoutGap="0.5rem">
                <div fxLayout="column" fxLayoutAlign="start center">
                    <mat-icon color="primary">info</mat-icon>
                    <mat-divider [vertical]="true" class="h-100"></mat-divider>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start" class="w-100">
                    <div class="fs-7">
                        <b>{{data?.dateCreated | date: 'dd MMM YYYY'}}</b> {{data?.dateCreated | date: 'h:mm a'}}
                    </div>
                    <nb-card class="custom-card mb-2 w-100" size="tiny"
                        [ngClass]="data?.action | activityCommon: 'classClick'" (click)="showConvertResult(data)">
                        <nb-card-body [style.backgroundColor]="data?.action | activityCommon: 'backgroundColor'">
                            <div [innerHTML]="content | safeHtml"></div>
                        </nb-card-body>
                    </nb-card>
                </div>
            </div>
        </ng-template>
    </ng-container>
</ng-template>

<ng-template #outreachTemp let-data="data">
    <ng-container *ngIf="(data | activityTimeline: 'outreach': null: null: model | safeHtml) as content">
        <ng-container *ngIf="!content; else bodyOutreach"></ng-container>
        <ng-template #bodyOutreach>
            <div fxLayout="row" class="w-100" fxLayoutGap="0.5rem">
                <div fxLayout="column" fxLayoutAlign="start center">
                    <mat-icon [style.color]="'#F6F6F6'">outlined_flag</mat-icon>
                    <mat-divider [vertical]="true" class="h-100"></mat-divider>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start" class="w-100">
                    <div class="fs-7">
                        <b>{{data?.dateCreated | date: 'dd MMM YYYY'}}</b> {{data?.dateCreated | date: 'h:mm a'}}
                    </div>
                    <nb-card class="custom-card mb-2 w-100" size="tiny">
                        <nb-card-body [style.backgroundColor]="data | activityDefault: 'background'">
                            <div [innerHTML]="data | activityTimeline: 'outreach': null: null: model | safeHtml"></div>
                        </nb-card-body>
                    </nb-card>
                </div>
            </div>
        </ng-template>
    </ng-container>
</ng-template>

<ng-template #smshistoryTemp let-data="data">
    <ng-container *ngIf="(data | activityTimeline: 'smshistory': null: null: model | safeHtml) as content">
        <ng-container *ngIf="!content; else bodySMSHistory"></ng-container>
        <ng-template #bodySMSHistory>
            <div fxLayout="row" class="w-100" fxLayoutGap="0.5rem">
                <div fxLayout="column" fxLayoutAlign="start center">
                    <mat-icon [style.color]="'#f5bc73'">textsms</mat-icon>
                    <mat-divider [vertical]="true" class="h-100"></mat-divider>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start" class="w-100">
                    <div class="fs-7">
                        <b>{{data?.dateCreated | date: 'dd MMM YYYY'}}</b> {{data?.dateCreated | date: 'h:mm a'}}
                    </div>
                    <nb-card class="custom-card mb-2 w-100" size="tiny">
                        <nb-card-body [style.backgroundColor]=" data?.action | activitySMSHistory : 'background'"
                            [style.wordBreak]="'break-all'">
                            <div [innerHTML]="data | activityTimeline: 'smshistory': null: null: model | safeHtml">
                            </div>
                        </nb-card-body>
                    </nb-card>
                </div>
            </div>
        </ng-template>
    </ng-container>
</ng-template>

<ng-template #defaultTemp let-data="data">
    <ng-container *ngIf="(data | activityTimeline: 'default': null: null: model | safeHtml) as content">
        <ng-container *ngIf="!content; else bodyDefault"></ng-container>
        <ng-template #bodyDefault>
            <div fxLayout="row" class="w-100" fxLayoutGap="0.5rem">
                <div fxLayout="column" fxLayoutAlign="start center">
                    <mat-icon [style.color]="data | activityDefault: 'color'">{{data | activityDefault}}</mat-icon>
                    <mat-divider [vertical]="true" class="h-100"></mat-divider>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start" class="w-100">
                    <div class="fs-7">
                        <b>{{data?.dateCreated | date: 'dd MMM YYYY'}}</b> {{data?.dateCreated | date: 'h:mm a'}}
                    </div>
                    <nb-card class="custom-card mb-2 w-100" size="tiny" (click)="defaultClicked(data)"
                        [ngClass]="data | activityDefault: 'classClick'">
                        <nb-card-body [style.backgroundColor]="data | activityDefault: 'background'">
                            <div [innerHTML]="data | activityTimeline: 'default': null: null: model | safeHtml"></div>
                        </nb-card-body>
                    </nb-card>
                </div>
            </div>
        </ng-template>
    </ng-container>
</ng-template>

<ng-template #loadingActivityEnd>
    <ngx-skeleton-loader class="custom-loader" count="1" appearance="line" animation="progress" [theme]="{
        height: '10px',
        margin: '0',
        padding: '0',
        'border-radius': '10px',
        background: '#83bef4'
    }"></ngx-skeleton-loader>
</ng-template>

<mat-menu #menuAction="matMenu">
    <button mat-menu-item appAddActivityLogBtn [id]="id" type="OPPORTUNITY" (onRefresh)="clickRefresh()">
        <mat-icon>add</mat-icon>Add
    </button>
    <button mat-menu-item (click)="onClickExport()">
        <mat-icon>file_upload</mat-icon>Export
    </button>
    <button mat-menu-item (click)="clickZoomIn()">
        <mat-icon>zoom_out_map</mat-icon>Zoom In
    </button>
</mat-menu>

<ng-template #blankActivityLog>
    <div *ngIf="!isLoading" class="d-flex h-95 w-100 fs-6" fxLayout="column" fxLayoutAlign="center center">
        <img src="assets/images/empty-search.jpg" alt="" [style.width]="'50%'">
        <span class="fs-6"><strong>No records have been logged</strong></span>
    </div>
</ng-template>

<ng-template #callFlowCallTemplate let-data="data">
    <ng-container *ngIf="(data | activityTimeline: 'cfcall': null: null: model) as activity">
        <ng-container *ngIf="!activity; else bodyCFCall"></ng-container>
        <ng-template #bodyCFCall>
            <div fxLayout="row" class="w-100" fxLayoutGap="0.5rem">
                <div fxLayout="column" fxLayoutAlign="start center">
                    <mat-icon [style.color]="data?.action | activityCall: 'colorIcon'">
                        {{data?.action | activityCall}}
                    </mat-icon>
                    <mat-divider [vertical]="true" class="h-100"></mat-divider>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start" class="w-100">
                    <div class="fs-7">
                        <b>{{data?.dateCreated | date: 'dd MMM YYYY'}}</b> {{data?.dateCreated | date: 'h:mm a'}}
                    </div>
                    <nb-card class="custom-card mb-2 w-100" size="tiny" debouncedClick
                        (throttleClick)="viewPhoneCallDetail(activity)">
                        <nb-card-body [style.backgroundColor]="data?.action | activityCall: 'color'">
                            <div fxFlex="auto" class="d-flex justify-content-start align-items-center">
                                <p class="mb-0 text-wrap fs-7">
                                    <b>{{(activity.userName == user.userName) ? 'Me': activity.userName}} </b>
                                    <ng-container *ngIf="(activity.logInfo2 | jsonParse) as jsonData">
                                        <span>
                                            started calling to:
                                            <b>{{jsonData?.outboundPhoneNumber | phoneFormat}}</b>
                                        </span>
                                        <span class="text-primary fs-7"
                                            style="text-decoration: underline; cursor: pointer"
                                            (click)="viewTranscription($event, jsonData)"
                                            *ngIf="jsonData?.transcription">
                                            (View Transcription)
                                        </span>
                                        <span [innerHTML]="jsonData?.message | safeHtml"></span>
                                    </ng-container>
                                </p>
                            </div>
                        </nb-card-body>
                    </nb-card>
                </div>
            </div>
        </ng-template>
    </ng-container>
</ng-template>

<ng-template #commentTemplate let-data="data">
    <ng-container *ngIf="(data | activityTimeline: 'Comment': null: null: model | safeHtml) as content">
        <ng-container *ngIf="!content; else bodyCommentTemplate"></ng-container>
        <ng-template #bodyCommentTemplate>
            <div fxLayout="row" class="w-100" fxLayoutGap="0.5rem">
                <div fxLayout="column" fxLayoutAlign="start center">
                    <mat-icon [style.color]="data | activityDefault: 'color'">comment</mat-icon>
                    <mat-divider [vertical]="true" class="h-100"></mat-divider>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start" class="w-100">
                    <div class="fs-7">
                        <b>{{data?.dateCreated | date: 'dd MMM YYYY'}}</b> {{data?.dateCreated | date: 'h:mm a'}}
                    </div>
                    <nb-card class="custom-card mb-2 w-100" size="tiny">
                        <nb-card-body [style.backgroundColor]=" data | activityDefault : 'background'"
                            [style.wordBreak]="'break-all'">
                            <div [innerHTML]="data | activityTimeline: 'Comment': null: null: model | safeHtml">
                            </div>
                        </nb-card-body>
                    </nb-card>
                </div>
            </div>
        </ng-template>
    </ng-container>
</ng-template>