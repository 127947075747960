<div class="crm-task-template d-flex flex-column align-items-center justify-content-center">
  <div class="d-flex flex-row py-2 font-weight-bold w-100 text-center header-card">
    <div class="w-100 d-flex align-items-center justify-content-center">
      <p style="color:white; font-weight: bolder">Create a task from templates</p>
    </div>
    <button mat-icon-button aria-label="Close the dialog" (click)="closeDialog()" class="ml-auto mr-2">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="pb-2 " style="width: 90%;">
    <button class="w-100 mt-2" mat-flat-button matTooltip="Create follow up email task"
      (click)="buttonChosen = 1; resetData()">
      Create an email follow-up task
    </button>
    <ng-container *ngIf="buttonChosen == 1" [ngTemplateOutlet]="createNewTaskTemplate">
    </ng-container>

    <button class="w-100 mt-2" mat-flat-button matTooltip="Create task to follow up"
      (click)="buttonChosen = 2; resetData()">
      Create a phone call follow-up task
    </button>
    <ng-container *ngIf="buttonChosen == 2" [ngTemplateOutlet]="createNewTaskTemplate">
    </ng-container>
  </div>
</div>

<ng-template #createNewTaskTemplate>
  <div class="w-100 d-flex flex-column align-items-center justify-content-center" style="background-color: white">
    <div style="width: 90%">
      <mat-form-field appearance="standard" style="padding: 0px">
        <mat-label>Description</mat-label>
        <textarea matInput cdkTextareaAutosize
          #autosize="cdkTextareaAutosize" 
          matTextareaAutosize 
          cdkAutosizeMinRows="1" 
          cdkAutosizeMaxRows="5" 
          autocomplete="off"
          trim="blur" [(ngModel)]="description">
        </textarea>
      </mat-form-field>
      <div class="d-flex">
        <p style="width: 35%">Type:</p>
        <mat-select [(value)]="selectedOption" (selectionChange)="changeType($event)" style="width: 65%">
          <mat-option *ngFor="let option of dueDateOption" [value]="option.value">{{option.display}}</mat-option>
        </mat-select>
      </div>
      <ng-container *ngIf="tabId == 0; else chooseDay">
        <div class="d-flex">
          <p class="form-label" style="width: 80%">Number days to follow up:</p>
          <mat-form-field style="width: 20%">
            <mat-select [(value)]="remainDaySelected" (selectionChange)="changeDateRemain($event)">
              <mat-option *ngFor="let day of recommendDay" [value]="day">{{day}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <p style="font-size: 12px">The task will due at <b>{{startDate | date: 'MMMM d, y'}}</b></p>
      </ng-container>
    </div>

    <div class="d-flex mb-3 mt-2">
      <button mat-stroked-button class="mr-3" (click)="reviewTask()">Review</button>
      <button mat-stroked-button color="primary" (click)="createTask()">Create Task</button>
    </div>
  </div>
</ng-template>



<ng-template #chooseDay>
  <div class="d-flex">
    <p class="form-label" style=" width: 40%">Chosen Date:</p>
    <div style="width: 60%" class="d-flex">
      <input matInput [matDatepicker]="picker" (dateChange)="changeDatePicker($event)" [value]="datePickerDay"
        style="padding-top: 0.75rem">
      <mat-datepicker #picker></mat-datepicker>
      <button mat-icon-button (click)="picker.open()" style="margin-top: 1rem">
        <mat-icon>today</mat-icon>
      </button>
    </div>
  </div>
  <p style="font-size: 12px">The task will due at <b>{{(tabId === 0 ? startDate : datePickerDay) | date: 'MMMM d,
      y'}}</b></p>
</ng-template>