<div fxFlex="100" fxLayout="column" fxLayoutAlign="start start" [nbSpinner]="isLoading">
  <div fxFlex="100" style="width: 100%">
    <div class="d-block">
      <span class="limit-line label-inline" style="--line: 1;">
        Relationship type
      </span>
      <mat-form-field fxFlex="100" appearance="standard" class="input-linked">
        <mat-select [formControl]="selectedForm" (openedChange)="changeSelected()" [panelClass]="customClassIndex"
          [disabled]="selectedValue" (selectionChange)="clearSearchForm()">
          <mat-select-trigger *ngIf="selected">
            <ng-container [ngTemplateOutlet]="cardTypeTemplate" [ngTemplateOutletContext]="{ object: selected }">
            </ng-container>
          </mat-select-trigger>
          <mat-option *ngFor="let object of typeObjects" [value]="object.key">
            <ng-container [ngTemplateOutlet]="cardTypeTemplate" [ngTemplateOutletContext]="{ object: object }">
            </ng-container>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="d-block">
      <ng-container *ngIf="selectedValue; else searchProfile">
        <span class="limit-line label-inline label-search" style="--line: 1;">
          Selected {{selectedValue.displayType || ''}}
        </span>
        <ng-container [ngTemplateOutlet]='profileDetail' [ngTemplateOutletContext]="{profile: selectedValue}">
        </ng-container>
      </ng-container>
    </div>
    <div class="d-flex justify-content-end mt-3">
      <button mat-flat-button (click)="closePopup()">Cancel</button>
      <button mat-flat-button color="primary" [disabled]="selectedValue == null" (click)="onConfirm()">{{objectAction}} {{moveObject || 'data'}}</button>
    </div>
  </div>
</div>

<ng-template #searchProfile>
  <span class="limit-line label-inline label-search" style="--line: 1;"
    [matTooltip]="customClassIndex ? '' : 'Enter 3 words to search...'" matTooltipPosition="left">
    Searching for {{selected.displayType || ''}}
  </span>
  <mat-form-field ngDefaultControl class="input-linked input-search" [nbSpinner]="isLoadingSearch">
    <input #inputSearch matInput [formControl]="searchForm" style="height: 26px" [matAutocomplete]="autocomplete">
    <mat-autocomplete #autocomplete="matAutocomplete" [class]="customClassIndex"
      (opened)="customClassIndex ? customIndexAutocomplete() : ''"
      (optionSelected)="selectedObject($event.option.value)">
      <mat-option *ngIf="!objects || objects.length == 0" [disabled]="true">Not found</mat-option>
      <mat-option *ngFor="let object of objects;" [value]="object" [disabled]="object.disabled">
        <span fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
          <img *ngIf="object?.media?.media1; else noImage" fxFlex="24px" class="option-img"
            [src]="object?.media?.media1 ?? defaultAvatar" (error)="defaultAvatar = 'assets/images/5.jpg'" height="24"
            width="24">
          <ng-template #noImage>
            <span fxFlex="30px" class="material-icons" [style.color]="selected.color ?? '#307abd'"
              style="font-size: 24px;">
              {{selected.iconType}}
            </span>
          </ng-template>
          <span fxFlex="1 1 calc(100%-30px)" class="limit-line" style="--line: 1;">
            {{object[selected.propName] || "Unknown"}}
          </span>
        </span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>

<ng-template #profileDetail let-profile="profile">
  <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center" class="mt-3">
    <span fxFlex="30px" class="material-icons" [style.color]="profile.color ?? '#307abd'" style="font-size: 24px;">
      {{profile.iconType}}
    </span>
    <span fxFlex="1 1 calc(100%-135px)" class="limit-line uppercase-letter" style="--line: 1;">
      <b>{{profile.value}}</b>
    </span>
    <button mat-icon-button matSuffix style="color:blue" (click)="goToProfile(profile)">
      <mat-icon class="custom-icon">open_in_new</mat-icon>
    </button>
    <button mat-icon-button matSuffix color="warn" (click)="onRemoveSelectedProfile()">
      <mat-icon class="custom-icon">delete</mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #cardTypeTemplate let-obj="object">
  <span fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
    <span fxFlex="30px" class="material-icons" [style.color]="obj.color ?? '#307abd'" style="font-size: 24px;">
      {{obj.iconType}}
    </span>
    <span fxFlex="1 1 calc(100%-30px)" class="limit-line uppercase-letter" style="--line: 1;">
      {{obj.displayType}}
    </span>
  </span>
</ng-template>
