import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { ProposalNonArtistFee, ProposalNonArtistFeeInputModel } from '../../opportunity.model';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { OpportunityManagementService } from '../../opportunity-management.service';
import { NbToastrService } from '@nebular/theme';
import { QuillConfiguration } from 'src/app/shared/components/stand-alone-component/rich-inline-edit/rich-inline-edit.component';
@Component({
  selector: 'app-add-edit-proposal-non-artist-income',
  templateUrl: './add-edit-proposal-non-artist-income.component.html',
  styleUrls: ['./add-edit-proposal-non-artist-income.component.scss']
})
export class AddEditProposalNonArtistIncomeComponent implements OnInit {
  action: TblActionType;
  isLoading = false;
  frmProposalNonArtist: FormGroup;
  isChange: boolean = false;
  className: string;
  proposalId:string;
  proposalNonArtistFee : ProposalNonArtistFee;
  editorOptions = QuillConfiguration;
  isEditor = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private frmBuilder: RxFormBuilder,
    public dialModalRef: MatDialogRef<AddEditProposalNonArtistIncomeComponent>,
    private cdref: ChangeDetectorRef,
    private service: OpportunityManagementService,
    private toast: NbToastrService,
  ) {
    this.action = data.action;
    this.proposalNonArtistFee = data.model ?? new ProposalNonArtistFeeInputModel();
    this.className = data.className;
    this.proposalId = data.proposalId;
  }

  ngOnInit(): void {
    this.frmProposalNonArtist = this.frmBuilder.formGroup(ProposalNonArtistFeeInputModel, this.proposalNonArtistFee);
    this.dialModalRef.updatePosition({ right: '0', });
  }

  ngAfterViewInit(): void {
    if (this.frmProposalNonArtist)
      this.frmProposalNonArtist.valueChanges.pipe(take(1))
        .subscribe(resp => this.isChange = true);

  }
  changeStatusEditor() {
    this.isEditor = !this.isEditor;
    // if (this.frmProposalNonArtist.value.description == null || this.frmProposalNonArtist.value.description === '') {
    //   this.frmProposalNonArtist.patchValue({
    //     description: 'Add content...'
    //   });
    // }
  }
  saveProposalNonArtistFee(){
    this.isLoading=true;
    var newProposal: ProposalNonArtistFeeInputModel = this.frmProposalNonArtist.value;
    newProposal.proposalId = this.proposalId ? this.proposalId : this.proposalNonArtistFee?.proposalId;
    newProposal.class = this.proposalNonArtistFee?.class;
    if(this.action == TblActionType.Edit){
      newProposal.proposalNonartistFeeId = this.proposalNonArtistFee?.proposalNonartistFeeId ? this.proposalNonArtistFee?.proposalNonartistFeeId : 0;
    }
    else if (this.action == TblActionType.Add){
      newProposal.class = this.className ?? '';
    }
    this.service.saveProposalNonArtistFee(newProposal).subscribe(res=>{
      if(res.result){
        if(this.proposalNonArtistFee?.proposalNonartistFeeId !=0 && this.proposalNonArtistFee?.proposalNonartistFeeId){
          this.toast.success(`Update Non-Artist ${newProposal?.class?.toLowerCase() ?? ''} successfully`,'Success')
        }
        else{
          this.toast.success(`Add Non-Artist ${newProposal?.class?.toLowerCase() ?? ''} successfully`,'Success');
        }
        this.dialModalRef.close(res.result);
      }
      this.isLoading = false;
    });
  }
  closeDialog() {
    this.dialModalRef.close(false);
  }
  ngAfterContentChecked(): void {
    //Called after every check of the component's or directive's content.
    //Add 'implements AfterContentChecked' to the class.
    this.cdref.detectChanges();
  }
}
