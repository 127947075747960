import { WorkFlow } from 'src/app/shared/models/workflow.model';
import { C } from "@angular/cdk/keycodes";
import { prop, required,email } from "@rxweb/reactive-form-validators";

export class CampaignInfo {
    @prop()
    @required()
    campaignName: string;
    @prop()
    category: string;
    @prop()
    workflowUUID: string;
    @prop()
    campaignDescription: string;
    @prop()
    groups: string;
    @prop()
    emailTemplate : string;
    @prop()
    @email()
    testData: string;
    @prop()
    workflowData: WorkFlow;
    @prop()
    contactConfig:string;
   
 }
export class CampaignInfoWithGroup extends CampaignInfo {
   @prop()
   groupName: string;
   @prop()
   lId : number[];
}
 export class EmailContactActivity {
    id: number
    firstName: string
    lastName: string
    email: string
    company: string
    country: string
    state: any
    city: any
    read: any
    click: any
    reply: any
    extenData: string
  }
  