<h1 mat-dialog-title>{{keywordName}} {{keywordIndex > 0 ? keywordIndex : ''}} History: {{data.clusterName}}</h1>
<div mat-dialog-content>
    <app-primas-table #primasTable (onRefresh)="refreshData($event)" [columnsTable]="columns"
         [resource]="resource.table" (onDelete)="onClickDelete($event)" [tableAction]="false" [optionHeight]="'45vh'" [allowSelectRow]="false">
    </app-primas-table>
</div>
<div mat-dialog-actions align="end">
    <button  mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
</div>

<ng-template #name let-row="row" let-column="column">
    <b class="pointer">{{row.user.userName}}</b>
</ng-template>

