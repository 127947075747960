<div class="d-flex flex-wrap" mat-dialog-title style="margin: unset;padding: 1px 10px 1px 28px;"
  [nbSpinner]="isLoading">
  <button mat-button color="primary" (click)="saveKeywordMapping()" [disabled]="!frm.valid">
    <mat-icon fontSet=" material-icons-outlined">save</mat-icon>{{action == mode.Custom ?'Restore' :'Save'}}
  </button>
  <button mat-button color="primary" *ngIf='!disableField' (click)="addDetail(true)">
    <mat-icon>add</mat-icon>
    Add Keyword
  </button>
  <button mat-icon-button aria-label="Close the dialog" (click)="closeDialog()" class="ml-auto">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="frm" class="container" style="padding-top: 25px;padding-right: 25px;padding-left: 25px;">
    <div class="row">
      <div class="col-12" *ngIf='duplicateAlert.length > 0 || duplicateInputMessage.length > 0' id="duplicateNotification">
        <nb-card class="body-last-note-card" style="height: auto !important;">
          <nb-card-body style="padding: 0rem 1rem 0rem; background-color:#fef9b7 !important; align-items: center; max-height: 29.86725663716814vh" >
            <ul style="margin-bottom: 0;">
              <li *ngFor="let alert of duplicateAlert" style="margin: 1rem 0rem;">
               Keyword <b>"{{alert?.key}}"</b> was duplicated in <a [href]="alert?.url">{{alert?.url}}</a>
              </li>
              <ng-container *ngFor="let error of duplicateInputMessage"  >
                <li  *ngIf="error?.key" style="margin: 1rem 0rem;">
                    Keyword <b>"{{error?.key}}"</b> was contained in {{error?.keywords}}
                </li>
              </ng-container>      
            </ul>     
          </nb-card-body>
        </nb-card>
      </div>
      <div class="col-12">

        <mat-form-field appearance="standard">
          <mat-label>Cluster Name</mat-label>
          <input matInput type="text" formControlName="Name" autocomplete="off" trim="blur" [readonly]="disableField" required>
          <mat-error *ngIf="frm.controls.Name.errors && (frm.controls.Name.dirty || frm.controls.Name.touched)">
            {{frm.controls.Name['errorMessage']}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="standard" *ngIf="keywordMapping.ReferenceType != 'CategoryLocation'">
          <mat-label>Target URL</mat-label>
          <input matInput type="text" formControlName="TargetUrl" autocomplete="off" trim="blur" [readonly]="disableField" required>
          <mat-error
            *ngIf="frm.controls.TargetUrl.errors && (frm.controls.TargetUrl.dirty || frm.controls.TargetUrl.touched)">
            {{frm.controls.TargetUrl['errorMessage']}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="standard" [nbSpinner]="isStatusLoading">
          <mat-label>Status</mat-label>
          <mat-select formControlName="Status" [disabled]="disableField">
            <mat-option *ngFor="let item of statusKeywordMapping" [value]="item.automateDataStateId">
              {{item.dataStateName}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>Content Type</mat-label>
          <input matInput type="text" formControlName="ContentType" autocomplete="off" trim="blur" [readonly]="disableField">
          <mat-error
            *ngIf="frm.controls.ContentType.errors && (frm.controls.ContentType.dirty || frm.controls.ContentType.touched)">
            {{frm.controls.ContentType['errorMessage']}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>Search Intent</mat-label>
          <input matInput type="text" formControlName="SearchIntent" autocomplete="off" trim="blur" [readonly]="disableField">
          <mat-error
            *ngIf="frm.controls.SearchIntent.errors && (frm.controls.SearchIntent.dirty || frm.controls.SearchIntent.touched)">
            {{frm.controls.SearchIntent['errorMessage']}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Total Volume (Auto populate)</mat-label>
          <input matInput type="text" [value]="keywordMapping.TotalVolume" autocomplete="off" trim="blur" [readonly]="true">
        </mat-form-field>

        <ng-container *ngFor="let detail of (frm?.controls?.KeywordLst['controls'] || []); let i = index"
          [ngTemplateOutlet]="blockDetail" [ngTemplateOutletContext]="{
            frmDetail: detail,
            index: i
        }">
        </ng-container>
      </div>
    </div>
  </form>
</mat-dialog-content>

<ng-template #blockDetail let-frmDetail="frmDetail" let-index="index">
  <div class="altus-card" style="margin-left:unset !important; margin-right:unset !important;"
    [ngClass]="(index % 2) ? 'bg-info' : 'bg-light'">
    <div fxLayout="row" fxLayoutAlign="end center">
      <button *ngIf="index !== 0 && !disableField" mat-icon-button color="primary" (click)="removeDetail(index)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div [formGroup]="frmDetail" class="kw-mapping-mentions">
      <div class="col-12">
        <mat-form-field appearance="standard">
          <mat-label>{{frmDetail.controls.IsPrimary.value ? 'Primary' : 'Secondary'}} Keyword</mat-label>
          <input matInput (change)="changeInput($event.target.value, index)" type="text" formControlName="Keyword" 
          autocomplete="off" trim="blur" [readonly]="disableField" [errorStateMatcher]="matcher"  [mentionConfig]="keywordMapping.ReferenceType =='CategoryLocation' ? mentionConfig : undefined"
          [mentionListTemplate]="mentionListTemplate" >
          <mat-error
            *ngIf="frmDetail.controls.Keyword.errors">
            {{frmDetail.controls.Keyword['errorMessage']}}</mat-error>
          <button matTooltip="History" mat-icon-button matSuffix
            (click)="openKeywordHistory(frmDetail.controls.IsPrimary.value ? 'Primary' : 'Secondary', index)">
            <mat-icon>history</mat-icon>
          </button>
          <mat-error *ngIf="frmDetail?.controls?.Keyword?.errors && frmDetail?.controls?.Keyword?.errors?.maxWordsErrorMessage">
            {{frmDetail?.controls?.Keyword?.errors?.maxWordsErrorMessage}}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12">
        <mat-form-field appearance="standard">
          <mat-label> {{frmDetail.controls.IsPrimary.value ? 'Primary' : 'Secondary'}} Search Volume</mat-label>
          <input matInput type="number" formControlName="SearchVolume" autocomplete="off" trim="blur" [readonly]="disableField">
          <mat-error
            *ngIf="frmDetail.controls.SearchVolume.errors && (frmDetail.controls.SearchVolume.dirty || frmDetail.controls.SearchVolume.touched)">
            {{frmDetail.controls.SearchVolume['errorMessage']}}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-12">
        <mat-form-field appearance="standard">
          <mat-label>{{frmDetail.controls.IsPrimary.value ? 'Primary' : 'Secondary'}} Kw Difficulty</mat-label>
          <input matInput type="number" formControlName="KwDifficulty" autocomplete="off" trim="blur" [readonly]="disableField">
          <mat-error
            *ngIf="frmDetail.controls.KwDifficulty.errors && (frmDetail.controls.KwDifficulty.dirty || frmDetail.controls.KwDifficulty.touched)">
            {{frmDetail.controls.KwDifficulty['errorMessage']}}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #mentionListTemplate let-item="item">
  <span>{{item.text}}</span>
</ng-template>