<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" style="padding-left: 20px; min-height: 40px">
        <!-- use a disabled button to provide padding for tree leaf -->
        <button mat-icon-button disabled></button>
        <mat-checkbox class="checklist-leaf-node " [checked]="checklistSelection.isSelected(node)"
            (change)="todoLeafItemSelectionToggle(node)" color="primary" [disabled]="node.disabled">
            <div style="margin-right: 10px;">
                {{node.name}}
            </div>
        </mat-checkbox>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" style="min-height: 40px" matTreeNodePadding>
        <button *ngIf="treeFlattener.getChildren(node)[0].id != node.id" mat-icon-button matTreeNodeToggle
            [attr.aria-label]="'Toggle ' + node.name" [disabled]="node.disabled">
            <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
        </button>
        <mat-checkbox class="remove-margin-bottom" [checked]="checklistSelection.isSelected(node)"
            [ngClass]="treeFlattener.getChildren(node)[0].id != node.id ? '': 'hidden-chevron-right'"
            (change)="todoItemSelectionToggle(node)" color="primary" [disabled]="node.disabled">
            <div>
                <strong>{{node.name}}</strong>
            </div>
        </mat-checkbox>
    </mat-tree-node>
</mat-tree>
