
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NbPopoverDirective, NbToastrService } from "@nebular/theme";
import { even, RxwebValidators } from "@rxweb/reactive-form-validators";
import { Focus, QuillEditorComponent } from "ngx-quill";
import { from, fromEvent, merge, Subject } from "rxjs";
import { debounceTime, last, mergeMap, pairwise, takeUntil } from "rxjs/operators";
import { KeyPairsValueObjectAPI, KeyPairsValueTypeObjectAPI } from "src/app/modules/admin/profile-management/profile-detail.model";
import { SaleLeadService } from "src/app/modules/admin/sale-lead-management/sale-lead.service";
import { ShadowProfileEnum } from "src/app/shared/enums/shadow-profile.enum";
import { Helper } from "src/app/shared/utility/Helper";
import { environment } from "src/environments/environment";
import { ConfirmModalComponent } from "../../confirm-modal/confirm-modal.component";
import { NoteManagementService } from "../../note-management/note-management.service";
import { Note, NoteDetails } from "../../note-management/noteManagement.model";
import { SearchTypeObjectsComponent } from "../../search-type-objects/search-type-objects.component";
// import { AdminTabModeService } from "../../stand-alone-component/admin-tab-mode/admin-tab-mode.service";
import { InlineEditComponent } from "../../stand-alone-component/inline-edit/inline-edit.component";
import { QuillConfiguration } from "../../stand-alone-component/rich-inline-edit/rich-inline-edit.component";
import { UserExperienceTrackingService } from 'src/app/shared/components/user-experience-tracking/user-experience-tracking.service';
import { SettingService } from "src/app/shared/services/setting.service";
import { EntityNameEnums } from "src/app/shared/enums/entity-color.enums";


@Component({
  selector: "app-note-detail-card",
  templateUrl: "./note-detail-card.component.html",
  styleUrls: ["./note-detail-card.component.scss"],
})
export class NoteDetailCardComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() note: NoteDetails;
  @Input() isDisableEditorArea: boolean = false;

  @Output() removeNote = new EventEmitter<NoteDetails>();
  @Output() saveNote = new EventEmitter<NoteDetails>();
  @Output() autosaveNote = new EventEmitter<NoteDetails>();
  @Output() deleteNote = new EventEmitter<NoteDetails>();
  @Output() onChanged = new EventEmitter<boolean>();

  @ViewChild("inlineTitleEdit", { static: true }) inlineTitleEdit: InlineEditComponent;
  @ViewChild('quillEditor') set setQuillEditor(content: QuillEditorComponent) {
    if (content) this.quillEditor = content
  }
  @ViewChild(NbPopoverDirective) popoverLinkComponent: NbPopoverDirective;

  linkComponent: SearchTypeObjectsComponent;
  @ViewChild("relationshipToObj", { static: false }) set setLinkComponent(content: SearchTypeObjectsComponent) {
    if (content) {
      var searchLink = window.document.querySelector<any>('.popover-link-note');
      if (searchLink) searchLink.parentNode.style.zIndex = 1005;
      this.linkComponent = content;
      this.linkComponent.typeObjects.splice(2, 0, {
        key: ShadowProfileEnum[ShadowProfileEnum.LEADS],
        displayType: environment.titleLead, iconType: 'account_circle',
        value: (data) => this.saleLeadService.searchSaleLead(data),
        color: '#5c5c8a', propId: 'profileId', propName: 'displayName'
      } as KeyPairsValueTypeObjectAPI)

      this.linkComponent.typeObjects = [...this.linkComponent.typeObjects];
      this.linkComponent.clickEditor();
    }
  };

  validProperties = {
    noteTitle: { valid: [RxwebValidators.required()], message: "This field is required" },
  }
  quillEditor: QuillEditorComponent;
  quill: any; // Type Quill model

  editorOptions = QuillConfiguration;
  noteWidth = "500";
  noteHeight = "550";
  displayBodyStatus = "block";
  displayFooterStatus = "flex";
  isMinimize = false;
  isInlineEditSaveButtonClicked = false;
  isChange: boolean = false;
  minimizeSpan: string = ""
  savingText = "Saving..."
  notifySave = ""
  isNewNote = false;
  noteHeaderBackground = "#f5da65";
  noteBodyBackground = "#fef9b7";
  quillEditorData = ""
  cacheNoteClosed: NoteDetails;
  isLoadingReference: boolean = false;
  previousNote: string;
  isChanged: boolean = false;

  private _destroy: Subject<void> = new Subject<void>();
  constructor(
    private dialog: MatDialog,
    private toastr: NbToastrService,
    private cdref: ChangeDetectorRef,
    private saleLeadService: SaleLeadService,
    private noteService: NoteManagementService,
    private router: Router,
    private settingService: SettingService,
    // private tabModeService: AdminTabModeService,
    private userExperienceTrackingService: UserExperienceTrackingService,
  ) {

  }

  @HostListener('focusout', ['$event'])
  onBlurHandler() {
    this.noteHeaderBackground = "#c9c9c9";
    this.noteBodyBackground = "#f3f3f3";
  }

  @HostListener('focusin', ['$event'])
  onFocusHandler() {
    this.noteHeaderBackground = "#f5da65";
    this.noteBodyBackground = "#fef9b7"
  }

  ngOnInit(): void {
    this.disableEditorArea();
    this.setupNoteDetailModel();
  }

  setupNoteDetailModel() {
    this.note = Object.assign(this.note, {
      "ownerFullName": (`${this.note?.user?.firstName || ''} ${this.note?.user?.lastName || ''}`).trim() || 'Unknown',
      "ownerUserName": this.note?.user?.userName,
      "lastUserFullName": (`${this.note?.lastUser?.firstName || ''} ${this.note?.lastUser?.lastName || ''}`).trim() || 'Unknown',
      "lastUserName": this.note?.lastUser?.userName,
      "lastUserAvatar": this.note?.lastUser?.pictureURL?.replace(/\\/g, '/'),
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changeNote = changes.note;
    if (changeNote && JSON.stringify(changeNote.currentValue) != JSON.stringify(changeNote.previousValue)) {
      this.cacheNoteClosed = Helper.copyRemoveHeap(this.note);
      this.checkReferenceNote();
    }
  }

  ngAfterViewInit(): void {
    this.cdref.detectChanges();
    this.resizeNote();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }



  checkReferenceNote() {
    if (this.note.referenceId && this.note.referenceType && !this.note.referenceName) {
      this.isLoadingReference = true;
      this.noteService.getReferenceNameLinkNote(this.note.referenceId, this.note.referenceType)
        .subscribe({
          next: resp => this.note.referenceName = resp.result ?? "Unknown",
          complete: () => this.isLoadingReference = false
        });
    }
  }

  minimizeNote() {
    if (!this.isMinimize) {
      this.displayBodyStatus = "none";
      this.displayFooterStatus = "none";
      this.noteHeight = "50";
      this.isMinimize = true;
      this.emitAutoSaveOnAction()
    }
    else {
      this.displayBodyStatus = "block";
      this.noteHeight = "550";
      this.displayFooterStatus = "flex";
      this.isMinimize = false;
    }
  }

  onCloseNote() {
    this.emitAutoSaveOnAction()
    this.removeNote.emit(this.note);
  }

  emitAutoSaveOnAction() {
    if (this.inlineTitleEdit.inputControl.status != "INVALID" && this.note.note && this.note?.note?.length != 0) {
      this.checkChangingAtInlineEditTitle()
      if (!this.detectChangeNote()) return;
      let saveNote: NoteDetails = Object.assign({}, this.note);
      this.autosaveNote.emit(saveNote)
    }
  }

  checkChangingAtInlineEditTitle() {
    if (this.inlineTitleEdit.inputControl.value.length == 0 || !this.inlineTitleEdit.inputControl.value)
      this.note.title = this.inlineTitleEdit.showInputData;
    else {
      if (this.inlineTitleEdit.inputControl.value != this.inlineTitleEdit.backupInput) {
        this.inlineTitleEdit.backupInput = this.inlineTitleEdit.inputControl.value
      }
      if (this.note.title != this.inlineTitleEdit.inputControl.value && !this.isInlineEditSaveButtonClicked) {
        this.note.title = this.inlineTitleEdit.inputControl.value
      }
    }
  }

  onSaveNote() {
    if (this.inlineTitleEdit.inputControl.status != "INVALID") {
      if (this.inlineTitleEdit.inputControl.value != this.inlineTitleEdit.backupInput) {
        this.inlineTitleEdit.backupInput = this.inlineTitleEdit.inputControl.value
      }
      if (this.note.title != this.inlineTitleEdit.inputControl.value && !this.isInlineEditSaveButtonClicked) {
        this.note.title = this.inlineTitleEdit.inputControl.value
      }
      if (this.inlineTitleEdit.isEditing) { this.inlineTitleEdit.isEditing = false }

      if (this.note?.note?.length != 0 && this.note.note) {
        if (!this.detectChangeNote()) return;
        this.saveNote.emit(this.note)
      } else {
        setTimeout(() => this.notifySave = "", 500);
        this.toastr.warning(`Please insert your note content`, "Warning");
      }
    }
  }

  onDeleteNote() {
    if (this.note.id == 0) {
      this.onCloseNote()
      return
    }

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      panelClass: 'custom-z-index-confirm',
      data: {
        message: 'Do you wish to delete this note?',
        zIndex: 1005,
      },
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response) this.deleteNote.emit(this.note);
    })
  }

  saveNoteTitle(data) {
    const delayNewNote = setInterval(() => {
      if (this.notifySave == this.savingText && this.note.id == 0) return;
      else {
        clearInterval(delayNewNote);
        this.isInlineEditSaveButtonClicked = true;
        this.inlineTitleEdit.backupInput = data;
        this.notifySave = this.savingText;
        this.note.title = data;
        this.onSaveNote();
      }
    }, 1000);
  }

  autoSave() {
    if (!this.detectChangeNote()) return;

    if (!this.note?.note || Helper.isEmptyOrSpaces(this.note?.note) || Helper.isNullOrEmpty(this.note?.note)) {
      this.note.note = "<p></p>";
    }

    this.notifySave = this.savingText
    this.checkChangingAtInlineEditTitle()
    let saveNote: NoteDetails = Object.assign({}, this.note);
    this.autosaveNote.emit(saveNote)
  }

  setFocus(editor) {
    if (editor) this.quill = editor;
    let containerEditor = this.quillEditor.elementRef.nativeElement.getElementsByClassName("ql-container ql-snow")[0];
    containerEditor.style = `height: 78%; overflow: auto`;
    containerEditor.classList.add("custom-edit-link");
    this.quill.focus();

    this.previousNote = this.note?.note;
    fromEvent(this.quill.container, 'keyup')
      .pipe(takeUntil(this._destroy))
      .subscribe(x => {
        if (this.previousNote != this.note?.note) this.isChanged = true;
        else this.isChanged = false;
        this.onChanged.emit(this.isChanged);
      });

    // fromEvent(this.quill.container, 'keyup')
    //   .pipe(debounceTime(2000), takeUntil(this._destroy))
    //   .subscribe(x => this.autoSave());
  }

  positionFocus(focus: Focus) {
    if (focus) {
      var quill = focus.editor;
      var length = quill.getLength();
      if (length && length > 0) {
        quill.setSelection(length - 1);
        quill.container.scrollTo(0, quill.container.scrollHeight);
      }
    }
  };


  setFocusClick() {
    if (this.quill) {
      if (this.isMinimize) this.minimizeNote();
      if (this.note && !this.note.referenceName)
        this.checkReferenceNote();
      setTimeout(() => this.quill.focus(), 100);
    }
  }

  autoSaveCompleted() {
    this.isNewNote = false;
    this.isChange = false;
    setTimeout(() => this.notifySave = "", 500);
  }

  saveLinkNote(data: KeyPairsValueObjectAPI) {
    if (data) {
      console.log(data);
      this.note = Object.assign(this.note, {
        referenceId: data.id,
        referenceType: data.key,
        referenceName: data.value
      })

      const delayLinkNote = setInterval(() => {
        if (this.notifySave == this.savingText && this.note.id == 0) return;
        else {
          clearInterval(delayLinkNote);
          this.notifySave = this.savingText;
          this.onSaveNote();
        }
      }, 1000);
    }
  }

  detectChangeNote() {
    if (this.note && this.cacheNoteClosed) {
      let objCurrent = Object.assign(Helper.copyRemoveHeap({
        id: this.note.id,
        title: this.note.title,
        note: this.note.note,
        referenceId: this.note.referenceId,
        referenceType: this.note.referenceType
      }));

      let objOld = Object.assign(Helper.copyRemoveHeap({
        id: this.cacheNoteClosed.id,
        title: this.cacheNoteClosed.title,
        note: this.cacheNoteClosed.note,
        referenceId: this.cacheNoteClosed.referenceId,
        referenceType: this.cacheNoteClosed.referenceType
      }));

      if (JSON.stringify(objCurrent) == JSON.stringify(objOld))
        return false;
    }

    return true;
  }

  openDetailRelationship() {
    if (this.note && this.note.referenceType && this.note.referenceId) {
      var type: string = Helper.getURLByRelationshipType(this.note.referenceType);
      if (type) {
        Helper.pushBackURL();
        Helper.circleDetail(this.note.referenceId, type, this.userExperienceTrackingService.getCurrentSessionPage(), this.router, this.userExperienceTrackingService);
      }
    }
  }

  disableEditorArea() {
    if (this.isDisableEditorArea) {
      this.editorOptions = {
        toolbar: []  // Disable the toolbar
      }
    }
  }

  resizeNote() {
    //Because at the first time render the note, the note doesn't apply the css.
    setTimeout(() => {
      let quillDom = document.getElementsByClassName("note-card-quill-editor")?.[0];
      let containerEditorDom = quillDom?.querySelector(".ql-container.ql-snow") as HTMLElement;
      let toolbarEditorDom = quillDom?.querySelector(".ql-toolbar.ql-snow") as HTMLElement;

      let ignoreChildrenDomArray: string[] = ["note-card-quill-editor"];
      let noteCardBodyContainerDom = document.getElementsByClassName("note-card-body-container")?.[0];
      let removePx: number = 0;

      for (let i = 0, length = noteCardBodyContainerDom?.children?.length; i < length; ++i) {
        let isIgnore: boolean = false;

        for (let ignoreClass of ignoreChildrenDomArray) {
          if ((noteCardBodyContainerDom.children[i] as HTMLElement).classList.contains(ignoreClass)) {
            isIgnore = true;
            break;
          }
        };

        if(isIgnore) {
          continue;
        }
        else {
          removePx += (noteCardBodyContainerDom.children[i] as HTMLElement).offsetHeight || 0;
        }
      }

      // if (containerEditorDom && toolbarEditorDom && ownerContainerDom && noteCardTitleDom) {
      if (containerEditorDom && toolbarEditorDom) {
        //If the area doesn't disable, apply the css back
        if (!this.isDisableEditorArea) {
          toolbarEditorDom.style.padding = "8px";
          toolbarEditorDom.style.height = "fit-content";
        }

        (quillDom as HTMLElement).style.cssText = `max-height: calc(100% - ${removePx}px)`;

        containerEditorDom.style.cssText = `max-height: calc(100% - ${(toolbarEditorDom?.offsetHeight || 0)}px); overflow: auto;`;
      }
    }, 500)
  }
}

