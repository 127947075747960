<div class="d-flex flex-wrap align-items-space-between" mat-dialog-title
  style="margin: unset;padding: 1px 10px 1px 28px;">
  <button mat-icon-button (click)="closeDialog()" class="ml-auto">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography">
  <mat-horizontal-stepper [linear]="true" #stepper [@.disabled]="true">
    <!-- STEP 1: CHOOSE ENTITIES -->
    <mat-step label="Select Entity" state="select">
      <ng-container [ngTemplateOutlet]="selectEntity">

      </ng-container>
    </mat-step>

    <!-- STEP 2: REVIEW -->
    <mat-step label="Review" state="review">
      <ng-container [ngTemplateOutlet]="reviewEntity">

      </ng-container>
    </mat-step>

    <!-- STEP 3: RESULT -->
    <mat-step label="Result" state="result">
      <ng-container [ngTemplateOutlet]="stepResult">

      </ng-container>
    </mat-step>
    <!-- Template -->
    <ng-template matStepperIcon="select">
      <mat-icon>check_box</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="review">
      <mat-icon>preview</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="result">
      <mat-icon>verified</mat-icon>
    </ng-template>

  </mat-horizontal-stepper>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button mat-button *ngIf="stepper.selectedIndex != 0" (click)="stepper.previous()"
    [disabled]="isSubmitSuccess">Back</button>
  <button mat-button *ngIf="stepper.selectedIndex == 0" [disabled]="!navigateStep(stepper)"
    (click)="stepper.next()">Next</button>
  <button mat-button *ngIf="stepper.selectedIndex == 1" [disabled]="!navigateStep(stepper) || stepLoading"
    [nbSpinner]="stepLoading" (click)="convertContact(stepper)">Submit</button>
  <button mat-button *ngIf="stepper.selectedIndex == 2" (click)="closeDialog(true)">Close</button>

</div>



<!-- Step 1 template -->
<ng-template #selectEntity>
  <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="10px" style="padding-top:5px">
    <mat-card class="col-lg-6" style="height:30vh">
      <mat-card-header>
        <mat-card-title>
          <mat-checkbox [(checked)]="isLead" (change)="radioCheck($event,entityEnums.SaleLead)" color="primary">Convert
            To
            {{environment.titleLead}}</mat-checkbox>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="container" fxLayout="column" fxLayoutAlign="center center">
          <nb-icon icon="credit-card-outline" style="width:150px;height:150px"></nb-icon>
          <div>
            <span style="font-size: 17px;">
              Using this contact to create a new {{environment.titleLead}}
            </span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="col-lg-6" style="height:30vh">
      <mat-card-header>
        <mat-card-title>
          <mat-checkbox color="primary" (change)="radioCheck($event,entityEnums.Profile)" [checked]="isProfile">Convert
            To Profile</mat-checkbox>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="container" fxLayout="column" fxLayoutAlign="center center">
          <nb-icon icon="book-outline" style="width:150px;height:150px"></nb-icon>
          <div>
            <span style="font-size: 17px;">
              Create a new profile and link to this contact
            </span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
  </div>
</ng-template>

<!-- Step 2 Template -->
<ng-template #reviewEntity>
  <ng-container [ngSwitch]="this.inputForm.controls.entity.value">
    <!-- new Profile -->
    <mat-card *ngSwitchCase="entityEnums.Profile" style="padding-top:5px">
      <mat-card-header>
        <mat-card-title>
          New Profile
        </mat-card-title>
        <mat-card-subtitle>
          New profile will be created with this <b class="success-icon-table">primary</b> contact
        </mat-card-subtitle>
      </mat-card-header>
      <form [formGroup]="addProfileForm" class="container">
        <ng-container *ngFor="let control of sortedProfileFormControls; let orderId = index">
          <ng-container *ngIf="!isExcluded(control,entityEnums.Profile)">
            <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
              <mat-label *ngIf="control">{{helper.displayNameProp(control)}}</mat-label>
              <input matInput autocomplete="off" trim="blur" [formControlName]="control"
                [required]="isRequired(addProfileForm.get(control))"
                [readonly]="isDisabled(control,entityEnums.Profile)">
              <mat-error
                *ngIf="addProfileForm.get(control).errors && (addProfileForm.get(control).dirty || addProfileForm.get(control).touched)">
                {{addProfileForm.get(control)['errorMessage']}}</mat-error>
            </mat-form-field>
          </ng-container>
        </ng-container>
      </form>
    </mat-card>
    <mat-card *ngSwitchCase="entityEnums.SaleLead" style="padding-top:5px">
      <mat-card-header>
        <mat-card-title>
          New {{environment.titleLead}}
        </mat-card-title>
        <mat-card-subtitle>
          New {{environment.titleLead}} will be created based on this contact
        </mat-card-subtitle>
      </mat-card-header>
      <!-- new lead -->
      <form [formGroup]="addLeadForm" class="container">
        <ng-container *ngFor="let control of sortedLeadFormControls; let orderId = index">
          <ng-container *ngIf="!isExcluded(control,entityEnums.SaleLead)">
            <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
              <mat-label>{{helper.displayNameProp(control)}}</mat-label>
              <input matInput autocomplete="off" trim="blur" [formControlName]="control"
                [required]="isRequired(addLeadForm.get(control))" [readonly]="isDisabled(control,entityEnums.SaleLead)">
              <mat-error
                *ngIf="addLeadForm.get(control).errors && (addLeadForm.get(control).dirty || addLeadForm.get(control).touched)">
                {{errorMessage(control,addLeadForm)}}</mat-error>
            </mat-form-field>
          </ng-container>
        </ng-container>
      </form>
    </mat-card>
  </ng-container>
</ng-template>

<!-- Step 3: Show result -->
<ng-template #stepResult>
  <ng-container *ngIf="isSubmitSuccess;else failResult">
    <mat-card style="padding-top:5px">
      <div fxLayout="column" fxLayoutAlign="center center">
        <img src="assets/images/success.gif" alt="" width="50">
      </div>
      <div class="hoverable" style="text-align: center;font-size: 18px;" (click)="openNewEntity()">
        Convert to new {{entityName}} successfully! <b><i><u class="success-icon-table">Click here to open</u></i></b>.
      </div>
    </mat-card>
  </ng-container>
</ng-template>
<ng-template #failResult>
  <mat-card style="padding-top:5px">
    <div fxLayout="column" fxLayoutAlign="center center">
      <img src="assets/images/error-gif.gif" alt="" width="200">
    </div>
    <div class="hoverable" style="text-align: center;font-size: 18px;">
      Convert to new {{entityName}} failed! This contact could be <b class="danger-icon-table">deleted</b> or
      <b>linked</b> to
      other entity, please try again.
    </div>
  </mat-card>
</ng-template>