import { HttpResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { HotToastService } from '@ngneat/hot-toast';
import { UserUploadManagementService } from 'src/app/modules/admin/user-upload-management/user-upload-management.service';
import { UserUpload, UserUploadHistory } from 'src/app/modules/admin/user-upload-management/user-upload.model';
import { permissionSaleAccountFile } from 'src/app/shared/contances/permission';
import { PrimasFilterType } from 'src/app/shared/enums/primas-value-type.enum';
import { DateTimeFormatPipe } from 'src/app/shared/pipes/date-time-format.pipe';
import { Helper } from 'src/app/shared/utility/Helper';
import { PrimasTableComponent } from '../../../template-management/primas-table/primas-table.component';

@Component({
  selector: 'app-profile-file-tab-history',
  templateUrl: './profile-file-tab-history.component.html',
  styleUrls: ['./profile-file-tab-history.component.scss']
})
export class ProfileFileTabHistoryComponent implements OnInit {
  @ViewChild('profileHistoryFileTable') profileHistoryFileTable: PrimasTableComponent;
  @ViewChild('fileNameCol', { static: true }) fileNameCol: TemplateRef<any>;
  @Input() userUploadHistoryId: string;
  @Input() showBottomAction: boolean = false;
  @Input() isAllowed: boolean = true;
  @Input() resource;
  downloading = false;
  downloadPercent = 0;
  selectedRowIndex = -1;

  columns = [];
  constructor(
    private service: UserUploadManagementService,
    private dateTimeFormatPipe: DateTimeFormatPipe,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: NbToastrService,
    private dialogRef: MatDialogRef<ProfileFileTabHistoryComponent>,
    private hotToast: HotToastService,
  ) {
    this.showBottomAction = data.showBottomAction ?? this.showBottomAction
    this.userUploadHistoryId = data.userUploadHistoryId ?? this.userUploadHistoryId;
    this.isAllowed = data.isAllowed ?? this.isAllowed;
    this.resource = data.resource ?? this.resource;
  }

  ngOnInit() {
    this.columns = [
      {
        name: "Date Modified",
        prop: "dateModified",
        pipe: this.dateTimeFormatPipe,
        headerClass: "text-center filter-date-time-column",
        cellClass: "text-center",
        sortable: false,
        filter: false,
      },
      {
        name: 'File Name',
        prop: 'userUpload.fileName',
        filter: false,
        import: false,
        sortable: false,
        cellTemplate: this.fileNameCol
      },
    ];
  }
  refreshData(reset: boolean = false): void {
    this.profileHistoryFileTable.isLoading = 1;
    var offSetX = this.profileHistoryFileTable.table.bodyComponent.offsetX;

    if (reset) {
      this.profileHistoryFileTable.page.pageNumber = 0;
      this.profileHistoryFileTable.cache = {};
      this.profileHistoryFileTable.rows = [];
      this.profileHistoryFileTable.selected = [];
      this.profileHistoryFileTable.table.offset = 0;
      this.profileHistoryFileTable.table.bodyComponent._offset = 0;
      offSetX = 0;
    }
    this.profileHistoryFileTable.page.size = -1;
    this.service.getUserUploadHistoryPagingById(this.profileHistoryFileTable.page, this.userUploadHistoryId).subscribe((resp) => {
      if (resp) {
        this.profileHistoryFileTable.setData(resp.result);
        setTimeout(() => {
          this.profileHistoryFileTable.table.recalculate();
          this.profileHistoryFileTable.table['cd'].markForCheck();
          document.body.style.width = 'auto';
          this.profileHistoryFileTable.table._offsetX.next(offSetX);
        }, 200);
      }
      this.profileHistoryFileTable.isLoading = 0;
    })
  }

  downLoadFile(row: UserUploadHistory, rowIndex: number) {
    if (row.userUpload && !row.userUpload.deleted) {
      if(row.userUpload.dataType && row.userUpload.dataType == 'URL' && row.userUpload.physicalPath){
        window.open(`${row.userUpload.physicalPath}`, '_blank','noreferrer');
      }
      else{
        this.hotToast.info('Your file is almost ready to be downloaded, please wait...');
        this.downloading = true;
        this.selectedRowIndex = rowIndex;
        this.service.downloadAsStream(row.userUploadId).subscribe({
          next: res => {
            this.downloadPercent = res.progress;
            if (res.response instanceof HttpResponse && res.response.body) {
              this.service.downLoadFile(res.response.body, row.userUpload?.fileName, true);
            }
            if (this.downloadPercent == 100) {
              this.downloading = false;
              this.selectedRowIndex = -1;
            }
          },
          complete: () => {
            this.downloading = false;
            this.downloadPercent = 0;
            this.selectedRowIndex = -1;
          }
        });
      }
    }
    else {
      this.toast.warning('Upload history of this file has been deleted or not existed', 'Warning');
    }
  }
  displayFormatFileName(row: UserUpload) {
    if(row.dataType == "URL"){
      return row.fileName;
    }
    else{
      if (row.fileName) {
        let extension = Helper.getFileExtension(row.fileName)[0];
        let rawName = row.fileName.slice().replace(`.${extension}`, "");
        rawName = rawName.slice(0, rawName.length - 6);
        return rawName + '.' + extension;
      }
      return row.fileName;
    }
    // if (fileName) {
    //   let extension = Helper.getFileExtension(fileName)[0];
    //   let rawName = fileName.slice().replace(`.${extension}`, "");
    //   rawName = rawName.slice(0, rawName.length - 6);
    //   return rawName + '.' + extension;
    // }
    // return fileName;
  }
  get status() {
    if (this.downloadPercent <= 25) {
      return 'danger';
    } else if (this.downloadPercent <= 50) {
      return 'warning';
    } else if (this.downloadPercent <= 75) {
      return 'info';
    } else {
      return 'success';
    }
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
}
