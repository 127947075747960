import { pattern, prop, propArray, propObject, required, url } from '@rxweb/reactive-form-validators';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { Category } from 'src/app/shared/models/category';
import { Media, SeoInfo } from '../../profile-management/profile-detail.model';
import { MediaModel } from '../../media-management/media.model';


export class FAQsModel{
    @required({ conditionalExpression: (x, y) => x.isCrmMode == true })
    @prop()
    question:string;
    @prop()
    answer:string;
}
export class AddCategoryModel {
    categoryId: string;
    @required()
    categoryName: string;
    @prop()
    parentId: string | null;
    @prop()
    description: string;
    // tslint:disable-next-line:no-inferrable-types
    deleted: boolean = false;
    action: TblActionType;
    parent: Category | null;
    seoInfoId = 0;
    @prop()
    title: string;
    @required()
    @pattern({ expression: { url: /^[a-zA-Z0-9]+([\- ]?[a-zA-Z0-9]+)*$/ }, message: 'This field cannot have any char different than alphabet and number!' })
    friendlyUrl: string = "";
    @prop()
    seoDescription: string;
    @prop()
    active: boolean = false;
    @prop()
    keywords: string;
    @prop()
    primaryKeyword: string;
    @prop()
    secondaryKeyword: string
    public convertSeoCategory(rowData): boolean {
        if(rowData?.media !== null) {
            this.imageName = rowData.media?.mediaId ? rowData.media?.media1.split(/(\\|\/)/g).pop().split(".")[0] : null;
        }

        if (rowData.seoInfo !== null) {
            this.seoInfoId = rowData.seoInfo.seoInfoId ?? 0;
            this.title = rowData.seoInfo.title ?? null;
            this.friendlyUrl = rowData.seoInfo.friendlyUrl ?? null;
            this.seoDescription = rowData.seoInfo.description ?? null;
            this.keywords = rowData.seoInfo.keywords ?? null;
            this.faqsList = rowData.faqs ? JSON.parse(rowData.faqs) as FAQsModel[] : null;
            //this.active = rowData.active;
            this.primaryKeyword = rowData.seoInfo.primaryKeyword ?? null;
            this.secondaryKeyword = rowData.seoInfo.secondaryKeyword ?? null;
            this.noIndex = rowData.seoInfo.noIndex ?? false;
            // this.imageName = rowData.media?.mediaId ? rowData.media?.media1.split(/(\\|\/)/g).pop().split(".")[0] : null;
            return true;
        }
        return false;
    }
    @prop()
    topDescription:string;


    @prop()
    askAnExpertImage: Media;
    @prop()
    askAnExpertDescription: string;
    @prop()
    firstHyperImage: Media;
    @prop()
    @url()
    firstHyperLink: string;
    @prop()
    firstHyperDescription: string;
    @prop()
    firstActive: boolean = false;
    @prop()
    secondHyperImage: Media;
    @prop()
    @url()
    secondHyperLink: string;
    @prop()
    secondHyperDescription: string;
    @prop()
    askAnExpertActive: boolean = false;
    @prop()
    secondActive: boolean = false;
    @prop()
    isCrmMode:boolean = false;
    @prop()
    @propArray(FAQsModel)
    faqsList: FAQsModel[] = [];
    @prop()
    tags: string;
    @prop()
    tagSelected: string;
    @prop()
    @pattern({expression:{'imageName': /^[a-zA-Z0-9\-_]+$/ }  ,message:'Invalid format. Please use only letters, numbers, hyphens, and underscores.' })
    imageName: string = "";
    @propObject(Media)
    media: Media = new Media();
    @prop()
    noIndex: boolean;
    
    seoInfo: SeoInfo;
}

export interface HyperDatas {
    hyperDataId: number;
    referenceType: string;
    referenceId: string;
    hyperData: string;
    hyperLink: string;
    hyperType: string;
    extendData: string;
    hyperDescription: string;
    media: Media;
    active: boolean;
}
