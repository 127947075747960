<div class="d-flex flex-wrap" mat-dialog-title style="margin: unset;padding: 1px 10px 1px 28px;" [nbSpinner]="isLoading"
    *ngIf="!openFromCustomPopup">
    <button mat-button color="primary" (click)="saveData()" [disabled]="!frmProfile.valid">
        <mat-icon fontSet=" material-icons-outlined">save</mat-icon>Save
    </button>
    <button mat-button color="primary" (click)="closeDialog()">
        <mat-icon>clear</mat-icon>Cancel
    </button>
    <button mat-icon-button aria-label="Close the dialog" (click)="closeDialog()" class="ml-auto">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-divider *ngIf="!openFromCustomPopup"></mat-divider>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="frmProfile" class="container" (ngSubmit)="saveData()"
        [ngStyle]="(!openFromCustomPopup) && { 'padding-top': '25px', 'padding-right': '50px', 'padding-left': '50px' }">
        <div class="row">
            <div class="col-12">
                <div class="col-12">
                    <div class="row">
                        <mat-form-field appearance="standard">
                            <mat-label>{{environment.titleAccount}} Name</mat-label>
                            <input matInput autocomplete="off" formControlName="accountName" trim="blur" required>
                            <mat-error
                                *ngIf="frmProfile.controls.accountName.errors && (frmProfile.controls.accountName.dirty || frmProfile.controls.accountName.touched)">
                                {{frmProfile.controls.accountName['errorMessage']}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row">
                        <mat-form-field appearance="standard">
                            <mat-label>{{environment.titleAccount}} Type</mat-label>
                            <mat-select formControlName="contactTypeLst" (selectionChange)="parentChange($event)">
                                <mat-option *ngFor="let item of parentContactType" [value]="item.contactTypeId">
                                    {{item.contactTypeName}}
                                </mat-option>
                                <mat-option [value]="undefined">None</mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="frmProfile.controls.contactTypeLst.errors && (frmProfile.controls.contactTypeLst.dirty || frmProfile.controls.contactTypeLst.touched)">
                                {{frmProfile.controls.contactTypeLst['errorMessage']}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <!-- Sub Account Type -->
                <div class="col-12" *ngIf="frmProfile.controls.contactTypeLst.value && childContactType.length > 0">
                    <div class="row">
                        <mat-form-field appearance="standard">
                            <mat-label>Sub Type</mat-label>
                            <mat-select formControlName="accountSubContactType">
                                <mat-option *ngFor="let item of childContactType" [value]="item.contactTypeId">
                                    {{item.contactTypeName}}
                                </mat-option>
                                <mat-option [value]="undefined">None</mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="frmProfile.controls.accountSubContactType.errors && (frmProfile.controls.accountSubContactType.dirty || frmProfile.controls.accountSubContactType.touched)">
                                {{frmProfile.controls.accountSubContactType['errorMessage']}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-12">
                    <div class="row">
                        <app-dynamic-content-form fxFlex="calc(100% - 120px)" [searchArrayAPI]="dynamicContentPhoneAPI"
                            label="Phone" [control]="frmProfile.controls.phoneNumber" [displayKey]="'content'"
                            isAscii="true">
                        </app-dynamic-content-form>
                        <mat-form-field fxFlex="120px" appearance="standard"
                            *nbIsGranted="['view', 'contact-phone-extension']">
                            <input matInput autocomplete="off" formControlName="primaryPhoneExt" prefix="ext. "
                                mask="000 000" [showMaskTyped]="true" [validation]="false">
                        </mat-form-field>
                        <!-- <mat-form-field appearance="standard">
                            <mat-label>Phone</mat-label>
                            <input matInput autocomplete="off" formControlName="phoneNumber" trim="blur">
                            <mat-error
                                *ngIf="frmProfile.controls.phoneNumber.errors && (frmProfile.controls.phoneNumber.dirty || frmProfile.controls.phoneNumber.touched)">
                                {{frmProfile.controls.phoneNumber['errorMessage']}}</mat-error>
                        </mat-form-field> -->
                    </div>
                </div>

                <div class="col-12">
                    <div class="row">
                        <app-dynamic-content-form style="width: 100%" [searchArrayAPI]="dynamicContentEmailAPI"
                            label="Email" [control]="frmProfile.controls.email" [displayKey]="'content'" isAscii="true">
                        </app-dynamic-content-form>
                        <!-- <mat-form-field appearance="standard">
                            <mat-label>Email</mat-label>
                            <input matInput autocomplete="off" formControlName="email" trim="blur">
                            <mat-error
                                *ngIf="frmProfile.controls.email.errors && (frmProfile.controls.email.dirty || frmProfile.controls.email.touched)">
                                {{frmProfile.controls.email['errorMessage']}}</mat-error>
                        </mat-form-field> -->
                    </div>
                </div>
                <div class="col-12">
                    <div class="row">
                        <!-- <mat-form-field appearance="standard">
                            <mat-label>Country</mat-label>
                            <input matInput autocomplete="off" formControlName="country" trim="blur">
                            <mat-error
                                *ngIf="frmProfile.controls.country.errors && (frmProfile.controls.country.dirty || frmProfile.controls.country.touched)">
                                {{frmProfile.controls.country['errorMessage']}}</mat-error>
                        </mat-form-field> -->
                        <app-dynamic-content-form style="width: 100%" [searchArrayAPI]="dynamicContentCountryAPI"
                            label="Country" [control]="frmProfile.controls.country" [displayKey]="'content'">
                        </app-dynamic-content-form>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row">
                        <app-dynamic-content-form style="width: 100%" [searchArrayAPI]="dynamicContentStateAPI"
                            label="State/Province" [control]="frmProfile.controls.state" [displayKey]="'content'">
                        </app-dynamic-content-form>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row">
                        <app-dynamic-content-form style="width: 100%" [searchArrayAPI]="dynamicContentWebsiteAPI"
                            label="Website" [control]="frmProfile.controls.website" [displayKey]="'content'">
                        </app-dynamic-content-form>
                        <!-- <mat-form-field appearance="standard">
                            <mat-label>Website</mat-label>
                            <input matInput autocomplete="off" formControlName="website" trim="blur">
                            <mat-error
                                *ngIf="frmProfile.controls.website.errors && (frmProfile.controls.website.dirty || frmProfile.controls.website.touched)">
                                {{frmProfile.controls.website['errorMessage']}}</mat-error>
                        </mat-form-field> -->
                    </div>
                </div>

                <div class="col-12">
                    <div class="row">
                        <mat-form-field appearance="standard">
                            <mat-label>Annual Revenue</mat-label>
                            <input matInput autocomplete="off" formControlName="annualRevenue" trim="blur">
                            <mat-error
                                *ngIf="frmProfile.controls.annualRevenue.errors && (frmProfile.controls.annualRevenue.dirty || frmProfile.controls.annualRevenue.touched)">
                                {{frmProfile.controls.annualRevenue['errorMessage']}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-12">
                    <div class="row">
                        <mat-form-field appearance="standard">
                            <mat-label>Employees</mat-label>
                            <input matInput autocomplete="off" formControlName="employees" trim="blur">
                            <mat-error
                                *ngIf="frmProfile.controls.employees.errors && (frmProfile.controls.employees.dirty || frmProfile.controls.employees.touched)">
                                {{frmProfile.controls.employees['errorMessage']}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div>
                    <mat-label class="custom-label">Description</mat-label>
                    <quill-editor class="content-editor" [modules]="editorOptions"
                        placeholder="Click to add a description..." formControlName="description"
                        style="min-height: 200px;">
                    </quill-editor>
                </div>



            </div>
        </div>
    </form>
</mat-dialog-content>