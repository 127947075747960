<h2 mat-dialog-title>{{isEdit == true ? 'Change' : 'Add'}} URL</h2>
  <div class="container">
    <form [formGroup]="urlInput">
      <mat-form-field appearance="standard">
        <mat-label>Your Url</mat-label>
        <input matInput autocomplete="off" required formControlName="uploadURLInput" multiple>
        <mat-error *ngIf="urlInput.controls.uploadURLInput.hasError('required')">
          Url required
        </mat-error>
        <mat-error *ngIf="urlInput.controls.uploadURLInput.hasError('pattern')">
          Url Pattern Invalid
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <div class="d-flex flex-row justify-content-between">
    <button mat-button color="warn" (click)="closeURLDialogRef()"> Cancel</button>
    <button mat-button color="primary" (click)="isEdit == false ? addURLInput() : ChangeURL()" [nbSpinner]="isUploadURL"
      [disabled]="!urlInput.valid || isUploadURL">Save</button>
  </div>