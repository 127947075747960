import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NbOverlayConfig, NbPopoverComponent, NbPopoverDirective, NbPosition, NbTrigger } from '@nebular/theme';
import { Priority, TaskStatus } from 'src/app/modules/admin/task-management/task-board/task-board-lane/task-status.model';
import { RangeDate, TaskFilterProp } from 'src/app/modules/admin/task-management/task-board/task-board-lane/task/task.model';
import { TaskBoardService } from 'src/app/modules/admin/task-management/task-board/task-board.service';
import { TaskFilterType } from 'src/app/shared/contances/task-filter';
import dateFormat, { masks } from 'dateformat';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-popover-detail-task',
  templateUrl: './popover-detail-task.component.html',
  styleUrls: ['./popover-detail-task.component.scss']
})
export class PopoverDetailTaskComponent implements OnInit, OnDestroy {

  @Input() contextInput: any;
  @Input() titleHeader: string = null;
  @Input() funcClickDetail: (data: string) => void = null;
  constructor(
    //private nbPopOver: NbPopoverComponent,
    private taskService: TaskBoardService,
  ) {
    //this.contextInput = this.nbPopOver.context ?? this.contextInput;
    this.taskService.taskStatusList().pipe(takeUntil(this.destroy$)).subscribe(resp => { if (resp) { this.taskStatusList = resp; } });

    this.taskService.taskPriorityList().pipe(takeUntil(this.destroy$)).subscribe(resp => { if (resp) this.priority = resp });
  }

  events: any[] = [];
  priority: Priority[] = [];
  taskStatusList: TaskStatus[] = [];
  user;

  //map filter selected date
  defaultFilter = TaskFilterType;
  filterList: TaskFilterProp[] = [];
  //end
  private destroy$: Subject<void> = new Subject<void>();

  ngOnInit(): void {

    if (this.contextInput.events) {
      this.events = this.contextInput.events;
      this.addFilterValue(7, null, null, null);
      var year = this.contextInput.month + 1 == 0 ? this.contextInput.year - 1 : this.contextInput.year;
      var month = this.contextInput.month + 1 == 0 ? '12' : this.contextInput.month + 1

      const rangeDay = new RangeDate();
      rangeDay.endDate = dateFormat(new Date(year + "-" + month + "-" + this.contextInput.day), 'mm-dd-yyyy 23:59:59');
      rangeDay.startDate = dateFormat(new Date(year + "-" + month + "-" + this.contextInput.day), 'mm-dd-yyyy 0:00:00');
      this.addFilterValue(3, null, rangeDay, null);

      this.taskService.updateListTaskSeletedCalendar(this.contextInput.events.map(x => x.task));
      this.taskService.updateFilterTaskProp(this.filterList);
      this.taskService.updateViewModelObser('calendar');
    }
    else {
      this.events = this.contextInput;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openDialogTaskInline(event) {
  }

  //Coppy function task management start
  addFilterValue(
    filterType: number,
    value: null | number[],
    dateValue: null | RangeDate,
    searchValue?: null | string
  ) {
    if (this.defaultFilter.find(i => i.key === filterType) !== undefined) {
      const filterValue = this.defaultFilter.find(i => i.key === filterType);
      if (this.filterList.find(i => i.key === filterValue.key) !== undefined) {
        // if filter is exist in the filter lst
        const filterListValue = this.filterList.findIndex(i => i.key === filterValue.key);
        this.filterList[filterListValue].propertyValue = value === null ? null : value;
        this.filterList[filterListValue].rangeDateValue = dateValue === null ? null : dateValue;
        this.filterList[filterListValue].searchValue = searchValue === null ? null : searchValue;
      } else {
        // filter is not exist in the filter lst
        this.filterList.push({
          key: filterValue.key, propertyValue: value === null ? null : value,
          rangeDateValue: dateValue === null ? null : dateValue, searchValue: searchValue === null ? null : searchValue
        });
      }
      //2022-03-02 vuonglqn add start
      this.taskService.updateFilterTaskProp(this.filterList);
      //2022-03-02 vuonglqn add end
    }
  }
  removeFilterValue(filterType: number) {
    if (this.filterList.find(i => i.key === filterType) !== undefined) {
      const index = this.filterList.findIndex(i => i.key === filterType);
      this.filterList.splice(index, 1);
      //2022-03-02 vuonglqn add start
      this.taskService.updateFilterTaskProp(this.filterList);
      //2022-03-02 vuonglqn add end
    }
  }
  //Coppy function task management end
}
