import { Page } from "../../models/paging/page";

export class PagingTreeFilter extends Page {
    treeFilters?: string[];
}

export class PagingContactFilter extends PagingTreeFilter {
    contactTypeFilter?: number[];
    isMyContact?: boolean;
    isGridView?: boolean = false;
}

